import React, { forwardRef, useState } from 'react';
import "./VideoCard.css";
/* import { ThumbUpSharp } from "@material-ui/icons"; */
import YouTube from 'react-youtube';
import movieTrailer from "movie-trailer";

const base_url = "https://i2.ytimg.com/vi/";
const end_url = "/hqdefault.jpg";

const VideoCard = forwardRef(({ video }, ref) => {

    const [trailerUrl, setTrailerUrl] = useState("");

    const opts = {
        height: "50%",
        width: "100%",
        playerVars: {
            //https://developers.google.com/youtube/player_parameters
            autoplay: 0,
            modestbranding: 1,
            rel: 0,
            showinfo: 0,
        },
    };

    const handleClick = (video) => {
        if (trailerUrl) {
            setTrailerUrl("");
        } else {
            movieTrailer(video?.title || "")
                .then((url) => {
                    const urlParams = new URLSearchParams(new URL(video?.videohref_yt_dmq).search);
                    setTrailerUrl(urlParams.get("v"));
                })
                .catch((error) => console.log(error));
        }
    };

    return (
        <div ref={ref} className="videoCard">
            <img 
                key={video.id}
                onClick={() => handleClick(video)}
                src={`${base_url}${video.videoId_yt_dmq}${end_url}`}  alt={video.title}
            />
            <div className="videoCard__player">
                {trailerUrl && <YouTube videoId ={trailerUrl} opts={opts} />}
            </div>
            <p className="videoCard__author">
                {video.director}
            </p>
            <h2 className="videoCard__title">{video.title}</h2>
            <div>
            <p className="videoCard__description">
            {video.synopsis}
            </p>
            </div>
        </div>
    );
});

export default VideoCard;
