import React, { useRef } from 'react';
/* import { useHistory } from "react-router-dom"; */
import {
    sendPasswordResetEmail,
  } from "firebase/auth";
import "./ResetPasswordScreen.css";
import NavProfile from "../components/NavProfile";
import FooterLinks from "../components/Footer/FooterLinks";
import Social from "../components/Footer/SocialLinks";
import Copyright from "../components/Footer/Copyright";
import { auth } from "../firebase.js";
import { Link } from "react-router-dom";
import GoToTop from '../GoToTop';
import {Helmet} from "react-helmet";
import Head from "next/head";

function ResetPasswordScreen() {

    const emailRef = useRef(null);
    /* const passwordRef = useRef(null);
    const history = useHistory(); */

    const forgotPassword = (emailRef) => {
        return sendPasswordResetEmail(auth, emailRef);
      };

    const forgotPasswordHandler = () => {
        const email = emailRef.current.value;
        if (email)
          forgotPassword(email).then(() => {
            emailRef.current.value = "";
          });
      };

  return (
    <main>

        <Head>
            <title>Cambio de password : Teleboing</title>
            <meta 
            name='description'
            content="Plataforma de streaming de cortometrajes gratis y bajo suscripción que ofrece acceso rápido, sencillo y personalizado a una amplia variedad de cortometrajes, películas, documentales, programas originales y series basadas en historias reales, drama, comedia, acción, terror y suspenso, y que pueden ser vistas en cualquier pantalla de cualquier dispositivo conectado a internet. Todo lo que quieras ver, sin límites, sin publicidad, a un costo muy accesible."
            />
        </Head>

        <Helmet>
            <title>Cambio de password : Teleboing</title>
            <meta 
            name='description'
            content="Cambio de password para acceder al servicio de entretenimiento audiovisual de Teleboing."
            />
            <meta 
            name='image' 
            content="https://firebasestorage.googleapis.com/v0/b/teleboing.appspot.com/o/resources%2Fcontent%2Fteleboing_cover08.webp?alt=media&token=f5d12ee1-3d72-4f00-8f20-e2687369315a"
            />
        </Helmet>

        <NavProfile />

        <header className="banner"
            style={{
                backgroundSize: "cover",
                backgroundImage: `linear-gradient(to top, black 5%, transparent 30%), linear-gradient(to top, #00001b, transparent), 
                     url(
                    "https://firebasestorage.googleapis.com/v0/b/teleboing.appspot.com/o/resources%2Fcontent%2Fteleboing_cover08.webp?alt=media&token=f5d12ee1-3d72-4f00-8f20-e2687369315a"
                )`, 
                backgroundPosition: "center center",
            }}>

            <>
            <div className="resetpassword">

            <div className="resetpassword__container">
                <div className="resetpassword__msg">
                    <p>cambia tu password</p>
                </div>

                <form >
                    <input  ref={emailRef} type="email" placeholder="email" id="email" />

                    <button 
                    className="resetpassword__button"
                    type="submit" onClick={forgotPasswordHandler}>
                        Cambia tu password
                    </button>
                    <p>Te enviaremos un enlace para cambiar tu password, por favor revisa tu bandeja de correo. Busca en tu bandeja de spam por si no lo encuentras.</p>
                </form>

                <div className="resetpassword__help">
                    <div className="resetpassword__help__signup">
                    <p >¿Ya tienes password?</p>
                    <Link to="/signin">
                    <span>Inicia sesión</span>
                    </Link>
                    </div>

                    <div className="resetpassword__help__signup">
                    <p >¿Aún no tienes cuenta?</p>
                    <Link to="/signup">
                    <span >Suscríbete aquí</span>
                    </Link>
                    </div>
                </div>

            </div>
            </div>
            </>
        </header>
        
        <Social />
        <FooterLinks />
        <Copyright />
        <GoToTop />
    </main>
  )
}

export default ResetPasswordScreen;