import React, { useState, useEffect } from 'react';
import "./RowCortoEstreno.css";
import YouTube from 'react-youtube';
import movieTrailer from "movie-trailer";
import { NavigateBeforeSharp, NavigateNextSharp, TimelapseSharp, FlagSharp } from "@material-ui/icons";
import Skeleton from "./skeleton/Skeleton";
import { cortometrajes } from "../assets/catalogoTLBNG";
import { bgcover } from "../assets/bgcover";
import { Link as ScrollVideo } from "react-scroll";

const base_url = "https://i2.ytimg.com/vi/";
const end_url = "/hqdefault.jpg";

function RowCortoEstreno({ title, fetchUrl, isLargeRow }) {
    const [creation,setCreation] = useState([]);
    const [trailerUrl, setTrailerUrl] = useState("");
    const [scrollX, setScrollX] = useState(0); //-50
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        async function fetchData() {
            setIsLoading(true);
            setCreation(cortometrajes
            );
            setIsLoading(false);
            return cortometrajes;
        }
        fetchData();

    }, []);

    const opts = {
        height: "390",
        width: "100%",
        playerVars: {
            //https://developers.google.com/youtube/player_parameters
            autoplay: 1,
            modestbranding: 1,
            rel: 0,
            showinfo: 0,
        },
    };

    const handleClick = (video) => {
        if (trailerUrl) {
            setTrailerUrl("");
        } else {
            movieTrailer(video?.title || "TELEBOING en vivo")
                .then((url) => {
                    const urlParams = new URLSearchParams(new URL(video?.videohref_yt_dmq).search);
                    setTrailerUrl(urlParams.get("v"));
                })
                .catch((error) => console.log(error));
        }
    };

    const handleLeftArrow = () => {
        let x = scrollX + Math.round(window.innerWidth / 2);
        if(x > 0) {
            x=0;
        }
        setScrollX(x);
    }

    const handleRightArrow = () => {
        let x = scrollX - Math.round(window.innerWidth / 2);
        let listW = creation.length * 420;
        if ((window.innerWidth - listW) > x) {
            x = (window.innerWidth - listW) - 500;
        }
        setScrollX(x);
    }

    function truncate(str, n) {
        return str?.length > n ? str.substr(0, n-1) + '...' : str;
    }

    return (
        <div 
        className="rowcortoestreno"
            style={{
            backgroundSize: "cover",
            backgroundImage: `linear-gradient(to top, black 10%, transparent 60%), linear-gradient(to right, #0c0810, transparent), linear-gradient(to bottom, black 35%, transparent 90%), url(${bgcover[8]?.url})`,
            backgroundPosition: "top center",
            }}
        >
            <div className="rowcortoestreno__container">
            <h2> {title} </h2>
            </div>
            <div className="rowcortoestreno__poster__left" onClick={handleLeftArrow}>
                < NavigateBeforeSharp style={{fontSize: 50}} />
            </div>
            <div className="rowcortoestreno__poster__right" onClick={handleRightArrow}>
                < NavigateNextSharp style={{fontSize: 50}} />
            </div>
            <div className="rowcortoestreno__posters" style={{marginLeft: scrollX/* , width: creation.length * 150 */}}>

            {isLoading ? (
            <Skeleton type="feed" />
            ) : (
            creation.filter((video, key) => 
            /* (video.genres.toLowerCase()).includes("comedia")&& */(video.year===2021)&&(video.status.toLowerCase()).includes("active")
            ).map((video, key) => (
                    <div className = {`rowcortoestreno__poster__props`} key={video.id}>
                        
                        <ScrollVideo to="video" offset={1100} smooth={true} delay={300} style={{ textDecoration: 'none' }}>
                        <img 
                            key={video.id}
                            onClick={() => handleClick(video)}
                            className= {`rowcortoestreno__poster`}
                            src={`${base_url}${isLargeRow ? video?.videoId_yt_dmq : video?.videoId_yt_dmq || video?.posterurl }${end_url}`} alt={video.title}
                            
                        />
                        </ScrollVideo>
                        
                        <p className= {`rowcortoestreno__poster__title`}>{video.title} 
                        &nbsp;&nbsp; • &nbsp;&nbsp; 
                        <FlagSharp style={{fontSize:"20px", paddingRight:"5px", opacity:"0.7"
                        }} />
                        {video.country} 
                        &nbsp;&nbsp; • &nbsp;&nbsp;
                        <TimelapseSharp style={{fontSize:"20px", paddingRight:"5px", opacity:"0.7"
                        }}/>
                        00:{video.duration} </p>
                    
                        <div className = {`rowcortoestreno__poster__details`}>
                            <h2 className= {`rowcortoestreno__poster__description`}>
                            {truncate(video?.synopsis, 120)}
                            </h2>
                        </div>
                    </div>
                )).reverse()
            )}

            </div>
            <div id='video' className="rowcortoestreno__player">
            {trailerUrl && <YouTube videoId ={trailerUrl} opts={opts} />}
            </div>

        </div>
    );
}

export default RowCortoEstreno;
