import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import "./Column.css";
/* import YouTube from 'react-youtube';
import movieTrailer from "movie-trailer"; */
import Skeleton from "./skeleton/Skeleton";
import { cortometrajes } from "../assets/catalogoTLBNG";

const base_url = "https://i2.ytimg.com/vi/";
const end_url = "/hqdefault.jpg";

function Column({ /* title,  */fetchUrl, isLargeRow }) {
    const [creation,setCreation] = useState([]);
    /* const [trailerUrl, setTrailerUrl] = useState(""); */
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        async function fetchData() {
            setIsLoading(true);
            setCreation(cortometrajes);
            setIsLoading(false);
            return cortometrajes;
        }
        fetchData();
    }/* , [fetchUrl] */);

    /* const opts = {
        height: "100%",
        width: "400px",
        playerVars: {
            autoplay: 1,
            modestbranding: 1,
            rel: 0,
            showinfo: 0,
        },
    };

    function truncate(str, n) {
        return str?.length > n ? str.substr(0, n-1) + '...' : str;
    } */

    return (
        <div className="column">
            {/* <div className="column__container">
            </div> */}
            <div className="column__posters">
            {isLoading ? (
            <Skeleton type="feed" />
            ) : (
                creation
                .filter((video, key) => 
                (video.status.toLowerCase()).includes("active")&&(video.fomo===1))
                .map((video, key) => (
                    <div className = {`column__poster__props`} key={video.id}>
                        <Link to={`/watch/${video?.id}`}>
                        <img 
                            key={video.id}
                            className= {`column__poster`}
                            src={`${base_url}${isLargeRow ? video.videoId_yt_dmq : video.videoId_yt_dmq}${end_url}`} alt={video.title}
                        />
                        </Link>
                        <p className= {`column__poster__title`}>{video.title}
                        </p>
                    
                        <div className = {`column__poster__details`}>

                        </div>
                    </div>
                ))
            )}
            </div>
        </div>
    );
}

export default Column;
