export const bgcover = 
[
    {
        "id":1,
        "url":"https://firebasestorage.googleapis.com/v0/b/teleboing.appspot.com/o/resources%2Fcontent%2Fteleboing_BGcover01.webp?alt=media&token=2b94af33-714a-448d-b45c-efcb67fb738c"
    },
    {
        "id":2,
        "url":"https://firebasestorage.googleapis.com/v0/b/teleboing.appspot.com/o/resources%2Fcontent%2Fteleboing_BGcover02.webp?alt=media&token=e31f11fb-2064-453e-af67-4a4f8c662384"
    },
    {
        "id":3,
        "url":"https://firebasestorage.googleapis.com/v0/b/teleboing.appspot.com/o/resources%2Fcontent%2Fteleboing_BGcover03.webp?alt=media&token=59e09ff1-c1f1-4f65-834e-a970975310d8"
    },
    {
        "id":4,
        "url":"https://firebasestorage.googleapis.com/v0/b/teleboing.appspot.com/o/resources%2Fcontent%2Fteleboing_BGcover04.webp?alt=media&token=1c1038ad-ca60-4362-ad57-856b705b89c5"
    },
    {
        "id":5,
        "url":"https://firebasestorage.googleapis.com/v0/b/teleboing.appspot.com/o/resources%2Fcontent%2Fteleboing_BGcover05.webp?alt=media&token=48f8d426-a4cd-4ed2-ae8d-9660b5c78784"
    },
    {
        "id":6,
        "url":"https://firebasestorage.googleapis.com/v0/b/teleboing.appspot.com/o/resources%2Fcontent%2Fteleboing_BGcover06.webp?alt=media&token=961a8f58-73fe-4167-80e8-a2b801e01ce3"
    },
    {
        "id":7,
        "url":"https://firebasestorage.googleapis.com/v0/b/teleboing.appspot.com/o/resources%2Fcontent%2Fteleboing_BGcover07.webp?alt=media&token=6cea064e-c789-4a48-8117-3e6b11650afe"
    },
    {
        "id":8,
        "url":"https://firebasestorage.googleapis.com/v0/b/teleboing.appspot.com/o/resources%2Fcontent%2Fteleboing_BGcover08.webp?alt=media&token=798d3cff-47e8-4e7b-81b0-b139609dceee"
    },
    {
        "id":9,
        "url":"https://firebasestorage.googleapis.com/v0/b/teleboing.appspot.com/o/resources%2Fcontent%2Fteleboing_cover09.webp?alt=media&token=b307d97b-9146-421c-95fd-ebecbd3961bd"
    }
]