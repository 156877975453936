import React from 'react';
import './HomeScreen.css';
import RowCortoTendencia from '../components/RowCortoTendencia';
import RowCortoEstreno from '../components/RowCortoEstreno';
import RowCortoAccion from '../components/RowCortoAccion';
import RowCortoComedia from '../components/RowCortoComedia';
import RowCortoThriller from '../components/RowCortoThriller';
import RowCortoTerror from '../components/RowCortoTerror';
import RowCortoDrama from '../components/RowCortoDrama';
import RowCortoFiccion from '../components/RowCortoFiccion';
import RowCortoEspecial from '../components/RowCortoEspecial';
import { LazyLoadComponent } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import Banner from '../components/Banner';
import Nav from '../components/Nav';
import OriginalsRow from '../components/OriginalsRow';
import FooterLinks from "../components/Footer/FooterLinks";
import Social from "../components/Footer/SocialLinks";
import Copyright from "../components/Footer/Copyright";
import NavBottom from "../components/NavBottom";
import GoToTop from '../GoToTop';
import { Helmet } from "react-helmet-async";

function HomeScreen() {
  return (
  <>
    <Helmet>
      <title>Home : Teleboing</title>
      <meta
        name="title"
        content="Home : Teleboing"
      />
      <meta
        name="description"
        content="Plataforma de streaming de cortometrajes gratis y bajo suscripción que ofrece acceso rápido, sencillo y personalizado a una amplia variedad de cortometrajes, películas, documentales, programas originales y series basadas en historias reales, drama, comedia, acción, terror y suspenso, y que pueden ser vistas en cualquier pantalla de cualquier dispositivo conectado a internet. Todo lo que quieras ver, sin límites, sin publicidad, a un costo muy accesible."
        />
      <link
        rel='canonical'
        href='/'
      />
      <meta 
        name='image' 
        content="https://firebasestorage.googleapis.com/v0/b/teleboing.appspot.com/o/resources%2Fcontent%2Fteleboing1-feat1-1.webp?alt=media&token=0a9957dc-c2db-4f29-b3cf-a8b077f80d04"
      />
    </Helmet>

    <div className="homeScreen">
      <Nav />
      <Banner />
      <LazyLoadComponent>
        <RowCortoTendencia 
          title="Es tendencia en teleboing" 
        />
      </LazyLoadComponent>
      <LazyLoadComponent>
        <RowCortoEstreno 
          title="Películas en estreno" 
        />
      </LazyLoadComponent>
      <LazyLoadComponent>
        <RowCortoEspecial 
          title="ESPECIAL : cortos peruanos" 
        />
      </LazyLoadComponent>
      <LazyLoadComponent>
        <RowCortoAccion 
          title="Acción y Aventura" 
        />
      </LazyLoadComponent>
      <LazyLoadComponent>
        <RowCortoComedia 
          title="Comedia y Humor Negro" 
        />
      </LazyLoadComponent>
      <LazyLoadComponent>
        <RowCortoThriller 
          title="Thriller y Suspenso" 
        />
      </LazyLoadComponent>
      <LazyLoadComponent>
        <RowCortoTerror 
          title="Terror y Horror" 
        />
      </LazyLoadComponent>
      <LazyLoadComponent>
        <RowCortoDrama 
          title="Drama y Documentales" 
        />
      </LazyLoadComponent>
      <LazyLoadComponent>
        <RowCortoFiccion 
          title="Ciencia Ficción y Fantasía" 
        />
      </LazyLoadComponent>
      <LazyLoadComponent>
        <OriginalsRow />
      </LazyLoadComponent>
      {/* <LazyLoadComponent>
        <CreadoresRow />
      </LazyLoadComponent> */}
      {/* <Row 
        title="Humor subido recientemente" 
        fetchUrl={requests.fetchHumor} 
        isLargeRow={true}
      />
      <Row 
        title="Noticias recientes" 
        fetchUrl={requests.fetchNoticias} 
        isLargeRow={true}
      /> */}
      <Social />
      <FooterLinks />
      <Copyright />
      <GoToTop />
      <NavBottom />
    </div>
  </>
  );
}

export default HomeScreen;
