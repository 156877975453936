import React, { useState, useEffect } from "react";
import "./Nav.css";

import { getAuth } from "firebase/auth";
import  firebaseApp from "../firebase";
import 'firebase/compat/storage';

import { Link } from "react-router-dom";

import logo from "../assets/teleboing.svg";


function Nav() {
    /* const auth = getAuth(firebaseApp); */
    const [show, handleShow] = useState(false);

    const transitionNavBar = () => {
        if (window.scrollY > 100) {
            handleShow(true);
        } else {
            handleShow(false);
        }
    };

    useEffect (() => {
        window.addEventListener("scroll", transitionNavBar);
        return () => window.removeEventListener("scroll", transitionNavBar);
    }, []);


    return (
        <div className={`nav ${show && "nav__black"}`}>
            <div className="nav__contents">
                <Link to="/" style={{ textDecoration: 'none' }}>
                <div className='nav__logo'>
                    <img 
                    style={{ width:"120px" }}
                    src={logo}
                    alt="TELEBOING" title="made for fun"
                    />
                </div> 
                </Link>

            </div>
        </div>
    )
}

export default Nav;
