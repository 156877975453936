import React, { useEffect, useState, useContext } from 'react';
import { Link, useHistory } from "react-router-dom";
import { PayPalScriptProvider, PayPalButtons } from '@paypal/react-paypal-js';
import  firebaseApp from "../firebase";
import 'firebase/compat/storage';
import { serverTimestamp } from "firebase/firestore";
import "firebase/compat/firestore";
import { SubsIdContext } from '../context/SubsIdContext';
import NavProfile from "../components/NavProfile";
import FooterLinks from "../components/Footer/FooterLinks";
import Social from "../components/Footer/SocialLinks";
import Copyright from "../components/Footer/Copyright";
import "./PlanScreen.css";
import GoToTop from '../GoToTop';
import {Helmet} from "react-helmet";
import Head from "next/head";

function PlanScreen() {
    const db = firebaseApp.firestore();
    const [subsId, setSubsId] = useContext(SubsIdContext);
    const [clientId, setClientId] = useState('');
    const [readyPush, setReadyPush] = useState(false);
    const history = useHistory();

    useEffect(() => { 
        /* setClientId('AZIZ1hXuAYNQRDBZ6Gsfrz60Ta0VRXB6XXsfUiwsid59LsyUMZ1lInz7wXcX8nRoLc6btRKjgdLQ7EuI'); */
        setClientId('AWd42cFKQ016LJbipFJ2gsy5LP3spyMBxA4iUloIWASAZGPsCsG-RwqF7uvh9ecz2zojfj6RjJO5GPWd');
        if (readyPush) {
          history.push("/newuser");
        }
    }, [readyPush]);
  
      const createSubscription1 = (data, actions) => {
        console.log(data.value);
        return actions.subscription.create({
          plan_id: 'P-0U7979735N114174BMPAZIZY'
        });
      };

      const createSubscription3 = (data, actions) => {
        console.log(data.value);
        return actions.subscription.create({
          plan_id: 'P-9LH6494328422990EMPAZMHA'
        });
      };

      const createSubscription6 = (data, actions) => {
        console.log(data.value);
        return actions.subscription.create({
          plan_id: 'P-5X9228287D077840BMPAZOSA'
        });
      };
    
      const onApprove = async (data, actions) => {
        /* console.log('Subscription approved:', data); */

        const orderId = data.orderID;
        const subsId = data.subscriptionID;

        await db.collection("subsDetails")
          .add({
            orderId:orderId,
            subsId:subsId,
            timestamp: serverTimestamp(),
          })
          .then(() => {
            /* console.log("Detalles de pago enviados a firebase"); */
            setReadyPush(true);
            setSubsId(data.subscriptionID);
          })
          .catch((error) => {
            alert(error.message);
        });
      };

      const onError = (err) => {
        console.error('Subscription error:', err);
        history.push("/error");
      };

    return (
    <main>
        <Head>
            <title>Plan de pago : Suscripción a Teleboing</title>
            <meta 
            name='description'
            content="Plataforma de streaming de cortometrajes gratis y bajo suscripción que ofrece acceso rápido, sencillo y personalizado a una amplia variedad de cortometrajes, películas, documentales, programas originales y series basadas en historias reales, drama, comedia, acción, terror y suspenso, y que pueden ser vistas en cualquier pantalla de cualquier dispositivo conectado a internet. Todo lo que quieras ver, sin límites, sin publicidad, a un costo muy accesible."
            />
        </Head>

        <Helmet>
            <title>Plan de pago : Suscripción a Teleboing</title>
            <meta 
            name='description'
            content="Plataforma de streaming de cortometrajes gratis y bajo suscripción que ofrece acceso rápido, sencillo y personalizado a una amplia variedad de cortometrajes, películas, documentales, programas originales y series basadas en historias reales, drama, comedia, acción, terror y suspenso, y que pueden ser vistas en cualquier pantalla de cualquier dispositivo conectado a internet. Todo lo que quieras ver, sin límites, sin publicidad, a un costo muy accesible."
            />
            <meta 
            name='image' 
            content="https://firebasestorage.googleapis.com/v0/b/teleboing.appspot.com/o/resources%2Fcontent%2Fteleboing_cover08.webp?alt=media&token=f5d12ee1-3d72-4f00-8f20-e2687369315a"
            />
        </Helmet>

        <NavProfile />

        <header className="banner"
            style={{
                backgroundSize: "cover",
                backgroundImage: `linear-gradient(to top, black 5%, transparent 30%), linear-gradient(to top, #00001b, transparent), 
                     url(
                    "https://firebasestorage.googleapis.com/v0/b/teleboing.appspot.com/o/resources%2Fcontent%2Fteleboing_cover08.webp?alt=media&token=f5d12ee1-3d72-4f00-8f20-e2687369315a"
                )`, 
                backgroundPosition: "center center",
                height:"100%"
            }}>

        <>
        <div>

        <PayPalScriptProvider options={{ 'client-id': 'AWd42cFKQ016LJbipFJ2gsy5LP3spyMBxA4iUloIWASAZGPsCsG-RwqF7uvh9ecz2zojfj6RjJO5GPWd', vault: true }}>

        <div className="planscreen">
            <div className="planscreen__container">
                <div className="planscreen__msg">
                    <span>Paso 1 de 2</span>
                    <p>Elige un plan de suscripción:</p>
                </div>

                <div className="planscreen__options">
                <ul>
                <button /* onClick={mes1} */>
                    <p>Paga 1 mes</p>
                    <p className="planscreen__options__price"><span>USD</span> $1<span>.99</span></p>
                    <PayPalButtons
                        key="1"
                        style={{ layout: 'horizontal' }}
                        createSubscription={createSubscription1}
                        onApprove={onApprove}
                        onError={onError}
                    />
                </button>
                <button /* onClick={mes2} */>
                    <p>Paga 3 meses</p>
                    <p className="planscreen__options__price"><span>USD</span> $4<span>.99</span></p>
                    <PayPalButtons
                        key="1"
                        style={{ layout: 'horizontal' }}
                        createSubscription={createSubscription3}
                        onApprove={onApprove}
                        onError={onError}
                    />
                </button>
                <button /* onClick={mes3} */>
                    <p>Paga 6 meses</p>
                    <p className="planscreen__options__price"><span>USD</span> $9<span>.99</span></p>
                    <PayPalButtons
                        key="1"
                        style={{ layout: 'horizontal' }}
                        createSubscription={createSubscription6}
                        onApprove={onApprove}
                        onError={onError}
                    />
                </button>
                </ul>
                </div>

                <div className="planscreen__punchline">
                  <p>Una dosis concentrada de entretenimiento. ¡Te engancharás en segundos!</p>
                </div>

                <div className="planscreen__help">
                    <p >¿Ya tienes cuenta?</p>
                    <Link to="/signin">
                    <span >Ingresa aquí</span>
                    </Link>
                </div>

            </div>
        </div>

        </PayPalScriptProvider>

        </div>
        </>
        </header>
        
        <Social />
        <FooterLinks />
        <Copyright />
        <GoToTop />
      
    </main>
  )
}

export default PlanScreen;