import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { getAuth } from "firebase/auth";
import  firebaseApp, {storage} from "../firebase";
import { serverTimestamp } from "firebase/firestore";
import { ref, uploadBytes } from "firebase/storage";
import 'firebase/compat/storage';
import EditSharpIcon from '@material-ui/icons/EditSharp';
import "./ProfileScreen.css";
import Nav from "../components/Nav";
import FooterLinks from "../components/Footer/FooterLinks";
import Social from "../components/Footer/SocialLinks";
import Copyright from "../components/Footer/Copyright";
import NavBottom from "../components/NavBottom";
import GoToTop from '../GoToTop';
import Column from '../components/Column';
import { Helmet } from "react-helmet-async";


function ProfileScreen() {
    
    const auth = getAuth(firebaseApp);
    const user = auth.currentUser;
    const db = firebaseApp.firestore();
    /* const storage = getStorage(firebaseApp); */
    const collectionRef = db.collection('subsDetails');
    const query = collectionRef.where('email', '==', user?.email);
    
    const [ paypalAccessToken, setPaypalAccessToken ] = useState("");//eslint-disable-next-line
    const [ datauser, setDatauser ] = useState();
    const [ datasub, setDatasub ] = useState();
    const [ subId, setSubId ] = useState("");//eslint-disable-next-line
    const [ imguser, setImguser ] = useState("");
    const [ firstname, setFirstname ] = useState();
    const [ lastname, setLastname ] = useState();
    const [ phone, setPhone ] = useState();
    /* const [ birthdate, setBirthdate ] = useState(); */
    const [ country, setCountry ] = useState();
    const [activeTab, setActiveTab] = useState(0);

    const handleTabClick = (index) => {
        setActiveTab(index);
    };

    const getfirstname = (e) => {
        setFirstname(e.target.value)
    }

    const getlastname = (e) => {
        setLastname(e.target.value)
    }

    const getphone = (e) => {
        setPhone(e.target.value)
    }

    /* const getbirthdate = (e) => {
        setBirthdate(e.target.value)
    } */

    const getcountry = (e) => {
        setCountry(e.target.value)
    }

    //cargar foto de perfil
    /* let img = document.getElementById('img'); */
    let file = {};

    function chooseFile(e) {
        file = e.target.files[0];
    }

    async function imgButtonPressed() {
        try {
          const storageRef = ref(storage, 'users/' + user.uid + '/profile.jpg');
          await uploadBytes(storageRef, file);
          window.location.reload(false);
        } catch (error) {
          console.log(error.message);
        }
      }

    //ingresar y actualizar datos personales.
    const setData = async (e) => {
        e.preventDefault();
        try {
            /* const {uid} = user.uid; */
            const userDmq = {
                firstname: firstname,
                lastname: lastname,
                phone: phone,
                /* birthdate: birthdate, */
                country: country,
                email: user.email,
                user_id: user.uid,
                timestamp: serverTimestamp(),
            };
            await db.collection('users').doc(user.uid).set(userDmq);
            window.location.reload(false);
          } catch(err) {
            console.log(err);
          }
    };

    //visualizar los datos ingresados del usuario.
    const getData = async () => {
        try {
          const documentSnapshot = await db
            .collection('users')
            .doc(user.uid)
            .get();
          const userData = documentSnapshot.data();
          setDatauser(userData);
        } catch(err) {
            console.log(err);
        }
    };

    useEffect(() => {
        getData();

        if (user) {
            const storage = firebaseApp.storage();
            const storageRef = storage.ref();
            const imagesRef = storageRef.child(`users/${user.uid}/profile.jpg`);
      
            imagesRef
              .getDownloadURL()
              .then((url) => {
                setImguser(url);
              })
              .catch((error) => {
                console.log('Error getting image URL:', error);
              });
          }
        
    }, [user]);

    /* useEffect(() => {
    console.log("imguser:", imguser);
    }, [imguser]) */

    //visualizar los datos ingresados del suscriptor.
    const getSub = async () => {
        try {
          const querySnapshot = await query.get();
          querySnapshot.forEach((doc) => {
            const data = doc.data();
            setDatasub(data);
            setSubId(doc.id);
          });
        } catch (err) {
          console.log(err);
        }
      };
    
    useEffect(() => {
    getSub();
    }, []);

      /* console.log("Datasub:", datasub); */

    const handleCancelSubscription = async () => {
        // crear access token
        var myHeadersAT = new Headers();
        myHeadersAT.append("Authorization", "Basic QVdkNDJjRktRMDE2TEpiaXBGSjJnc3k1TFAzc3B5TUJ4QTRpVWxvSVdBU0FaR1BzQ3NHLVJ3cUY3dXZoOWVjejJ6b2pmajZSakpPNUdQV2Q6RUZwOWRMdVhvSzVhRXdOaUZHUmhfS0t6TmNKQnphUE0ya0t0U01fa2EtcWZndHYzVlhidGlIS3c5aVFjU0NmQUNNNjZMR0RlZnR3MmF2M3Y=");
        myHeadersAT.append("Content-Type", "application/x-www-form-urlencoded");
            
        var urlencodedAT = new URLSearchParams();
        urlencodedAT.append("grant_type", "client_credentials");
        
        var requestOptionsAT = {
        method: 'POST',
        headers: myHeadersAT,
        body: urlencodedAT,
        redirect: 'follow'
        };
        
        fetch ("https://api-m.paypal.com/v1/oauth2/token", requestOptionsAT)
        .then(responseAT => responseAT.json())
        .then(resultAT => {
            /* console.log(typeof resultAT.access_token);
            console.log("Token:", resultAT.access_token); */
            setPaypalAccessToken(resultAT.access_token);

            // Cancelación de suscripción.
            try {
                const cancelUrl = `https://api-m.paypal.com/v1/billing/subscriptions/${datasub?.subsId}/cancel`;
                const cancelResponse = axios.post(cancelUrl, null, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${resultAT.access_token}`,
                },
                });

                const cancellationData = cancelResponse.data;

                alert("Tu suscripción ha sido cancelada. En breve recibirás un email.");

                /* console.log('Subscription Cancelled:', cancellationData); */

                query.get().then((querySnapshot) => {
                    const updatePromises = [];
                    
                    querySnapshot.forEach((doc) => {
                      const documentRef = collectionRef.doc(doc.id);

                      updatePromises.push(
                      documentRef.update({
                        cancel_date: serverTimestamp(),
                      })
                      );
                    });

                    Promise.all(updatePromises)
                    .then(() => {
                        console.log("")
                    })
                    .catch((error) => {
                        console.error('Error updating documents:', error);
                    });
                });
                return cancellationData;
            } catch (error) {
                console.error('Error canceling subscription:', error);
            }
            })
            .catch(error => console.log('error', error));
    };

    return (
        <div className="profile">

        <Helmet>
            <title>Profile : Teleboing</title>
            <meta
                name="title"
                content="Profile : Teleboing"
            />
            <meta
                name="description"
                content=""
                />
            <link
                rel='canonical'
                href='/'
            />
            <meta 
                name='image' 
                content="https://firebasestorage.googleapis.com/v0/b/teleboing.appspot.com/o/resources%2Fcontent%2Fteleboing1-feat1-1.webp?alt=media&token=0a9957dc-c2db-4f29-b3cf-a8b077f80d04"
            />
        </Helmet>

        <Nav />

        <div className="tabs-container">
        <div className="tabs-header">
            <button
            className={`tab ${activeTab === 0 ? 'active' : ''}`}
            onClick={() => handleTabClick(0)}
            >
            Tus datos personales
            </button>
            { datasub?.status === "ACTIVE" && 
                <button
                className={`tab ${activeTab === 1 ? 'active' : ''}`}
                onClick={() => handleTabClick(1)}
                >
                Gestiona tu suscripción
                </button>
            }
            <button
            className={`tab ${activeTab === 2 ? 'active' : ''}`}
            onClick={() => handleTabClick(2)}
            >
            Películas recomendadas
            </button>
        </div>
        <div className="tab-content">
            {activeTab === 0 && 
            <div className="profile__container">
                <div className="profile__data">
                    <form className="profile__data__img">
                        <img 
                        src= {imguser} 
                        id="imagen" 
                        alt="" /* {datauser && datauser.firstname} */
                        onError={(e)=>{e.target.onerror = null; e.target.src="https://firebasestorage.googleapis.com/v0/b/teleboing.appspot.com/o/resources%2Fcontent%2FTLBNGbrand_tshirt_square.webp?alt=media&token=2f23ddfe-d381-4f94-a829-fe162ea4a004"}} />
                        <input type="file"
                        style={{ color:"white", display:"flex", justifyContent:"center", margin:"0 auto" }}
                        onChange={chooseFile} />
                        <button onClick={imgButtonPressed} >Cargar foto de perfil</button>
                        <p>email: {user.email}</p>
                        <p>Id: {datasub?.subsId || ""}</p>
                    </form>
                    <form key={ user.uid } className="profile__data__updated">
                    <ul>
                        <li>
                            <p>{(datauser && datauser.firstname) || (datasub?.subscriber?.name?.given_name)}</p>
                            <EditSharpIcon style={{size:50}} />
                            <input onChange={getfirstname} id="firstname" type="text" placeholder="Nombre"></input>
                        </li>
                        
                        <li>
                            <p>{(datauser && datauser.lastname) || (datasub?.subscriber?.name?.surname)}</p>
                            <EditSharpIcon />
                            <input onChange={getlastname} id="lastname" type="text" placeholder="Apellido(s)"></input>
                        </li>
                        <li>
                            <p>{datauser && datauser.phone}</p>
                            <EditSharpIcon />
                            <input onChange={getphone} id="phonenumber" type="tel" placeholder="Teléfono"></input>
                        </li>
                        {/* <li>
                            <p>{datauser && datauser.birthdate}</p>
                            <EditSharpIcon />
                            <input onChange={getbirthdate} id="birthdate" type="date" placeholder="Fecha de nacimiento"></input>
                        </li> */}
                        <li>
                            <p>{datauser && datauser.country}</p>
                            <EditSharpIcon />
                            <input onChange={getcountry} id="country" type="text" placeholder="País de residencia(s)"></input>
                        </li>
                        <p style={{ fontSize:'1rem', fontWeight:400, padding:'20px 0', textAlign:"center" }}>Por favor ingresa todas las casillas con información nueva o confirma la existente. </p>
                        
                        <button id={ user.uid } onClick={ setData } type="submit">Actualizar mis datos</button>
                    </ul>
                    </form>
                </div>
            </div>}

            {activeTab === 1 && datasub?.status === "ACTIVE" && 
                <div className='profile__sub__data'>
                <ul>
                    <li>
                        <p>Siguiente pago:<span> {datasub?.billing?.next_billing_time} </span></p>
                    </li>
                    <li>
                        <p>Monto:<span> {datasub?.billing?.last_payment?.amount?.currency_code} {datasub?.billing?.last_payment?.amount?.value} </span></p>
                    </li>
                    <li>
                        <p>Último pago:<span> {datasub?.billing?.last_payment?.time} </span></p>
                    </li>
                </ul>
                <button className='profile__sub__cancel' onClick={handleCancelSubscription}>Cancela tu Suscripción</button>
            </div>}

            {activeTab === 2 && 
                <div className="profile__interestingvideos">
                    <Column 
                    />
                </div>
            }
        </div>
        </div>
        <Social />
        <FooterLinks />
        <Copyright />
        <GoToTop />
        <NavBottom />
    </div>
    )
}

export default ProfileScreen;