import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./Banner.css";
import YouTube from 'react-youtube';
import movieTrailer from "movie-trailer";
import { cortometrajes } from "../assets/catalogoTLBNG";
import { Link as ScrollVideo } from "react-scroll";


function Banner() {

    const [creation, setCreation] = useState([]);
    const [trailerUrl, setTrailerUrl] = useState("");

    useEffect(() => {
        let isMounted = true;
    
        async function fetchData() {
          const newArray = cortometrajes.filter(
            (videocorto) =>
              videocorto.status.toLowerCase().includes("active") &&
              videocorto.fomo === 1
          );
    
          if (isMounted) {
            setCreation(
              newArray[Math.floor(Math.random() * newArray.length - 1)]
            );
          }
        }
    
        fetchData();
    
        return () => {
          isMounted = false;
        };
    }, []);
    
    /* var newArray = cortometrajes.filter(function (videocorto)
    {
      return (videocorto.status.toLowerCase()).includes("active")&&(videocorto.fomo===1);
    }); 

    useEffect(() => {
         async function fetchData() {
            
            setCreation(
                newArray [
                    Math.floor(Math.random() * newArray.length - 1)
                ]
            );
            return newArray;
        }
        fetchData();
    }, []); */
    
    function truncate(str, n) {
        return str?.length > n ? str.substr(0, n-1) + '...' : str;
    }

    const opts = {
        height: "390",
        width: "100%",
        playerVars: {
            //https://developers.google.com/youtube/player_parameters
            autoplay: 1,
            modestbranding: 1,
            rel: 0,
            showinfo: 0,
        },
    };

    const handleClick = (creation) => {
        if (trailerUrl) {
            setTrailerUrl("");
        } else {
            movieTrailer(creation?.title || creation?.videoId_yt_dmq || "")
                .then((url) => {
                    const urlParams = new URLSearchParams(new URL(creation?.videohref_yt_dmq).search);
                    setTrailerUrl(urlParams.get("v"));
                })
                .catch((error) => console.log(error));
        }
    };
        
    return (
    <div>        
        <header className="banner__home"
            style={{
                backgroundSize: "cover",
                backgroundImage: `linear-gradient(to top, black 10%, transparent 45%), linear-gradient(to right, #0c0810, transparent), url(${creation?.bgurl})`,
                backgroundPosition: "top center",
            }}>
        <div className="banner__frame">
            <div key={creation?.id} className="banner__contents">
                <p className="banner__live">
                    Streaming now!
                </p>

                <p className="banner__pretitle"> {creation?.genres} </p>

                <h1 className="banner__title">
                    {creation?.title}
                </h1>

                <p className="banner__subtitle1"> {creation?.year} &nbsp;&nbsp; | &nbsp;&nbsp; <span>Director:</span> {creation?.director} &nbsp;&nbsp; | &nbsp;&nbsp; {creation?.maxres} </p>

                <p className="banner__subtitle2">{creation?.country} &nbsp;&nbsp; | &nbsp;&nbsp;<span>Duración:</span> 00:{creation?.duration} &nbsp;&nbsp; | &nbsp;&nbsp;<span>Edad:</span> {creation?.age}</p>
                                
                <h2 className="banner__description">
                    {truncate(creation?.synopsis, 250)}
                </h2>

                <div className="banner__buttons">
                <ScrollVideo to="video" offset={-150} smooth={true} delay={300} style={{ textDecoration: 'none' }}>
                    <button 
                    onClick={() => handleClick(creation)}
                    className="banner__button">► Reproducir</button>
                </ScrollVideo>

                    <button 
                    onClick={() => handleClick(creation)}
                    className="banner__button">■ Detener</button>

                    <Link to={`/watch/${creation?.id}`}>
                    <button 
                    className="banner__button">Ficha técnica</button>
                    </Link>

                </div>
            </div>
        </div>

        </header>

        <div key={creation?.id} id='video' className="banner__youtube">
        {trailerUrl && <YouTube videoId={trailerUrl} opts={opts} />}
        </div>

    </div>

    );
}

export default Banner;