import React, { /* useState,  */useContext } from 'react';
import { SearchContext } from '../context/SearchContext';
import "./SearchScreen.css";
import Nav from "../components/Nav";
//import Sidebar from "../components/Sidebar";
import RecommendedVideos from "../components/RecommendedVideos";
import FooterLinks from "../components/Footer/FooterLinks";
import Social from "../components/Footer/SocialLinks";
import Copyright from "../components/Footer/Copyright";
/* import Headline from "../components/Headline"; */
import NavBottom from "../components/NavBottom";
import GoToTop from '../GoToTop';


function SearchScreen() {
    //const [inputSearch, setInputSearch] = useState('');
    const [inputSearch, setInputSearch] = useContext(SearchContext);// eslint-disable-next-line

    return (
        <div>
            {/* <Headline /> */}
            <Nav />
            <div className="search__container">
                {/* <Sidebar /> */}
                <RecommendedVideos  setInputSearch={setInputSearch} />
            </div>
            <Social />
            <FooterLinks />
            <Copyright />
            <GoToTop />
            <NavBottom />
        </div>
    )
}

export default SearchScreen;
