export const cortometrajes =
[
 {
  "id": 1,
  "videoId_yt": "tzcZnH_RaR8",
  "videoId_vm": null,
  "videoId_yt_dmq": "KrzSVsCBBBU",
  "videoId_svr": null,
  "videohref_yt": "https://www.youtube.com/watch?v=tzcZnH_RaR8",
  "videohref_vm": null,
  "videohref_yt_dmq": "https://www.youtube.com/watch?v=KrzSVsCBBBU",
  "videohref_svr": null,
  "title": "Quédate",
  "synopsis": "Un corto que se centra en el amor y la pandemia",
  "description": "Dirección: David Sanchez Alva\nDirección de Fotografía: Josué Sanchez Alva\nActuación: Leslie Abad & Fabricio Plasencia\nProducción: Sanchez Alva Studio\nMúsica Original: Arturo Contreras (ZSandow) & David Sanchez Alva",
  "awards": null,
  "duration": "05:14",
  "starring": "Leslie Abad, Fabricio Plasencia.",
  "producer": null,
  "director": "David Sanchez Alva",
  "city": "Chimbote",
  "country": "Perú",
  "year": 2021,
  "thumbnailurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fthumbnail%2Ft1.jpg?alt=media&token=1397319c-ed71-4ffe-8034-fe52f661fa22",
  "posterurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter%2Fp1.jpg?alt=media&token=bbcd700a-ec44-4f3e-b8b6-b73278da059e",
  "poster375webpurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter375webp%2Fp1.webp?alt=media&token=51eed90d-41db-4ffb-9499-fc7934a0b960",
  "bgurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fbg%2Fb1.jpg?alt=media&token=d2ab605e-32f1-42fb-bf5d-b03cec0523e7",
  "genres": "Drama",
  "age": "Público en general",
  "maxres": "HD 1080p",
  "type": "Cortometraje",
  "language": "ES",
  "status": "active",
  "fomo": 0,
  
 },
 {
  "id": 2,
  "videoId_yt": "FqaZCI3W21s",
  "videoId_vm": null,
  "videoId_yt_dmq": "kKMXztVIF4o",
  "videoId_svr": null,
  "videohref_yt": "https://www.youtube.com/watch?v=FqaZCI3W21s",
  "videohref_vm": null,
  "videohref_yt_dmq": "https://www.youtube.com/watch?v=kKMXztVIF4o",
  "videohref_svr": null,
  "title": "Yawar",
  "synopsis": null,
  "description": "Las familias en situación de pobreza buscan progresar, en la necesidad de trabajo se convierten en víctimas de trata laboral como una forma de esclavitud moderna del sistema capitalista neoliberal, esta es la realidad de muchos en el extranjero a 200 años de la República del Perú.",
  "awards": null,
  "duration": "06:36",
  "starring": "Joel Caushi Castillo, Olfer Leonardo, Jose Garcia.",
  "producer": null,
  "director": "Bridguith Valverde",
  "city": null,
  "country": "Perú",
  "year": 2021,
  "thumbnailurl": "https://img.youtube.com/vi/FqaZCI3W21s/maxresdefault.jpg",
  "posterurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter%2Fp2.jpg?alt=media&token=bc0e84a9-68bc-43d0-9d27-d6f21bc65a5b",
  "poster375webpurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter375webp%2Fp2.webp?alt=media&token=943f2a1d-f025-47cc-ab6a-d67d5c4b0503",
  "bgurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fbg%2Fb2.jpg?alt=media&token=ed137337-edd1-45f8-9c74-3f1be9cc0a3d",
  "genres": "Drama",
  "age": "Público en general",
  "maxres": "HD 1080p",
  "type": "Cortometraje",
  "language": "ES",
  "status": "active",
  "fomo": 0,
  
 },
 {
  "id": 3,
  "videoId_yt": "dINNk4qGlvc",
  "videoId_vm": null,
  "videoId_yt_dmq": "sUJ5yNnLQxs",
  "videoId_svr": null,
  "videohref_yt": "https://www.youtube.com/watch?v=dINNk4qGlvc",
  "videohref_vm": null,
  "videohref_yt_dmq": "https://www.youtube.com/watch?v=sUJ5yNnLQxs",
  "videohref_svr": null,
  "title": "Al nacer",
  "synopsis": "Se trata de una historia de suerte, o más bien de casualidades. Aunque aquí somos más de causalidades y de creer que todo pasa por algo, con un fin, un motivo.",
  "description": "Seleccionado en el IV Certamen de Cortos de la Provincia de Sevilla",
  "awards": null,
  "duration": "13:38",
  "starring": "Jose María Corts, Rayan David Zuloaga, Juan Gil.",
  "producer": null,
  "director": "Irene Garnica",
  "city": "Sevilla",
  "country": "España",
  "year": 2020,
  "thumbnailurl": "https://img.youtube.com/vi/dINNk4qGlvc/maxresdefault.jpg",
  "posterurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter%2Fp3.jpg?alt=media&token=8c9764cf-6a10-42cc-a682-509d8bfb26db",
  "poster375webpurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter375webp%2Fp3.webp?alt=media&token=94be71f6-fd30-4bd0-a510-d3bdb982c36e",
  "bgurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fbg%2Fb3.jpg?alt=media&token=b68df0cc-a00a-41f7-a778-1c3af6c31613",
  "genres": "Drama",
  "age": "Público en general",
  "maxres": "HD 1080p",
  "type": "Cortometraje",
  "language": "ES",
  "status": "active",
  "fomo": 0,
  
 },
 {
  "id": 4,
  "videoId_yt": "D1cR_DfYSGc",
  "videoId_vm": null,
  "videoId_yt_dmq": "OB77_1YQhFw",
  "videoId_svr": null,
  "videohref_yt": "https://www.youtube.com/watch?v=D1cR_DfYSGc",
  "videohref_vm": null,
  "videohref_yt_dmq": "https://www.youtube.com/watch?v=OB77_1YQhFw",
  "videohref_svr": null,
  "title": "Sí, quiero (Corredor)",
  "synopsis": "Clara, una chica valenciana, y Arnau, un chico catalán, han decidido casarse en el mismo tren en el que se conocieron. Un primo murciano, una supervisora andaluza y sus familias y amigos los acompañan en un accidentado viaje por la costa mediterránea que llevará al límite la paciencia y el amor.",
  "description": null,
  "awards": null,
  "duration": "23:01",
  "starring": "Alvaro Cervantes, Nuria Herrero, Macarena Gómez.",
  "producer": null,
  "director": "Alfonso Sánchez",
  "city": "Valencia",
  "country": "España",
  "year": 2021,
  "thumbnailurl": "https://img.youtube.com/vi/D1cR_DfYSGc/maxresdefault.jpg",
  "posterurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter%2Fp4.jpg?alt=media&token=591b906a-0197-4fd8-9a76-009690f3b5e3",
  "poster375webpurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter375webp%2Fp4.webp?alt=media&token=a0ca7fa0-24a9-46f0-b738-d1baf2c1434c",
  "bgurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fbg%2Fb4.jpg?alt=media&token=89cc675c-e265-4d3a-8d0b-8b9b8c491fed",
  "genres": "Romance, Comedia",
  "age": "16+",
  "maxres": "HD 1080p",
  "type": "Cortometraje",
  "language": "ES",
  "status": "active",
  "fomo": 0,
  
 },
 {
  "id": 5,
  "videoId_yt": "jjlsdtmiRig",
  "videoId_vm": null,
  "videoId_yt_dmq": "PAOKcwqgAGo",
  "videoId_svr": null,
  "videohref_yt": "https://www.youtube.com/watch?v=jjlsdtmiRig",
  "videohref_vm": null,
  "videohref_yt_dmq": "https://www.youtube.com/watch?v=PAOKcwqgAGo",
  "videohref_svr": null,
  "title": "Papers please",
  "synopsis": "obra original creada por @Никита Ордынский y doblada al español, en homenaje al video juego Papers Please.",
  "description": "REPARTO DE DOBLAJE· \nIgor Savochkin .....  @DOBLAJES MANDRIL  \nAntonina Kravtsova ..... Desireé Alvarez\nMikhail Panyukov .......Seluwu Dubs\nViktoriya Tsygankova ..... EstherDub\nEvgeni Tokarev ..... Dante",
  "awards": null,
  "duration": "11:52",
  "starring": "Igor Savochkin, Antonina Kravtsova, Mikhail Panyukov.",
  "producer": null,
  "director": "Evgeni Tokarev - Dante",
  "city": null,
  "country": "España",
  "year": 2018,
  "thumbnailurl": "https://img.youtube.com/vi/jjlsdtmiRig/maxresdefault.jpg",
  "posterurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter%2Fp5.jpg?alt=media&token=579898aa-583d-4f89-84c0-b4688cfea79d",
  "poster375webpurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter375webp%2Fp5.webp?alt=media&token=13c70759-9f48-4f5e-8bc9-29a891542a0c",
  "bgurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fbg%2Fb5.jpg?alt=media&token=016a10a4-62b1-405f-adb0-6f8e39704f35",
  "genres": "Comedia",
  "age": "16+",
  "maxres": "HD 1080p",
  "type": "Cortometraje",
  "language": "ES",
  "status": "active",
  "fomo": 1,
  
 },
 {
  "id": 6,
  "videoId_yt": "GVzvdmgq57g",
  "videoId_vm": null,
  "videoId_yt_dmq": "cj3IzHcGtSc",
  "videoId_svr": null,
  "videohref_yt": "https://www.youtube.com/watch?v=GVzvdmgq57g",
  "videohref_vm": null,
  "videohref_yt_dmq": "https://www.youtube.com/watch?v=cj3IzHcGtSc",
  "videohref_svr": null,
  "title": "Soledad",
  "synopsis": "Habla sobre las situación de soledad en la que en algunos casos, quedan nuestros mayores. ",
  "description": "Premio al mejor cortometraje internacional en Fidema Film Festival. \n\nCortometraje rodado y editado en Madrid en el año 2021, durante la pandemia.",
  "awards": null,
  "duration": "06:54",
  "starring": "Adelaida Campaña.",
  "producer": null,
  "director": "Luis Ovejero",
  "city": "Madrid",
  "country": "España",
  "year": 2021,
  "thumbnailurl": "https://img.youtube.com/vi/GVzvdmgq57g/maxresdefault.jpg",
  "posterurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter%2Fp6.jpg?alt=media&token=c5849f35-bd78-4bf7-93b5-feda6836b253",
  "poster375webpurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter375webp%2Fp6.webp?alt=media&token=93d0e5f4-56f1-47de-acb0-1fc14e37c7fe",
  "bgurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fbg%2Fb6.jpg?alt=media&token=381d6e45-8c8c-4812-927e-7a36abb7ad18",
  "genres": "Drama",
  "age": "16+",
  "maxres": "HD 1080p",
  "type": "Cortometraje",
  "language": "ES",
  "status": "active",
  "fomo": 0,
  
 },
 {
  "id": 7,
  "videoId_yt": "j9TDhdLm3-s",
  "videoId_vm": null,
  "videoId_yt_dmq": "2FpZJoMjhPU",
  "videoId_svr": null,
  "videohref_yt": "https://www.youtube.com/watch?v=j9TDhdLm3-s",
  "videohref_vm": null,
  "videohref_yt_dmq": "https://www.youtube.com/watch?v=2FpZJoMjhPU",
  "videohref_svr": null,
  "title": "Cerrando Ciclos",
  "synopsis": "¿Alguna vez te han roto el corazón?\nA Romina si. Ella luego de stalkear a su ex empieza a pensar en el motivo de su ruptura.\n¿Será momento de ir Cerrando Ciclos o volver a repetirlos?",
  "description": "Dirección: Rita Rivera Prado\nDirectora de Fotografía: Silvana Fernandez Maldonado \nActuación: Andrea Acuña y Santiago Murrugarra\nDirectora de Arte: Romina Siguas\nProductor: Sergio Arce\nAsistente de producción: Nicole Barreto, Isabella Becerra y Flavia Siguas\nEdición: Rita Rivera y Sergio Arce \nAnimación: Romina Siguas\nColorización: Silvana Fernandez Maldonado \nGuión: Rita Rivera Prado y Sergio Arce",
  "awards": null,
  "duration": "06:23",
  "starring": "Andrea Acuña, Santiago Murrugarra.",
  "producer": null,
  "director": "Rita Rivera Prado",
  "city": "Lima",
  "country": "Perú",
  "year": 2021,
  "thumbnailurl": "https://img.youtube.com/vi/j9TDhdLm3-s/maxresdefault.jpg",
  "posterurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter%2Fp7.jpg?alt=media&token=f2338970-1738-4039-96b6-a7f038d6cd45",
  "poster375webpurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter375webp%2Fp7.webp?alt=media&token=ef4d07bd-1960-46c5-b09b-c5581888808a",
  "bgurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fbg%2Fb7.jpg?alt=media&token=cbce1557-3fbe-47bc-b005-d56095fb81fc",
  "genres": "Drama",
  "age": "16+",
  "maxres": "HD 1080p",
  "type": "Cortometraje",
  "language": "ES",
  "status": "active",
  "fomo": 0,
  
 },
 {
  "id": 8,
  "videoId_yt": "9AGhx4KwLtY",
  "videoId_vm": null,
  "videoId_yt_dmq": "ZkQvcNZHrcI",
  "videoId_svr": null,
  "videohref_yt": "https://www.youtube.com/watch?v=9AGhx4KwLtY",
  "videohref_vm": null,
  "videohref_yt_dmq": "https://www.youtube.com/watch?v=ZkQvcNZHrcI",
  "videohref_svr": null,
  "title": "Abuela",
  "synopsis": "Nos muestra el día a día de nuestros abuelos durante la cuarentena.",
  "description": "Confinamiento de una abuela",
  "awards": null,
  "duration": "04:59",
  "starring": "María Vázquez Gómez.",
  "producer": null,
  "director": "Pablo Baamonde Soengas",
  "city": "Galicia",
  "country": "España",
  "year": 2020,
  "thumbnailurl": "https://img.youtube.com/vi/9AGhx4KwLtY/maxresdefault.jpg",
  "posterurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter%2Fp8.jpg?alt=media&token=800c8784-8e89-4109-bb20-6f38a819520a",
  "poster375webpurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter375webp%2Fp8.webp?alt=media&token=2296923f-68cb-4b4a-859f-301364af3a0d",
  "bgurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fbg%2Fb8.jpg?alt=media&token=c1f6d5af-5138-47f6-b28d-addc0334ee1d",
  "genres": "Drama",
  "age": "13+",
  "maxres": "HD 1080p",
  "type": "Cortometraje",
  "language": "ES",
  "status": "active",
  "fomo": 0,
  
 },
 {
  "id": 9,
  "videoId_yt": "_JCqBSjctZg",
  "videoId_vm": null,
  "videoId_yt_dmq": "DixUR_-Vt78",
  "videoId_svr": null,
  "videohref_yt": "https://www.youtube.com/watch?v=_JCqBSjctZg",
  "videohref_vm": null,
  "videohref_yt_dmq": "https://www.youtube.com/watch?v=DixUR_-Vt78",
  "videohref_svr": null,
  "title": "Café solo",
  "synopsis": "La mente nos hace jugadas que nuestro cuerpo no puede siquiera pensar en hacer. Es el poder mismo de crear lo alterno.",
  "description": "Productora: Zolen Films\nCon la colaboración de :Dilaina studio\nDirección: Toni Villazan\nGuión: Eva de Salas\nEdición: Isaac Hermo (Dilaina)\nColor y ayudante de edición: Toni Villazan\nVFX: Judith Queralt (Dilaina)\nMúsica:  Daniel López-Vázquez\nReparto: Adrian Disch, Laura Grau, Gloria Estéllez, David Herrero.",
  "awards": null,
  "duration": "10:18",
  "starring": "Adrian Disch, Laura Grau, Gloria Estéllez, David Herrero.",
  "producer": null,
  "director": "Toni Villazan",
  "city": "Bogotá",
  "country": "Colombia",
  "year": 2017,
  "thumbnailurl": "https://img.youtube.com/vi/_JCqBSjctZg/maxresdefault.jpg",
  "posterurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter%2Fp9.jpg?alt=media&token=4643287b-0a17-431a-8d90-4fb3df7f4adb",
  "poster375webpurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter375webp%2Fp9.webp?alt=media&token=1689517a-92a5-4ef8-8a5c-f884140d57a1",
  "bgurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fbg%2Fb9.jpg?alt=media&token=1ffe0067-27d4-429e-a546-4952102248be",
  "genres": "Romance, Drama",
  "age": "13+",
  "maxres": "HD 1080p",
  "type": "Cortometraje",
  "language": "ES",
  "status": "active",
  "fomo": 0,
  
 },
 {
  "id": 10,
  "videoId_yt": "Fv2HK10YEk0",
  "videoId_vm": null,
  "videoId_yt_dmq": "3l1nRx3tUFE",
  "videoId_svr": null,
  "videohref_yt": "https://www.youtube.com/watch?v=Fv2HK10YEk0",
  "videohref_vm": null,
  "videohref_yt_dmq": "https://www.youtube.com/watch?v=3l1nRx3tUFE",
  "videohref_svr": null,
  "title": "La historia de siempre",
  "synopsis": "La conversación telefónica de un hombre tiene en vilo a todos los pasajeros del autobús en el que viajan. Habla con su mujer, la cual le abandonó hace ya un mes. Ella está en su casa. Él va a ayudarla a encontrar algo que se dejó olvidado al hacer la maleta, pero sobre todas las cosas va a intentar tener una segunda oportunidad cambiando la vida de todos los que le rodean.",
  "description": "Cortometraje producido por Bastian Films, dirigido por J.L. Montesinos y protagonizado por Miguel Ángel Jenner.\nGalardonado con 130 premios a nivel Nacional e Internacional, entre los que destacan Festivales como Málaga, Medina del Campo, Mieres, Trieste, Hong Kong, Rhode Island.",
  "awards": "Festival Iberoamericano de Huelva – Premio del Público\nFestival Internacional de Cine de Soria – Premio al Mejor Actor y Premio del Público\nFestival de Cineculpable de Vilareal – Premios al Mejor Actor, Mejor Director y Público\nConcurso de Cortos de Laguardia – Premios al Mejor Corto, Director, Guión y del Público\nFestival Internac. de Cine de Aguilar de Campóo – Premio del Jurado de la Prensa y Mejor Actor\nFestival de Cine de Mieres, Asturias – Premios al Mejor Corto, Actor y Premio del Público\nFestival de Medina del Campo – Premio del Público Joven\nFestival de Cine Español de Málaga – Biznaga de Plata al Mejor Actor\nMuestra Internacional de Cine de Palencia – Premio del Público y Premio al Mejor Actor\nMaremetraggio Intl. Short Film Festival, Trieste – Grand Prix Mejor Corto y Premio del Público\nFestival de Cine Pamplona – Premio del Público y Premio al Mejor Actor\nFestival de Cine Independiente de Elche – Premio del Público\nRhode Island International Film Festival, USA – Grand Prize del Público\nFestival Internacional de Cine de Montecatini, Italia – Premio del Público",
  "duration": "11:37",
  "starring": "Miguel Ángel Jenner.",
  "producer": null,
  "director": "J.L. Montesinos ",
  "city": "Málaga",
  "country": "España",
  "year": 2012,
  "thumbnailurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fthumbnail%2Ft10.jpg?alt=media&token=c99a3a64-6246-4471-bf2e-7603f87329d4",
  "posterurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter%2Fp10.jpg?alt=media&token=4b071852-982d-462a-957b-41a354043a68",
  "poster375webpurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter375webp%2Fp10.webp?alt=media&token=1f2ecd22-655a-495d-bcb7-444811db9381",
  "bgurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fbg%2Fb10.jpg?alt=media&token=a5b801f2-dece-4729-8702-6e73e1a361eb",
  "genres": "Drama",
  "age": "16+",
  "maxres": "480p",
  "type": "Cortometraje",
  "language": "ES",
  "status": "active",
  "fomo": 0,
  
 },
 {
  "id": 11,
  "videoId_yt": "1n2TjtZaT6g",
  "videoId_vm": null,
  "videoId_yt_dmq": "ziKryfw_Lw8",
  "videoId_svr": null,
  "videohref_yt": "https://www.youtube.com/watch?v=1n2TjtZaT6g",
  "videohref_vm": null,
  "videohref_yt_dmq": "https://www.youtube.com/watch?v=ziKryfw_Lw8",
  "videohref_svr": null,
  "title": "!Hey, guapa¡",
  "synopsis": "Una chica espera su movilidad tranquilamente en un paradero, cuando de pronto un muchacho intentará acercarse a ella de una forma peculiar.",
  "description": "Escrito y Dirigido por: Daniel Medina\nProducción: Eri Nagai\nCámaras: CashNau\nSonido Directo: José Diaz\nEdición: Jordan Fuentes\nDirección de Arte: Maykol Paredes\nAsistentes de Producción: Heyner Chinchay- Kevin Peralta- Katerine Cabrejos\n\nActores: Madeleyne Castro- Daniel Medina- Diego Berrios",
  "awards": null,
  "duration": "07:04",
  "starring": "Madeleyne Castro, Daniel Medina, Diego Berrios.",
  "producer": null,
  "director": "Daniel Medina",
  "city": "Chiclayo",
  "country": "Perú",
  "year": 2021,
  "thumbnailurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fthumbnail%2Ft11.jpg?alt=media&token=dbe4e831-6b29-445e-83ae-76d06e365a05",
  "posterurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter%2Fp11.jpg?alt=media&token=989e3c6b-de5b-438e-b766-17a4175218bf",
  "poster375webpurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter375webp%2Fp11.webp?alt=media&token=79cdd7d0-44b2-4128-9425-8cd9cb76b41c",
  "bgurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fbg%2Fb11.jpg?alt=media&token=a06b927f-4b87-4e49-a458-5c03eb917d66",
  "genres": "Romance, Drama",
  "age": "16+",
  "maxres": "HD 1080p",
  "type": "Cortometraje",
  "language": "ES",
  "status": "none",
  "fomo": 0,
  
 },
 {
  "id": 12,
  "videoId_yt": "G2AKw_aXC9c",
  "videoId_vm": null,
  "videoId_yt_dmq": "1DOWz9QuAps",
  "videoId_svr": null,
  "videohref_yt": "https://www.youtube.com/watch?v=G2AKw_aXC9c",
  "videohref_vm": null,
  "videohref_yt_dmq": "https://www.youtube.com/watch?v=1DOWz9QuAps",
  "videohref_svr": null,
  "title": "Desesperación",
  "synopsis": "Una dentista con parkinson batalla para poder llevar a cabo acciones del día a día.\nUna dentista con parkinson batalla para poder llevar a cabo acciones del día a día.",
  "description": "Productora general - Andrea Varón\nProductora de campo - Ursula Coello\nDirector - Sebastán Plasencia\nAsistente de dirección - Gonzalo Valdez\nProductor - Eduardo Mendoza\nProductor - Kevin Vera\nAsistente de producción - Fabrizio Castro\nDirector de fotografía - Gonzalo Valdez\nDirectora de fotografía - Valeria Talavera\nDirectora de arte - Catherine \"Cathy\" Acosta\nDirección de sonido - Ericka García\nMaquillaje - Yennifer Roa\nMake-up FX - Marcia Vidal\n\nEditores - Ursula Coello & Sebastián Plasencia\nPost-producción - Sebastián Plasencia.",
  "awards": null,
  "duration": "06:13",
  "starring": "Luz María De La Torre, Ugarte, Omar García, Renato García, Federico De Las Casas, Jimena Franco, Yennifer Roa",
  "producer": null,
  "director": "Sebastián Plasencia",
  "city": "Lima",
  "country": "Perú",
  "year": 2017,
  "thumbnailurl": "https://img.youtube.com/vi/G2AKw_aXC9c/maxresdefault.jpg",
  "posterurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter%2Fp12.jpg?alt=media&token=2d60d2d0-ce69-4847-a94f-3080141724a2",
  "poster375webpurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter375webp%2Fp12.webp?alt=media&token=e2355acd-c8ae-4ff2-b43d-e84fde922fb7",
  "bgurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fbg%2Fb12.jpg?alt=media&token=e10c6e1c-ffc0-48e6-a910-60912bbdc859",
  "genres": "Muda, Drama",
  "age": "16+",
  "maxres": "HD 1080p",
  "type": "Cortometraje",
  "language": "ES",
  "status": "active",
  "fomo": 0,
  "Column31": "https://www.facebook.com/splasens/"
 },
 {
  "id": 13,
  "videoId_yt": "JspGwNke_lE",
  "videoId_vm": null,
  "videoId_yt_dmq": "Kw33mqR33uQ",
  "videoId_svr": null,
  "videohref_yt": "https://www.youtube.com/watch?v=JspGwNke_lE",
  "videohref_vm": null,
  "videohref_yt_dmq": "https://www.youtube.com/watch?v=Kw33mqR33uQ",
  "videohref_svr": null,
  "title": "G.",
  "synopsis": "Angel Mamani, un joven estudiante que de día trabaja en la barberia de su madre, esta obsesionado con el punto G de las mujeres.",
  "description": "Cortometraje ganador del primer Lugar en el 48 Hour FIlm Project Lima 2015.",
  "awards": null,
  "duration": "08:07",
  "starring": "Gabriel Gonzales, Brenda Gutierrez.",
  "producer": null,
  "director": "Andres Manco Otiniano",
  "city": "Lima",
  "country": "Perú",
  "year": 2015,
  "thumbnailurl": "https://img.youtube.com/vi/JspGwNke_lE/maxresdefault.jpg",
  "posterurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter%2Fp13.jpg?alt=media&token=d67d0ed8-7280-44e0-bddf-8af145b1e131",
  "poster375webpurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter375webp%2Fp13.webp?alt=media&token=6e9fa26c-1068-4469-a39a-2c873477081a",
  "bgurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fbg%2Fb13.jpg?alt=media&token=a8bb40ba-47a0-4d9f-913e-3ff2f47cdd20",
  "genres": "Comedia, Drama",
  "age": "16+",
  "maxres": "HD 1080p",
  "type": "Cortometraje",
  "language": "ES",
  "status": "active",
  "fomo": 0,
  
 },
 {
  "id": 14,
  "videoId_yt": "MIFRUGnCcsQ",
  "videoId_vm": null,
  "videoId_yt_dmq": "6nXkgcctEwM",
  "videoId_svr": null,
  "videohref_yt": "https://www.youtube.com/watch?v=MIFRUGnCcsQ",
  "videohref_vm": null,
  "videohref_yt_dmq": "https://www.youtube.com/watch?v=6nXkgcctEwM",
  "videohref_svr": null,
  "title": "Mar",
  "synopsis": "Alex, un joven amante del buceo, conoce a una chica con su misma pasión. Entrará en su vida tan rápidamente, que supondrá un gran impacto para él.",
  "description": "Escrito y dirigido por Thomas Ragusa\n\nDirección de fotografía y operadora cámara: Paula Gallego\nAyudante de cámara y dirección: Sofia Ramis\nOperador cámara submarina: Alberto Aguiló\nOperador dron: Joan Cañellas\nMontaje y posproducción: Paula Gallego y OMYMOVIES\nMúsica y voz en off: MathewOTB\nFotografía y making off: Natalia Porcel\nRunner: Nando Ragusa",
  "awards": null,
  "duration": "20:12",
  "starring": "Thomas Ragusa, Claudia Porcel, Yatel Catrón, Álvaro Bosch, Miquel Angel Ros, Mariano C. Nistico.",
  "producer": null,
  "director": "Thomas Ragusa",
  "city": "Mallorca",
  "country": "España",
  "year": 2020,
  "thumbnailurl": "https://img.youtube.com/vi/MIFRUGnCcsQ/maxresdefault.jpg",
  "posterurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter%2Fp14.jpg?alt=media&token=a6b74880-b384-47e2-a386-91456f51d4d7",
  "poster375webpurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter375webp%2Fp14.webp?alt=media&token=bf7b10b8-ea3e-42dc-b769-2a3f704cf98b",
  "bgurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fbg%2Fb14.jpg?alt=media&token=31f3ac1a-b6c6-43dd-bb88-beaa4a097c90",
  "genres": "Drama",
  "age": "16+",
  "maxres": "HD 1440p",
  "type": "Cortometraje",
  "language": "ES",
  "status": "active",
  "fomo": 0,
  "Column31": "https://www.instagram.com/thomaassr/"
 },
 {
  "id": 15,
  "videoId_yt": "OY62JFt88iU",
  "videoId_vm": null,
  "videoId_yt_dmq": "U3_ClAs-k3k",
  "videoId_svr": null,
  "videohref_yt": "https://www.youtube.com/watch?v=OY62JFt88iU",
  "videohref_vm": null,
  "videohref_yt_dmq": "https://www.youtube.com/watch?v=U3_ClAs-k3k",
  "videohref_svr": null,
  "title": "La Otra Cara",
  "synopsis": "Busca transmitir los problemas de los jóvenes hoy en día; las drogas, la depresión y la baja autoestima.",
  "description": null,
  "awards": null,
  "duration": "05:38",
  "starring": "Mateo Ortiz, Javier Jaque, Benjamín Morales y Felipe González.",
  "producer": null,
  "director": "Claudio Juárez V.",
  "city": null,
  "country": "Latinoamérica",
  "year": 2019,
  "thumbnailurl": "https://i3.ytimg.com/vi/OY62JFt88iU/hqdefault.jpg",
  "posterurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter%2Fp15.jpg?alt=media&token=d0307e32-a322-4d19-ad2d-22bb506e0e7c",
  "poster375webpurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter375webp%2Fp15.webp?alt=media&token=cfd8701e-41ca-441b-a2c3-94c7229c880b",
  "bgurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fbg%2Fb15.jpg?alt=media&token=afc9eccb-3a73-4784-a8f8-d6e1be58a779",
  "genres": "Drama",
  "age": "16+",
  "maxres": "HD 1080p",
  "type": "Cortometraje",
  "language": "ES",
  "status": "active",
  "fomo": 0,
  
 },
 {
  "id": 16,
  "videoId_yt": "9OwxosO-xVo",
  "videoId_vm": null,
  "videoId_yt_dmq": "BD79vopMU7w",
  "videoId_svr": null,
  "videohref_yt": "https://www.youtube.com/watch?v=9OwxosO-xVo",
  "videohref_vm": null,
  "videohref_yt_dmq": "https://www.youtube.com/watch?v=BD79vopMU7w",
  "videohref_svr": null,
  "title": "Ciudadanos",
  "synopsis": "Una historia que refleja el enfrentamiento entre la gente cuando el dinero es nuestra única forma de sobrevivir. La falta de trabajo lleva a los ciudadanos a una situación de emergencia que les enfrenta a unos contra otros. Una lucha por la supervivencia ante la situación limite de nuestra protagonista, una situación de ley contra moral, una lucha contra la injusticia cuando la ley es demasiado lenta.",
  "description": "Estamos en una realidad en España en la que se producen desahucios a diario, las familias se quedan sin techo, se llevan a cabo cargas policiales contra ciudadanos, la crisis económica sigue sin resolverse, la violencia en la política llega hasta las calles, y entre nosotros, los ciudadanos, se llegan a situación de violencia.\n\nLa falta de trabajo lleva a los ciudadanos a una situación de emergencia, enfrentándoles unos contra otros, obligándoles a despertar su propio egoísmo para conseguir su supervivencia. Ante la violencia y la injusticia ciudadanos como Noe sufren la ausencia de leyes efectivas.\n\n\"Ciudadanos\" un cortometraje sobre derechos humanos escrito y dirigido por Marc Nadal.\nProtagonizado por los actores Núria Molina, Àlex Casanovas, Maria Molins, Manel Barceló, Marc Aguilar Sebastián y Sandra Olivé.\nSonido y fotografías de rodaje por Sandra Barrachina.\nAño: 2017. Drama.",
  "awards": null,
  "duration": "14:38",
  "starring": "Núria Molina, Álex Casanovas, Maria Molins.",
  "producer": null,
  "director": "Marc Nadal",
  "city": "Barcelona",
  "country": "España",
  "year": 2017,
  "thumbnailurl": "https://img.youtube.com/vi/9OwxosO-xVo/maxresdefault.jpg",
  "posterurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter%2Fp16.jpg?alt=media&token=3430e2ce-1747-461b-8b2e-37060c6dda34",
  "poster375webpurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter375webp%2Fp16.webp?alt=media&token=1149c637-1ed9-400a-9ed3-908b5890cfa0",
  "bgurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fbg%2Fb16.jpg?alt=media&token=15e94395-dc45-4f16-b122-725d28f49894",
  "genres": "Drama",
  "age": "16+",
  "maxres": "HD 1080p",
  "type": "Cortometraje",
  "language": "ES",
  "status": "active",
  "fomo": 0,
  
 },
 {
  "id": 17,
  "videoId_yt": "AxvRH-ymLGs",
  "videoId_vm": null,
  "videoId_yt_dmq": "VDQYD3KbwWQ",
  "videoId_svr": null,
  "videohref_yt": "https://www.youtube.com/watch?v=AxvRH-ymLGs",
  "videohref_vm": null,
  "videohref_yt_dmq": "https://www.youtube.com/watch?v=VDQYD3KbwWQ",
  "videohref_svr": null,
  "title": "Contagio mortal",
  "synopsis": "Esta historia trata de una pareja de esposos que tienen dos hijos, donde jorge es un destacado ingeniero y magalis una ama de casa muy dedicada a su familia.\nLa terquedad del esposo, de no querer respetar los protocolos de seguridad e higiene, contagia su esposa del coronavirus, llevándola a la muerte inmediata.\nMagalis, como toda madre responsable, trata de cuidar a su familia y orientarlos a usar sus mascarillas y respetar los protocolos, pero lucha con la conducta inadecuada de su esposo, quien llega a mal influenciar a sus hijos.\nJorge, sin importarle mucho las consecuencias de nada, sigue con su vida social en la calle donde es contagiado del virus, pero no se da cuenta porque resulta ser asintomático.\n",
  "description": null,
  "awards": null,
  "duration": "14:37",
  "starring": null,
  "producer": null,
  "director": "Yohan Gabriel y Monalisa Ferreira",
  "city": null,
  "country": "República Dominicana",
  "year": 2020,
  "thumbnailurl": "https://img.youtube.com/vi/AxvRH-ymLGs/maxresdefault.jpg",
  "posterurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter%2Fp17.jpg?alt=media&token=a7945860-8356-419f-a818-f89a785b55ef",
  "poster375webpurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter375webp%2Fp17.webp?alt=media&token=c0ef5641-b428-4787-8ac4-7d5325e10d0e",
  "bgurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fbg%2Fb17.jpg?alt=media&token=c2501728-316c-4a35-be36-f8f9cb4c1410",
  "genres": "Drama",
  "age": "16+",
  "maxres": "4K 2160p",
  "type": "Cortometraje",
  "language": "ES",
  "status": "active",
  "fomo": 0,
  
 },
 {
  "id": 18,
  "videoId_yt": "FnT-fjuuJcM",
  "videoId_vm": null,
  "videoId_yt_dmq": "ptQM9kh3_3Q",
  "videoId_svr": null,
  "videohref_yt": "https://www.youtube.com/watch?v=FnT-fjuuJcM",
  "videohref_vm": null,
  "videohref_yt_dmq": "https://www.youtube.com/watch?v=ptQM9kh3_3Q",
  "videohref_svr": null,
  "title": "Atrapada",
  "synopsis": "Karina(20) hija de padres separados, tras el duro golpe emocional de la situación tanto familiar como sentimental con su novio, más la nueva etapa de instituto, trata de encontrar el sentido de su vida basándose en la aceptación de los demás hacia ella, haciendo de su misma vida una mentira que poco a poco ira tomando un lugar muy denso y difícil de remediar en su corazón.",
  "description": "Cortometraje basado en las cartas reales de Karina Villegas (1997 - 2017).",
  "awards": null,
  "duration": "06:31",
  "starring": "Amira Buendia.",
  "producer": null,
  "director": "Jean Pitot",
  "city": null,
  "country": "Perú",
  "year": 2021,
  "thumbnailurl": "https://img.youtube.com/vi/FnT-fjuuJcM/maxresdefault.jpg",
  "posterurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter%2Fp18.jpg?alt=media&token=9c9f9c19-afa9-4761-87be-6e61759131ff",
  "poster375webpurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter375webp%2Fp18.webp?alt=media&token=4c99ac79-dd77-4700-bbd5-a8ca8359dd88",
  "bgurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fbg%2Fb18.jpg?alt=media&token=b60e19da-4495-4b31-b53b-272ae77065c4",
  "genres": "Drama",
  "age": "16+",
  "maxres": "HD 1080p",
  "type": "Cortometraje",
  "language": "ES",
  "status": "active",
  "fomo": 0,
  
 },
 {
  "id": 19,
  "videoId_yt": "jEdx1qp0N7M",
  "videoId_vm": null,
  "videoId_yt_dmq": "uBzOHNUuV-A",
  "videoId_svr": null,
  "videohref_yt": "https://www.youtube.com/watch?v=jEdx1qp0N7M",
  "videohref_vm": null,
  "videohref_yt_dmq": "https://www.youtube.com/watch?v=uBzOHNUuV-A",
  "videohref_svr": null,
  "title": "La Renuncia",
  "synopsis": "Una carta que llega sin llegar y guía los pasos de un hombre que, desconociendo su destino,  morirá con la bala de un arma que carga su propia intención, cerrando así un dudoso círculo de justicia divina.",
  "description": null,
  "awards": null,
  "duration": "07:00",
  "starring": "Anthony Alan, Ma. Eugenia Rodríguez Borges.",
  "producer": null,
  "director": "Martín Lander",
  "city": "Montevideo",
  "country": "Uruguay",
  "year": 2020,
  "thumbnailurl": "https://img.youtube.com/vi/jEdx1qp0N7M/maxresdefault.jpg",
  "posterurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter%2Fp19.jpg?alt=media&token=be07e571-69b7-4fa3-b49a-1347429b1bd4",
  "poster375webpurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter375webp%2Fp19.webp?alt=media&token=65e3e03c-731d-460c-b65c-a7ccfac92b61",
  "bgurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fbg%2Fb19.jpg?alt=media&token=e3291733-dbee-4e56-9b2b-b7525a00bd61",
  "genres": "Suspenso, Drama",
  "age": "16+",
  "maxres": "HD 1080p",
  "type": "Cortometraje",
  "language": "ES",
  "status": "active",
  "fomo": 0,
  
 },
 {
  "id": 20,
  "videoId_yt": "ykt5Pq6P0pQ",
  "videoId_vm": null,
  "videoId_yt_dmq": "Laj2TxCuYVc",
  "videoId_svr": null,
  "videohref_yt": "https://www.youtube.com/watch?v=ykt5Pq6P0pQ",
  "videohref_vm": null,
  "videohref_yt_dmq": "https://www.youtube.com/watch?v=Laj2TxCuYVc",
  "videohref_svr": null,
  "title": "La Visita",
  "synopsis": "Con la llegada del sol, un niño llega a visitar a una pareja. Pasan el día juntos, pero cuando el sol comienza a esconderse, el niño también tiene que irse. Ahora deben esperar un año para volver a estar juntos.",
  "description": null,
  "awards": null,
  "duration": "05:29",
  "starring": "Tony Solís, Priscila Lozano, Ismael López.",
  "producer": null,
  "director": "Eliud Silva",
  "city": null,
  "country": "México",
  "year": 2021,
  "thumbnailurl": "https://img.youtube.com/vi/ykt5Pq6P0pQ/maxresdefault.jpg",
  "posterurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter%2Fp20.jpg?alt=media&token=1e20f144-f2e9-40c7-9572-5fd1d105ca19",
  "poster375webpurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter375webp%2Fp20.webp?alt=media&token=0cd00d08-db47-44db-a7a0-eb81c2c9b1a1",
  "bgurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fbg%2Fb20.jpg?alt=media&token=ecdb89fa-6940-4905-a38b-da2e55ffd87d",
  "genres": "Drama",
  "age": "16+",
  "maxres": "HD 1080p",
  "type": "Cortometraje",
  "language": "ES",
  "status": "active",
  "fomo": 0,
  
 },
 {
  "id": 21,
  "videoId_yt": "adaQojODBFo",
  "videoId_vm": null,
  "videoId_yt_dmq": "W6tDL7MBzio",
  "videoId_svr": null,
  "videohref_yt": "https://www.youtube.com/watch?v=adaQojODBFo",
  "videohref_vm": null,
  "videohref_yt_dmq": "https://www.youtube.com/watch?v=W6tDL7MBzio",
  "videohref_svr": null,
  "title": "Los últimos",
  "synopsis": "Un grupo de jóvenes desconocidos pueden llegar a ser los últimos supervivientes en un lugar recóndito donde el poder irrumpe con fuerza hasta el punto de devastarlo todo. ¿Conseguirán mantenerse unidos para afrontar el reto de seguir vivos?",
  "description": null,
  "awards": null,
  "duration": "08:41",
  "starring": "Raquel Cabello, Mirella Reina, Aitana Reyes.",
  "producer": null,
  "director": "Carlos Poleo",
  "city": null,
  "country": "España",
  "year": 2020,
  "thumbnailurl": "https://img.youtube.com/vi/adaQojODBFo/maxresdefault.jpg",
  "posterurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter%2Fp21.jpg?alt=media&token=19861d8e-473b-44d2-977e-f9afc529a404",
  "poster375webpurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter375webp%2Fp21.webp?alt=media&token=d80a1397-88c1-49b5-a958-ab9304a187da",
  "bgurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fbg%2Fb21.jpg?alt=media&token=fd47675f-7566-445f-b2b3-d86e3ad8b1ac",
  "genres": "Drama",
  "age": "16+",
  "maxres": "4K 2160p",
  "type": "Cortometraje",
  "language": "ES",
  "status": "active",
  "fomo": 0,
  
 },
 {
  "id": 22,
  "videoId_yt": "Tpid1GhYivE",
  "videoId_vm": null,
  "videoId_yt_dmq": "b3wY-oEjheg",
  "videoId_svr": null,
  "videohref_yt": "https://www.youtube.com/watch?v=Tpid1GhYivE",
  "videohref_vm": null,
  "videohref_yt_dmq": "https://www.youtube.com/watch?v=b3wY-oEjheg",
  "videohref_svr": null,
  "title": "Estoy aquí",
  "synopsis": "Marta vive un momento duro en su instituto por el acoso de unas compañeras de clase. Dentro de todo su caos sueña sin embargo con días en los que sus compañeros la ayudan y protegen. Pero puede que sea tan solo eso, un sueño. ¿o puede que no?",
  "description": null,
  "awards": null,
  "duration": "07:46",
  "starring": "Azahara Escamilla, Aitana Reyes, Lorena Cabello.",
  "producer": null,
  "director": "Carlos Poleo",
  "city": null,
  "country": "España",
  "year": 2018,
  "thumbnailurl": "https://img.youtube.com/vi/Tpid1GhYivE/maxresdefault.jpg",
  "posterurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter%2Fp22.jpg?alt=media&token=11a53cac-164c-4efb-93f4-c06e90c21ceb",
  "poster375webpurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter375webp%2Fp22.webp?alt=media&token=b0437750-27c7-4f2d-8053-ed78b8aff9c4",
  "bgurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fbg%2Fb22.jpg?alt=media&token=489e0fc4-07b8-49d4-ad1b-26476b3b9a9e",
  "genres": "Drama Juvenil",
  "age": "13+",
  "maxres": "HD 1080p",
  "type": "Cortometraje",
  "language": "ES",
  "status": "active",
  "fomo": 0,
  
 },
 {
  "id": 23,
  "videoId_yt": "u4tNn0Sa7Kk",
  "videoId_vm": null,
  "videoId_yt_dmq": "NQJF92CBaK4",
  "videoId_svr": null,
  "videohref_yt": "https://www.youtube.com/watch?v=u4tNn0Sa7Kk",
  "videohref_vm": null,
  "videohref_yt_dmq": "https://www.youtube.com/watch?v=NQJF92CBaK4",
  "videohref_svr": null,
  "title": "Shock",
  "synopsis": "Porque la realidad es nuestro peor enemigo.",
  "description": "DIRECCIÓN Y FOTOGRAFÍA: Gabriel Villazón\nACTORES: Graciela Sanabria es Maryan, Mariela Olivera como la Madre, \nÁlvaro Michael Soto como el Padre, Alan Leandro Rojas como el hermano\nCAMARAS: Paul Romualdo Flores, Alan Leandro Rojas, Gabriel Villazón, Ronald David Acosta\nSONIDO: Ernesto Jesús García\nEDICIÓN Y MONTAJE: Gabriel Villazón\nCASTING: Ernesto Jesús García\nMUSICALIZACIÓN: Rock Still\nMAQUILLAJE: Gabriela Choque, Stefany Maita",
  "awards": null,
  "duration": "11:01",
  "starring": "Graciela Sanabria, Mariela Olivera, Álvaro Michael Soto.",
  "producer": null,
  "director": "Gabriel Villazón",
  "city": "Cochabamba",
  "country": "Bolivia",
  "year": 2019,
  "thumbnailurl": "https://img.youtube.com/vi/u4tNn0Sa7Kk/maxresdefault.jpg",
  "posterurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter%2Fp23.jpg?alt=media&token=0f31bd39-61af-43b9-917d-6d87a54f7368",
  "poster375webpurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter375webp%2Fp23.webp?alt=media&token=38a838b6-ada1-4565-86db-3177895b01fd",
  "bgurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fbg%2Fb23.jpg?alt=media&token=48b55a64-f75b-47ab-8f89-3b86b8da61c0",
  "genres": "Terror",
  "age": "16+",
  "maxres": "HD 1080p",
  "type": "Cortometraje",
  "language": "ES",
  "status": "active",
  "fomo": 0,
  
 },
 {
  "id": 24,
  "videoId_yt": "L9RiGUT0i5Q",
  "videoId_vm": null,
  "videoId_yt_dmq": "O_e6oMYiKJ8",
  "videoId_svr": null,
  "videohref_yt": "https://www.youtube.com/watch?v=L9RiGUT0i5Q",
  "videohref_vm": null,
  "videohref_yt_dmq": "https://www.youtube.com/watch?v=O_e6oMYiKJ8",
  "videohref_svr": null,
  "title": "Gajes del Oficio",
  "synopsis": "Alba una afamada periodista de investigación, confiada en lo que ha aprendido de su jefe Martín, se dispone a indagar un caso de corrupción cometido por un ministro muy influyente quién mediante artimañas y engaños la convierte en una víctima más de su poder que la lleva a un destino inesperado.",
  "description": "Dirección: Manuel Paulino\nProducción: Ivonne Mercader",
  "awards": null,
  "duration": "13:30",
  "starring": "Marilissa Garcia, Yvan Jimenez, Alvaro A. Guzman.",
  "producer": null,
  "director": "Manuel Paulino",
  "city": null,
  "country": "República Dominicana",
  "year": 2019,
  "thumbnailurl": "https://img.youtube.com/vi/L9RiGUT0i5Q/maxresdefault.jpg",
  "posterurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter%2Fp24.jpg?alt=media&token=4329c090-17c2-48a7-9dba-ba0c384551be",
  "poster375webpurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter375webp%2Fp24.webp?alt=media&token=3d7b2bd5-743f-4b14-8d8e-f801d7a58fa4",
  "bgurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fbg%2Fb24.jpg?alt=media&token=b91e3d5c-4089-4ae3-90c8-326ef412766b",
  "genres": "Acción",
  "age": "16+",
  "maxres": "4K 2160p",
  "type": "Cortometraje",
  "language": "ES",
  "status": "active",
  "fomo": 0,
  
 },
 {
  "id": 25,
  "videoId_yt": "v4oUfL1NSaE",
  "videoId_vm": null,
  "videoId_yt_dmq": "2vJ-6Y8VKYg",
  "videoId_svr": null,
  "videohref_yt": "https://www.youtube.com/watch?v=v4oUfL1NSaE",
  "videohref_vm": null,
  "videohref_yt_dmq": "https://www.youtube.com/watch?v=2vJ-6Y8VKYg",
  "videohref_svr": null,
  "title": "Caníbal",
  "synopsis": "Dicen que contar historias de terror a medianoche puede ser algo peligroso y esta vez decidimos ponerlo a prueba pero lo que nos ocurrió a continuación no lo puedo explicar, ¡Tienen que verlo con sus propios ojos! ",
  "description": null,
  "awards": null,
  "duration": "13:00",
  "starring": "Lizbeth Rodriguez, Edén Villavicencio, Lisette Cuevas.",
  "producer": null,
  "director": "César Rincón Jiménez",
  "city": null,
  "country": "México",
  "year": 2021,
  "thumbnailurl": "https://img.youtube.com/vi/v4oUfL1NSaE/maxresdefault.jpg",
  "posterurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter%2Fp25.jpg?alt=media&token=8f02a87d-f46e-4017-ad5f-24b9d23668c0",
  "poster375webpurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter375webp%2Fp25.webp?alt=media&token=37d54bb5-9ab5-480e-9d71-bfccc18c416b",
  "bgurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fbg%2Fb25.jpg?alt=media&token=5840b26c-2d64-4455-897d-48e58dbddbca",
  "genres": "Terror",
  "age": "16+",
  "maxres": "4K 2160p",
  "type": "Cortometraje",
  "language": "ES",
  "status": "active",
  "fomo": 0,
  
 },
 {
  "id": 26,
  "videoId_yt": "r9J3PFYXJig",
  "videoId_vm": null,
  "videoId_yt_dmq": "JkAGNvNnRZM",
  "videoId_svr": null,
  "videohref_yt": "https://www.youtube.com/watch?v=r9J3PFYXJig",
  "videohref_vm": null,
  "videohref_yt_dmq": "https://www.youtube.com/watch?v=JkAGNvNnRZM",
  "videohref_svr": null,
  "title": "Persuasión",
  "synopsis": "¿Has sentido alguna vez que alguien te persigue? Este hombre pasa por esa situación, un acoso agobiante y desesperado que lo lleva a tomar una decisión fatal.",
  "description": null,
  "awards": null,
  "duration": "06:17",
  "starring": null,
  "producer": null,
  "director": "Kobal Company - Kobal Entertainment",
  "city": "Cuenca",
  "country": "Ecuador",
  "year": 2019,
  "thumbnailurl": "https://img.youtube.com/vi/r9J3PFYXJig/maxresdefault.jpg",
  "posterurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter%2Fp26.jpg?alt=media&token=9fe442f9-1127-4301-95bb-deed73c6c63d",
  "poster375webpurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter375webp%2Fp26.webp?alt=media&token=f0f00dda-8d4d-454f-85cc-98afca6c52cf",
  "bgurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fbg%2Fb26.jpg?alt=media&token=5c7d794a-8406-47e3-bd70-0a01c18e547c",
  "genres": "Suspenso, Drama",
  "age": "16+",
  "maxres": "720p",
  "type": "Cortometraje",
  "language": "ES",
  "status": "active",
  "fomo": 0,
  
 },
 {
  "id": 27,
  "videoId_yt": "m5_eW9Hj28Y",
  "videoId_vm": null,
  "videoId_yt_dmq": "z1zVGdYmni8",
  "videoId_svr": null,
  "videohref_yt": "https://www.youtube.com/watch?v=m5_eW9Hj28Y",
  "videohref_vm": null,
  "videohref_yt_dmq": "https://www.youtube.com/watch?v=z1zVGdYmni8",
  "videohref_svr": null,
  "title": "Déjà vu",
  "synopsis": "En un futuro distópico, los viajes inter-dimensionales están totalmente prohibidos. Aún así, Alexis va contra todo en busca de una dimensión en la que Aisha siga viva.",
  "description": null,
  "awards": null,
  "duration": "14:59",
  "starring": "LUCIANO NUÑEZ, MARA LUCILA MANSILLA, ISAAC EISEN (EL FENÓMENO).",
  "producer": null,
  "director": "JAVIER AREDA",
  "city": null,
  "country": "Latinoamérica",
  "year": 2021,
  "thumbnailurl": "https://img.youtube.com/vi/m5_eW9Hj28Y/maxresdefault.jpg",
  "posterurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter%2Fp27.jpg?alt=media&token=6dd3ba0d-60c3-4713-8b88-548aa0c694aa",
  "poster375webpurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter375webp%2Fp27.webp?alt=media&token=ef6ef9df-e757-40ef-9ccb-74f6ba36a6a7",
  "bgurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fbg%2Fb27.jpg?alt=media&token=b2c651a8-b3be-4269-a34f-2ed32052921b",
  "genres": "Drama, Thriller",
  "age": "16+",
  "maxres": "4K 2160p",
  "type": "Cortometraje",
  "language": "ES",
  "status": "active",
  "fomo": 0,
  
 },
 {
  "id": 28,
  "videoId_yt": "uVOq0cR_ouU",
  "videoId_vm": null,
  "videoId_yt_dmq": "06voAirNOBA",
  "videoId_svr": null,
  "videohref_yt": "https://www.youtube.com/watch?v=uVOq0cR_ouU",
  "videohref_vm": null,
  "videohref_yt_dmq": "https://www.youtube.com/watch?v=06voAirNOBA",
  "videohref_svr": null,
  "title": "El intruso minecraft",
  "synopsis": "Una historia en el mundo de Minecraft.",
  "description": "Corto creado por Kolo y Killercreeper55",
  "awards": null,
  "duration": "17:28",
  "starring": null,
  "producer": null,
  "director": "Killercreeper55",
  "city": null,
  "country": "Latinoamérica",
  "year": 2021,
  "thumbnailurl": "https://img.youtube.com/vi/uVOq0cR_ouU/maxresdefault.jpg",
  "posterurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter%2Fp28.jpg?alt=media&token=4659e4f4-5644-4821-8757-c5db316abc04",
  "poster375webpurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter375webp%2Fp28.webp?alt=media&token=f9e6d4c7-3ee2-4121-a148-71c757c32953",
  "bgurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fbg%2Fb28.jpg?alt=media&token=d61cb25a-f43a-4f60-9c94-a89466261400",
  "genres": "Animación, Acción",
  "age": "11+",
  "maxres": "HD 1080p",
  "type": "Cortometraje",
  "language": "ES",
  "status": "active",
  "fomo": 0,
  
 },
 {
  "id": 29,
  "videoId_yt": "NunLm7zAm-w",
  "videoId_vm": null,
  "videoId_yt_dmq": "3Gz5Ut6zwlo",
  "videoId_svr": null,
  "videohref_yt": "https://www.youtube.com/watch?v=NunLm7zAm-w",
  "videohref_vm": null,
  "videohref_yt_dmq": "https://www.youtube.com/watch?v=3Gz5Ut6zwlo",
  "videohref_svr": null,
  "title": "Souvenir",
  "synopsis": "Tras el intento fallido de escapar de sí misma, termina destruyendo a lo que ama. ",
  "description": null,
  "awards": null,
  "duration": "07:36",
  "starring": "Salomé Vargas, Agustín Gómez.",
  "producer": null,
  "director": "Candelaria Abello",
  "city": null,
  "country": "Latinoamérica",
  "year": 2019,
  "thumbnailurl": "https://img.youtube.com/vi/NunLm7zAm-w/maxresdefault.jpg",
  "posterurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter%2Fp29.jpg?alt=media&token=901dadd6-13e3-4f2c-865d-c6dcf90d5d0a",
  "poster375webpurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter375webp%2Fp29.webp?alt=media&token=f626f9a2-767e-4ce7-bd7f-bf5f509a8ca3",
  "bgurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fbg%2Fb29.jpg?alt=media&token=323e20c5-9571-47ee-9142-e5a297d81a12",
  "genres": "Drama",
  "age": "13+",
  "maxres": "720p",
  "type": "Cortometraje",
  "language": "ES",
  "status": "active",
  "fomo": 0,
  
 },
 {
  "id": 30,
  "videoId_yt": "iypYEARxkjA",
  "videoId_vm": null,
  "videoId_yt_dmq": "WFuW2JxvNkc",
  "videoId_svr": null,
  "videohref_yt": "https://www.youtube.com/watch?v=iypYEARxkjA",
  "videohref_vm": null,
  "videohref_yt_dmq": "https://www.youtube.com/watch?v=WFuW2JxvNkc",
  "videohref_svr": null,
  "title": "El Cementerio",
  "synopsis": null,
  "description": "Dirigido por: Karlos Pin-Punk\nProducido por: Stephanie Torres Moro\nProtagonizado por: Ana Mariel Hernández Sánchez, Ricardo Badillo Vázquez, J.J. Samuel Becerril Brito, Armando Trujillo Miranda, María de la Paz Hernández Sánchez, Diego Ferran León\nMúsica por: Christian Salazar",
  "awards": null,
  "duration": "09:48",
  "starring": "Ana Mariel Hernández Sánchez, Ricardo Badillo Vázquez, J.J. Samuel Becerril Brito.",
  "producer": null,
  "director": "Karlos Pin-Punk",
  "city": null,
  "country": "México",
  "year": 2020,
  "thumbnailurl": "https://img.youtube.com/vi/iypYEARxkjA/maxresdefault.jpg",
  "posterurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter%2Fp30.jpg?alt=media&token=5208fefb-0de5-4746-bb43-c9cc38200777",
  "poster375webpurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter375webp%2Fp30.webp?alt=media&token=f561a3b6-c073-452c-92ec-1754816e912c",
  "bgurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fbg%2Fb30.jpg?alt=media&token=8782d1ed-5f1a-41de-a16f-553c17239d25",
  "genres": "Acción, Drama",
  "age": "16+",
  "maxres": "HD 1080p",
  "type": "Cortometraje",
  "language": "ES",
  "status": "active",
  "fomo": 0,
  
 },
 {
  "id": 31,
  "videoId_yt": "TtLqNQPCxPQ",
  "videoId_vm": null,
  "videoId_yt_dmq": "J0DiiladS1E",
  "videoId_svr": null,
  "videohref_yt": "https://www.youtube.com/watch?v=TtLqNQPCxPQ",
  "videohref_vm": null,
  "videohref_yt_dmq": "https://www.youtube.com/watch?v=J0DiiladS1E",
  "videohref_svr": null,
  "title": "Anomalía",
  "synopsis": "Ulises, un actor conocido por su franquicia de películas de acción, se queda sin trabajo y vive en decadencia. Al enterarse que será suplantado en una nueva entrega de la saga, su mente se fractura haciendo que su voz interna lo incite a dar un giro final en su vida y sentirse como la estrella que alguna vez fue.",
  "description": "Guión: Juan G. Rodriguez Idea Original: Franco Salemme Juan G. Rodriguez Dirección de Fotografía y Cámara: Cristian Santillan Asistente de producción: Carla Gill Producción: Hostel Films (Franco Salemme, Juan G. Rodriguez) Catering: Luis Fukunaga Dirección de arte: Guadalupe Rodríguez Petersen Maquillaje: Fernanda Muñoz Sonido Directo: Wilmer Ibague, Sofia Caracotche Diseño sonoro y Mezcla: Nicolas Carcagno Música original: Camila Fawape Montaje: Juan G. Rodriguez Asistente de Montaje: Nataly Funes  ",
  "awards": null,
  "duration": "21:43",
  "starring": "Franco Salemme, Ayelén Escalzo Nuñez, Fernando Alegre.",
  "producer": null,
  "director": "Juan G. Rodriguez",
  "city": null,
  "country": "Argentina",
  "year": 2021,
  "thumbnailurl": "https://img.youtube.com/vi/TtLqNQPCxPQ/maxresdefault.jpg",
  "posterurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter%2Fp31.jpg?alt=media&token=a62b3ad3-e50d-4f1b-8ff2-2a9dca218303",
  "poster375webpurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter375webp%2Fp31.webp?alt=media&token=51364fad-7ec7-4dd5-98c3-1f8465a31535",
  "bgurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fbg%2Fb31.jpg?alt=media&token=6eed7182-aaef-441b-bc3e-0f708e41e28f",
  "genres": "Thriller",
  "age": "16+",
  "maxres": "4K 2160p",
  "type": "Cortometraje",
  "language": "ES",
  "status": "active",
  "fomo": 0,
  
 },
 {
  "id": 32,
  "videoId_yt": "roWI0z82zDQ",
  "videoId_vm": null,
  "videoId_yt_dmq": "rnAw1zrNyCk",
  "videoId_svr": null,
  "videohref_yt": "https://www.youtube.com/watch?v=roWI0z82zDQ",
  "videohref_vm": null,
  "videohref_yt_dmq": "https://www.youtube.com/watch?v=rnAw1zrNyCk",
  "videohref_svr": null,
  "title": "¿Me Puedes Ayudar?",
  "synopsis": "Dos chicas, un baúl, un piso, ¿Alguien las ayuda a subirlo? ¿Qué hay dentro de ese baúl? Una comedia donde las apariencias engañan.",
  "description": null,
  "awards": null,
  "duration": "10:44",
  "starring": "Patricia Teruel, Victoria Avinyó, Joan Montañana.",
  "producer": null,
  "director": "Alfredo Montesinos y Victoria Avinyó",
  "city": null,
  "country": "España",
  "year": 2021,
  "thumbnailurl": "https://img.youtube.com/vi/roWI0z82zDQ/maxresdefault.jpg",
  "posterurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter%2Fp32.jpg?alt=media&token=3d48beee-cec6-47ac-af76-7b4623ec70f4",
  "poster375webpurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter375webp%2Fp32.webp?alt=media&token=e379edac-a51f-4e12-b8ea-b19b768bf629",
  "bgurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fbg%2Fb32.jpg?alt=media&token=520e3596-05e9-4aeb-9771-5a1cf1b0920b",
  "genres": "Comedia",
  "age": "13+",
  "maxres": "4K 2160p",
  "type": "Cortometraje",
  "language": "ES",
  "status": "active",
  "fomo": 0,
  
 },
 {
  "id": 33,
  "videoId_yt": "M_-bTNipYXs",
  "videoId_vm": null,
  "videoId_yt_dmq": "4BvvDJ8FOGA",
  "videoId_svr": null,
  "videohref_yt": "https://www.youtube.com/watch?v=M_-bTNipYXs",
  "videohref_vm": null,
  "videohref_yt_dmq": "https://www.youtube.com/watch?v=4BvvDJ8FOGA",
  "videohref_svr": null,
  "title": "Pantomimus",
  "synopsis": "Es una historia narrada en lenguaje de señas, donde se nos cuenta el momento previo de un mimo antes de salir a presentar su acto en el escenario. El mimo bajo estrés y pánico, se le presentan la tragedia y la comedia para ayudarlo a combatir sus temores.",
  "description": null,
  "awards": null,
  "duration": "15:13",
  "starring": "José Espinoza, Abisai Torres, Frida Garza.",
  "producer": null,
  "director": "Clío Acostas",
  "city": null,
  "country": "México",
  "year": 2021,
  "thumbnailurl": "https://img.youtube.com/vi/M_-bTNipYXs/maxresdefault.jpg",
  "posterurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter%2Fp33.jpg?alt=media&token=3ea0dc29-8028-40ae-9496-34176df6a7ed",
  "poster375webpurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter375webp%2Fp33.webp?alt=media&token=79f08838-1a58-4e65-a3be-d95730e91665",
  "bgurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fbg%2Fb33.jpg?alt=media&token=f77259b6-84e9-4e33-84d0-9266cb03c940",
  "genres": "Comedia, Drama",
  "age": "16+",
  "maxres": "4K 2160p",
  "type": "Cortometraje",
  "language": "ES",
  "status": "active",
  "fomo": 0,
  
 },
 {
  "id": 34,
  "videoId_yt": "XxCRwcm7C0s",
  "videoId_vm": null,
  "videoId_yt_dmq": "zZNWzHaswfQ",
  "videoId_svr": null,
  "videohref_yt": "https://www.youtube.com/watch?v=XxCRwcm7C0s",
  "videohref_vm": null,
  "videohref_yt_dmq": "https://www.youtube.com/watch?v=zZNWzHaswfQ",
  "videohref_svr": null,
  "title": "Puñalada",
  "synopsis": "Después de una ruptura, Juan Carlos se encuentra con sus amigos, con quienes tendrá una conversación casual, que llevará a una inesperada confesión. ",
  "description": null,
  "awards": null,
  "duration": "11:32",
  "starring": "Merly Morello, Fausto Molina, Thiago Vernal.",
  "producer": null,
  "director": "Samuel Sunderland",
  "city": "Lima",
  "country": "Perú",
  "year": 2020,
  "thumbnailurl": "https://img.youtube.com/vi/XxCRwcm7C0s/maxresdefault.jpg",
  "posterurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter%2Fp34.jpg?alt=media&token=3f153b2e-a8e9-4308-bd9c-1c7617220d47",
  "poster375webpurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter375webp%2Fp34.webp?alt=media&token=eb04b27a-a97b-4d97-8e0c-5081ee493f9c",
  "bgurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fbg%2Fb34.jpg?alt=media&token=26806ddd-d30e-4059-90a1-8beb692a0988",
  "genres": "Romance, Drama",
  "age": "13+",
  "maxres": "HD 1080p",
  "type": "Cortometraje",
  "language": "ES",
  "status": "active",
  "fomo": 0,
  
 },
 {
  "id": 35,
  "videoId_yt": "FraqWdgPiu4",
  "videoId_vm": null,
  "videoId_yt_dmq": "Z2WLf61U0lE",
  "videoId_svr": null,
  "videohref_yt": "https://www.youtube.com/watch?v=FraqWdgPiu4",
  "videohref_vm": null,
  "videohref_yt_dmq": "https://www.youtube.com/watch?v=Z2WLf61U0lE",
  "videohref_svr": null,
  "title": "Una Vida Social Redes sociales",
  "synopsis": "Despertarse. Antes de salir de la cama, mirar el teléfono móvil. Levantarse, calzarse las zapatillas de correr. Hacerles una foto y subirla. Más tarde, comprar fruta y verdura. Fotografiar los productos y subir la foto. Este tipo de cosas son muy habituales desde el estallido de las redes sociales, pero, ¿son reales?",
  "description": "Una vida social es el título de un cortometraje creado por la directora Kerith Lemon, que ha recibido varios premios por su retrato de la vacuidad de muchos de nuestros comportamientos en las redes.\n\nRecoge la vida de Meredith, una joven que vive obsesionada con la imagen que da en las redes sociales, y que resulta ser totalmente falsa: aunque se pone zapatillas y compra fruta y verdura, no corre y consume comida rápida.\n\nEl vídeo es una crítica a lo que se ha venido a llamar 'postureo' en las redes sociales, y propone un mensaje liberador de la dictadura de lo correcto.\n\nEl trabajo ha sido premiado en el Canberra International Short Film Festival, en Australia, y el Lady Filmmakers Festival de Pacific Grove, California.",
  "awards": null,
  "duration": "08:26",
  "starring": "Rosalind Ruth Ross",
  "producer": null,
  "director": "Kerith Lemon",
  "city": null,
  "country": "España",
  "year": 2021,
  "thumbnailurl": "https://img.youtube.com/vi/FraqWdgPiu4/maxresdefault.jpg",
  "posterurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter%2Fp35.jpg?alt=media&token=3efc4890-b75c-4b58-a2ca-09b6c3cb4814",
  "poster375webpurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter375webp%2Fp35.webp?alt=media&token=480a3027-bbf5-4b1c-9f93-9a764df327e2",
  "bgurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fbg%2Fb35.jpg?alt=media&token=59196d24-d905-46d2-84e3-c1e239c7b220",
  "genres": "Drama",
  "age": "13+",
  "maxres": "720p",
  "type": "Cortometraje",
  "language": "ES",
  "status": "active",
  "fomo": 0,
  
 },
 {
  "id": 36,
  "videoId_yt": "6mvxbr_YKJE",
  "videoId_vm": null,
  "videoId_yt_dmq": "fjNFgOUDCG8",
  "videoId_svr": null,
  "videohref_yt": "https://www.youtube.com/watch?v=6mvxbr_YKJE",
  "videohref_vm": null,
  "videohref_yt_dmq": "https://www.youtube.com/watch?v=fjNFgOUDCG8",
  "videohref_svr": null,
  "title": "Presa",
  "synopsis": "Candela y Pedro, dos jóvenes muy distintos, se conocen por Tinder. Guillermo insiste en acelerar su encuentro, pero Candela tiene otros planes para su relación. Historia que mezcla humor, suspenso y drama. Con un final insperado.",
  "description": "Solo el 24% de los jóvenes prefiere hablar cara a cara. El auge de las nuevas tecnologías ha modificado las conductas de comunicación y los jóvenes están cada vez más inmersos en las redes sociales.",
  "awards": null,
  "duration": "07:29",
  "starring": "Candela Hernández y Manuel Bello",
  "producer": null,
  "director": "Mauricio Plada",
  "city": "Montevideo",
  "country": "Uruguay",
  "year": 2018,
  "thumbnailurl": "https://img.youtube.com/vi/6mvxbr_YKJE/maxresdefault.jpg",
  "posterurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter%2Fp36.jpg?alt=media&token=75489ef2-94a2-4aa2-80a9-f0dd9dac21ca",
  "poster375webpurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter375webp%2Fp36.webp?alt=media&token=84b5a642-763c-4157-8b24-da40fa4de416",
  "bgurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fbg%2Fb36.jpg?alt=media&token=0b4330fe-3583-472d-85fa-ff617d8dc56f",
  "genres": "Drama",
  "age": "13+",
  "maxres": "HD 1080p",
  "type": "Cortometraje",
  "language": "ES",
  "status": "active",
  "fomo": 1,
  
 },
 {
  "id": 37,
  "videoId_yt": "-ONVlK0EEUw",
  "videoId_vm": null,
  "videoId_yt_dmq": "2VN2XLsFYTc",
  "videoId_svr": null,
  "videohref_yt": "https://www.youtube.com/watch?v=-ONVlK0EEUw",
  "videohref_vm": null,
  "videohref_yt_dmq": "https://www.youtube.com/watch?v=2VN2XLsFYTc",
  "videohref_svr": null,
  "title": "Era yo",
  "synopsis": "María, una adolescente con un carácter arrollador, cree tener el derecho a hacer lo que quiera y cuando quiera. Lo que no sabe es que, a veces en la vida, uno también recibe lo que da.",
  "description": null,
  "awards": null,
  "duration": "11:36",
  "starring": "Marta Belenguer, María Rivera, Fátima Baeza.",
  "producer": null,
  "director": "Andrea Casaseca",
  "city": null,
  "country": "España",
  "year": 2021,
  "thumbnailurl": "https://img.youtube.com/vi/-ONVlK0EEUw/maxresdefault.jpg",
  "posterurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter%2Fp37.jpg?alt=media&token=3975804f-4221-4ec3-aea0-e0c9c264fa4a",
  "poster375webpurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter375webp%2Fp37.webp?alt=media&token=3b208c5d-7359-4d95-9cad-7a379bc87035",
  "bgurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fbg%2Fb37.jpg?alt=media&token=510e425f-454f-4ad9-a8d1-d537437c55e6",
  "genres": "Drama",
  "age": "16+",
  "maxres": "HD 1080p",
  "type": "Cortometraje",
  "language": "ES",
  "status": "active",
  "fomo": 0,
  
 },
 {
  "id": 38,
  "videoId_yt": "KikbdhyguTQ",
  "videoId_vm": null,
  "videoId_yt_dmq": "c_gXXbR-cxQ",
  "videoId_svr": null,
  "videohref_yt": "https://www.youtube.com/watch?v=KikbdhyguTQ",
  "videohref_vm": null,
  "videohref_yt_dmq": "https://www.youtube.com/watch?v=c_gXXbR-cxQ",
  "videohref_svr": null,
  "title": "Maniquí",
  "synopsis": "Un cortometraje fantástico que relata la amistad de una niña y un maniquí a lo largo del tiempo . La mamá de la niña es  cómplice  y testigo de la magia.\nUna niña. Una mirada.\nEl instante mágico de la imaginación.",
  "description": null,
  "awards": null,
  "duration": "04:49",
  "starring": "Aliwue Finucci C, Rodrigo Iseppi, Paula Finucci.",
  "producer": null,
  "director": "Rodrigo Iseppi",
  "city": null,
  "country": "Argentina",
  "year": 2021,
  "thumbnailurl": "https://i3.ytimg.com/vi/KikbdhyguTQ/hqdefault.jpg",
  "posterurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter%2Fp38.jpg?alt=media&token=8d61d001-193b-46c5-9e25-60bc74409687",
  "poster375webpurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter375webp%2Fp38.webp?alt=media&token=7e75f76b-d2e5-447c-97cd-0d7a72972d87",
  "bgurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fbg%2Fb38.jpg?alt=media&token=1841f658-bdb0-4510-8576-cc2bd47579a7",
  "genres": "Fantasía, Comedia",
  "age": null,
  "maxres": "HD 1080p",
  "type": "Cortometraje",
  "language": "ES",
  "status": "active",
  "fomo": 0,
  "Column31": "https://www.instagram.com/rorroiseppi/"
 },
 {
  "id": 39,
  "videoId_yt": "8fcoWBYHVIA",
  "videoId_vm": null,
  "videoId_yt_dmq": "CvjnsVELiYk",
  "videoId_svr": null,
  "videohref_yt": "https://www.youtube.com/watch?v=8fcoWBYHVIA",
  "videohref_vm": null,
  "videohref_yt_dmq": "https://www.youtube.com/watch?v=CvjnsVELiYk",
  "videohref_svr": null,
  "title": "Olvido",
  "synopsis": "Es la historia de una mujer que se encuentra en una plaza sin saber dónde está y cómo llegó ahí, hasta que un hombre decide ofrecerle ayuda.",
  "description": null,
  "awards": null,
  "duration": "07:28",
  "starring": "Merly Morello, Gabriel Rondón.",
  "producer": null,
  "director": "Rodrigo Sáez Díaz",
  "city": "Lima",
  "country": "Perú",
  "year": 2021,
  "thumbnailurl": "https://img.youtube.com/vi/8fcoWBYHVIA/maxresdefault.jpg",
  "posterurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter%2Fp39.jpg?alt=media&token=d116896a-2b74-4445-88c9-51cde238943b",
  "poster375webpurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter375webp%2Fp39.webp?alt=media&token=ffebdeab-c78b-41d7-ba2b-09f0b788b9a4",
  "bgurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fbg%2Fb39.jpg?alt=media&token=22accde5-2ad6-4379-8a52-f9e21aadea10",
  "genres": "Suspenso, Drama",
  "age": "13+",
  "maxres": "HD 1080p",
  "type": "Cortometraje",
  "language": "ES",
  "status": "active",
  "fomo": 0,
  
 },
 {
  "id": 40,
  "videoId_yt": "L2vta4-cv7M",
  "videoId_vm": null,
  "videoId_yt_dmq": "GnRxjRiASZE",
  "videoId_svr": null,
  "videohref_yt": "https://www.youtube.com/watch?v=L2vta4-cv7M",
  "videohref_vm": null,
  "videohref_yt_dmq": "https://www.youtube.com/watch?v=GnRxjRiASZE",
  "videohref_svr": null,
  "title": "De La Mano",
  "synopsis": "Dos chicas, dos amantes, una pareja. Viven y son felices pero algo falta.",
  "description": "Elenco: Eugenia María Del Bono como Renata, Malena Kirschner como Isabella, Ángela Gerpe como Marta, Germán Paez como Héctor\nProductora: Guillermina Montegna\nDirectora: Eloisa Alfeiran\nAsistentes de dirección: Rocío Belen Palacios, María Eugenia Garibotti\nIdea: María Eugenia Garibotti, Guillermina Montegna, Valentina Montegna, Rocío Belen Palacios, Eloisa Alfeiran, David Galeano, Emilio María Del Bono, María Victoria Pisani Di Martino, Magalí Rocío Noguera Fernandez, Marina Chiara Bertinotti\nGuion: María Eugenia Garibotti, David Galeano",
  "awards": null,
  "duration": "23:20",
  "starring": "Eugenia María Del Bono, Malena Kirschner, Ángela Gerpe.",
  "producer": null,
  "director": "Eloisa Alfeiran",
  "city": null,
  "country": "Latinoamérica",
  "year": 2021,
  "thumbnailurl": "https://img.youtube.com/vi/L2vta4-cv7M/maxresdefault.jpg",
  "posterurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter%2Fp40.jpg?alt=media&token=1c1843ed-7760-44e8-8368-d95a9f560450",
  "poster375webpurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter375webp%2Fp40.webp?alt=media&token=d475ea6c-bd90-4d57-ad68-378529afbd68",
  "bgurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fbg%2Fb40.jpg?alt=media&token=3eba215e-acd2-479c-b9ef-c9feffa24f36",
  "genres": "Drama",
  "age": "16+",
  "maxres": "HD 1080p",
  "type": "Cortometraje",
  "language": "ES",
  "status": "active",
  "fomo": 0,
  
 },
 {
  "id": 41,
  "videoId_yt": "RT40ml6WSAU",
  "videoId_vm": null,
  "videoId_yt_dmq": "rZFnPFmONDE",
  "videoId_svr": null,
  "videohref_yt": "https://www.youtube.com/watch?v=RT40ml6WSAU",
  "videohref_vm": null,
  "videohref_yt_dmq": "https://www.youtube.com/watch?v=rZFnPFmONDE",
  "videohref_svr": null,
  "title": "Vecinos",
  "synopsis": "Hemos escuchado sobre los Ángeles Custodios desde que somos pequeños y hasta les hemos rezado. Sin embargo, al crecer, nos olvidamos de ellos y pensamos que son personajes de cuentos para niños. ¿Sabías que la Iglesia Católica los proclama como verdades de fe? Con el fin de reivindicar a nuestros guardianes olvidados, nace “Vecinos”.",
  "description": null,
  "awards": null,
  "duration": "11:35",
  "starring": null,
  "producer": null,
  "director": "Jonatan Medina",
  "city": "Lima",
  "country": "Perú",
  "year": 2021,
  "thumbnailurl": "https://img.youtube.com/vi/RT40ml6WSAU/maxresdefault.jpg",
  "posterurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter%2Fp41.jpg?alt=media&token=956fab9b-d96c-4196-a723-67fb9e1021fd",
  "poster375webpurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter375webp%2Fp41.webp?alt=media&token=bec9fcb6-2228-4f1a-8e5e-8ddb8647d3e9",
  "bgurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fbg%2Fb41.jpg?alt=media&token=81c4f62a-6e10-4b53-8a98-2f3a6d64d0b6",
  "genres": "Drama",
  "age": "16+",
  "maxres": "HD 1080p",
  "type": "Cortometraje",
  "language": "ES",
  "status": "active",
  "fomo": 0,
  
 },
 {
  "id": 42,
  "videoId_yt": "QpCTF-L7x14",
  "videoId_vm": null,
  "videoId_yt_dmq": "-SDa0BQ7HGs",
  "videoId_svr": null,
  "videohref_yt": "https://www.youtube.com/watch?v=QpCTF-L7x14",
  "videohref_vm": null,
  "videohref_yt_dmq": "https://www.youtube.com/watch?v=-SDa0BQ7HGs",
  "videohref_svr": null,
  "title": "Un 9 de abril, Colombia",
  "synopsis": "Cortometraje que nos recuerda lo sucedido el 9 de Abril de 1948 y que ya muchos de nosotros hemos olvidado.",
  "description": null,
  "awards": null,
  "duration": "14:43",
  "starring": null,
  "producer": null,
  "director": "Edgar Álvarez",
  "city": null,
  "country": "Colombia",
  "year": 2021,
  "thumbnailurl": "https://img.youtube.com/vi/QpCTF-L7x14/maxresdefault.jpg",
  "posterurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter%2Fp42.jpg?alt=media&token=df59ede6-bf97-48ac-983a-a0631267f055",
  "poster375webpurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter375webp%2Fp42.webp?alt=media&token=7c37b096-8a39-428c-91f3-7171d12d545b",
  "bgurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fbg%2Fb42.jpg?alt=media&token=032e9bf3-850e-4a49-81ac-d3b714137b0a",
  "genres": "Animación, Drama",
  "age": "11+",
  "maxres": "HD 1080p",
  "type": "Cortometraje",
  "language": "ES",
  "status": "active",
  "fomo": 0,
  
 },
 {
  "id": 43,
  "videoId_yt": "dgJD8zjxg2E",
  "videoId_vm": null,
  "videoId_yt_dmq": "4-_QQLydaYs",
  "videoId_svr": null,
  "videohref_yt": "https://www.youtube.com/watch?v=dgJD8zjxg2E",
  "videohref_vm": null,
  "videohref_yt_dmq": "https://www.youtube.com/watch?v=4-_QQLydaYs",
  "videohref_svr": null,
  "title": "Moiré",
  "synopsis": "Sergio es un niño tímido y con pocos amigos al que su abuela ayuda, como todas, a cumplir sus deseos: durante una hora al día el pequeño se encierra bajo llave en una habitación para hacer lo que más le gusta. 60 minutos diarios en los que la oscuridad de su entorno se transforma en luz.\nEste secreto a voces no le gusta nada a su autoritario abuelo, empeñado en hacer sufrir al niño ante la presencia de una madre y una abuela hartas del carácter dictatorial del anciano.",
  "description": "La triste realidad que se vive en silencio con una persona dominante y abusadora. Ser transexual y convivir con éste tipo de personas suele ser una auténtica pesadilla.",
  "awards": null,
  "duration": "14:59",
  "starring": "INMA CUESTA, ANA GRACIA, HUGO ARBÚES.",
  "producer": null,
  "director": "JUANCHO BAÑUELOS & ESTEFANÍA CORTÉS",
  "city": null,
  "country": "España",
  "year": 2014,
  "thumbnailurl": "https://i3.ytimg.com/vi/dgJD8zjxg2E/hqdefault.jpg",
  "posterurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter%2Fp43.jpg?alt=media&token=3ce9ffcf-ae07-4842-ae5f-d43039e01832",
  "poster375webpurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter375webp%2Fp43.webp?alt=media&token=7c083a30-8c29-449e-8f19-662cf18a0e8c",
  "bgurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fbg%2Fb43.jpg?alt=media&token=0115ffca-1f1a-4efb-82c8-7806a53bc845",
  "genres": "Drama",
  "age": "16+",
  "maxres": "HD 1080p",
  "type": "Cortometraje",
  "language": "ES",
  "status": "active",
  "fomo": 1,
  
 },
 {
  "id": 44,
  "videoId_yt": "UPjCZlgOPbs",
  "videoId_vm": null,
  "videoId_yt_dmq": "LUFZHhiKCh0",
  "videoId_svr": null,
  "videohref_yt": "https://www.youtube.com/watch?v=UPjCZlgOPbs",
  "videohref_vm": null,
  "videohref_yt_dmq": "https://www.youtube.com/watch?v=LUFZHhiKCh0",
  "videohref_svr": null,
  "title": "Dalia Sigue Aquí",
  "synopsis": "Dalia Sigue Aquí' es una historia hecha a mano sobre 5 mil 200 periódicos que pretende dar atención al tema de desapariciones en México y el incansable espíritu de los familiares que buscan a sus seres queridos.",
  "description": null,
  "awards": null,
  "duration": "11:32",
  "starring": "Nuria Menchaca, Carlos Isaac González.",
  "producer": null,
  "director": "Nuria Menchaca",
  "city": null,
  "country": "México",
  "year": 2021,
  "thumbnailurl": "https://img.youtube.com/vi/UPjCZlgOPbs/maxresdefault.jpg",
  "posterurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter%2Fp44.jpg?alt=media&token=4d37c3f9-52d2-415e-8c65-de90de6c7897",
  "poster375webpurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter375webp%2Fp44.webp?alt=media&token=cf63295b-d31a-4de1-a675-85267ac2b6b9",
  "bgurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fbg%2Fb44.jpg?alt=media&token=51a6c2bb-ede1-45f7-aebd-e4e619abda80",
  "genres": "Drama",
  "age": "13+",
  "maxres": "HD 1080p",
  "type": "Cortometraje",
  "language": "ES",
  "status": "active",
  "fomo": 0,
  
 },
 {
  "id": 45,
  "videoId_yt": "TOPYesLQquk",
  "videoId_vm": null,
  "videoId_yt_dmq": "yJgn2ok0yoI",
  "videoId_svr": null,
  "videohref_yt": "https://www.youtube.com/watch?v=TOPYesLQquk",
  "videohref_vm": null,
  "videohref_yt_dmq": "https://www.youtube.com/watch?v=yJgn2ok0yoI",
  "videohref_svr": null,
  "title": "Pizza Fría",
  "synopsis": "Un cortometraje independiente y autoproducido de genero musical dividido en tres canciones que nos presentan la Fantasía, Realidad y Bajona de una noche de ligoteo en la pista de baile.",
  "description": null,
  "awards": null,
  "duration": "15:51",
  "starring": "Dani Rosado y Conchi Espejo.",
  "producer": null,
  "director": "Daniel Cuenca",
  "city": "Madrid",
  "country": "España",
  "year": 2022,
  "thumbnailurl": "https://img.youtube.com/vi/TOPYesLQquk/maxresdefault.jpg",
  "posterurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter%2Fp45.jpg?alt=media&token=f3ae79cd-cc4d-498a-bf69-4c8f8ab9b6bf",
  "poster375webpurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter375webp%2Fp45.webp?alt=media&token=e3fe2054-e2cf-4c4d-ad7a-d7aa016bf59d",
  "bgurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fbg%2Fb45.jpg?alt=media&token=97f3d927-1c1f-4b39-b8eb-b225a5ec8d0e",
  "genres": "Romance, Musical, Comedia",
  "age": "13+",
  "maxres": "4K 2160p",
  "type": "Cortometraje",
  "language": "ES",
  "status": "active",
  "fomo": 0,
  
 },
 {
  "id": 46,
  "videoId_yt": "3spfarmUrIU",
  "videoId_vm": null,
  "videoId_yt_dmq": "EBlPrY7OnEE",
  "videoId_svr": null,
  "videohref_yt": "https://www.youtube.com/watch?v=3spfarmUrIU",
  "videohref_vm": null,
  "videohref_yt_dmq": "https://www.youtube.com/watch?v=EBlPrY7OnEE",
  "videohref_svr": null,
  "title": "Bihar: Elegir el mañana",
  "synopsis": "BIHAR: Elegir el Mañana es un ejercicio de pausa, de mirar lo que está cambiando y sobre todo, de reflexión futura sobre lo que puede suceder si seguimos apostando por modelos no sostenibles.  El futuro de BIHAR, nuestro futuro, está en el presente. \n\nCon cada decisión elegimos si hundirnos, o sacar la cabeza. ",
  "description": "Vivimos en un mundo en el que la velocidad, la inmediatez o la facilidad determinan muchas de nuestras decisiones. Este mismo ritmo acelerado de vida hace que los cambios que ocurren a nuestro alrededor muchas veces pasen desapercibidos.",
  "awards": null,
  "duration": "17:01",
  "starring": "Gabriela Patilla, Noemí Cupido, Agurtzane Zubizarreta.",
  "producer": null,
  "director": "Claudia Barral",
  "city": "Bilbao",
  "country": "España",
  "year": 2021,
  "thumbnailurl": "https://img.youtube.com/vi/3spfarmUrIU/maxresdefault.jpg",
  "posterurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter%2Fp46.jpg?alt=media&token=40853da9-a664-4f06-aafb-ecb9d3d6bff2",
  "poster375webpurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter375webp%2Fp46.webp?alt=media&token=f2e32077-aa10-4ca9-99d1-24c685c37377",
  "bgurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fbg%2Fb46.jpg?alt=media&token=3aebc6db-a935-4059-b318-3ddcd1ea0d5a",
  "genres": "Drama",
  "age": "16+",
  "maxres": "HD 1080p",
  "type": "Cortometraje",
  "language": "ES",
  "status": "active",
  "fomo": 0,
  
 },
 {
  "id": 47,
  "videoId_yt": "BrCCCLajE6I",
  "videoId_vm": null,
  "videoId_yt_dmq": "SLeWMkcx8qA",
  "videoId_svr": null,
  "videohref_yt": "https://www.youtube.com/watch?v=BrCCCLajE6I",
  "videohref_vm": null,
  "videohref_yt_dmq": "https://www.youtube.com/watch?v=SLeWMkcx8qA",
  "videohref_svr": null,
  "title": "¡Sin Conexión!",
  "synopsis": "Astrid se queda sola en casa y decide pasar la noche viendo su programa favorito, cuando su mejor amiga Sabrina le escribe para salir de fiesta pero se da cuenta que no puede responder sus mensajes, de repente una entidad extraña le envía fotos a su teléfono que la esta observando. Desde ese momento todo se vuelve oscuro y sus peores sueños comienzan a sentirse reales y no puede salir de ellos.",
  "description": null,
  "awards": null,
  "duration": "10:16",
  "starring": "Oriana de Deus.",
  "producer": null,
  "director": "Hendel Romero",
  "city": "Maracay",
  "country": "Venezuela",
  "year": 2021,
  "thumbnailurl": "https://img.youtube.com/vi/BrCCCLajE6I/maxresdefault.jpg",
  "posterurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter%2Fp47.jpg?alt=media&token=b167270a-04c2-4482-a7a6-e30c10f270f3",
  "poster375webpurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter375webp%2Fp47.webp?alt=media&token=4d042de3-d555-4c14-bf32-5ef890ea501a",
  "bgurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fbg%2Fb47.jpg?alt=media&token=e4c85264-de0d-43f3-a634-b1c7eb56097e",
  "genres": "Terror",
  "age": "16+",
  "maxres": "HD 1080p",
  "type": "Cortometraje",
  "language": "ES",
  "status": "active",
  "fomo": 0,
  
 },
 {
  "id": 48,
  "videoId_yt": "-T2APdfl9v4",
  "videoId_vm": null,
  "videoId_yt_dmq": "37Cg_mvNwaI",
  "videoId_svr": null,
  "videohref_yt": "https://www.youtube.com/watch?v=-T2APdfl9v4",
  "videohref_vm": null,
  "videohref_yt_dmq": "https://www.youtube.com/watch?v=37Cg_mvNwaI",
  "videohref_svr": null,
  "title": "Hiena",
  "synopsis": "Hiena es una historia de ficción que nos narra el desenlace de una relación tóxica. Ese tipo de relación en la que el maltrato físico y psicológico llega a tal punto que sólo nos deja dos posibles escapatorias, morir o matar.",
  "description": null,
  "awards": null,
  "duration": "06:43",
  "starring": null,
  "producer": null,
  "director": "Brian Lee Arnold",
  "city": null,
  "country": "España",
  "year": 2021,
  "thumbnailurl": "https://img.youtube.com/vi/-T2APdfl9v4/maxresdefault.jpg",
  "posterurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter%2Fp48.jpg?alt=media&token=94fcb656-222e-4195-83b1-5baa384020c9",
  "poster375webpurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter375webp%2Fp48.webp?alt=media&token=ac19909f-0a34-469f-a595-f585e76914a5",
  "bgurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fbg%2Fb48.jpg?alt=media&token=ae7d402f-6862-4829-88e1-aa6149e7eee1",
  "genres": "Animación, Thriller, Drama",
  "age": "13+",
  "maxres": "HD 1080p",
  "type": "Cortometraje",
  "language": "ES",
  "status": "active",
  "fomo": 0,
  
 },
 {
  "id": 49,
  "videoId_yt": "YSW0O-kEeBg",
  "videoId_vm": null,
  "videoId_yt_dmq": "G4qCeW4f3t0",
  "videoId_svr": null,
  "videohref_yt": "https://www.youtube.com/watch?v=YSW0O-kEeBg",
  "videohref_vm": null,
  "videohref_yt_dmq": "https://www.youtube.com/watch?v=G4qCeW4f3t0",
  "videohref_svr": null,
  "title": "Gato",
  "synopsis": "Drama en blanco y negro.",
  "description": "GATO - Cortometraje (2021) - Short film written and directed by David Bohorquez",
  "awards": null,
  "duration": "07:16",
  "starring": "Johanna Ospina, Germán Naranjo.",
  "producer": null,
  "director": "David Bohorquez",
  "city": null,
  "country": "Colombia",
  "year": 2021,
  "thumbnailurl": "https://img.youtube.com/vi/YSW0O-kEeBg/maxresdefault.jpg",
  "posterurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter%2Fp49.jpg?alt=media&token=8a59f134-71cb-4312-9660-f47a4a967021",
  "poster375webpurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter375webp%2Fp49.webp?alt=media&token=bf6ce2f5-dffa-40f0-a116-720372a9463e",
  "bgurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fbg%2Fb49.jpg?alt=media&token=f46ac4ed-8cf3-4216-8486-ecc23c27de7c",
  "genres": "Drama",
  "age": "16+",
  "maxres": "HD 1080p",
  "type": "Cortometraje",
  "language": "ES",
  "status": "active",
  "fomo": 0,
  
 },
 {
  "id": 50,
  "videoId_yt": "vC2atbR_09Y",
  "videoId_vm": null,
  "videoId_yt_dmq": "ypWRJYHJZ68",
  "videoId_svr": null,
  "videohref_yt": "https://www.youtube.com/watch?v=vC2atbR_09Y",
  "videohref_vm": null,
  "videohref_yt_dmq": "https://www.youtube.com/watch?v=ypWRJYHJZ68",
  "videohref_svr": null,
  "title": "Quimera",
  "synopsis": "David es un hombre de 45 años que vive en Sevilla desde hace  unos años con su hija Laura de 10 años. Hoy es el cumpleaños de  la pequeña y su padre le tiene preparada un día increíble fuera de casa. Los dos se lo pasan en grande pero ya es hora de volver.  \nCuando llegan, Laura deja las cosas en su cuarto y se dirige  rápidamente al salón, está ansiosa esperando su tarta. Mientras  tanto David se encuentra en la cocina preparando los últimos  detalles de la tarta de cumpleaños de su hija. Cuando acaba, se  dirige al salón con el pastel para seguir con la celebración, pero cuando cruza la puerta se da cuenta de algo extraño, Laura no  está. ",
  "description": null,
  "awards": null,
  "duration": "09:03",
  "starring": "Lucia Fernández, Martin H.",
  "producer": null,
  "director": "Alberto Morales",
  "city": "Sevilla",
  "country": "España",
  "year": 2021,
  "thumbnailurl": "https://img.youtube.com/vi/vC2atbR_09Y/maxresdefault.jpg",
  "posterurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter%2Fp50.jpg?alt=media&token=043c1f01-d847-4f64-b2be-5354d93aa3ef",
  "poster375webpurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter375webp%2Fp50.webp?alt=media&token=fe7f9d76-981a-46a4-95a7-bf76878b615f",
  "bgurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fbg%2Fb50.jpg?alt=media&token=0a70c96b-86c7-41c1-83db-c027a0b5d92d",
  "genres": "Drama",
  "age": "16+",
  "maxres": "HD 1080p",
  "type": "Cortometraje",
  "language": "ES",
  "status": "active",
  "fomo": 0,
  
 },
 {
  "id": 51,
  "videoId_yt": "ibfWEVwdnV4",
  "videoId_vm": null,
  "videoId_yt_dmq": "Jw68qDaOkBc",
  "videoId_svr": null,
  "videohref_yt": "https://www.youtube.com/watch?v=ibfWEVwdnV4",
  "videohref_vm": null,
  "videohref_yt_dmq": "https://www.youtube.com/watch?v=Jw68qDaOkBc",
  "videohref_svr": null,
  "title": "Cuchi",
  "synopsis": "Tras cruzarse en una carretera salteña, un chancho muere atropellado por un bus que transporta a los barrabravas de un club de fútbol.",
  "description": null,
  "awards": null,
  "duration": "12:20",
  "starring": "Claudio Torres, Angel Collante, Richard Wagener.",
  "producer": null,
  "director": "Emanuel Mosccoso",
  "city": "Salta",
  "country": "Argentina",
  "year": 2021,
  "thumbnailurl": "https://img.youtube.com/vi/ibfWEVwdnV4/maxresdefault.jpg",
  "posterurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter%2Fp51.jpg?alt=media&token=21780975-8ab1-4478-90ec-3a4906f6acac",
  "poster375webpurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter375webp%2Fp51.webp?alt=media&token=d140028d-6560-4211-a86c-8e5c298d5659",
  "bgurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fbg%2Fb51.jpg?alt=media&token=c8c4f07d-9be8-4ee7-b732-b1a5058eac4e",
  "genres": "Suspenso, Drama",
  "age": "16+",
  "maxres": "720p",
  "type": "Cortometraje",
  "language": "ES",
  "status": "active",
  "fomo": 0,
  
 },
 {
  "id": 52,
  "videoId_yt": "ul4gGJcOX8Y",
  "videoId_vm": null,
  "videoId_yt_dmq": "JrY33S_byBU",
  "videoId_svr": null,
  "videohref_yt": "https://www.youtube.com/watch?v=ul4gGJcOX8Y",
  "videohref_vm": null,
  "videohref_yt_dmq": "https://www.youtube.com/watch?v=JrY33S_byBU",
  "videohref_svr": null,
  "title": "¿Cómo se lo digo?",
  "synopsis": "Después de ser aceptado el matrimonio igualitario en Ecuador. Una chica es presionada por su novia para qué acepte su relación, pero se enfrentará al miedo de ser rechazada por su madre.",
  "description": "Cortometraje que forma parte de Guayaquil de mis amores, cuarta edición.",
  "awards": null,
  "duration": "12:50",
  "starring": "Julissa Mera, Elizabeth Zambrano, Sebastian Salazar.",
  "producer": null,
  "director": "Emily Chicaiza Sotomayor",
  "city": "Guayaquil",
  "country": "Ecuador",
  "year": 2021,
  "thumbnailurl": "https://img.youtube.com/vi/ul4gGJcOX8Y/maxresdefault.jpg",
  "posterurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter%2Fp52.jpg?alt=media&token=a7c4c0b8-274c-4d3b-894a-8cec5e95dd03",
  "poster375webpurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter375webp%2Fp52.webp?alt=media&token=b5ef10c3-feb0-43cb-b375-5bd36b40f83c",
  "bgurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fbg%2Fb52.jpg?alt=media&token=f768bd08-185e-4a99-8337-ad810a7a77e1",
  "genres": "Romance, Drama",
  "age": "16+",
  "maxres": "HD 1080p",
  "type": "Cortometraje",
  "language": "ES",
  "status": "active",
  "fomo": 0,
  
 },
 {
  "id": 53,
  "videoId_yt": null,
  "videoId_vm": 532054877,
  "videoId_yt_dmq": "C9RPsM433-M",
  "videoId_svr": null,
  "videohref_yt": null,
  "videohref_vm": "https://vimeo.com/532054877",
  "videohref_yt_dmq": "https://www.youtube.com/watch?v=C9RPsM433-M",
  "videohref_svr": null,
  "title": "Mijo",
  "synopsis": "Mijo es un joven soñador bendecido con el don del Rey Midas. Sin embargo, su habilidad tiene algo de único: todo lo que toca se vuelve elegantemente chulo.",
  "description": "Rodada en las calles desconocidas de la Ciudad de México, Mijo es un fashion film lleno de color. Se inspira en la moda de los pachucos de los años 40. La película representa una colaboración con artistas locales independientes, capaz de mostrar la sastrería tradicional mexicana, su moda, su música y sus ilustraciones todo en un solo proyecto. Gracias al espíritu vanguardista y urbano de la ciudad, Mijo reinterpreta el mito del Rey Midas a través de una lente mexicana moderna.\n\n“La película es un tributo a mi amor por el realismo mágico. Quería crear algo narrativo, pero con un toque de surrealismo. También quería asegurarme de que se pudiese ver el México de hoy, donde la tradición y la modernidad se encuentran, y mostrar el maravilloso talento creativo que tenemos aquí”.",
  "awards": null,
  "duration": "05:29",
  "starring": "Guión: Mazdey Snob\nReparto: Gerardo Rocha, Dolores Black, Alejandra Soto\nFotografía: Alberto Casuso\nMúsica: Jarana Beat\nDirección Artística: Mazdey Snob",
  "producer": "Snob Solutions",
  "director": "Mazdey Snob",
  "city": null,
  "country": "México",
  "year": 2022,
  "thumbnailurl": null,
  "posterurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter%2Fp53.jpg?alt=media&token=b76f0524-2411-4dd0-b1b8-0cd488672d39",
  "poster375webpurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter375webp%2Fp53.webp?alt=media&token=f6af5a93-b9df-4f8b-bb12-5d299966072e",
  "bgurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fbg%2Fb53.jpg?alt=media&token=47ed7214-b335-4738-a0e0-8bccb753e24d",
  "genres": "Fashion Film, Realismo mágico, Comedia, Fantástico",
  "age": "16+",
  "maxres": "1080p",
  "type": "Cortometraje",
  "language": "ES",
  "status": "active",
  "fomo": 0,
  "Column31": "https://www.facebook.com/SnobSolutions"
 },
 {
  "id": 54,
  "videoId_yt": "6uTJ93gJt8",
  "videoId_vm": null,
  "videoId_yt_dmq": null,
  "videoId_svr": null,
  "videohref_yt": "https://www.youtube.com/watch?v=-6uTJ93gJt8",
  "videohref_vm": null,
  "videohref_yt_dmq": null,
  "videohref_svr": null,
  "title": "3 A. M.",
  "synopsis": "Cuando llega la madrugada en una estación de servicio, hechos aterradores pueden pasar.",
  "description": null,
  "awards": null,
  "duration": "11:05",
  "starring": "Guion: Nicolás Galliano\nReparto: Micol Estevez, Solange Verina, Diego Alonso, Esteban Prol, Fabio Di Tomaso, Gonzalo Quintana\nFotografía: Florencia Velozo\nMúsica: One Minute Silence\nSonido: Luciérnaga sonido\nDirección Artística: Canario\nEfectos visuales: Cynthia Romero\nMaquillaje: Cynthia Romero\nMontaje: Gian Blanco",
  "producer": "Insurrectas Producciones",
  "director": "Nicolás Galliano",
  "city": null,
  "country": "Argentina",
  "year": 2021,
  "thumbnailurl": null,
  "posterurl": "https://cortosdemetraje.com/wp-content/uploads/2021/12/3-AM.jpg",
  "bgurl": null,
  "genres": "Terror, Suspenso",
  "age": "16+",
  "maxres": "1080p",
  "type": "Cortometraje",
  "language": "ES",
  "status": "none",
  "fomo": 0,
  
 },
 {
  "id": 55,
  "videoId_yt": "4JTG4Qbdiag",
  "videoId_vm": null,
  "videoId_yt_dmq": "BEJDt6Q9FnI",
  "videoId_svr": null,
  "videohref_yt": "https://www.youtube.com/watch?v=4JTG4Qbdiag",
  "videohref_vm": null,
  "videohref_yt_dmq": "https://www.youtube.com/watch?v=BEJDt6Q9FnI",
  "videohref_svr": null,
  "title": "De menos",
  "synopsis": "¿Qué ocurre en una pareja cuando la mujer crece profesionalmente? ¿La sociedad está acostumbrada a que las mujeres vivan siempre bajo el techo de cristal? ¿Cómo perciben los hombres esta realidad?",
  "description": "Estas son algunas de las preguntas que plantea De Menos, un corto donde dos parejas de amigos charlan animadamente en una cena. Marta, el personaje de Kira Miró, ha sido ascendida a un puesto de gran responsabilidad en su trabajo, pero pronto nota que algo no va bien con su pareja, Andrés, a quien encarna Víctor Clavijo. A lo largo del cortometraje descubriremos que hay una verdad incómoda detrás de todo cuando la pareja de invitados encarnada por Montse Pla y Juan López-Tagle abandone la casa.",
  "awards": null,
  "duration": "08:31",
  "starring": "Guion: Cristina Martín, María José Martín\nReparto: Kira Miró, Víctor Clavijo, Montse Plá, Juan López-Tagle\nFotografía: Cesar Belandia\nSonido: Raquel Caridad\nDirección Artística: Begoña Olavarrieta\nVestuario: Anitta Ruiz, Virginia Tourón\nMaquillaje: María Solans\nMontaje: Cristina Martín, María José Martín",
  "producer": "Cosmo, Lasdelcine",
  "director": "Cristina Martín, María José Martín",
  "city": null,
  "country": "España",
  "year": 2021,
  "thumbnailurl": null,
  "posterurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter%2Fp55.jpg?alt=media&token=4c7af1e3-20e4-4f0a-8ead-03b4c816af5b",
  "poster375webpurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter375webp%2Fp55.webp?alt=media&token=2aeef30d-84b4-43fb-96ea-824d6be360a4",
  "bgurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fbg%2Fb55.jpg?alt=media&token=eb2186b9-65ae-42fd-9b48-8acf490a6291",
  "genres": "Drama, Violencia de género",
  "age": "16+",
  "maxres": "1080p",
  "type": "Cortometraje",
  "language": "ES",
  "status": "active",
  "fomo": 0,
  
 },
 {
  "id": 56,
  "videoId_yt": "SFmMJTBwJz8",
  "videoId_vm": null,
  "videoId_yt_dmq": "1NswXBSHLAo",
  "videoId_svr": null,
  "videohref_yt": "https://www.youtube.com/watch?v=SFmMJTBwJz8",
  "videohref_vm": null,
  "videohref_yt_dmq": "https://www.youtube.com/watch?v=1NswXBSHLAo",
  "videohref_svr": null,
  "title": "El silencio de Marta",
  "synopsis": "La película muestra la problemática del bullying, que sufren muchos niños y jóvenes y que puede generar graves problemas de salud mental.",
  "description": "“El acoso escolar duplica el riesgo de depresión, ansiedad o intentos de suicidio, no sólo en las edades tempranas, sino también en la adulta” – Ana González-Pinto. Presidenta de la FEPSM.",
  "awards": null,
  "duration": "21:06",
  "starring": "Reparto: Carmen Arcas, Miguel Fernández, Adán Latonda, Sofía Ambrossi, Clara García\nFotografía: Chus Arcas\nSonido: José María Bernal, Adán Latonda",
  "producer": "Fundación Española de Psiquiatría y Salud Mental",
  "director": "José Cabanach",
  "city": null,
  "country": "España",
  "year": 2021,
  "thumbnailurl": null,
  "posterurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter%2Fp56.jpg?alt=media&token=e64d2381-7dc9-4c8e-9688-5addf71c0f7c",
  "poster375webpurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter375webp%2Fp56.webp?alt=media&token=87d0df5c-8fd1-427e-935f-4cd7f69ce2f7",
  "bgurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fbg%2Fb56.jpg?alt=media&token=1039e6be-8628-4d1f-a420-068cf81c815b",
  "genres": "Drama, Bullying, Drama social",
  "age": "16+",
  "maxres": "1080p",
  "type": "Cortometraje",
  "language": "ES",
  "status": "active",
  "fomo": 0,
  
 },
 {
  "id": 57,
  "videoId_yt": "0Y1A07ZInjg",
  "videoId_vm": null,
  "videoId_yt_dmq": "FQei54D6Prs",
  "videoId_svr": null,
  "videohref_yt": "https://www.youtube.com/watch?v=0Y1A07ZInjg",
  "videohref_vm": null,
  "videohref_yt_dmq": "https://www.youtube.com/watch?v=FQei54D6Prs",
  "videohref_svr": null,
  "title": "Paripé",
  "synopsis": "Andrea y Alejandro acuden a la iglesia a pedir al padre Ramón que les case, pero antes, éste tiene que hacerles una serie de preguntas.",
  "description": null,
  "awards": null,
  "duration": "10:15",
  "starring": "Guion: Rafa Puerto\nReparto: Juan Carlos Montilla, Ana Janer, Rafa Puerto, Jessica Saborito, Mariángeles Saborito\nFotografía: Miguel A. Almanza\nSonido: Eugenio Mateo \"Ehu\", Miguel A. Almanza\nMontaje: Miguel A. Almanza",
  "producer": "Producciones Pan & Circo, Rafa Puerto",
  "director": "Miguel A. Almanza",
  "city": null,
  "country": "España",
  "year": 2021,
  "thumbnailurl": null,
  "posterurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter%2Fp57.jpg?alt=media&token=25b2957b-b3db-4a36-b0c6-3e67e24df2e2",
  "poster375webpurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter375webp%2Fp57.webp?alt=media&token=386fca01-fe1d-4c7d-8516-366a353230cf",
  "bgurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fbg%2Fb57.jpg?alt=media&token=494ecf64-ac57-4427-8b59-30043cb23d15",
  "genres": "Comedia, Social, Sátira",
  "age": "13+",
  "maxres": "1080p",
  "type": "Cortometraje",
  "language": "ES",
  "status": "active",
  "fomo": 0,
  
 },
 {
  "id": 58,
  "videoId_yt": "AhEygoOZeiE",
  "videoId_vm": null,
  "videoId_yt_dmq": "IU1aP4jERGs",
  "videoId_svr": null,
  "videohref_yt": "https://www.youtube.com/watch?v=AhEygoOZeiE",
  "videohref_vm": null,
  "videohref_yt_dmq": "https://www.youtube.com/watch?v=IU1aP4jERGs",
  "videohref_svr": null,
  "title": "Tu Mínima Caricia",
  "synopsis": "Una mujer extremadamente tímida está enamorada. Su máximo deseo es una mínima caricia.",
  "description": null,
  "awards": null,
  "duration": "07:59",
  "starring": "Guion: Omar Gómez Arias\nReparto: Karina Hurtado, Felipe García, Samuel González, Andrea Figueroa\nFotografía: Sergio Ruiz Velasco\nSonido: Germán Torres\nDirección Artística: Omar Gómez Arias\nVestuario: Andrea Romero Riggen\nMaquillaje: Andrea Romero Riggen\nMontaje: Alejandro Álvarez del Castillo",
  "producer": "Victor Cartas, Magali Espinosa, Pedro Pablo Hernández, Sergio Ruiz Velasco, Alejandro Álvarez del Castillo, Omar Gómez Arias, Círculo Blanco",
  "director": "Omar Gómez Arias",
  "city": null,
  "country": "México",
  "year": 2021,
  "thumbnailurl": null,
  "posterurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter%2Fp58.jpg?alt=media&token=6afc96df-55b2-45ce-b076-2b810d4d7eed",
  "poster375webpurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter375webp%2Fp58.webp?alt=media&token=cae3a378-10df-4a84-aa6a-bf6216ded261",
  "bgurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fbg%2Fb58.jpg?alt=media&token=39393dd6-ba85-4c0e-b85d-7131d1b8cc6d",
  "genres": "Drama",
  "age": "16+",
  "maxres": "1080p",
  "type": "Cortometraje",
  "language": "ES",
  "status": "active",
  "fomo": 0,
  
 },
 {
  "id": 59,
  "videoId_yt": "eLVhIN4xPMU",
  "videoId_vm": null,
  "videoId_yt_dmq": "49zthfeMFQs",
  "videoId_svr": null,
  "videohref_yt": "https://www.youtube.com/watch?v=eLVhIN4xPMU",
  "videohref_vm": null,
  "videohref_yt_dmq": "https://www.youtube.com/watch?v=49zthfeMFQs",
  "videohref_svr": null,
  "title": "La hora de la bruja",
  "synopsis": "Ramón Badía es el presentador de La hora de la bruja, un programa donde la gente relata historias de terror reales y eventos paranormales pero una de esas llamadas demuestra algo fuera de lo común.",
  "description": null,
  "awards": null,
  "duration": "23:44",
  "starring": "Guion: Abel Gil Erquicia\nReparto: Lako Estévez, Roberto Casal, Sara Fernández\nFotografía: Antonio Marchantes\nMúsica: Luis López\nSonido: Luis López, Santiago Moret\nEfectos visuales: Antonio Marchantes\nMaquillaje: Paula Gil\nPeluquería: Paula Gil\nMontaje: Abel Gil Erquicia",
  "producer": "Producciones Alucine",
  "director": "Abel Gil Erquicia",
  "city": null,
  "country": "España",
  "year": 2021,
  "thumbnailurl": null,
  "posterurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter%2Fp59.jpg?alt=media&token=1e9cbd66-5172-4246-a262-6ee73f7a00a0",
  "poster375webpurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter375webp%2Fp59.webp?alt=media&token=221ba817-374f-4730-8ce9-54deccd51235",
  "bgurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fbg%2Fb59.jpg?alt=media&token=30fc50c4-7524-43ab-ae76-3549860bfe29",
  "genres": "Terror, Suspenso",
  "age": "16+",
  "maxres": "1080p",
  "type": "Cortometraje",
  "language": "ES",
  "status": "active",
  "fomo": 0,
  
 },
 {
  "id": 60,
  "videoId_yt": "LNrqti9Z_QE",
  "videoId_vm": null,
  "videoId_yt_dmq": "y0CGBXWwhDk",
  "videoId_svr": null,
  "videohref_yt": "https://www.youtube.com/watch?v=LNrqti9Z_QE",
  "videohref_vm": null,
  "videohref_yt_dmq": "https://www.youtube.com/watch?v=y0CGBXWwhDk",
  "videohref_svr": null,
  "title": "El Secreto Del Valle",
  "synopsis": "El día domingo 23 de septiembre de 1973, Tamara y Daniel pareja de adolescentes enamorados se encontraban de paseo en el valle de Wara. Ellos, solían ir de paseo los fines de semana a aquel lugar, en uno de esos días se encontraron con unos violadores; Tamara fue violada y Daniel asesinado colgado en un árbol.",
  "description": "Desde aquel día, cada 23 de septiembre Tamara iba al lugar donde la violaron y se quedaba mirando todo día el árbol donde colgaron a Daniel, ella hizo la misma rutina cada 23 de septiembre hasta el año 1984, año en que desapareció sin dejar ningún rastro. A partir de la desaparición de Tamara, cada año en que el 23 de septiembre sea un día domingo, de las parejas que van a aquel lugar en esas fechas, todos los varones y, las chicas que aún son vírgenes desaparecen para nunca más regresar a sus casas y solamente las encuentran completamente desquiciadas a las que ya no son vírgenes. Finalmente, todas ellas encerradas en sus habitaciones terminan matándose cruelmente.",
  "awards": null,
  "duration": "21:06",
  "starring": "Guion: Aris Querova\nReparto: Naty Rottiers, Pamela Quispe, Yuditza Gutiérrez, Richard Mendoza, Carlos Mejía\nFotografía: Carlos Mejía, Richard Mendoza, Yuditza Gutierrez\nMúsica: Horror House\nSonido: Augusto Astete\nDirección Artística: Aris Querova\nEfectos visuales: Aris Querova\nVestuario: Yuditza Gutierrez\nMaquillaje: Yuditza Gutiérrez, Naty Rottiers\nMontaje: Aris Querova",
  "producer": "Imagen REAL",
  "director": "Aris Querova",
  "city": null,
  "country": "Perú",
  "year": 2021,
  "thumbnailurl": null,
  "posterurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter%2Fp60.jpg?alt=media&token=c60d8074-44e5-495f-ad30-3708bce8539e",
  "poster375webpurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter375webp%2Fp60.webp?alt=media&token=16a10634-685d-4200-acfe-44bdd6e66660",
  "bgurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fbg%2Fb60.jpg?alt=media&token=347570f0-fae8-4372-905b-03ca61b5b9a3",
  "genres": "Terror, Suspenso",
  "age": "16+",
  "maxres": "1080p",
  "type": "Cortometraje",
  "language": "ES",
  "status": "active",
  "fomo": 0,
  
 },
 {
  "id": 61,
  "videoId_yt": "WthK3XKvDIQ",
  "videoId_vm": null,
  "videoId_yt_dmq": "cMzW1CgW6Dk",
  "videoId_svr": null,
  "videohref_yt": "https://www.youtube.com/watch?v=WthK3XKvDIQ",
  "videohref_vm": null,
  "videohref_yt_dmq": "https://www.youtube.com/watch?v=cMzW1CgW6Dk",
  "videohref_svr": null,
  "title": "Mia",
  "synopsis": "La vida de la excéntrica Mia Wallace.",
  "description": null,
  "awards": null,
  "duration": "10:37",
  "starring": "Guion: Nicolás Galliano\nReparto: Malena Luchetti, Micol Estevez, Pablo Alarcon\nFotografía: Flope Velozo\nMúsica: Loquero\nSonido: Luciernaga\nDirección Artística: Canarius beta\nMaquillaje: Lourdes vazquez",
  "producer": "Insurrectas Producciones",
  "director": "Nicolás Galliano",
  "city": null,
  "country": "España, Argentina",
  "year": 2021,
  "thumbnailurl": null,
  "posterurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter%2Fp61.jpg?alt=media&token=565e8017-6045-4742-a46c-7ec3a1628035",
  "poster375webpurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter375webp%2Fp61.webp?alt=media&token=2a35a9fd-bd00-4fc9-bc98-f95c4fa1f3be",
  "bgurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fbg%2Fb61.jpg?alt=media&token=eff6c1cd-5cec-4289-957f-d401a932171a",
  "genres": "Drama",
  "age": "16+",
  "maxres": "1080p",
  "type": "Cortometraje",
  "language": "ES",
  "status": "active",
  "fomo": 0,
  
 },
 {
  "id": 62,
  "videoId_yt": "RaBnuRNyw5E",
  "videoId_vm": null,
  "videoId_yt_dmq": "V7dyP2KMHfg",
  "videoId_svr": null,
  "videohref_yt": "https://www.youtube.com/watch?v=RaBnuRNyw5E",
  "videohref_vm": null,
  "videohref_yt_dmq": "https://www.youtube.com/watch?v=V7dyP2KMHfg",
  "videohref_svr": null,
  "title": "Ivy – FanFilm",
  "synopsis": "“IVY” es un fanfilm basado en los orígenes de una de las villanas más míticas del universo DC Comics, Poison Ivy, de Batman, visto desde una perspectiva más intimista, con toques de Drama y Thriller de terror.",
  "description": "Una historia que nos cuenta la relación entre la joven botánica, la Dra. Pamela Isley, y su mentor, el Dr. Jason Woodrue, una relación que pronto irá creciendo en algo más personal,y que los llevará a descender a lo más profundo y oscuro de ambos, cambiándolos para siempre.",
  "awards": null,
  "duration": "22:47",
  "starring": "Guion: Fran Campos\nReparto: Laura Ávila, Jorge Porras, Javier Cerero, Roxanna Panero\nFotografía: Yen Galvez\nMúsica: Perdiphonk, Carmen Robles\nSonido: Alex Varela\nDirección Artística: Jose Ruiz\nEfectos visuales: Alejandro Farfan\nVestuario: Pilar Moreno, Afrikaans Moda\nMaquillaje: Yolanda de Miguel\nMontaje: Alejandro Farfan",
  "producer": null,
  "director": "Fran Campos",
  "city": null,
  "country": "España",
  "year": 2021,
  "thumbnailurl": null,
  "posterurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter%2Fp62.jpg?alt=media&token=d97fb6ce-7fe0-4aa3-a652-acf84c05e485",
  "poster375webpurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter375webp%2Fp62.webp?alt=media&token=575d4c3a-4352-45fe-b4cc-382ddedf5aa5",
  "bgurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fbg%2Fb62.jpg?alt=media&token=ecc4b285-f2a6-4fd2-8eb5-2381b1db16c0",
  "genres": "Drama, Fantástico, FanFilm",
  "age": "13+",
  "maxres": "1080p",
  "type": "Cortometraje",
  "language": "ES",
  "status": "active",
  "fomo": 0,
  
 },
 {
  "id": 63,
  "videoId_yt": "_cVuXEQxkmg",
  "videoId_vm": null,
  "videoId_yt_dmq": "-l1rDLzH9uE",
  "videoId_svr": null,
  "videohref_yt": "https://www.youtube.com/watch?v=_cVuXEQxkmg",
  "videohref_vm": null,
  "videohref_yt_dmq": "https://www.youtube.com/watch?v=-l1rDLzH9uE",
  "videohref_svr": null,
  "title": "Mis otros yo",
  "synopsis": "¿Qué habría pasado si en vez de tomar una decisión concreta, hubieras tomado otra? Somos el resultado de las decisiones que tomamos en nuestra vida, pero en ocasiones se necesita apoyo para tomar buenas decisiones. Y es que a todos nos toca decidir a lo largo de nuestra vida, pero no siempre tenemos que hacerlo solos.",
  "description": "#MisOtroYo es un cortometraje de Banco Mediolanum protagonizado por Ernesto Alterio que te explica la historia de Juan y de cuáles serían sus otros “yo” según las decisiones que hubiera podido tomar en el pasado.",
  "awards": null,
  "duration": "08:14",
  "starring": "Reparto: Ernesto Alterio\nFotografía: Paco Femenía\nMúsica: Joan Vilà\nSonido: Arthur Cañameras\nDirección Artística: Romain Gautry\nMaquillaje: Patricia Reyes",
  "producer": "Banco Mediolanum",
  "director": "Claudia Llosa",
  "city": null,
  "country": "Latinoamérica",
  "year": 2021,
  "thumbnailurl": null,
  "posterurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter%2Fp63.jpg?alt=media&token=6cf97e4f-e04b-4818-b649-c6c5f2081318",
  "poster375webpurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter375webp%2Fp63.webp?alt=media&token=7223925f-0e04-4262-8dfb-b9cd77fa6a1b",
  "bgurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fbg%2Fb63.jpg?alt=media&token=d6384944-78bb-4c30-957e-7cb788acdcf4",
  "genres": "Drama, Fantástico",
  "age": "13+",
  "maxres": "1080p",
  "type": "Cortometraje",
  "language": "ES",
  "status": "active",
  "fomo": 0,
  
 },
 {
  "id": 64,
  "videoId_yt": "5IYBamwnyF8",
  "videoId_vm": null,
  "videoId_yt_dmq": null,
  "videoId_svr": null,
  "videohref_yt": "https://www.youtube.com/watch?v=5IYBamwnyF8",
  "videohref_vm": null,
  "videohref_yt_dmq": null,
  "videohref_svr": null,
  "title": "El secuestro",
  "synopsis": "Un joven estudiante es traicionado por su mejor amigo. El mejor amigo lo secuestran en complicidad con otros delincuentes.",
  "description": null,
  "awards": null,
  "duration": "10:12",
  "starring": "Guion: Aris Querova\nReparto: Richard Mendoza, Abril Gonzales, Yuditza Guierrez, Michael Dalquerre, Pamela Yisel, Anghelo Escalante, Juan Leí Pérez, Bryam Nancay, Fernando Prudencio, Augusto Astete\nFotografía: Anghelo Escalante\nMúsica: Aris Querova\nSonido: Aris Querova\nDirección Artística: Aris Querova\nVestuario: Richard Mendoza, Abril Gonzales, Yuditza Guierrez, Michael Dalquerre, Pamela Yisel, Anghelo Escalante, Juan Leí Pérez, Bryam Nancay, Fernando Prudencio, Augusto Astete\nMaquillaje: Richard Mendoza, Abril Gonzales, Yuditza Guierrez, Michael Dalquerre, Pamela Yisel, Anghelo Escalante, Juan Leí Pérez, Bryam Nancay, Fernando Prudencio, Augusto Astete\nMontaje: Aris Querova",
  "producer": "Imagen REAL",
  "director": "Aris Querova",
  "city": null,
  "country": "Perú",
  "year": 2019,
  "thumbnailurl": null,
  "posterurl": null,
  "bgurl": null,
  "genres": "Thriller",
  "age": "16+",
  "maxres": "1080p",
  "type": "Cortometraje",
  "language": "ES",
  "status": "none",
  "fomo": 0,
  
 },
 {
  "id": 65,
  "videoId_yt": "0UaxXjl0txE",
  "videoId_vm": null,
  "videoId_yt_dmq": null,
  "videoId_svr": null,
  "videohref_yt": "https://www.youtube.com/watch?v=0UaxXjl0txE",
  "videohref_vm": null,
  "videohref_yt_dmq": null,
  "videohref_svr": null,
  "title": "Destino fatal",
  "synopsis": "Una pareja de recién casados se encuentra de paseo. La esposa es asesinada por unos pandilleros.",
  "description": "Cortometraje realizado por los alumnos del taller de actuación Imagen REAL, Ayacucho – Perú, 2019. Realizado en la ciudad de Huamanga.",
  "awards": null,
  "duration": "14:58",
  "starring": "Guion: Aris Querova\nReparto: Abril Gonzales, Michael Dalquerre, Nick Ledesma, Pamela Yisel, Anghelo Escalante, Juan Leí Pérez\nFotografía: Anghelo Escalante\nMúsica: Aris Querova\nSonido: Aris Querova\nDirección Artística: Aris Querova\nVestuario: Abril Gonzales, Augusto Astete, Nick Ledesma, Pamela Yisel, Michael Dalguerre, Juna Leí Pérez\nMaquillaje: Abril Gonzales, Augusto Astete, Nick Ledesma, Pamela Yisel, Michael Dalguerre, Juna Leí Pérez\nMontaje: Aris Querova",
  "producer": "Imagen REAL",
  "director": "Aris Querova",
  "city": null,
  "country": "Perú",
  "year": 2019,
  "thumbnailurl": null,
  "posterurl": null,
  "bgurl": null,
  "genres": "Drama, Violencia",
  "age": "16+",
  "maxres": "1080p",
  "type": "Cortometraje",
  "language": "ES",
  "status": "none",
  "fomo": 0,
  
 },
 {
  "id": 66,
  "videoId_yt": "MmYm7zf-13c",
  "videoId_vm": null,
  "videoId_yt_dmq": null,
  "videoId_svr": null,
  "videohref_yt": "https://www.youtube.com/watch?v=MmYm7zf-13c",
  "videohref_vm": null,
  "videohref_yt_dmq": null,
  "videohref_svr": null,
  "title": "El americano",
  "synopsis": "Existen varias versiones sobre la historia del Americano, plato bandera de Arequipa, nosotros lo contamos de una forma pícara y jocosa.",
  "description": "Este cortometraje es una ficción. No intentamos herir tu orgullo Arequipeño.",
  "awards": null,
  "duration": "11:25",
  "starring": "Reparto: Anita Carrazco, Lina Alviz, Lupita Mora, Martha Rebaza, Nikolle Mercado",
  "producer": null,
  "director": "Santiago Fierro",
  "city": null,
  "country": "Perú",
  "year": 2017,
  "thumbnailurl": null,
  "posterurl": null,
  "bgurl": null,
  "genres": "Comedia, Histórico",
  "age": "13+",
  "maxres": "1080p",
  "type": "Cortometraje",
  "language": "ES",
  "status": "none",
  "fomo": 0,
  
 },
 {
  "id": 67,
  "videoId_yt": "c6aDUtLE7CQ",
  "videoId_vm": null,
  "videoId_yt_dmq": "IvLln-LZ1bo",
  "videoId_svr": null,
  "videohref_yt": "https://www.youtube.com/watch?v=c6aDUtLE7CQ",
  "videohref_vm": null,
  "videohref_yt_dmq": "https://www.youtube.com/watch?v=IvLln-LZ1bo",
  "videohref_svr": null,
  "title": "La Lotería",
  "synopsis": "Mientras espera que su avión se embarque para poder emigrar a Estados Unidos, Augusto Ramírez recuerda los tres mayores arrepentimientos de su vida.",
  "description": null,
  "awards": null,
  "duration": "08:21",
  "starring": "Guion: Shahir Daud, Brendan McArthy, Gavin McGibbon\nReparto: Ismael Cruz Cordova, Ines Milans, Juan Pichardo, Manny Rey, Angela Rodriguez, Samantha Shane, Adam Tyler Waters",
  "producer": null,
  "director": "Shahir Daud",
  "city": null,
  "country": "EEUU",
  "year": 2014,
  "thumbnailurl": null,
  "posterurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter%2Fp67.jpg?alt=media&token=0174dbbb-65bd-4b79-9ec5-430cfe1aa3a1",
  "poster375webpurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter375webp%2Fp67.webp?alt=media&token=3e2d1ce8-96f5-4ef5-b9a5-ce9d91dec09a",
  "bgurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fbg%2Fb67.jpg?alt=media&token=914b3627-5530-458a-b9c0-50903d4082e1",
  "genres": "Comedia, Drama",
  "age": "13+",
  "maxres": "1080p",
  "type": "Cortometraje",
  "language": "ES",
  "status": "active",
  "fomo": 0,
  
 },
 {
  "id": 68,
  "videoId_yt": "9XWNrVvvdU",
  "videoId_vm": null,
  "videoId_yt_dmq": "xh6S-MvA16o",
  "videoId_svr": null,
  "videohref_yt": "https://www.youtube.com/watch?v=-9XWNrVvvdU",
  "videohref_vm": null,
  "videohref_yt_dmq": "https://www.youtube.com/watch?v=xh6S-MvA16o",
  "videohref_svr": null,
  "title": "Cambio de Guardia",
  "synopsis": "Ruperto, un Guardia de Seguridad solitario, excelente trabajador y buen hijo, espera por su sustituto; un mujeriego que gusta del sexo con jovencitas. Tony llega todos los días tarde a relevarlo en la posta del aeropuerto. El encuentro de ese día cambiará el destino de ambos para siempre.",
  "description": null,
  "awards": null,
  "duration": "14:12",
  "starring": "Guion: Ernesto García, Carlos Acosta-Milian\nReparto: Carlos Acosta-Milian, Adrian Más, Ricardo Alamo\nFotografía: Ricardo Alamo\nMúsica: Ernesto García\nSonido: Armando Díaz\nMontaje: Ricardo Alamo",
  "producer": "Nirvana Films",
  "director": "Ricardo Alamo",
  "city": null,
  "country": "EEUU",
  "year": 2019,
  "thumbnailurl": null,
  "posterurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter%2Fp68.jpg?alt=media&token=009f0974-cb4a-4169-bdad-ac8d3dd486ec",
  "poster375webpurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter375webp%2Fp68.webp?alt=media&token=8eaff5f6-8273-4b0e-ae0b-fea9e4946860",
  "bgurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fbg%2Fb68.jpg?alt=media&token=dad3313e-b755-4f35-b67b-0189491342b6",
  "genres": "Drama",
  "age": "16+",
  "maxres": "1080p",
  "type": "Cortometraje",
  "language": "ES",
  "status": "active",
  "fomo": 0,
  
 },
 {
  "id": 69,
  "videoId_yt": "iOxXFflttyg",
  "videoId_vm": null,
  "videoId_yt_dmq": "yEzPT4cF6z0",
  "videoId_svr": null,
  "videohref_yt": "https://www.youtube.com/watch?v=iOxXFflttyg",
  "videohref_vm": null,
  "videohref_yt_dmq": "https://www.youtube.com/watch?v=yEzPT4cF6z0",
  "videohref_svr": null,
  "title": "Street Fighter: Matador",
  "synopsis": "Cortometraje que ahonda en el pasado de Vega, el personaje español de los videojuegos de Street Fighter, conocido por la garra que usa como arma.",
  "description": null,
  "awards": null,
  "duration": "14:30",
  "starring": "Guion: Brett D. Bayonne\nReparto: Adán Aguilar, Emanuel Loarca, Sebastián Naranjo, Patricia Peinado, José De Vega, David Reinprecht, David Leon Espina",
  "producer": "Rekon Productions, Chubby Boy Productions",
  "director": "Vahe Gabuchian",
  "city": null,
  "country": "EEUU",
  "year": 2014,
  "thumbnailurl": null,
  "posterurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter%2Fp69.jpg?alt=media&token=4e414f24-7063-4e12-aa8d-30ba4b071068",
  "poster375webpurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter375webp%2Fp69.webp?alt=media&token=1ba057ec-9a2f-4874-9169-5696fb571c88",
  "bgurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fbg%2Fb69.jpg?alt=media&token=4a8f8c79-2744-4bbc-81c0-4f3e8742437b",
  "genres": "Acción, Videojuego, FanFilm",
  "age": "13+",
  "maxres": "1080p",
  "type": "Cortometraje",
  "language": "ES",
  "status": "active",
  "fomo": 0,
  
 },
 {
  "id": 70,
  "videoId_yt": "53VBCrAUhNA",
  "videoId_vm": null,
  "videoId_yt_dmq": "8DDag8IH0GI",
  "videoId_svr": null,
  "videohref_yt": "https://www.youtube.com/watch?v=53VBCrAUhNA",
  "videohref_vm": null,
  "videohref_yt_dmq": "https://www.youtube.com/watch?v=8DDag8IH0GI",
  "videohref_svr": null,
  "title": "Pablo On Wheels",
  "synopsis": "Seis meses después de la deportación de sus padres, Rey (18) y Pablo (11) hacen lo que pueden para sobrevivir en la ciudad de Nueva York. El vínculo del skateboard los mantiene unidos, pero la cultura que los rodea los separa. Un solo error de Rey amenaza con llevarse a la última familia que le queda.",
  "description": null,
  "awards": null,
  "duration": "36:24",
  "starring": "Guion: Brett Sorem, Elya Ottenberg\nReparto: Ana Asensio, Andrew Gelles, Anthony Ruiz, Antoinette Elias, Bryan Fitzgerald, Bryant Pearson, Charlie Castelluzzo, Chauncey Johnson, Chris Perry, Dominick Bonnet, Ellie Diez, Luis Tolentino, Ricardo Alvarado, Sef Sharrock, Shevy Gutierrez",
  "producer": "Nocturnal Features",
  "director": "Brett Sorem",
  "city": null,
  "country": "EEUU",
  "year": 2011,
  "thumbnailurl": null,
  "posterurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter%2Fp70.jpg?alt=media&token=60276618-30e3-4ef4-94e7-feecc6578537",
  "poster375webpurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter375webp%2Fp70.webp?alt=media&token=f1a1fe61-4641-4f52-9948-377ea0cfac70",
  "bgurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fbg%2Fb70.jpg?alt=media&token=17310f5d-c1cd-44b9-b12d-9658373e03c8",
  "genres": "Drama",
  "age": "16+",
  "maxres": "1080p",
  "type": "Cortometraje",
  "language": "ES",
  "status": "active",
  "fomo": 0,
  
 },
 {
  "id": 71,
  "videoId_yt": "VPfHJez07PU",
  "videoId_vm": null,
  "videoId_yt_dmq": null,
  "videoId_svr": null,
  "videohref_yt": "https://www.youtube.com/watch?v=VPfHJez07PU",
  "videohref_vm": null,
  "videohref_yt_dmq": null,
  "videohref_svr": null,
  "title": "Overwatch: Honor y gloria",
  "synopsis": "El origen del caballero Overwatch, portador del martillo a reacción.",
  "description": null,
  "awards": null,
  "duration": "07:26",
  "starring": "Guion: Andrew Robinson",
  "producer": "Blizzard Entertainment",
  "director": "Ben Dai",
  "city": null,
  "country": "EEUU",
  "year": 2017,
  "thumbnailurl": null,
  "posterurl": "https://cortosdemetraje.com/wp-content/uploads/2019/03/overwatch-honor-y-gloria.jpg",
  "bgurl": null,
  "genres": "Ciencia Ficción, Videojuegos, Fantástico",
  "age": "13+",
  "maxres": "1080p",
  "type": "Cortometraje",
  "language": "ES",
  "status": "none",
  "fomo": 0,
  
 },
 {
  "id": 72,
  "videoId_yt": "ItuFYRc21Is",
  "videoId_vm": null,
  "videoId_yt_dmq": null,
  "videoId_svr": null,
  "videohref_yt": "https://www.youtube.com/watch?v=ItuFYRc21Is",
  "videohref_vm": null,
  "videohref_yt_dmq": null,
  "videohref_svr": null,
  "title": "Overwatch: Infiltración",
  "synopsis": "“Infiltración” relata cómo Reaper, Widowmaker y Sombra intentan asesinar a un objetivo de gran prioridad. Sin embargo, cuando la operación se complica, los agentes de Talon se ven obligados a improvisar.",
  "description": null,
  "awards": null,
  "duration": "06:20",
  "starring": null,
  "producer": null,
  "director": "Doug Alexander",
  "city": null,
  "country": "EEUU",
  "year": 2016,
  "thumbnailurl": null,
  "posterurl": "https://cortosdemetraje.com/wp-content/uploads/2017/04/overwatch_infiltration_s-552342984-mmed1.jpg",
  "bgurl": null,
  "genres": "Ciencia Ficción, Videojuegos, Fantástico",
  "age": "13+",
  "maxres": "1080p",
  "type": "Cortometraje",
  "language": "ES",
  "status": "none",
  "fomo": 0,
  
 },
 {
  "id": 73,
  "videoId_yt": null,
  "videoId_vm": 66507747,
  "videoId_yt_dmq": "eZvv_hYeTa8",
  "videoId_svr": null,
  "videohref_yt": null,
  "videohref_vm": "https://vimeo.com/66507747",
  "videohref_yt_dmq": "https://www.youtube.com/watch?v=eZvv_hYeTa8",
  "videohref_svr": null,
  "title": "El Último Hielero",
  "synopsis": "Dos veces a la semana por más de medio siglo, Baltazar Ushca ha subido por las laderas del Chimborazo, la montaña más alta en Ecuador, para cosechar el hielo de los glaciares naturales que cubren las mayores altitudes de este volcán inactivo.",
  "description": "En el pasado, cerca de cuarenta hieleros hacían el viaje a la montaña para extraer el hielo; ahora, sin embargo, Baltazar trabaja solo. Incluso sus hermanos, Gregorio y Juan, ambos criados como hieleros, se han retirado de la montaña para encontrar un trabajo más estable.\nEl Último Hielero cuenta una historia del cambio cultural y estilo de la vida indígena a través de la perspectiva de tres hermanos, quienes han tratado con el cambio de diferentes maneras. Busqué retratar a los personajes como las personas dignas que son y mostrar la muy humana historia de sus circunstancias – hacer una película que presente a la comunidad indígena de manera positiva tanto para el público como para la propia comunidad.",
  "awards": null,
  "duration": "14:11",
  "starring": "Reparto: Baltazar Ushca, Gregorio Ushca, Juan Ushca",
  "producer": null,
  "director": "Sandy Patch",
  "city": null,
  "country": "EEUU",
  "year": 2012,
  "thumbnailurl": null,
  "posterurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter%2Fp73.jpg?alt=media&token=469c4cee-fbe0-4c79-89b9-77b7a646c2d3",
  "poster375webpurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter375webp%2Fp73.webp?alt=media&token=e6bb33bc-9c33-4787-b471-315025a590c3",
  "bgurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fbg%2Fb73.jpg?alt=media&token=ac45f6c3-d87f-4f74-b389-b23a156bbe1d",
  "genres": "Drama",
  "age": "16+",
  "maxres": "1080p",
  "type": "Cortometraje",
  "language": "ES",
  "status": "active",
  "fomo": 0,
  
 },
 {
  "id": 74,
  "videoId_yt": "xU1kgAg0nHM",
  "videoId_vm": null,
  "videoId_yt_dmq": "Ss6_3Nno4Yo",
  "videoId_svr": null,
  "videohref_yt": "https://www.youtube.com/watch?v=xU1kgAg0nHM",
  "videohref_vm": null,
  "videohref_yt_dmq": "https://www.youtube.com/watch?v=Ss6_3Nno4Yo",
  "videohref_svr": null,
  "title": "Detrás de la puerta",
  "synopsis": "Milagros realiza un ritual para hablar una última vez con su padre fallecido. Necesita pedirle un favor, pero no sabe que llamar a los muertos puede traer consecuencias terribles.",
  "description": null,
  "awards": null,
  "duration": "09:44",
  "starring": "Guion: Andrés Borghi\nReparto: Claudia Salas, Florencia Ansaldo, Carlos Piñeiro, Germán Baudino\nFotografía: Matías Rispau\nMúsica: Pablo Borghi\nSonido: Fernando Ribero\nDirección Artística: Mae Bermúdez\nMontaje: Andrés Borghi",
  "producer": "Estrella infernal",
  "director": "Andrés Borghi",
  "city": null,
  "country": "Argentina",
  "year": 2019,
  "thumbnailurl": null,
  "posterurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter%2Fp74.jpg?alt=media&token=bf37233f-6b01-4c2d-aa3e-661a87221179",
  "poster375webpurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter375webp%2Fp74.webp?alt=media&token=173b3e56-3c6a-4a79-bb16-1ec0bbe64a0f",
  "bgurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fbg%2Fb74.jpg?alt=media&token=1411aefb-f51b-43a7-b5eb-cb42a8df17d0",
  "genres": "Terror",
  "age": "16+",
  "maxres": "1080p",
  "type": "Cortometraje",
  "language": "ES",
  "status": "active",
  "fomo": 0,
  
 },
 {
  "id": 75,
  "videoId_yt": "PW_j7PcV9IU",
  "videoId_vm": null,
  "videoId_yt_dmq": "EjvDWJSzbpo",
  "videoId_svr": null,
  "videohref_yt": "https://www.youtube.com/watch?v=PW_j7PcV9IU",
  "videohref_vm": null,
  "videohref_yt_dmq": "https://www.youtube.com/watch?v=EjvDWJSzbpo",
  "videohref_svr": null,
  "title": "Khurku: Frontera Muerta",
  "synopsis": "En un mundo hostil para muchos, ellos tenían un plan que estaba saliendo bien, pero invadieron el territorio que no debían. Ni sus enemigos podrán salvarlos.",
  "description": null,
  "awards": null,
  "duration": "08:00",
  "starring": "Guion: Daro Ceballos\nReparto: Gaston Brouet, Pedro Muñoz, Yamila Fortuzi, Emmanuel Rubio, Javier Ivan Cano, Mauro Chialvo\nFotografía: Javier Ivan Cano\nMúsica: Daro Ceballos\nSonido: Stefano Lopez\nDirección Artística: Santiago Oliva, Paula Tissera\nEfectos visuales: Pablo Matos, Santiago Oliva\nMontaje: Daro Ceballos, Joaquin Fuertes, Pablo Matos",
  "producer": "Otro Plan Films",
  "director": "Daro Ceballos, Joaquin Fuertes",
  "city": null,
  "country": "Argentina",
  "year": 2020,
  "thumbnailurl": null,
  "posterurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter%2Fp75.jpg?alt=media&token=150f80c3-ca6e-454e-bede-3cd7ebc2b9a6",
  "poster375webpurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter375webp%2Fp75.webp?alt=media&token=08b1c2ad-b610-4aa5-a3cf-7531c2bd7fac",
  "bgurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fbg%2Fb75.jpg?alt=media&token=8a8ffa72-6069-4041-8308-5523dcfd0c61",
  "genres": "Terror",
  "age": "16+",
  "maxres": "1080p",
  "type": "Cortometraje",
  "language": "ES",
  "status": "active",
  "fomo": 0,
  
 },
 {
  "id": 76,
  "videoId_yt": "9lAMkmeiLpA",
  "videoId_vm": null,
  "videoId_yt_dmq": "aL26-J1VhLw",
  "videoId_svr": null,
  "videohref_yt": "https://www.youtube.com/watch?v=9lAMkmeiLpA",
  "videohref_vm": null,
  "videohref_yt_dmq": "https://www.youtube.com/watch?v=aL26-J1VhLw",
  "videohref_svr": null,
  "title": "El hilo negro",
  "synopsis": "Blanca Ravenna, anciana y enferma, selecciona a la joven Alma para que la ayude en sus tareas y asuntos personales. Durante la primera noche hay extrañas apariciones de muñecos y un misterioso baúl. A la mañana siguiente Blanca se encuentra rejuvenecida y con Alma convertida en muñeca.",
  "description": null,
  "awards": null,
  "duration": "08:15",
  "starring": "Guion: Bernardo Lorenzo\nReparto: Leticia Ramos, Ana Pordomingo\nFotografía: Angélica Díaz Reyes\nMúsica: Joaquín Ignacio Mendoza\nSonido: Camila López\nDirección Artística: Micaela Noguera Benítez\nMaquillaje: Micaela Noguera Benítez, Daniela Coronel\nMontaje: Bernardo Lorenzo",
  "producer": null,
  "director": "Bernardo Lorenzo",
  "city": null,
  "country": "Argentina",
  "year": 2019,
  "thumbnailurl": null,
  "posterurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter%2Fp76.jpg?alt=media&token=c73da979-8201-49af-ab0a-39bc7c36bd5c",
  "poster375webpurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter375webp%2Fp76.webp?alt=media&token=c4a0119e-6563-4d7b-bf34-f1838888f88f",
  "bgurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fbg%2Fb76.jpg?alt=media&token=f7957534-f091-4a3e-860b-988636f315e5",
  "genres": "Terror",
  "age": "16+",
  "maxres": "1080p",
  "type": "Cortometraje",
  "language": "ES",
  "status": "active",
  "fomo": 0,
  
 },
 {
  "id": 77,
  "videoId_yt": null,
  "videoId_vm": 340206810,
  "videoId_yt_dmq": "EPz3-NPk6uk",
  "videoId_svr": null,
  "videohref_yt": null,
  "videohref_vm": "https://vimeo.com/340206810",
  "videohref_yt_dmq": "https://www.youtube.com/watch?v=EPz3-NPk6uk",
  "videohref_svr": null,
  "title": "Chike",
  "synopsis": "La adolescencia es muchas veces un camino sinuoso y solitario. Ana y Carla se encuentran para experimentar con sus deseos, determinaciones y miedos para vivir momentos fugaces pero decisivos.",
  "description": null,
  "awards": null,
  "duration": "16:04",
  "starring": "Guion: Lucas Perna Gutiérrez, Lucía Ravanelli\nReparto: Rosario Ramos Ísola, Sofía del Tuffo\nFotografía: Carola Forster\nSonido: Lourdes Pérez\nDirección Artística: Manuel Franco",
  "producer": null,
  "director": "Lucía Ravanelli",
  "city": null,
  "country": "Argentina",
  "year": 2017,
  "thumbnailurl": null,
  "posterurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter%2Fp77.jpg?alt=media&token=9d9c6068-4d09-4a88-86c9-1812d7b320e9",
  "poster375webpurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter375webp%2Fp77.webp?alt=media&token=4428b7ba-b429-477f-b01c-9a7bde4e5536",
  "bgurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fbg%2Fb77.jpg?alt=media&token=035a021a-21a9-4fe5-aa7d-702cdb82b62e",
  "genres": "Drama, Adolescencia, LGBT",
  "age": "16+",
  "maxres": "1080p",
  "type": "Cortometraje",
  "language": "ES",
  "status": "none",
  "fomo": 0,
  
 },
 {
  "id": 78,
  "videoId_yt": "WdQCJp7TFkw",
  "videoId_vm": null,
  "videoId_yt_dmq": "6O-juymmnYk",
  "videoId_svr": null,
  "videohref_yt": "https://www.youtube.com/watch?v=WdQCJp7TFkw",
  "videohref_vm": null,
  "videohref_yt_dmq": "https://www.youtube.com/watch?v=6O-juymmnYk",
  "videohref_svr": null,
  "title": "Una cabrita sin cuernos",
  "synopsis": "El comisario de un pueblo debe investigar un libro para niños que fue confiscado a una alumna de 7 años. El autor del libro es soviético. ¿Por qué una niña tiene un libro posiblemente comunista? ¿Quién se lo dio? Todo esto tendrá que ser investigado, independientemente de las consecuencias.",
  "description": null,
  "awards": null,
  "duration": "14:15",
  "starring": "Guion: Sebastián Dietsch\nReparto: Gabriel Fernández, Javier Schonholz, Mario Alarcón, Mariana Brangeri, Liliana Capurro\nFotografía: Massimo Ruggieri, Aureliano Gentile\nMúsica: Alejandro Mendez Sierra\nSonido: Juan Pablo Ojeda\nVestuario: Romina Quiroga",
  "producer": "INCAA",
  "director": "Sebastian Dietsch",
  "city": null,
  "country": "Argentina",
  "year": 2018,
  "thumbnailurl": null,
  "posterurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter%2Fp78.jpg?alt=media&token=764c8df4-5e24-4482-8b72-d5fdf1f6ad1e",
  "poster375webpurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter375webp%2Fp78.webp?alt=media&token=517c92f9-1546-43bc-8ba8-a4ba16a63ce5",
  "bgurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fbg%2Fb78.jpg?alt=media&token=94807e9a-8776-4b70-ac97-620143b89069",
  "genres": "Comedia, Drama",
  "age": "16+",
  "maxres": "1080p",
  "type": "Cortometraje",
  "language": "ES",
  "status": "active",
  "fomo": 0,
  
 },
 {
  "id": 79,
  "videoId_yt": null,
  "videoId_vm": 114794052,
  "videoId_yt_dmq": "8guy9nsr7Rs",
  "videoId_svr": null,
  "videohref_yt": null,
  "videohref_vm": "https://vimeo.com/114794052",
  "videohref_yt_dmq": "https://www.youtube.com/watch?v=8guy9nsr7Rs",
  "videohref_svr": null,
  "title": "La hora de la siesta",
  "synopsis": "Alma se escapa de su casa a la hora de la siesta para jugar y explorar en los alrededores del pueblo, un día encuentra un viejo tanque australiano y ya nada volverá a ser lo mismo.",
  "description": null,
  "awards": null,
  "duration": "13:51",
  "starring": "Guion: Candelaria Pantaleón\nReparto: Lucía Castillo, Julia Azar\nFotografía: Antonela Zaniratto\nMúsica: Carlos Galmes\nSonido: Carlos Galmes\nDirección Artística: Constanza López, Catalina Mercuri\nMaquillaje: Sabina de Simone\nPeluquería: Sabina de Simone\nMontaje: Carolina Sanfortunato",
  "producer": null,
  "director": "Candelaria Pantaleón",
  "city": null,
  "country": "Argentina",
  "year": 2014,
  "thumbnailurl": null,
  "posterurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter%2Fp79.jpg?alt=media&token=48bea249-51ec-418e-91cb-c4742ac8e8ea",
  "poster375webpurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter375webp%2Fp79.webp?alt=media&token=e6f1ea19-f8e0-4034-9cfd-fcdaa6cc057b",
  "bgurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fbg%2Fb79.jpg?alt=media&token=060f908c-316e-4f49-984a-6580eb84680f",
  "genres": "Drama",
  "age": "16+",
  "maxres": "1080p",
  "type": "Cortometraje",
  "language": "ES",
  "status": "active",
  "fomo": 0,
  
 },
 {
  "id": 80,
  "videoId_yt": "AqSDGWQTXcw",
  "videoId_vm": null,
  "videoId_yt_dmq": "NmR_rwApOSg",
  "videoId_svr": null,
  "videohref_yt": "https://www.youtube.com/watch?v=AqSDGWQTXcw",
  "videohref_vm": null,
  "videohref_yt_dmq": "https://www.youtube.com/watch?v=NmR_rwApOSg",
  "videohref_svr": null,
  "title": "Electoral",
  "synopsis": "Poco antes de asumir, este presidente tiene algo que decir.",
  "description": null,
  "awards": null,
  "duration": "05:33",
  "starring": "Guion: Alejandro Luna\nReparto: German Baso\nFotografía: Alejandro Luna\nMúsica: Alejandro Luna\nSonido: Cristian Rivero\nDirección Artística: Alejandro Luna\nEfectos visuales: Alejandro Luna\nVestuario: Alejandro Luna\nMaquillaje: Florencia Colarussa\nPeluquería: Florencia Colarussa\nMontaje: Alejandro Luna",
  "producer": "Trascendencia Films",
  "director": "Alejandro Luna",
  "city": null,
  "country": "Argentina",
  "year": 2019,
  "thumbnailurl": null,
  "posterurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter%2Fp80.jpg?alt=media&token=b86bce92-e275-459d-b544-fef238ef5729",
  "poster375webpurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter375webp%2Fp80.webp?alt=media&token=7061d5c5-445d-45ea-abb0-742c92897286",
  "bgurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fbg%2Fb80.jpg?alt=media&token=c10fe4cf-d3aa-49ec-87a3-7345b64a3c7f",
  "genres": "Drama",
  "age": "16+",
  "maxres": "1080p",
  "type": "Cortometraje",
  "language": "ES",
  "status": "active",
  "fomo": 0,
  
 },
 {
  "id": 81,
  "videoId_yt": null,
  "videoId_vm": 403447362,
  "videoId_yt_dmq": "cP3-jpY7ynY",
  "videoId_svr": null,
  "videohref_yt": null,
  "videohref_vm": "https://vimeo.com/403447362",
  "videohref_yt_dmq": "https://www.youtube.com/watch?v=cP3-jpY7ynY",
  "videohref_svr": null,
  "title": "Steven",
  "synopsis": "Steven y Javier viven en lo alto de un páramo en lo que parece ser un sueño vivido o una pesadilla.",
  "description": null,
  "awards": null,
  "duration": "07:08",
  "starring": "Reparto: Steven Quiñones, Javier Quiñone\nMúsica: Joaquin Bartolomé Otero Sojo\nSonido: Gonzalo Komel\nMontaje: Felipe Bergaño, Alexan Sarikamichian",
  "producer": "Alexan Films",
  "director": "Alexan Sarikamichian, Felipe Bergaño, Samir Marun",
  "city": null,
  "country": "Argentina",
  "year": 2019,
  "thumbnailurl": null,
  "posterurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter%2Fp81.jpg?alt=media&token=8ff802c4-62c4-4961-8dde-8260b3948d0b",
  "poster375webpurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter375webp%2Fp81.webp?alt=media&token=42111fa4-4632-41a2-8a1c-4c6698498266",
  "bgurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fbg%2Fb81.jpg?alt=media&token=22acab9d-d988-4528-a133-e477e7d4955c",
  "genres": "Drama, Distopía",
  "age": "16+",
  "maxres": "1080p",
  "type": "Cortometraje",
  "language": "ES",
  "status": "active",
  "fomo": 0,
  
 },
 {
  "id": 82,
  "videoId_yt": "rqjKQ-OOSvs",
  "videoId_vm": null,
  "videoId_yt_dmq": "NldT1SoAe2A",
  "videoId_svr": null,
  "videohref_yt": "https://www.youtube.com/watch?v=rqjKQ-OOSvs",
  "videohref_vm": null,
  "videohref_yt_dmq": "https://www.youtube.com/watch?v=NldT1SoAe2A",
  "videohref_svr": null,
  "title": "Tincho",
  "synopsis": "Juan (16 años) es un adolescente que lidia con problemas típicos de su edad como cualquier otro, sin embargo, distintas situaciones, lo llevaran a descubrir una verdad que cambiara su vida.",
  "description": null,
  "awards": null,
  "duration": "20:32",
  "starring": "Guion: Ezequiel Pelaez\nReparto: Facundo Pelaez, Maximiliano Leiva, Iara Rearte, Ladislao Gigli, Gabina Ghiano, Sebastian Staltari, Andrea Villarroel\nFotografía: Tomas Reyes\nMúsica: Franco Pelaez\nSonido: Agustina Tardon\nDirección Artística: Ezequiel Pelaez\nEfectos visuales: Ezequiel Pelaez\nVestuario: Jimena Pelaez\nMaquillaje: Belen Mansilla\nPeluquería: Belen Mansilla",
  "producer": "ColoShots & Cinescrupulos",
  "director": "Ezequiel Pelaez",
  "city": null,
  "country": "Argentina",
  "year": 2020,
  "thumbnailurl": null,
  "posterurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter%2Fp82.jpg?alt=media&token=6376f962-fd15-4468-9ad6-6a0d0c319119",
  "poster375webpurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter375webp%2Fp82.webp?alt=media&token=f65a86fb-124f-4cdc-bc8d-419c8982385a",
  "bgurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fbg%2Fb82.jpg?alt=media&token=97921735-78b1-4a97-9790-8de0349686c3",
  "genres": "Drama",
  "age": "16+",
  "maxres": "1080p",
  "type": "Cortometraje",
  "language": "ES",
  "status": "active",
  "fomo": 0,
  
 },
 {
  "id": 83,
  "videoId_yt": null,
  "videoId_vm": 378876091,
  "videoId_yt_dmq": "fjVoUyZP2O4",
  "videoId_svr": null,
  "videohref_yt": null,
  "videohref_vm": "https://vimeo.com/378876091",
  "videohref_yt_dmq": "https://www.youtube.com/watch?v=fjVoUyZP2O4",
  "videohref_svr": null,
  "title": "Mi Misión",
  "synopsis": "Juan, un experto astronauta, tiene una misión de descubrir si el planeta Marte podría ser posible hogar para el ser humano, pero descubre una terrible verdad.",
  "description": null,
  "awards": null,
  "duration": "09:37",
  "starring": "Guion: Nicolás Lobos\nReparto: Sebastián Aisen\nFotografía: Sergio Castell\nMúsica: Isaac Salinas\nSonido: Maxi Tucho Cortez\nDirección Artística: Ruben Casenave\nEfectos visuales: Nicolas Lobos\nVestuario: Milagros Mignorance\nMaquillaje: Celeste Oyola\nPeluquería: Celeste Oyola\nMontaje: Nicolas Lobos",
  "producer": "Celeste Oyola",
  "director": "Nicolás Lobos",
  "city": null,
  "country": "Argentina",
  "year": 2019,
  "thumbnailurl": null,
  "posterurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter%2Fp83.jpg?alt=media&token=cac6e022-8067-4063-a05a-c59e16293b02",
  "poster375webpurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter375webp%2Fp83.webp?alt=media&token=3316597d-c080-477b-b147-087d728ebbe2",
  "bgurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fbg%2Fb83.jpg?alt=media&token=43e920a2-9118-4f7b-b4f4-14899b765c47",
  "genres": "Acción, Thriller, Ciencia Ficción",
  "age": "13+",
  "maxres": "1080p",
  "type": "Cortometraje",
  "language": "ES",
  "status": "active",
  "fomo": 0,
  
 },
 {
  "id": 84,
  "videoId_yt": "ujP5IgAVgzM",
  "videoId_vm": null,
  "videoId_yt_dmq": "ZejG-A56p2o",
  "videoId_svr": null,
  "videohref_yt": "https://www.youtube.com/watch?v=ujP5IgAVgzM",
  "videohref_vm": null,
  "videohref_yt_dmq": "https://www.youtube.com/watch?v=ZejG-A56p2o",
  "videohref_svr": null,
  "title": "La otra Mirada",
  "synopsis": "Una joven adolescente sorda se imagina un mundo donde todos/as hablan lengua de señas para sobrellevar un accidente.",
  "description": null,
  "awards": null,
  "duration": "08:05",
  "starring": "Guion: Luciano Nacci\nReparto: Catalina Hannon, Valentina Zapata, Julian Giorello\nFotografía: Santiago Grassi\nMúsica: Lucas Kremar\nSonido: Lucas Kremar\nDirección Artística: Canario\nVestuario: Canario\nMaquillaje: Canario\nMontaje: Luciano Nacci",
  "producer": "Federico Sykes, Luciano Nacci, KM Sur Films",
  "director": "Luciano Nacci",
  "city": null,
  "country": "Argentina",
  "year": 2018,
  "thumbnailurl": null,
  "posterurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter%2Fp84.jpg?alt=media&token=948f9d4e-1c78-4740-b429-c97d8866fb60",
  "poster375webpurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter375webp%2Fp84.webp?alt=media&token=17f8dd87-a57d-4acb-b284-47a4f7708be8",
  "bgurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fbg%2Fb84.jpg?alt=media&token=525edcab-ee22-4082-8c78-b1f60a47c484",
  "genres": "Drama",
  "age": "16+",
  "maxres": "1080p",
  "type": "Cortometraje",
  "language": "ES",
  "status": "active",
  "fomo": 0,
  
 },
 {
  "id": 85,
  "videoId_yt": null,
  "videoId_vm": 139759679,
  "videoId_yt_dmq": "47E0wezVSLM",
  "videoId_svr": null,
  "videohref_yt": null,
  "videohref_vm": "https://vimeo.com/139759679",
  "videohref_yt_dmq": "https://www.youtube.com/watch?v=47E0wezVSLM",
  "videohref_svr": null,
  "title": "Puente",
  "synopsis": "Andrés y Ana se encuentran envueltos en la imposibilidad de atravesar un puente. Aislados, se sumen en una discusión que atraviesa sus propios miedos y desencantos, buscando la forma de cruzar finalmente a un sitio que los lleve a un nuevo destino.",
  "description": null,
  "awards": null,
  "duration": "18:42",
  "starring": "Guion: Natxo Fuentes\nReparto: Guillermo Berthold, Manuela Fernández Vivian\nFotografía: Lautaro Martínez\nMúsica: David Prats Juan\nSonido: Ariel Gaspoz\nMontaje: Ivan Oleksak, Javier Andrés Vigil",
  "producer": "Paula Arcucci, Alejandra Raselli",
  "director": "Javier Andrés Vigil",
  "city": null,
  "country": "Argentina, España",
  "year": 2016,
  "thumbnailurl": null,
  "posterurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter%2Fp85.jpg?alt=media&token=d56726df-4a06-4f78-a1a7-91a72055b60a",
  "poster375webpurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter375webp%2Fp85.webp?alt=media&token=111082d1-b488-4cb8-adc5-cc145658ed6f",
  "bgurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fbg%2Fb85.jpg?alt=media&token=e9b52782-cdff-440d-93e2-acf48ee0a500",
  "genres": "Drama, Romance",
  "age": "16+",
  "maxres": "1080p",
  "type": "Cortometraje",
  "language": "ES",
  "status": "active",
  "fomo": 0,
  
 },
 {
  "id": 86,
  "videoId_yt": "LbiKO9JyV-8",
  "videoId_vm": null,
  "videoId_yt_dmq": "UZ0ElB-DCc8",
  "videoId_svr": null,
  "videohref_yt": "https://www.youtube.com/watch?v=LbiKO9JyV-8",
  "videohref_vm": null,
  "videohref_yt_dmq": "https://www.youtube.com/watch?v=UZ0ElB-DCc8",
  "videohref_svr": null,
  "title": "Warriors",
  "synopsis": "Pablo es un joven artista marcial que vive con su abuela y, por falta de dinero, está a punto de tomar una decisión equivocada. En medio de una enorme confusión, Víctor aparecerá, un ser espiritual que hará que Pablo entienda su vida desde otro punto de vista. Una batalla interior entre el bien y el mal.",
  "description": null,
  "awards": null,
  "duration": "20:56",
  "starring": "Guion: Fabricio Martin\nReparto: Pablo Castro, Walter Gomez, Alejandro Lopez Hiriart, Fabricio Martin, Yoana Pizzano, Juan Carlos Puppo, Manuel Rael, Alejandro Rinaldi, Viviana Sendra\nFotografía: Cristian Tapia Marchiori, Fabricio Martín\nSonido: Cristian Tapia Marchiori\nDirección Artística: Fabricio Martín\nMontaje: Fabricio Martín",
  "producer": "Victory Producciones",
  "director": "Fabricio Martin",
  "city": null,
  "country": "Argentina",
  "year": 2013,
  "thumbnailurl": null,
  "posterurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter%2Fp86.jpg?alt=media&token=9238ca53-3907-417b-a382-83f9301bcf04",
  "poster375webpurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter375webp%2Fp86.webp?alt=media&token=dda6da37-b4c7-4036-a86d-36d1e1f77a17",
  "bgurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fbg%2Fb86.jpg?alt=media&token=f1b105b1-e802-4aaf-b218-0155839b2482",
  "genres": "Acción, Aventuras, Artes Marciales",
  "age": "16+",
  "maxres": "1080p",
  "type": "Cortometraje",
  "language": "ES",
  "status": "active",
  "fomo": 0,
  
 },
 {
  "id": 87,
  "videoId_yt": "02-5eMLofZ4",
  "videoId_vm": null,
  "videoId_yt_dmq": "RMsg06hxp6g",
  "videoId_svr": null,
  "videohref_yt": "https://www.youtube.com/watch?v=02-5eMLofZ4",
  "videohref_vm": null,
  "videohref_yt_dmq": "https://www.youtube.com/watch?v=RMsg06hxp6g",
  "videohref_svr": null,
  "title": "Tardes de sol noches de agua",
  "synopsis": "Lamia, una joven mujer, con la mentalidad de una niña, vive con su madre. Hera, una mujer castradora y supersticiosa. Debido a la enfermiza relación que esta mantiene con su hija, el contacto de Lamia con el mundo exterior es casi nulo. La única oportunidad que la joven tiene para recorrer las calles del pueblo, es a través de mandados cotidianos.",
  "description": "Es en estos paseos, que la muchacha engendra una adoración obsesiva por Conejo. Un hombre del pueblo, el cual está siempre acompañado por su pandilla. Aprovechándose de la condición de Lamia y del amor infantil que ésta tiene para con él, Conejo la seduce con dobles intenciones.",
  "awards": null,
  "duration": "19:59",
  "starring": "Guion: Vicente D. Menzella\nReparto: Lara Todeschini, Adriana Frodella, David Giménez, Héctor Vilmar, Mumo Oviedo, Francisco Fissolo, Ricardo Martínez, Gloria Piñero\nFotografía: Victorio Parodi\nSonido: Aldo Dignani\nDirección Artística: Antonela Solier\nMontaje: Vicente D. Menzella",
  "producer": "Fiama Donnet, C.A.Y. Productora",
  "director": "Vicente D. Menzella",
  "city": null,
  "country": "Argentina",
  "year": 2019,
  "thumbnailurl": null,
  "posterurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter%2Fp87.jpg?alt=media&token=73abd2a4-a482-473a-b602-563a2819a939",
  "poster375webpurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter375webp%2Fp87.webp?alt=media&token=8d57e409-db06-468a-8166-42c62f323ef1",
  "bgurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fbg%2Fb87.jpg?alt=media&token=8ad18e86-ca97-4a45-8ada-90a09a394a6b",
  "genres": "Drama, Fantasia, Terror, Surrealismo, Discapacidad",
  "age": "16+",
  "maxres": "1080p",
  "type": "Cortometraje",
  "language": "ES",
  "status": "active",
  "fomo": 0,
  
 },
 {
  "id": 88,
  "videoId_yt": "YNNq8WqNJrI",
  "videoId_vm": null,
  "videoId_yt_dmq": "fzUzOs2LuTg",
  "videoId_svr": null,
  "videohref_yt": "https://www.youtube.com/watch?v=YNNq8WqNJrI",
  "videohref_vm": null,
  "videohref_yt_dmq": "https://www.youtube.com/watch?v=fzUzOs2LuTg",
  "videohref_svr": null,
  "title": "Error 404",
  "synopsis": "Una empresa. El jefe y sus empleados trabajan frente a sus computadoras, concentrados, productivos y prestan solo atención a su monitor. De repente ocurre una catástrofe, se corta Internet. La empresa está en crisis.",
  "description": null,
  "awards": null,
  "duration": "13:46",
  "starring": "Guion: Mariana Wainstein\nReparto: Julieta Zylberberg, Paula Grinszpan, Walter Jakob, Iair Said, Katia Szechtman, Esteban Lamothe, María Fernanda Callejón, Arnaldo André",
  "producer": null,
  "director": "Mariana Wainstein",
  "city": null,
  "country": "Argentina",
  "year": 2016,
  "thumbnailurl": null,
  "posterurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter%2Fp88.jpg?alt=media&token=8db49f3d-6233-4db3-a8b9-c7f63c544137",
  "poster375webpurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter375webp%2Fp88.webp?alt=media&token=75c45f31-a973-47be-9b65-4b85d4933727",
  "bgurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fbg%2Fb88.jpg?alt=media&token=dd9bfc88-af85-4e2c-aec8-9c9597b6c81a",
  "genres": "Comedia",
  "age": "13+",
  "maxres": "1080p",
  "type": "Cortometraje",
  "language": "ES",
  "status": "active",
  "fomo": 0,
  
 },
 {
  "id": 89,
  "videoId_yt": "yFP-oIdykRw",
  "videoId_vm": null,
  "videoId_yt_dmq": null,
  "videoId_svr": null,
  "videohref_yt": "https://www.youtube.com/watch?v=yFP-oIdykRw",
  "videohref_vm": null,
  "videohref_yt_dmq": null,
  "videohref_svr": null,
  "title": "Yo nunca",
  "synopsis": "Juguemos a un juego: “Yo nunca\"",
  "description": null,
  "awards": null,
  "duration": "15:44",
  "starring": "Guion: Liesel Bretscher, Fede Logo\nReparto: Stephanie Troiano, Sofía Smolar, Sofía Centelles, Liesel Bretscher, Sofía Kaspar, Nancy Pros, Mónica Ibarra, Rubén Loguancio",
  "producer": "Murder city, Malpelo Films",
  "director": "Fede Logo",
  "city": null,
  "country": "Argentina",
  "year": 2013,
  "thumbnailurl": null,
  "posterurl": null,
  "bgurl": null,
  "genres": "Thriller, Adolescencia",
  "age": "16+",
  "maxres": "1080p",
  "type": "Cortometraje",
  "language": "ES",
  "status": "none",
  "fomo": 0,
  
 },
 {
  "id": 90,
  "videoId_yt": "6G0mQBeozkI",
  "videoId_vm": null,
  "videoId_yt_dmq": "uhM0qyMxiyg",
  "videoId_svr": null,
  "videohref_yt": "https://www.youtube.com/watch?v=6G0mQBeozkI",
  "videohref_vm": null,
  "videohref_yt_dmq": "https://www.youtube.com/watch?v=uhM0qyMxiyg",
  "videohref_svr": null,
  "title": "Citas",
  "synopsis": "Noelia y Damián tienen su primera cita. Damián y Noelia tienen su última cita. Damián intenta seducir a Noelia. Noelia intenta cortar con Damián.",
  "description": null,
  "awards": null,
  "duration": "08:46",
  "starring": "Guion: Guido Assenza Parisi\nReparto: Florencia Antonucci, Tomás Buccella",
  "producer": "Valeria Romero",
  "director": "Guido Assenza Parisi",
  "city": null,
  "country": "Argentina",
  "year": 2015,
  "thumbnailurl": null,
  "posterurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter%2Fp90.jpg?alt=media&token=4c50b9c5-f00e-4d44-8b4e-fa90adf806e0",
  "poster375webpurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter375webp%2Fp90.webp?alt=media&token=3c86addf-9ce1-43d0-940a-749c18b0957d",
  "bgurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fbg%2Fb90.jpg?alt=media&token=219061f4-f650-4b82-be0d-cc6821e7fc3d",
  "genres": "Drama, Romance",
  "age": "16+",
  "maxres": "1080p",
  "type": "Cortometraje",
  "language": "ES",
  "status": "active",
  "fomo": 0,
  
 },
 {
  "id": 91,
  "videoId_yt": null,
  "videoId_vm": 322644353,
  "videoId_yt_dmq": "hD9PuprCCJU",
  "videoId_svr": null,
  "videohref_yt": null,
  "videohref_vm": "https://vimeo.com/322644353",
  "videohref_yt_dmq": "https://www.youtube.com/watch?v=hD9PuprCCJU",
  "videohref_svr": null,
  "title": "Severiano",
  "synopsis": "Severiano es el reflejo de una juventud perdida. Se reconoce en su barrio y su único sostén son sus amigos. Como en un sueño adolescente lleno de nostalgia y melancolía, Severiano atraviesa sus días de juventud y adultez, donde el presente y la incertidumbre de su futuro se parecen estar encontrando.",
  "description": null,
  "awards": null,
  "duration": "18:52",
  "starring": "Guion: Francisco Mazziotti, Alexan Sarikamichian\nReparto: Severiano Astrada, Lucía Corral, Guido Raguza, Franco Dente",
  "producer": "Francisco Mazziotti, Alexan Sarikamichian",
  "director": "Francisco Mazziotti, Alexan Sarikamichian",
  "city": null,
  "country": "Argentina",
  "year": 2019,
  "thumbnailurl": null,
  "posterurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter%2Fp91.jpg?alt=media&token=8cdabbe9-9b3c-4f8b-8f43-bd0a5a58b662",
  "poster375webpurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter375webp%2Fp91.webp?alt=media&token=300a3c15-a973-4073-9147-96703c01f64c",
  "bgurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fbg%2Fb91.jpg?alt=media&token=8e8e1037-3c8b-4076-adff-3b11e1e7a0c5",
  "genres": "Drama, Romance",
  "age": "16+",
  "maxres": "1080p",
  "type": "Cortometraje",
  "language": "ES",
  "status": "active",
  "fomo": 0,
  
 },
 {
  "id": 92,
  "videoId_yt": "QW7dcWHFO9I",
  "videoId_vm": null,
  "videoId_yt_dmq": "lC_TpDhA6cw",
  "videoId_svr": null,
  "videohref_yt": "https://www.youtube.com/watch?v=QW7dcWHFO9I",
  "videohref_vm": null,
  "videohref_yt_dmq": "https://www.youtube.com/watch?v=lC_TpDhA6cw",
  "videohref_svr": null,
  "title": "Soy Buenos Aires",
  "synopsis": "Si Buenos Aires fuera una persona, ¿cómo sería? Un hombre joven cuenta su historia de ascenso a la fama desde la camilla de una habitación de un hospital. ",
  "description": "De sus orígenes mesiánicos y su infancia en el exilio, el nombre que le había sido dado por un error burocrático le marcaría por siempre su destino: ser ecléctico y contradictorio, elegante y pobre, nostálgico e idealista; ser Buenos Aires.",
  "awards": null,
  "duration": "15:05",
  "starring": "Guion: Tomás Gómez Bustillo\nReparto: Dante Venesio, Fernando Azzollina, Hernan Bustamante, Paula Trucchi, Santiago Vinollo",
  "producer": "Julieta Cejas, Lucas Balbiani",
  "director": "Tomás Gómez Bustillo",
  "city": null,
  "country": "Argentina",
  "year": 2016,
  "thumbnailurl": null,
  "posterurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter%2Fp92.jpg?alt=media&token=7f3547db-f91d-4a4a-9789-4b4fcd35071f",
  "poster375webpurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter375webp%2Fp92.webp?alt=media&token=f03f84f3-65cd-441b-bc54-47aa198fee19",
  "bgurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fbg%2Fb92.jpg?alt=media&token=65730653-a467-4a50-bff3-6e294c7d6dbb",
  "genres": "Comedia",
  "age": "13+",
  "maxres": "1080p",
  "type": "Cortometraje",
  "language": "ES",
  "status": "active",
  "fomo": 0,
  
 },
 {
  "id": 93,
  "videoId_yt": "Hiv3iE5pwG4",
  "videoId_vm": null,
  "videoId_yt_dmq": "8HGk--jbYtM",
  "videoId_svr": null,
  "videohref_yt": "https://www.youtube.com/watch?v=Hiv3iE5pwG4",
  "videohref_vm": null,
  "videohref_yt_dmq": "https://www.youtube.com/watch?v=8HGk--jbYtM",
  "videohref_svr": null,
  "title": "La Primera Víctima",
  "synopsis": "Una mujer que recibe continuas llamadas de un acosador, es visitada por un periodista, que dice conocer la identidad del criminal.",
  "description": null,
  "awards": null,
  "duration": "21:17",
  "starring": "Guion: Nicolás Ratier\nReparto: Julieta Bermudez, Francisco Mainella\nFotografía: Nicolás Ratier\nMúsica: Patricio Franzi\nSonido: Emiliano Caceres, Martin Handel\nMontaje: Nicolás Ratier",
  "producer": "Nicolás Ratier",
  "director": "Nicolás Ratier",
  "city": null,
  "country": "Argentina",
  "year": 2018,
  "thumbnailurl": null,
  "posterurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter%2Fp93.jpg?alt=media&token=00575cd9-311f-4162-8dc5-43d4acd2b393",
  "poster375webpurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter375webp%2Fp93.webp?alt=media&token=72c934e2-2d2b-4901-879b-e1c165bb292d",
  "bgurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fbg%2Fb93.jpg?alt=media&token=9bbc7a13-e47e-4177-9b28-77d1045745a4",
  "genres": "Comedia, Thriller",
  "age": "16+",
  "maxres": "1080p",
  "type": "Cortometraje",
  "language": "ES",
  "status": "active",
  "fomo": 0,
  
 },
 {
  "id": 94,
  "videoId_yt": "rl4N1nAxT90",
  "videoId_vm": null,
  "videoId_yt_dmq": "ci9IBVa3glk",
  "videoId_svr": null,
  "videohref_yt": "https://www.youtube.com/watch?v=rl4N1nAxT90",
  "videohref_vm": null,
  "videohref_yt_dmq": "https://www.youtube.com/watch?v=ci9IBVa3glk",
  "videohref_svr": null,
  "title": "Besos Azules",
  "synopsis": "Una historia de fantasía con múltiples posibles lecturas, buscando encontrar en el espectador a un sujeto activo.",
  "description": null,
  "awards": null,
  "duration": "14:32",
  "starring": "Reparto: Rodrigo Rojo, Katherina Leeha Ortiz Trench\nFotografía: Florencia Pugliese Micópeles\nSonido: Gonzalo Beain Fernández, Ailén Morosi, Eric Larsen, Lean Müler\nEfectos visuales: Esteban Fernández San Juan\nVestuario: Melina Galera\nMaquillaje: Nieves González\nPeluquería: Camila Mancero, Melina Galera, Julieta Oregioni",
  "producer": null,
  "director": "Gustavo Gutiérrez",
  "city": null,
  "country": "Argentina",
  "year": 2018,
  "thumbnailurl": null,
  "posterurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter%2Fp94.jpg?alt=media&token=50fa7ac8-2233-48db-bc58-5cae93d1e253",
  "poster375webpurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter375webp%2Fp94.webp?alt=media&token=2a5e901f-17e6-443b-958b-137796465305",
  "bgurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fbg%2Fb94.jpg?alt=media&token=7c3b9c4d-b64c-41e3-b2c5-6a4875661cc6",
  "genres": "Fantasía, Ficción",
  "age": "13+",
  "maxres": "1080p",
  "type": "Cortometraje",
  "language": "ES",
  "status": "active",
  "fomo": 0,
  
 },
 {
  "id": 95,
  "videoId_yt": "qnATMh_eBC0",
  "videoId_vm": null,
  "videoId_yt_dmq": "A0fwux1TMcg",
  "videoId_svr": null,
  "videohref_yt": "https://www.youtube.com/watch?v=qnATMh_eBC0",
  "videohref_vm": null,
  "videohref_yt_dmq": "https://www.youtube.com/watch?v=A0fwux1TMcg",
  "videohref_svr": null,
  "title": "Apariencias",
  "synopsis": "La vida perfecta de Soledad se derrumba cuando una ex amiga regresa a su pueblo para destruir su felicidad.",
  "description": null,
  "awards": null,
  "duration": "14:53",
  "starring": "Reparto: Sofía Alladio, Pablo Pautasso\nFotografía: Joaquín Domínguez\nMúsica: Felipe López\nSonido: Felipe López\nDirección Artística: Josefina Pierucci\nEfectos visuales: Mariano Ortega\nVestuario: Josefina Pierucci\nMaquillaje: Sofía Alladio\nMontaje: Mariano Ortega",
  "producer": "Mariano Ortega",
  "director": "Mariano Ortega",
  "city": null,
  "country": "Argentina",
  "year": 2018,
  "thumbnailurl": null,
  "posterurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter%2Fp95.jpg?alt=media&token=88539e74-dff6-41ed-8546-05c233afbaf3",
  "poster375webpurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter375webp%2Fp95.webp?alt=media&token=ab6bb1c5-5429-4adb-aa61-059446f7fc33",
  "bgurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fbg%2Fb95.jpg?alt=media&token=04ab5f5d-6b76-44d2-9c95-2940e8dd1606",
  "genres": "Drama, Thriller, Terror, Sátira, Surrealismo",
  "age": "16+",
  "maxres": "1080p",
  "type": "Cortometraje",
  "language": "ES",
  "status": "active",
  "fomo": 0,
  
 },
 {
  "id": 96,
  "videoId_yt": "T63ofzrUKPA",
  "videoId_vm": null,
  "videoId_yt_dmq": "xSn5eun50mE",
  "videoId_svr": null,
  "videohref_yt": "https://www.youtube.com/watch?v=T63ofzrUKPA",
  "videohref_vm": null,
  "videohref_yt_dmq": "https://www.youtube.com/watch?v=xSn5eun50mE",
  "videohref_svr": null,
  "title": "La hoguera",
  "synopsis": "Al notar que se avecina una tormenta, Roque y Macarena vuelven de una tarde de pesca. En la oscuridad del camino, descubren una hoguera encendida entre los árboles.",
  "description": null,
  "awards": null,
  "duration": "07:12",
  "starring": "Guion: Nicolás Amelio-Ortiz\nReparto: Alberto Arias, Daiana Hernández, Facundo R. Salomón, Tomás Gonzalez-Motalvo",
  "producer": "ZEPfilms",
  "director": "Nicolás Amelio-Ortiz",
  "city": null,
  "country": "Argentina",
  "year": 2016,
  "thumbnailurl": null,
  "posterurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter%2Fp96.jpg?alt=media&token=14a114c0-8f8f-49d4-8ed5-0f7d85ad308c",
  "poster375webpurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter375webp%2Fp96.webp?alt=media&token=6eb54b67-d2f4-4661-b43a-5b32b86275b3",
  "bgurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fbg%2Fb96.jpg?alt=media&token=a4c01282-87fb-4ae5-a4b3-0f2b53dc2be0",
  "genres": "Terror",
  "age": "16+",
  "maxres": "1080p",
  "type": "Cortometraje",
  "language": "ES",
  "status": "active",
  "fomo": 0,
  
 },
 {
  "id": 97,
  "videoId_yt": "Gs-ieSciS6A",
  "videoId_vm": null,
  "videoId_yt_dmq": "-9Wt8EhiwXY",
  "videoId_svr": null,
  "videohref_yt": "https://www.youtube.com/watch?v=Gs-ieSciS6A",
  "videohref_vm": null,
  "videohref_yt_dmq": "https://www.youtube.com/watch?v=-9Wt8EhiwXY",
  "videohref_svr": null,
  "title": "Salón Royale",
  "synopsis": "Ana se dirige junto a dos amigas a una fiesta. Tiene la ilusión de conocer a alguien, pero sus expectativas cambian cuando una de ellas le revela que a la fiesta… parece que va su ex.",
  "description": null,
  "awards": null,
  "duration": "13:55",
  "starring": "Guion: Sabrina Campos\nReparto: Julieta Zylberberg, Luciana Lifschitz, Mariana Bellati",
  "producer": "Sabrina Campos",
  "director": "Sabrina Campos",
  "city": null,
  "country": "Argentina",
  "year": 2011,
  "thumbnailurl": null,
  "posterurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter%2Fp97.jpg?alt=media&token=b0d9de33-010b-4d05-ae7a-df60ef634fad",
  "poster375webpurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter375webp%2Fp97.webp?alt=media&token=364e58f5-3913-43ce-8c5c-5413dcc972c5",
  "bgurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fbg%2Fb97.jpg?alt=media&token=5c42b8f1-5a9c-40b6-b6ef-7a2c9cedb1ee",
  "genres": "Comedia",
  "age": "13+",
  "maxres": "1080p",
  "type": "Cortometraje",
  "language": "ES",
  "status": "active",
  "fomo": 0,
  
 },
 {
  "id": 98,
  "videoId_yt": "zvSzGkZYnNs",
  "videoId_vm": null,
  "videoId_yt_dmq": "UNDNUONL2Qc",
  "videoId_svr": null,
  "videohref_yt": "https://www.youtube.com/watch?v=zvSzGkZYnNs",
  "videohref_vm": null,
  "videohref_yt_dmq": "https://www.youtube.com/watch?v=UNDNUONL2Qc",
  "videohref_svr": null,
  "title": "La invitación",
  "synopsis": "Robertito, un hombre superado por las circunstancias de la vida conyugal recibe una invitación que lo meterá en más problemas de los que esperaba.",
  "description": null,
  "awards": null,
  "duration": "14:12",
  "starring": "Reparto: Delia Spinola, Esther Ramos, Ivan Esquerré, Marcelo Sein, Marina Nicala, Natalia Maure",
  "producer": null,
  "director": "Pablo Mangiarotti",
  "city": null,
  "country": "Argentina",
  "year": 2013,
  "thumbnailurl": null,
  "posterurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter%2Fp98.jpg?alt=media&token=d5875b00-a273-48b3-8526-b4eae2f370fa",
  "poster375webpurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter375webp%2Fp98.webp?alt=media&token=e42f4435-5201-4ed5-bc52-a73732633a6c",
  "bgurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fbg%2Fb98.jpg?alt=media&token=eccd2215-4b8e-4c35-a256-66e318e68d2c",
  "genres": "Comedia",
  "age": "13+",
  "maxres": "1080p",
  "type": "Cortometraje",
  "language": "ES",
  "status": "active",
  "fomo": 0,
  
 },
 {
  "id": 99,
  "videoId_yt": "lwyUShTKuX4",
  "videoId_vm": null,
  "videoId_yt_dmq": "1SSp7o04ZMQ",
  "videoId_svr": null,
  "videohref_yt": "https://www.youtube.com/watch?v=lwyUShTKuX4",
  "videohref_vm": null,
  "videohref_yt_dmq": "https://www.youtube.com/watch?v=1SSp7o04ZMQ",
  "videohref_svr": null,
  "title": "Alfil. Primer movimiento",
  "synopsis": "Un asesino a sueldo es contratado para recuperar a la hija de un político reconocido, donde la acción y el suspense son protagonistas en esta breve historia.",
  "description": null,
  "awards": null,
  "duration": "16:35",
  "starring": "Reparto: Cumelén Sanz, Gaston Biagioni, Juan Carlos Mono López, Santiago Varela",
  "producer": null,
  "director": "Juan Pablo Contreras",
  "city": null,
  "country": "Argentina",
  "year": 2014,
  "thumbnailurl": null,
  "posterurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter%2Fp99.jpg?alt=media&token=af1722ed-07b3-4b22-8508-dbfceef49126",
  "poster375webpurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter375webp%2Fp99.webp?alt=media&token=ddaa510f-e2ab-4ab7-9266-a9d07f68fefe",
  "bgurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fbg%2Fb99.jpg?alt=media&token=e73d8c6f-cb29-4114-ba16-9417c8a9f45e",
  "genres": "Acción, Suspenso",
  "age": "16+",
  "maxres": "1080p",
  "type": "Cortometraje",
  "language": "ES",
  "status": "active",
  "fomo": 0,
  
 },
 {
  "id": 100,
  "videoId_yt": "zFr8-AH_36s",
  "videoId_vm": null,
  "videoId_yt_dmq": "QQNPRTUeCsg",
  "videoId_svr": null,
  "videohref_yt": "https://www.youtube.com/watch?v=zFr8-AH_36s",
  "videohref_vm": null,
  "videohref_yt_dmq": "https://www.youtube.com/watch?v=QQNPRTUeCsg",
  "videohref_svr": null,
  "title": "Grietas",
  "synopsis": "Cortometraje policíaco argentino de Gonzalo Piñero.",
  "description": null,
  "awards": null,
  "duration": "15:05",
  "starring": "Guion: Alejandro Muñoz, Gonzalo Piñero\nReparto: Julio Costa, Esteban Cozzo, Martín Campos, Vitto Manccini, Alejandro Muñoz\nFotografía: Gonzalo Piñero\nMontaje: Gonzalo Piñero",
  "producer": null,
  "director": "Gonzalo Piñero",
  "city": null,
  "country": "Argentina",
  "year": 2017,
  "thumbnailurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fthumbnail%2Ft100.jpg?alt=media&token=03f699c8-d8d4-48c3-b4ae-fd9a2cc00954",
  "posterurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter%2Fp100.jpg?alt=media&token=3e99dbf7-5c54-486b-8676-9256c0c37816",
  "poster375webpurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter375webp%2Fp100.webp?alt=media&token=a2fd8a69-850f-406e-be95-6e11eb72d6b5",
  "bgurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fbg%2Fb100.jpg?alt=media&token=cf631fb4-f56a-44ea-9245-db75c20dd153",
  "genres": "Acción, Suspenso",
  "age": "16+",
  "maxres": "1080p",
  "type": "Cortometraje",
  "language": "ES",
  "status": "active",
  "fomo": 0,
  
 },
 {
  "id": 101,
  "videoId_yt": "wtc0WNgZwNw",
  "videoId_vm": null,
  "videoId_yt_dmq": null,
  "videoId_svr": null,
  "videohref_yt": "https://www.youtube.com/watch?v=wtc0WNgZwNw",
  "videohref_vm": null,
  "videohref_yt_dmq": null,
  "videohref_svr": null,
  "title": "Metro Deliré",
  "synopsis": "Un adolescente experimenta una serie de sucesos en los subtes de Buenos Aires.",
  "description": null,
  "awards": null,
  "duration": "06:50",
  "starring": "Reparto: Nacho Vigliocco, Pasquin Lopez",
  "producer": null,
  "director": "Luciano Nacci",
  "city": null,
  "country": "Argentina",
  "year": 2013,
  "thumbnailurl": null,
  "posterurl": null,
  "bgurl": null,
  "genres": "Comedia, Drama",
  "age": "16+",
  "maxres": "1080p",
  "type": "Cortometraje",
  "language": "ES",
  "status": "none",
  "fomo": 0,
  
 },
 {
  "id": 102,
  "videoId_yt": "gzlaG8-Sh44",
  "videoId_vm": null,
  "videoId_yt_dmq": "2B9gHHqZifE",
  "videoId_svr": null,
  "videohref_yt": "https://www.youtube.com/watch?v=gzlaG8-Sh44",
  "videohref_vm": null,
  "videohref_yt_dmq": "https://www.youtube.com/watch?v=2B9gHHqZifE",
  "videohref_svr": null,
  "title": "El Caminante",
  "synopsis": "Un hombre se detiene en medio de la calle, mientras los autos esperan. La ansiedad crece; las bocinas y los reclamos también.",
  "description": null,
  "awards": null,
  "duration": "07:59",
  "starring": "Reparto: Debora Rodriguez, Germán Moldován, Matias Tisocco, Pasquin Lopez",
  "producer": null,
  "director": "Luciano Nacci",
  "city": null,
  "country": "Argentina",
  "year": 2013,
  "thumbnailurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fthumbnail%2Ft102.jpg?alt=media&token=95eaea11-1970-49d2-892b-fe5fab49cbec",
  "posterurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter%2Fp102.jpg?alt=media&token=f3cb0b02-d0f5-45e6-a5c5-d73cee7caeb7",
  "poster375webpurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter375webp%2Fp102.webp?alt=media&token=70346a34-1d50-4260-84e1-b9b439e5c7b3",
  "bgurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fbg%2Fb102.jpg?alt=media&token=b4a8a60b-1a57-4ba6-bc22-a243dd05136d",
  "genres": "Drama, Fantasia",
  "age": "16+",
  "maxres": "1080p",
  "type": "Cortometraje",
  "language": "ES",
  "status": "active",
  "fomo": 0,
  
 },
 {
  "id": 103,
  "videoId_yt": null,
  "videoId_vm": null,
  "videoId_yt_dmq": null,
  "videoId_svr": "x2ytfpg",
  "videohref_yt": null,
  "videohref_vm": null,
  "videohref_yt_dmq": null,
  "videohref_svr": "https://www.dailymotion.com/video/x2ytfpg",
  "title": "Atracción",
  "synopsis": "Dos empleadas de una firma de abogados  se enamoran del Director General. Mientras una oculta sus sentimientos en secretos, la otra lo seduce abiertamente. De cualquier modo, sólo una de ellas estará con él.",
  "description": null,
  "awards": null,
  "duration": "24:34",
  "starring": "Reparto: Agustina Olano Rúa, Alejandra Sticberg, Carlos Ledo, Iván Romero Sineiro, María Belén Spencer, Matías Broglia",
  "producer": null,
  "director": "Alejandro Gabriel Cuberos",
  "city": null,
  "country": "Argentina",
  "year": 2012,
  "thumbnailurl": null,
  "posterurl": null,
  "bgurl": null,
  "genres": "Drama, Romance",
  "age": "16+",
  "maxres": "1080p",
  "type": "Cortometraje",
  "language": "ES",
  "status": "none",
  "fomo": 0,
  
 },
 {
  "id": 104,
  "videoId_yt": "HbmplRqVVpg",
  "videoId_vm": null,
  "videoId_yt_dmq": "oEVCI__fgdI",
  "videoId_svr": null,
  "videohref_yt": "https://www.youtube.com/watch?v=HbmplRqVVpg",
  "videohref_vm": null,
  "videohref_yt_dmq": "https://www.youtube.com/watch?v=oEVCI__fgdI",
  "videohref_svr": null,
  "title": "Un juego absurdo",
  "synopsis": "Javier está enamorado de Romina. En una fiesta, escuchamos sus pensamientos y lo acompañamos, entre realidad y fantasía, mientras intenta sacudir sus miedos y acercarse a la chica de sus sueños.",
  "description": null,
  "awards": null,
  "duration": "12:28",
  "starring": "Reparto: Bruno Iacobaccio, Eliana González, Ignacio Gauna, Irene Almus, Laila Horwitz, María Fernanda Callejón, Martín Piroyansky, Nicolás Torcanowsky, Sergio Pangaro, Vando Villamil",
  "producer": null,
  "director": "Gastón Rothschild",
  "city": null,
  "country": "Argentina",
  "year": 2009,
  "thumbnailurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fthumbnail%2Ft104.jpg?alt=media&token=ebb0edd2-23bf-4df7-a12a-b7ef6ddc84d7",
  "posterurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter%2Fp104.jpg?alt=media&token=e0318bbf-32b9-496e-a68c-62065b80e4d6",
  "poster375webpurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter375webp%2Fp104.webp?alt=media&token=de40690d-123b-4027-91c7-4cb8f06e4eb5",
  "bgurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fbg%2Fb104.jpg?alt=media&token=1582fef2-c7b3-4bcf-9bd4-8f9a338bd391",
  "genres": "Comedia, Romance",
  "age": "13+",
  "maxres": "1080p",
  "type": "Cortometraje",
  "language": "ES",
  "status": "active",
  "fomo": 0,
  
 },
 {
  "id": 105,
  "videoId_yt": "N_wm_m5N3Pc",
  "videoId_vm": null,
  "videoId_yt_dmq": "Z9asgCLZFfw",
  "videoId_svr": null,
  "videohref_yt": "https://www.youtube.com/watch?v=N_wm_m5N3Pc",
  "videohref_vm": null,
  "videohref_yt_dmq": "https://www.youtube.com/watch?v=Z9asgCLZFfw",
  "videohref_svr": null,
  "title": "Loop",
  "synopsis": "Julián vive junto a su mujer e hija en una casa quinta a las afuera de la ciudad. Julián despierta una mañana como cualquier otra, pero no logra encontrar a su familia, algo pasó con su mujer e hija mientras dormía y debe averiguar que, sin embargo, cada vez que se acerca a la verdad, vuelve a despertar y a repetir la situación una y otra vez.",
  "description": null,
  "awards": null,
  "duration": "12:16",
  "starring": "Guion: Juan Mirarchi\nReparto: Azul Pocai, Matías Quiroga",
  "producer": "Juan Mirarchi, Raquel Piñeiro",
  "director": "Juan Mirarchi",
  "city": null,
  "country": "Argentina",
  "year": 2016,
  "thumbnailurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fthumbnail%2Ft105.jpg?alt=media&token=daf6258b-5d90-4b97-816f-06770368fafc",
  "posterurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter%2Fp105.jpg?alt=media&token=3a7c058d-4352-45e7-b5b8-e7919f0e788e",
  "poster375webpurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter375webp%2Fp105.webp?alt=media&token=09f4b033-ec59-4a7d-b203-2e1d8d66f2b5",
  "bgurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fbg%2Fb105.jpg?alt=media&token=a1aab17e-0a6c-499c-9f18-3c879f2416ae",
  "genres": "Drama, Suspenso, Terror",
  "age": "16+",
  "maxres": "1080p",
  "type": "Cortometraje",
  "language": "ES",
  "status": "active",
  "fomo": 0,
  
 },
 {
  "id": 106,
  "videoId_yt": null,
  "videoId_vm": 392875596,
  "videoId_yt_dmq": "oQtkftRpNK8",
  "videoId_svr": null,
  "videohref_yt": null,
  "videohref_vm": "https://vimeo.com/392875596",
  "videohref_yt_dmq": "https://www.youtube.com/watch?v=oQtkftRpNK8",
  "videohref_svr": null,
  "title": "Un segundo desplazados",
  "synopsis": "Leonardo está desplazado un segundo del tiempo y se siente atrapado por su particular condición. Aunque es capaz de ver lo que sucede en el mundo temporalmente normal, no se siente conectado con la realidad, hasta que aparece Mariana.",
  "description": null,
  "awards": null,
  "duration": "21:26",
  "starring": "Guion: Fernando Méndez Arroyo\nReparto: Adriana Llabrés, Pablo Mendoza, Francisco Helguera\nFotografía: Antonio Ayala\nMúsica: Jimena Contreras\nSonido: Carlos Rotz\nDirección Artística: Jou Prado\nMontaje: Fernando Méndez Arroyo",
  "producer": "Primogénito Films",
  "director": "Fernando Méndez Arroyo",
  "city": null,
  "country": "México",
  "year": 2020,
  "thumbnailurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fthumbnail%2Ft106.jpg?alt=media&token=22fcd366-d6fe-4f6a-a946-38de273f325a",
  "posterurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter%2Fp106.jpg?alt=media&token=f8ac5b6d-7e4c-4650-b9ab-ab36cd8ff86e",
  "poster375webpurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter375webp%2Fp106.webp?alt=media&token=c883a5be-b7cc-4dbe-aa16-617e6e00fe48",
  "bgurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fbg%2Fb106.jpg?alt=media&token=b7050127-6bfc-4ed0-8aad-c391407b2ddb",
  "genres": "Romance, Fantasía, Ciencia Ficción",
  "age": "16+",
  "maxres": "1080p",
  "type": "Cortometraje",
  "language": "ES",
  "status": "active",
  "fomo": 0,
  
 },
 {
  "id": 107,
  "videoId_yt": null,
  "videoId_vm": 302358990,
  "videoId_yt_dmq": "hQwwn_6fhDk",
  "videoId_svr": null,
  "videohref_yt": null,
  "videohref_vm": "https://vimeo.com/302358990",
  "videohref_yt_dmq": "https://www.youtube.com/watch?v=hQwwn_6fhDk",
  "videohref_svr": null,
  "title": "Lingo lilingo, Baile que Baile",
  "synopsis": "Desde que amanece hasta que anochece, los bailadores de la Ciudad de México celebran la vida al ritmo de la música. Este corto documental recorre los lugares de baile más emblemáticos de la metrópolis a través de los personajes que los frecuentan.",
  "description": null,
  "awards": null,
  "duration": "15:06",
  "starring": "Guion: Miguel Salguero\nFotografía: Jorge Saim\nSonido: Francisco Toro\nMontaje: Miguel Salguero",
  "producer": "Canaclara Audiovisual",
  "director": "Jorge Saim, Miguel Salguero",
  "city": null,
  "country": "México",
  "year": 2018,
  "thumbnailurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fthumbnail%2Ft107.jpg?alt=media&token=2db46102-44ac-4d7e-9111-7229e4af4bd4",
  "posterurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter%2Fp107.jpg?alt=media&token=5a12f07f-c456-4b96-813c-8a28a5cc65c9",
  "poster375webpurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter375webp%2Fp107.webp?alt=media&token=1a2c8666-fa00-4e99-9916-2b4d3e46ba98",
  "bgurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fbg%2Fb107.jpg?alt=media&token=497f0a79-e9b1-4a26-b91c-93079f463d16",
  "genres": "Documental, Comedia, Drama",
  "age": "PG",
  "maxres": "1080p",
  "type": "Cortometraje",
  "language": "ES",
  "status": "active",
  "fomo": 0,
  
 },
 {
  "id": 108,
  "videoId_yt": "9dECVlDIUkg",
  "videoId_vm": null,
  "videoId_yt_dmq": "iKnPLmBbAzc",
  "videoId_svr": null,
  "videohref_yt": "https://www.youtube.com/watch?v=9dECVlDIUkg",
  "videohref_vm": null,
  "videohref_yt_dmq": "https://www.youtube.com/watch?v=iKnPLmBbAzc",
  "videohref_svr": null,
  "title": "Ideas basura",
  "synopsis": "Carlo Vazzqui, el protagonista, tendrá una semana para lograr convertirse en un gran artista para entregar un proyecto escolar pero hay un problema, su falta de creatividad.",
  "description": null,
  "awards": null,
  "duration": "08:59",
  "starring": "Guion: Carlos R. Muñoz\nReparto: Carlos R. Muñoz, Melanie Juliette",
  "producer": null,
  "director": "Carlos R. Muñoz",
  "city": null,
  "country": "México",
  "year": 2021,
  "thumbnailurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fthumbnail%2Ft108.jpg?alt=media&token=05f1dcce-9f7c-47dd-98e4-b206e965b224",
  "posterurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter%2Fp108.jpg?alt=media&token=bf42d3c1-3609-4df4-b0b3-53417bb55246",
  "poster375webpurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter375webp%2Fp108.webp?alt=media&token=9b64ad56-6914-4406-9e08-2f592d589071",
  "bgurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fbg%2Fb108.jpg?alt=media&token=2a9db210-a649-42aa-b2d6-619104b37398",
  "genres": "Comedia, Drama",
  "age": "16+",
  "maxres": "1080p",
  "type": "Cortometraje",
  "language": "ES",
  "status": "active",
  "fomo": 0,
  
 },
 {
  "id": 109,
  "videoId_yt": "LLXTq3IUJG0",
  "videoId_vm": null,
  "videoId_yt_dmq": "l9ziVoqgZdQ",
  "videoId_svr": null,
  "videohref_yt": "https://www.youtube.com/watch?v=LLXTq3IUJG0",
  "videohref_vm": null,
  "videohref_yt_dmq": "https://www.youtube.com/watch?v=l9ziVoqgZdQ",
  "videohref_svr": null,
  "title": "Tiempodefecto",
  "synopsis": "Lorena y César atraviesan una crisis matrimonial debido a la rutina en la que se ha convertido su vida en pareja. Un día, Lorena cita a César en un restaurante para convencerlo de volver a vivir con ella. Al final de la cena, César se dispone a terminar la relación cuando el restaurante es asaltado por dos ladrones armados. Lorena deberá tomar la decisión correcta para evitar una tragedia.",
  "description": null,
  "awards": null,
  "duration": "10:12",
  "starring": "Reparto: Carlos Ever Fonseca, Paola Herrera",
  "producer": null,
  "director": "Danna Juliana Pineda",
  "city": null,
  "country": "Colombia",
  "year": 2013,
  "thumbnailurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fthumbnail%2Ft109.jpg?alt=media&token=d472d817-9c39-4880-baf8-7144db469a5d",
  "posterurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter%2Fp109.jpg?alt=media&token=6d92e272-4096-4066-b982-f073f20f6bd4",
  "poster375webpurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter375webp%2Fp109.webp?alt=media&token=f9b61d27-183e-4ec3-95f2-eeb96db5c044",
  "bgurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fbg%2Fb109.jpg?alt=media&token=a4dbc2c0-105b-4554-b395-bbf936eec30d",
  "genres": "Drama, Romance, Thriller",
  "age": "16+",
  "maxres": "1080p",
  "type": "Cortometraje",
  "language": "ES",
  "status": "active",
  "fomo": 0,
  
 },
 {
  "id": 110,
  "videoId_yt": null,
  "videoId_vm": 85002648,
  "videoId_yt_dmq": "gPSTzB0HyG0",
  "videoId_svr": null,
  "videohref_yt": null,
  "videohref_vm": "https://vimeo.com/85002648",
  "videohref_yt_dmq": "https://www.youtube.com/watch?v=gPSTzB0HyG0",
  "videohref_svr": null,
  "title": "Vienna waits for you",
  "synopsis": "Después de romper con su novio Daniel, Anna sólo quiere encerrarse en algún agujero y recuperarse. Pero tendría que haberle prestado atención a la letra pequeña de su contrato de alquiler, ya que el viejoapartamento vienés es una criatura con un apetito sin fin, viviendo de la juventud de sus residentes. Ya no hay escapatoria. Anna se mira en el espejo y se ha convertido en una anciana. A menos que encuentre una nueva víctima.",
  "description": "Spitzendeckchen",
  "awards": null,
  "duration": "26:17",
  "starring": "Reparto: Adele Neuhauser, Alexander E. Fennon, Cosima Lehninger, Moritz Vierboom, Nikolaus Habjan, Petra Staduan, Traute Furthner",
  "producer": null,
  "director": "Dominik Hartl",
  "city": null,
  "country": "Chile",
  "year": 2012,
  "thumbnailurl": null,
  "posterurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter%2Fp110.jpg?alt=media&token=f0d70193-b4a2-4450-a1db-ac6edbe2e1ab",
  "poster375webpurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter375webp%2Fp110.webp?alt=media&token=7b36b106-ac6d-4154-a368-30b75caaf57b",
  "bgurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fbg%2Fb110.jpg?alt=media&token=48417182-8a88-405c-859e-6bb0b2729334",
  "genres": "Comedia, Drama, Misterio",
  "age": "16+",
  "maxres": "1080p",
  "type": "Cortometraje",
  "language": "ES",
  "status": "active",
  "fomo": 1,
  
 },
 {
  "id": 111,
  "videoId_yt": "tkdDznOjX8w",
  "videoId_vm": null,
  "videoId_yt_dmq": "vtPh6LCdl8c",
  "videoId_svr": null,
  "videohref_yt": "https://www.youtube.com/watch?v=tkdDznOjX8w",
  "videohref_vm": null,
  "videohref_yt_dmq": "https://www.youtube.com/watch?v=vtPh6LCdl8c",
  "videohref_svr": null,
  "title": "Preboda",
  "synopsis": "Un fotografo/videografo da un presupuesto de boda y …",
  "description": null,
  "awards": null,
  "duration": "10:34",
  "starring": "Guion: Miguel A. Almanza\nReparto: Ignacio Suárez, David Ferrero, Carmen Vega, Silvana Bonnato, Rafa Puerto",
  "producer": "Un platillo de Pipirrana",
  "director": "Miguel A. Almanza",
  "city": null,
  "country": "España",
  "year": 2019,
  "thumbnailurl": null,
  "posterurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter%2Fp111.jpg?alt=media&token=4c5d65eb-81a9-49c8-8676-4d24a22f7c89",
  "poster375webpurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter375webp%2Fp111.webp?alt=media&token=cd1b0073-4c72-4fa2-8887-a9967dfe84e5",
  "bgurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fbg%2Fb111.jpg?alt=media&token=9fec1d54-d9fc-492d-a2d1-01ba5d54af9d",
  "genres": "Comedia",
  "age": "16+",
  "maxres": "1080p",
  "type": "Cortometraje",
  "language": "ES",
  "status": "active",
  "fomo": 0,
  
 },
 {
  "id": 112,
  "videoId_yt": null,
  "videoId_vm": 542366274,
  "videoId_yt_dmq": "V6cUY_JtLhs",
  "videoId_svr": null,
  "videohref_yt": null,
  "videohref_vm": "https://vimeo.com/542366274",
  "videohref_yt_dmq": "https://www.youtube.com/watch?v=V6cUY_JtLhs",
  "videohref_svr": null,
  "title": "Room for Rent",
  "synopsis": "Afrodita Bunny, una joven actriz se queda tirada con su coche en un camino apartado, después de conducir durante horas y decide refugiarse en un apartado hotel de carretera regentado por la agradable señora Montalvo, que no espera visita.",
  "description": null,
  "awards": null,
  "duration": "10:47",
  "starring": "Guion: Fernando Simarro\nReparto: Silvia Casanova, La China Patino, Fernando Colomo\nFotografía: Daniel González\nMúsica: Juan Hernando\nSonido: Casa Sonora\nDirección Artística: Jaime Boyero\nEfectos visuales: Pablo Quetglas\nVestuario: Kareev Renclar, Alexandra Xanlo\nMaquillaje: Tamara Millán, Laura Olmeda\nPeluquería: Tamara Millán, Laura Olmeda\nMontaje: Raúl Garán",
  "producer": "Halloween Films",
  "director": "Fernando Simarro",
  "city": null,
  "country": "España",
  "year": 2018,
  "thumbnailurl": null,
  "posterurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter%2Fp112.jpg?alt=media&token=b7dc700f-4709-41d5-b662-87b9a789f6f9",
  "poster375webpurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter375webp%2Fp112.webp?alt=media&token=89c79b67-7207-4aff-88f1-84f5a6c8dd9c",
  "bgurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fbg%2Fb112.jpg?alt=media&token=bbe4ec96-7905-43ec-992a-c678cb1f5610",
  "genres": "Terror, Fantasía",
  "age": "16+",
  "maxres": "1080p",
  "type": "Cortometraje",
  "language": "ES",
  "status": "active",
  "fomo": 1,
  
 },
 {
  "id": 113,
  "videoId_yt": null,
  "videoId_vm": 305963174,
  "videoId_yt_dmq": "kSyEFVK-eko",
  "videoId_svr": null,
  "videohref_yt": null,
  "videohref_vm": "https://vimeo.com/305963174",
  "videohref_yt_dmq": "https://www.youtube.com/watch?v=kSyEFVK-eko",
  "videohref_svr": null,
  "title": "La barrera",
  "synopsis": "Dani y Salva ven la vida pasar mientras toman unas cañas. Ambos tienen treinta y tantos, esa edad en la que todo empieza a ir en serio. Donde la barrera que separa la inmadurez de la edad adulta es cada vez más fina. Pero… ¿han pasado realmente la barrera?",
  "description": null,
  "awards": null,
  "duration": "14:43",
  "starring": "Guion: Raúl Mancilla, Pablo D. Morilla\nReparto: Álvaro Carrero, Salvi Pérez, Helena Kaittane, Laura Vil, Dylan Moreno\nMúsica: Jesús Calderón\nSonido: Jaime Bresca\nDirección Artística: Sergio Hernández\nMaquillaje: Laura Vacas\nPeluquería: Laura Vacas\nMontaje: Antonio Fernández",
  "producer": "Marila Films",
  "director": "Chiqui Palma",
  "city": null,
  "country": "España",
  "year": 2018,
  "thumbnailurl": null,
  "posterurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter%2Fp113.jpg?alt=media&token=be1fdbff-c1b1-416c-8e41-2074f171f66e",
  "poster375webpurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter375webp%2Fp113.webp?alt=media&token=0656c61e-99ef-44f1-9ae2-422dc35591f2",
  "bgurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fbg%2Fb113.jpg?alt=media&token=99f46a11-04c1-45f3-b8f3-13d119151f1c",
  "genres": "Comedia",
  "age": "13+",
  "maxres": "1080p",
  "type": "Cortometraje",
  "language": "ES",
  "status": "active",
  "fomo": 0,
  
 },
 {
  "id": 114,
  "videoId_yt": "n_5vaz1dehQ",
  "videoId_vm": null,
  "videoId_yt_dmq": "U6P6L18CI8E",
  "videoId_svr": null,
  "videohref_yt": "https://www.youtube.com/watch?v=n_5vaz1dehQ",
  "videohref_vm": null,
  "videohref_yt_dmq": "https://www.youtube.com/watch?v=U6P6L18CI8E",
  "videohref_svr": null,
  "title": "No",
  "synopsis": "“No” es la historia de Celia (Paula Utrilla) , una joven cuya tóxica y autodestructiva relación con Juan Carlos (Alejandro Vergara) afecta a todo el círculo familiar y cercano de la joven, sin embargo, todos compartimos una incómoda responsabilidad… pero solo Celia puede dar el primer paso.",
  "description": null,
  "awards": null,
  "duration": "13:04",
  "starring": "Guion: Adrián Vereda\nReparto: Paula Utrilla, Alejandro Vergara, Adrian Vereda, Aída sánchez, Kiu López, Isabel Ávila, Paula Millán, Irene Ruiz, Diego Banderas, Fran Román\nMúsica: Adrián Berenguer, Grégoire Lourme\nSonido: Carlos Robles\nMontaje: Adrián Vereda",
  "producer": "Adrián Vereda",
  "director": "Adrián Vereda",
  "city": null,
  "country": "España",
  "year": 2018,
  "thumbnailurl": null,
  "posterurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter%2Fp114.jpg?alt=media&token=712b837a-2315-403d-b35b-aeee15b8cec8",
  "poster375webpurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter375webp%2Fp114.webp?alt=media&token=198dc8ca-3e23-4021-a96b-efcf23eb0588",
  "bgurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fbg%2Fb114.jpg?alt=media&token=bcfa5d2e-857b-49f1-9474-f648664b7de7",
  "genres": "Drama",
  "age": "16+",
  "maxres": "1080p",
  "type": "Cortometraje",
  "language": "ES",
  "status": "active",
  "fomo": 0,
  
 },
 {
  "id": 115,
  "videoId_yt": "Px05buhmo-0",
  "videoId_vm": null,
  "videoId_yt_dmq": "Lkb6lvoEGmU",
  "videoId_svr": null,
  "videohref_yt": "https://www.youtube.com/watch?v=Px05buhmo-0",
  "videohref_vm": null,
  "videohref_yt_dmq": "https://www.youtube.com/watch?v=Lkb6lvoEGmU",
  "videohref_svr": null,
  "title": "Time out",
  "synopsis": "Una chica busca desesperadamente algo muy valioso para ella, en un intento de escapar a su inminente desaparición.",
  "description": null,
  "awards": null,
  "duration": "08:32",
  "starring": "Guion: Manuel León Caballero\nReparto: Luis Lodos, Miguel Sansón, Saida Fuentes",
  "producer": "Manuel León Caballero",
  "director": "Manuel León Caballero",
  "city": null,
  "country": "España",
  "year": 2018,
  "thumbnailurl": null,
  "posterurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter%2Fp115.jpg?alt=media&token=a13d7e16-3f03-4214-ba6e-12957befd0c4",
  "poster375webpurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter375webp%2Fp115.webp?alt=media&token=e70531be-3909-4a8c-8837-16d5332bf16e",
  "bgurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fbg%2Fb115.jpg?alt=media&token=065f7069-d80f-4df7-b226-c490ebf8c647",
  "genres": "Terror, Fantasía",
  "age": "16+",
  "maxres": "1080p",
  "type": "Cortometraje",
  "language": "ES",
  "status": "active",
  "fomo": 0,
  
 },
 {
  "id": 116,
  "videoId_yt": "DrGdry27tAg",
  "videoId_vm": null,
  "videoId_yt_dmq": "lapEzscl8Dc",
  "videoId_svr": null,
  "videohref_yt": "https://www.youtube.com/watch?v=DrGdry27tAg",
  "videohref_vm": null,
  "videohref_yt_dmq": "https://www.youtube.com/watch?v=lapEzscl8Dc",
  "videohref_svr": null,
  "title": "Coma y beba",
  "synopsis": "Érase una vez dos payasos en un pequeño circo español: Ramón y Perico. Ramón sueña con ser un artista reconocido en Francia y Perico solo sueña con comida. Perico seguramente es el único payaso gordo de la posguerra. La malcriada hija del coronel Muñoz celebra su primera comunión y quiere que los payasos amenicen la velada. Ramón tiene una idea: robarán la casa del coronel y escaparán a Francia.",
  "description": null,
  "awards": "FESTIVAL DE CORTOMETRAJES DE ÍSCAR (Spain)\nFINALISTA AL MEJOR CORTOMETRAJE\nFINALIST FOR THE BEST SHORT FILM\nFESTIVAL LATINOAMERICANO DE CORTOMETRAJESIMAGENESOCIALES (Argentina)\nMENCIÓN ESPECIAL DEL JURADO\nSPECIAL JURY MENTION\nFESTIVAL EUROPEO DE CORTOMETRAJESVILLAMAYOR DE CINE (Spain)\nNOMINADO AL MEJOR CORTOMETRAJE Y MEJOR ACTOR (PEPE VIYUELA)\nNOMINATED FOR BEST SHORT FILM & BEST ACTOR (PEPE VIYUELA)",
  "duration": "14:46",
  "starring": "Guion: Alberto Sierra, Jaime Figueroa\nReparto: Pepe Viyuela, Kayto, Celine Peña, Olga Hueso, Jon Viar, Bermúdez, Félix Granado\nFotografía: Jaime Figueroa\nMúsica: Gonzalo G. Baz\nSonido: Berto Alcaime\nDirección Artística: Beatriz Moreno\nVestuario: Liza Bassi\nMaquillaje: Miriam Fermosell, Natalia Ortiz de Elguea, Regina Capdevilla, Tania Carrasco\nPeluquería: Miriam Fermosell, Natalia Ortiz de Elguea, Regina Capdevilla, Tania Carrasco\nMontaje: Vicky Lammers",
  "producer": "Masube",
  "director": "Jaime Figueroa",
  "city": null,
  "country": "España",
  "year": 2017,
  "thumbnailurl": null,
  "posterurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter%2Fp116.jpg?alt=media&token=34aa89b4-b5e6-44d9-9484-a54ff665a4a8",
  "poster375webpurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter375webp%2Fp116.webp?alt=media&token=bf74fde3-fa71-482d-83ce-384421a50515",
  "bgurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fbg%2Fb116.jpg?alt=media&token=35a1a372-3ecb-4972-8cd7-cb1e39ce3d3e",
  "genres": "Drama, Thriller",
  "age": "16+",
  "maxres": "1080p",
  "type": "Cortometraje",
  "language": "ES",
  "status": "active",
  "fomo": 0,
  
 },
 {
  "id": 117,
  "videoId_yt": "cCk_JwB1vU0",
  "videoId_vm": null,
  "videoId_yt_dmq": "zg0vuG95AjE",
  "videoId_svr": null,
  "videohref_yt": "https://www.youtube.com/watch?v=cCk_JwB1vU0",
  "videohref_vm": null,
  "videohref_yt_dmq": "https://www.youtube.com/watch?v=zg0vuG95AjE",
  "videohref_svr": null,
  "title": "Pizza para tres",
  "synopsis": "Es de noche. Tres amigos encuentran una pizza oculta en el frigorífico, pero pronto estalla el conflicto: todos quieren comer la misma cantidad. Enfrentados, tratan de encontrar la respuesta a una pregunta: cómo dividir la pizza en tres trozos iguales. ",
  "description": "Cortometraje de HUMOR ABSURDO. Un proyecto muy CORAL y divertido que no dejará a nadie indiferente. El actor más joven rondaba los 7 años y el mayor los 70 años. Rodado en octubre de 2016, es uno de los PRIMEROS trabajos como actor de Julio Espinosa. Una historia muy singular, atrevida, divertida y muy loca. Tres jóvenes hacen de la absoluta equidad un reto comunitario.",
  "awards": null,
  "duration": "15:56",
  "starring": "Guion: Álvaro Ortiz, Braulio Valdivielso\nReparto: Adrián Reyes, Alejandro Vergara, Álvaro Gijón, Jesús Andrés de Dios, Antonio Coca, Alfonso Sánchez, Gustavo Peñalva, Julio Espinosa, Javier Zurita, Carmen Mínguez, , Maite Montero, Miguel Guerra, Pepe Aguilar, Josito Sánchez, Dani Santos, Marian García, Emÿ Nogales, David Sorroche, Pablo Gómez, Carmen Fernández, Florencia Aguilar, José Luis Sánchez\nFotografía: Sergio Mediavilla\nMúsica: Fran Romguer\nSonido: Tony Muñoz\nDirección Artística: Álvaro Ortiz\nVestuario: Patricia Aznárez\nMaquillaje: Ana María Díaz, Patricia Aznárez\nPeluquería: Ana María Díaz, Patricia Aznárez\nMontaje: Dani Díaz",
  "producer": "Paula Hinojosa, Producciones Madredemivida",
  "director": "Álvaro Ortiz",
  "city": null,
  "country": "España",
  "year": 2017,
  "thumbnailurl": null,
  "posterurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter%2Fp117.jpg?alt=media&token=72c40986-b1a4-4bd9-82db-10af60208c1a",
  "poster375webpurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter375webp%2Fp117.webp?alt=media&token=d220a86b-345c-4340-8e60-194f0f7e8ebe",
  "bgurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fbg%2Fb117.jpg?alt=media&token=f176875f-3ef7-40b3-97db-5d191d392d41",
  "genres": "Comedia",
  "age": "13+",
  "maxres": "1080p",
  "type": "Cortometraje",
  "language": "ES",
  "status": "active",
  "fomo": 0,
  
 },
 {
  "id": 118,
  "videoId_yt": "HDmrJBx14UM",
  "videoId_vm": null,
  "videoId_yt_dmq": "Lcyug3WhI-E",
  "videoId_svr": null,
  "videohref_yt": "https://www.youtube.com/watch?v=HDmrJBx14UM",
  "videohref_vm": null,
  "videohref_yt_dmq": "https://www.youtube.com/watch?v=Lcyug3WhI-E",
  "videohref_svr": null,
  "title": "Au Revoir, Mon Amour!",
  "synopsis": "María y Rodrigo escapan a un lugar retirado después de atracar un banco. Ella quiere ir a París, pero él tiene otros planes muy distintos que no gustan nada a María.",
  "description": null,
  "awards": null,
  "duration": "08:09",
  "starring": "Guion: Mik J. López\nReparto: Estefi García, María Jordi Ra, Rodrigo Pol Figuerola\nFotografía: Nico Aguado\nMúsica: Ricardo Boya\nSonido: Jorge I. Espitia\nDirección Artística: Ferrán Amatller\nEfectos visuales: Marcos Díaz\nVestuario: Teresa Sánchez\nMaquillaje: Alba Ortega\nPeluquería: Pamela Illánez Torres\nMontaje: Oria Doménech",
  "producer": "ESCAC Films",
  "director": "Mik J. López",
  "city": null,
  "country": "España",
  "year": 2017,
  "thumbnailurl": null,
  "posterurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter%2Fp118.jpg?alt=media&token=9e2a2b35-8897-4daa-a586-120519327f3f",
  "poster375webpurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter375webp%2Fp118.webp?alt=media&token=14cf8ea3-1745-45ba-9db4-5b787772df7e",
  "bgurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fbg%2Fb118.jpg?alt=media&token=f8427170-7ace-4b56-b284-831bc71477f5",
  "genres": "Comedia, Romance",
  "age": "13+",
  "maxres": "1080p",
  "type": "Cortometraje",
  "language": "ES",
  "status": "active",
  "fomo": 0,
  
 },
 {
  "id": 119,
  "videoId_yt": "zIZQ0Xvp0UI",
  "videoId_vm": null,
  "videoId_yt_dmq": "Ke3h2FEdt7M",
  "videoId_svr": null,
  "videohref_yt": "https://www.youtube.com/watch?v=zIZQ0Xvp0UI",
  "videohref_vm": null,
  "videohref_yt_dmq": "https://www.youtube.com/watch?v=Ke3h2FEdt7M",
  "videohref_svr": null,
  "title": "Las Aventuras de Trending & Topic",
  "synopsis": "Trending y Topic son una pareja de influencers. Tienen millones de seguidores y audiencias incalculables, marcan tendencia y despiertan la admiración y envidia de miles de personas. Todo el mundo les escucha… excepto ellos mismos",
  "description": "Comedia que sigue a una pareja de adictos a las redes sociales. Su fachada de influencers triunfadores y positivos, llena de postureo, esconde un vacío vital y existencial muy grande.",
  "awards": null,
  "duration": "08:37",
  "starring": "Guion: Isaac García Mainar\nReparto: Etna Sampera, Roger Batalla\nFotografía: Sigurdur Hegalsson, Mónica Salom\nSonido: Kemen Longo\nDirección Artística: Marta Millán, Eva Lombás, Axel Magnani\nMontaje: Isaac García Mainar",
  "producer": null,
  "director": "Isaac García Mainar",
  "city": null,
  "country": "España",
  "year": 2017,
  "thumbnailurl": null,
  "posterurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter%2Fp119.jpg?alt=media&token=1a8d75f2-534f-429f-bf82-c6f59d122027",
  "poster375webpurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter375webp%2Fp119.webp?alt=media&token=9ff3bcff-1b3e-410e-b02b-ece03b4adbdf",
  "bgurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fbg%2Fb119.jpg?alt=media&token=41d27825-2d01-476d-926e-abe613ed6e7c",
  "genres": "Comedia",
  "age": "13+",
  "maxres": "1080p",
  "type": "Cortometraje",
  "language": "ES",
  "status": "active",
  "fomo": 0,
  
 },
 {
  "id": 120,
  "videoId_yt": null,
  "videoId_vm": 208169093,
  "videoId_yt_dmq": "9u5_-g2XX8o",
  "videoId_svr": null,
  "videohref_yt": null,
  "videohref_vm": "https://vimeo.com/208169093",
  "videohref_yt_dmq": "https://www.youtube.com/watch?v=9u5_-g2XX8o",
  "videohref_svr": null,
  "title": "Todo por Galactus",
  "synopsis": "Una cena, cuatro comensales y una hipótesis: ¿viajaríais en el tiempo para matar a Hitler cuando tan solo era un bebé?",
  "description": null,
  "awards": null,
  "duration": "15:55",
  "starring": "Reparto: María Roja, Machi Salgado, Bea Campos, Pedro Brandariz, Cris Vidal, Fuco Gómez\nFotografía: Pablo Kaufmann\nMúsica: Xavier Bértolo\nSonido: Xacobe Barxa\nDirección Artística: Alberto Ardid, Araceli Paz, Ana Gallego\nVestuario: Iria Carrera\nMaquillaje: Agar Iglesias\nMontaje: Ángel Martínez, Jose Araújo",
  "producer": "Mendufest Productions",
  "director": "Jose Araújo, Rodrigo Lamoso",
  "city": null,
  "country": "España",
  "year": 2017,
  "thumbnailurl": null,
  "posterurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter%2Fp120.jpg?alt=media&token=eb9f7ae5-c18d-473f-af12-df7adf433600",
  "poster375webpurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter375webp%2Fp120.webp?alt=media&token=7d692b1e-4487-453b-b0ac-f2c65f1cec19",
  "bgurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fbg%2Fb120.jpg?alt=media&token=97c9698a-a8b5-4083-a0ff-170880dbaa52",
  "genres": "Comedia, Fantasía, Ciencia Ficción",
  "age": "13+",
  "maxres": "1080p",
  "type": "Cortometraje",
  "language": "ES",
  "status": "active",
  "fomo": 0,
  
 },
 {
  "id": 121,
  "videoId_yt": "3_yQu4poJXM",
  "videoId_vm": null,
  "videoId_yt_dmq": "0n3xqr0Dgrk",
  "videoId_svr": null,
  "videohref_yt": "https://www.youtube.com/watch?v=3_yQu4poJXM",
  "videohref_vm": null,
  "videohref_yt_dmq": "https://www.youtube.com/watch?v=0n3xqr0Dgrk",
  "videohref_svr": null,
  "title": "Vae Victis",
  "synopsis": "Dos legionarios cabalgan con noticias de Roma. En el camino se topan con una familia hispana y deciden hacer un alto para satisfacer sus instintos más primitivos. Este extraño clan, lejos de ser la inofensiva familia que ellos esperaban, esconde un secreto muy macabro. La suerte de los legionarios cambiará para siempre.",
  "description": null,
  "awards": null,
  "duration": "12:22",
  "starring": "Guion: Fran Porras\nReparto: Jose Carretero, Julio Mascaraque, Natalia Bilbao, Alonso Posadas, Daniel Sotomayor\nFotografía: José Luis Martínez Díaz\nMúsica: Laura Solano\nSonido: Yelow Soundmarine\nDirección Artística: Catalina Monmeneu\nEfectos visuales: Fran Porras\nMaquillaje: Laura Pérez, Lidia Hernández\nMontaje: Ángel Andradre",
  "producer": null,
  "director": "Fran Porras",
  "city": null,
  "country": "España",
  "year": 2017,
  "thumbnailurl": null,
  "posterurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter%2Fp121.jpg?alt=media&token=264f0106-c09e-45e2-ba80-51a86560fa16",
  "poster375webpurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter375webp%2Fp121.webp?alt=media&token=a0e05f98-489d-4b3f-b8de-96bc9ee804b1",
  "bgurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fbg%2Fb121.jpg?alt=media&token=facbf22a-70a1-422f-a407-dabc26c856ae",
  "genres": "Thriller, Terror, Acción",
  "age": "16+",
  "maxres": "1080p",
  "type": "Cortometraje",
  "language": "ES",
  "status": "active",
  "fomo": 0,
  
 },
 {
  "id": 122,
  "videoId_yt": null,
  "videoId_vm": 360591963,
  "videoId_yt_dmq": "-__QHc00t3Y",
  "videoId_svr": null,
  "videohref_yt": null,
  "videohref_vm": "https://vimeo.com/360591963",
  "videohref_yt_dmq": "https://www.youtube.com/watch?v=-__QHc00t3Y",
  "videohref_svr": null,
  "title": "Waste",
  "synopsis": "Cinco chicas rinden culto a un extraño lápiz que aparece aleatoriamente en los cajones del piso en el que viven aisladas.",
  "description": null,
  "awards": null,
  "duration": "16:21",
  "starring": "Guion: Laura Sisteró, Alejo Levis, Doscabezas\nReparto: Mireia Oriol, Laia Manzanares, Vicky Luengo, Alba Ribas, Louise Good, Ona Casamiquela, Josep Mª Roviralta\nFotografía: Juli Carné Martorell\nMúsica: Marc Barrera, Nico Roig\nSonido: Marc Barrera, Anna Rajadell, Júlia Benach, Pol Fontanals\nDirección Artística: Anna Romero, María Ballester\nEfectos visuales: Lúber Mujica\nVestuario: Tamara Bucio\nMaquillaje: Cristina Pellicer\nPeluquería: Cristina Pellicer\nMontaje: Laura Sisteró, Alejo Levis, Doscabezas",
  "producer": "Petra Garmon",
  "director": "Laura Sisteró, Alejo Levis, Doscabezas",
  "city": null,
  "country": "España",
  "year": 2016,
  "thumbnailurl": null,
  "posterurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter%2Fp122.jpg?alt=media&token=0e841359-da37-4874-b2ed-2897ef8591bd",
  "poster375webpurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter375webp%2Fp122.webp?alt=media&token=ca25db66-f184-4a13-86ce-22e364414823",
  "bgurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fbg%2Fb122.jpg?alt=media&token=d42794b0-832e-4988-8c7f-50edeac60e02",
  "genres": "Fantasía, Ficción, Thriller",
  "age": "13+",
  "maxres": "1080p",
  "type": "Cortometraje",
  "language": "ES",
  "status": "active",
  "fomo": 1,
  
 },
 {
  "id": 123,
  "videoId_yt": "GKOf1PpA2-w",
  "videoId_vm": null,
  "videoId_yt_dmq": "u3Z1hx6eoaY",
  "videoId_svr": null,
  "videohref_yt": "https://www.youtube.com/watch?v=GKOf1PpA2-w",
  "videohref_vm": null,
  "videohref_yt_dmq": "https://www.youtube.com/watch?v=u3Z1hx6eoaY",
  "videohref_svr": null,
  "title": "Lunes",
  "synopsis": "Juanma Utrera es un hombre familiar aparentemente normal: se despierta pronto por las mañanas, desayuna, va a trabajar… Todo parece rutinario y banal en su vida. Pero las apariencias nunca son lo que parecen. Cada segundo, cada acción, cada decisión forman un alambicado e inestable castillo de naipes.",
  "description": null,
  "awards": null,
  "duration": "10:28",
  "starring": "Guion: Javier Chavanel\nReparto: Mario Zorrilla\nFotografía: Juan Aguilar\nMúsica: Luis Ivars",
  "producer": "El Cornijal",
  "director": "Carmelo Egea",
  "city": null,
  "country": "España",
  "year": 2016,
  "thumbnailurl": null,
  "posterurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter%2Fp123.jpg?alt=media&token=9b2af339-6735-4f3d-b11e-7cf7df3b5be9",
  "poster375webpurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter375webp%2Fp123.webp?alt=media&token=0014cee2-815f-4742-9acb-83645d46da9e",
  "bgurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fbg%2Fb123.jpg?alt=media&token=1b654431-4b15-4050-ad00-34d6b895cd1e",
  "genres": "Thriller",
  "age": "16+",
  "maxres": "1080p",
  "type": "Cortometraje",
  "language": "ES",
  "status": "active",
  "fomo": 0,
  
 },
 {
  "id": 124,
  "videoId_yt": "s7M61BC8WIo",
  "videoId_vm": null,
  "videoId_yt_dmq": "AovzaoAlhLo",
  "videoId_svr": null,
  "videohref_yt": "https://www.youtube.com/watch?v=s7M61BC8WIo",
  "videohref_vm": null,
  "videohref_yt_dmq": "https://www.youtube.com/watch?v=AovzaoAlhLo",
  "videohref_svr": null,
  "title": "Gentlemen",
  "synopsis": "Un grupo de hombres se reúnen en la Barcelona de la época victoriana para fines poco comunes y paranormales.",
  "description": null,
  "awards": null,
  "duration": "20:19",
  "starring": "Guion: Joaquim Bundó\nReparto: Enric Barba, Salvador Carol, Ramon Godino, Albert Green, Félix Hérzog, Brigitta Lamoure, Ivan Padilla, José Manuel Serrano, Miguel Sitjar",
  "producer": "Mussol Produccions",
  "director": "Joaquim Bundó, Esteve Rovira",
  "city": null,
  "country": "España",
  "year": 2016,
  "thumbnailurl": null,
  "posterurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter%2Fp124.jpg?alt=media&token=fe778cfc-ad2d-4467-aa35-108bdc45bf88",
  "poster375webpurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter375webp%2Fp124.webp?alt=media&token=5145bc5b-cf0f-4955-866c-d3767df07ab6",
  "bgurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fbg%2Fb124.jpg?alt=media&token=6da52cb9-2121-4fe5-81c8-3ac6993b4d52",
  "genres": "Thriller",
  "age": "16+",
  "maxres": "1080p",
  "type": "Cortometraje",
  "language": "ES",
  "status": "active",
  "fomo": 0,
  
 },
 {
  "id": 125,
  "videoId_yt": "0ADguXwWcqM",
  "videoId_vm": null,
  "videoId_yt_dmq": "4oh53dCPii4",
  "videoId_svr": null,
  "videohref_yt": "https://www.youtube.com/watch?v=0ADguXwWcqM",
  "videohref_vm": null,
  "videohref_yt_dmq": "https://www.youtube.com/watch?v=4oh53dCPii4",
  "videohref_svr": null,
  "title": "Club All In La apuesta final",
  "synopsis": "Marina es una joven que recibe una oferta de trabajo de un traficante y proxeneta durante una testificación por robo con violencia. Tras recibir una paliza por parte de su marido, Marina decide aceptar la oferta para trabajar como camarera en un prostíbulo. Allí se ve envuelta en una trama de corrupción.",
  "description": null,
  "awards": null,
  "duration": "20:01",
  "starring": "Reparto: Álvaro Carrero, Elena de Cara, Estrella Martín, Jesús Ortiz, Juanma Lara, Lucio González, Miguel Guardiola, Monti Cruz, Patricia Medina, Salva Reina, Simón Ramos",
  "producer": null,
  "director": "Gonzalo Martínez",
  "city": null,
  "country": "España",
  "year": 2016,
  "thumbnailurl": null,
  "posterurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter%2Fp125.jpg?alt=media&token=369ab10f-e3f9-49a6-a684-4d600b9488de",
  "poster375webpurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter375webp%2Fp125.webp?alt=media&token=1fdf0429-86e7-470e-ba0c-64d0223ed30f",
  "bgurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fbg%2Fb125.jpg?alt=media&token=f4aefcd4-fa92-4c59-addf-054c508ab936",
  "genres": "Thriller",
  "age": "16+",
  "maxres": "1080p",
  "type": "Cortometraje",
  "language": "ES",
  "status": "active",
  "fomo": 0,
  
 },
 {
  "id": 126,
  "videoId_yt": null,
  "videoId_vm": 126157989,
  "videoId_yt_dmq": "IQUEVr3c2jg",
  "videoId_svr": null,
  "videohref_yt": null,
  "videohref_vm": "https://vimeo.com/126157989",
  "videohref_yt_dmq": "https://www.youtube.com/watch?v=IQUEVr3c2jg",
  "videohref_svr": null,
  "title": "One Shot",
  "synopsis": "Un disparo. Un solo disparo es suficiente para desencadenar toda una serie de oscuros acontecimientos: un trato con drogas no cerrado, una prostituta que no debería haber pasado por allí o incluso un chico joven que preguntó a la persona equivocada en el momento equivocado.",
  "description": null,
  "awards": null,
  "duration": "10:07",
  "starring": "Reparto: Adrián Viador, Ed de Vega, José Troncoso, Mariona Terés, Fernando Valdivielso",
  "producer": null,
  "director": "Andrea Casaseca Ferrer",
  "city": null,
  "country": "España",
  "year": 2016,
  "thumbnailurl": null,
  "posterurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter%2Fp126.jpg?alt=media&token=4dc37502-aebb-4185-906b-b786edd63d26",
  "poster375webpurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter375webp%2Fp126.webp?alt=media&token=956d0520-6fb8-4c57-9e95-f6beb5a57805",
  "bgurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fbg%2Fb126.jpg?alt=media&token=69fe837e-76b8-440e-9918-f1db25714f03",
  "genres": "Thriller",
  "age": "16+",
  "maxres": "1080p",
  "type": "Cortometraje",
  "language": "ES",
  "status": "active",
  "fomo": 0,
  
 },
 {
  "id": 127,
  "videoId_yt": null,
  "videoId_vm": 266012937,
  "videoId_yt_dmq": "DAolwAzCvuE",
  "videoId_svr": null,
  "videohref_yt": null,
  "videohref_vm": "https://vimeo.com/266012937",
  "videohref_yt_dmq": "https://www.youtube.com/watch?v=DAolwAzCvuE",
  "videohref_svr": null,
  "title": "Anónimo",
  "synopsis": "Un voluntario del teléfono de la esperanza recibe la llamada de un suicida que quiere confesar un secreto que lleva escondiendo toda su vida.",
  "description": null,
  "awards": null,
  "duration": "09:54",
  "starring": "Guion: Ivan Sokolov\nReparto: Mikel Losada, Ander Vildosola, Aitziber Garmendia, Begoña Manterola, Imanol Méndez, Aratz Zuazu, Belén Cruz, Irving Bonet\nFotografía: Javi Agirre\nMúsica: Aránzazu Calleja\nSonido: Mikel Pintado, Txomin Villena, Haimar Olaskoaga\nDirección Artística: Natalie Garrido\nVestuario: Lierni Esnaola\nMaquillaje: Usoa San Román\nPeluquería: Usoa San Román\nMontaje: Iván Sokolov",
  "producer": "Lateral Filming",
  "director": "Ivan Sokolov",
  "city": null,
  "country": "España",
  "year": 2016,
  "thumbnailurl": null,
  "posterurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter%2Fp127.jpg?alt=media&token=d7dccaa6-0dde-4294-9ac5-2f216df6d88c",
  "poster375webpurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter375webp%2Fp127.webp?alt=media&token=484f641a-f043-4eb2-b40c-4b0fcbd0dd4b",
  "bgurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fbg%2Fb127.jpg?alt=media&token=0c16c478-1a3c-462b-b0f6-fe73db434707",
  "genres": "Drama",
  "age": "16+",
  "maxres": "1080p",
  "type": "Cortometraje",
  "language": "ES",
  "status": "active",
  "fomo": 0,
  
 },
 {
  "id": 128,
  "videoId_yt": "OpbLRfulAnw",
  "videoId_vm": null,
  "videoId_yt_dmq": "EjXxaRg0DVU",
  "videoId_svr": null,
  "videohref_yt": "https://www.youtube.com/watch?v=OpbLRfulAnw",
  "videohref_vm": null,
  "videohref_yt_dmq": "https://www.youtube.com/watch?v=EjXxaRg0DVU",
  "videohref_svr": null,
  "title": "Amén",
  "synopsis": "Tres hermanos de familia conservadora, Begoña, Pelayo y María, preparan el velatorio de su madre. La confusión se instala en la casa familiar cuando descubren que la anciana quería ser enterrada con un traje “muy especial”.",
  "description": null,
  "awards": null,
  "duration": "20:00",
  "starring": "Guion: Antonio Naharro\nReparto: Ana Gracia, Antonio Naharro, Natalia Moreno, Manuel Morón, María Morales, María Bravo, Emilio Buale, Esther Ortega, Carmen Saiz Ruiz, Encina Álvarez\nFotografía: Alfonso Postigo\nMúsica: Ara Malikian\nSonido: Carlos Padilla\nDirección Artística: Elena Pardo\nMaquillaje: Esther Ruiz Aranda",
  "producer": "Producciones Come y Calla S.L",
  "director": "Antonio Naharro",
  "city": null,
  "country": "España",
  "year": 2016,
  "thumbnailurl": null,
  "posterurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter%2Fp128.jpg?alt=media&token=46e550e2-9bc6-466b-a2fb-46e46b90648b",
  "poster375webpurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter375webp%2Fp128.webp?alt=media&token=07e6780d-9d58-4749-be2b-f0ea001d73fe",
  "bgurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fbg%2Fb128.jpg?alt=media&token=2e3f542a-881d-4e65-b8b1-21ccdea29dab",
  "genres": "Comedia",
  "age": "13+",
  "maxres": "1080p",
  "type": "Cortometraje",
  "language": "ES",
  "status": "active",
  "fomo": 0,
  
 },
 {
  "id": 129,
  "videoId_yt": null,
  "videoId_vm": 178915705,
  "videoId_yt_dmq": "574G6vyzheI",
  "videoId_svr": null,
  "videohref_yt": null,
  "videohref_vm": "https://vimeo.com/178915705",
  "videohref_yt_dmq": "https://www.youtube.com/watch?v=574G6vyzheI",
  "videohref_svr": null,
  "title": "El andar del borracho",
  "synopsis": "Como los borrachos, las partículas son incapaces de mantener trayectorias rectas debido a fuerzas externas. Viven inmersas en un caos incontrolable que las hace cambiar de rumbo una y otra vez. Como los elementos de la naturaleza. Como los grandes objetos. Como las personas.",
  "description": null,
  "awards": null,
  "duration": "15:04",
  "starring": "Guion: Pol Armengol\nReparto: Ann Perelló, Eduard Benito, Imanol García, Miguel Ángel Jenner, Núria Deulofeu, Txema Blasco, Xavier Pàmies",
  "producer": "ESCAC Films",
  "director": "Pol Armengol",
  "city": null,
  "country": "España",
  "year": 2016,
  "thumbnailurl": null,
  "posterurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter%2Fp129.jpg?alt=media&token=0f3f8b82-ec13-4da2-9bf6-fc79db2f2eee",
  "poster375webpurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter375webp%2Fp129.webp?alt=media&token=eda7f27a-f500-4357-be01-8f4be2a86760",
  "bgurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fbg%2Fb129.jpg?alt=media&token=a1b6e1eb-4e84-49b3-9b82-10a89c41a47a",
  "genres": "Comedia",
  "age": "13+",
  "maxres": "1080p",
  "type": "Cortometraje",
  "language": "ES",
  "status": "active",
  "fomo": 0,
  
 },
 {
  "id": 130,
  "videoId_yt": "4uTQncjDzAg",
  "videoId_vm": null,
  "videoId_yt_dmq": "1LKcFkxYluI",
  "videoId_svr": null,
  "videohref_yt": "https://www.youtube.com/watch?v=4uTQncjDzAg",
  "videohref_vm": null,
  "videohref_yt_dmq": "https://www.youtube.com/watch?v=1LKcFkxYluI",
  "videohref_svr": null,
  "title": "17 años juntos",
  "synopsis": "17 años juntos, cuenta una historia de amor que empezó hace 17 años y que, después de pasar momentos fáciles, difíciles, buenos y menos buenos, sigue tan viva como el primer día.",
  "description": null,
  "awards": null,
  "duration": "14:36",
  "starring": "Guion: Claro García, Javier Fesser\nReparto: Diana Peñalver, Gonzalo Villamizar, Julia Bautista Suárez de Arellano, Luis Bermejo, Milagros Monrroy, Waldemar Vidalón",
  "producer": "Películas Pendelton, Pepe Jordana",
  "director": "Javier Fesser",
  "city": null,
  "country": "España",
  "year": 2016,
  "thumbnailurl": null,
  "posterurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter%2Fp130.jpg?alt=media&token=8d6ca1a0-cc72-4c52-b7c0-3c0902e28c92",
  "poster375webpurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter375webp%2Fp130.webp?alt=media&token=12d18860-666f-4309-9b4a-9e6a0e4e6b96",
  "bgurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fbg%2Fb130.jpg?alt=media&token=1c14a729-deff-49da-aab8-8d771e7ae408",
  "genres": "Drama",
  "age": "16+",
  "maxres": "1080p",
  "type": "Cortometraje",
  "language": "ES",
  "status": "active",
  "fomo": 1,
  
 },
 {
  "id": 131,
  "videoId_yt": null,
  "videoId_vm": 166501699,
  "videoId_yt_dmq": "dCCnq20XPcg",
  "videoId_svr": null,
  "videohref_yt": null,
  "videohref_vm": "https://vimeo.com/166501699",
  "videohref_yt_dmq": "https://www.youtube.com/watch?v=dCCnq20XPcg",
  "videohref_svr": null,
  "title": "Las reglas del subjuntivo",
  "synopsis": "Un empresario vive en su clase de español una catarsis terapéutica a través de la gramática.",
  "description": null,
  "awards": null,
  "duration": "11:43",
  "starring": "Guion: Leticia Torres\nReparto: Pepe Lorente, Leticia Torres, Ana Laan\nFotografía: Ricardo de Gracia\nMúsica: Alejandro Martínez\nSonido: Ricardo Alonso\nVestuario: Antonia Payeras\nMaquillaje: Regina Capdevila\nMontaje: Perig Guinamant",
  "producer": null,
  "director": "Leticia Torres",
  "city": null,
  "country": "España",
  "year": 2016,
  "thumbnailurl": null,
  "posterurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter%2Fp131.jpg?alt=media&token=3820a214-9da4-4351-836d-22b30e9bba9b",
  "poster375webpurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter375webp%2Fp131.webp?alt=media&token=3e5de3ce-8a8e-4191-89b9-f8c5be60aa87",
  "bgurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fbg%2Fb131.jpg?alt=media&token=d970032d-1c13-4c44-8e30-ef46907b2bf0",
  "genres": "Drama",
  "age": "16+",
  "maxres": "1080p",
  "type": "Cortometraje",
  "language": "ES",
  "status": "active",
  "fomo": 0,
  
 },
 {
  "id": 132,
  "videoId_yt": "RU4_x90oSl8",
  "videoId_vm": null,
  "videoId_yt_dmq": "GyhXoq-HHgE",
  "videoId_svr": null,
  "videohref_yt": "https://www.youtube.com/watch?v=RU4_x90oSl8",
  "videohref_vm": null,
  "videohref_yt_dmq": "https://www.youtube.com/watch?v=GyhXoq-HHgE",
  "videohref_svr": null,
  "title": "Behind",
  "synopsis": "Arianne, una madre divorciada, obsesionada con la idea de que su bebé caiga en manos de su ex marido, conoce durante un cumpleaños infantil a Leonor, una anciana que le dice estar viendo a un hombre de aspecto terrorífico pegado a su espalda. Al volver a casa con su hijo, la sugestión, el miedo, y esos diablos de la mente, contra los que lucha sin descanso, harán que esa madre, emocionalmente afectada, viva una noche de auténtica pesadilla.",
  "description": "El director, Ángel Gómez Hernández, nos muestra como los recovecos de la mente pueden convertirse en un laberinto poblado de monstruos, Pero el instinto maternal es siempre más poderoso que los miedos más profundos.",
  "awards": null,
  "duration": "15:01",
  "starring": "Guion: Ángel Gómez Hernández\nReparto: Javier Botet, Lone Fleming, Macarena Gómez, Ruth Díaz",
  "producer": "Producciones Diodati, Estudio V Producciones",
  "director": "Ángel Gómez Hernández",
  "city": null,
  "country": "España",
  "year": 2016,
  "thumbnailurl": null,
  "posterurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter%2Fp132.jpg?alt=media&token=da6796bb-6e7e-4c34-b5f7-8e55ae504b98",
  "poster375webpurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter375webp%2Fp132.webp?alt=media&token=16d4c0b6-7691-42d8-a609-d43d41015a10",
  "bgurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fbg%2Fb132.jpg?alt=media&token=14d95feb-cf88-46ae-a17d-f436eb91d83e",
  "genres": "Drama, Terror",
  "age": "16+",
  "maxres": "1080p",
  "type": "Cortometraje",
  "language": "ES",
  "status": "active",
  "fomo": 0,
  
 },
 {
  "id": 133,
  "videoId_yt": null,
  "videoId_vm": 250569840,
  "videoId_yt_dmq": "mDWAg_tWUb4",
  "videoId_svr": null,
  "videohref_yt": null,
  "videohref_vm": "https://vimeo.com/250569840",
  "videohref_yt_dmq": "https://www.youtube.com/watch?v=mDWAg_tWUb4",
  "videohref_svr": null,
  "title": "Einstein-Rosen",
  "synopsis": "Verano del 82. Teo asegura que ha encontrado un agujero de gusano. Su hermano Óscar no le cree… Al menos de momento.",
  "description": null,
  "awards": null,
  "duration": "09:07",
  "starring": "Guion: Olga Osorio\nReparto: Teo Galiñanes, Óscar Galiñanes, Xúlio Abonjo, Ricardo de Barreiro\nFotografía: Suso Bello\nMúsica: Sergio Moure de Oteyza\nSonido: Alberto Blanco, Jorge G. Colado\nDirección Artística: Beatriz Gayoso Prieto\nEfectos visuales: Pablo Lamosa\nVestuario: Tatiana Grande\nMaquillaje: Susana Veira\nPeluquería: Susana Veira\nMontaje: Olga Osorio, Juan Galiñanes",
  "producer": "Miss Movies",
  "director": "Olga Osorio",
  "city": null,
  "country": "España",
  "year": 2016,
  "thumbnailurl": null,
  "posterurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter%2Fp133.jpg?alt=media&token=8e89cde0-74c8-4edc-8a51-18299db0a5b5",
  "poster375webpurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter375webp%2Fp133.webp?alt=media&token=7d350ac6-78f2-448f-85c0-ed652a9d5506",
  "bgurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fbg%2Fb133.jpg?alt=media&token=ba440c56-ca58-41bd-ab7c-6f9cf1b95630",
  "genres": "Ciencia Ficción",
  "age": "16+",
  "maxres": "1080p",
  "type": "Cortometraje",
  "language": "ES",
  "status": "active",
  "fomo": 1,
  
 },
 {
  "id": 134,
  "videoId_yt": "qJmFteWyrvY",
  "videoId_vm": null,
  "videoId_yt_dmq": "aewLSdJ3wYY",
  "videoId_svr": null,
  "videohref_yt": "https://www.youtube.com/watch?v=qJmFteWyrvY",
  "videohref_vm": null,
  "videohref_yt_dmq": "https://www.youtube.com/watch?v=aewLSdJ3wYY",
  "videohref_svr": null,
  "title": "Dieciocho",
  "synopsis": "Es el 18 cumpleaños de Jennifer. De menú: una sopa de ojos, dedos en su salsa y nudillos fritos. Todo muy normal hasta que… ¡Feliz cumpleaños!",
  "description": null,
  "awards": null,
  "duration": "08:21",
  "starring": "Guion: Tonet Ferrer\nReparto: Lucía Alcázar, Sergio Caballero, Loles León, Cristina Plazas, Gil Zorrilla\nFotografía: Edu Esteban\nMúsica: Juan Ernesto Artuñedo\nSonido: Leticia Argudo\nDirección Artística: Óscar Gozálvez\nEfectos visuales: Juan Ramón Bonillo, Sandra Díaz\nVestuario: Vera Cebriá\nMaquillaje: Sandra Arnal\nPeluquería: Sandra Arnal\nMontaje: Tonet Ferror",
  "producer": "Teaser Films",
  "director": "Tonet Ferrer",
  "city": null,
  "country": "España",
  "year": 2016,
  "thumbnailurl": null,
  "posterurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter%2Fp134.jpg?alt=media&token=0132721b-fc1b-4b5a-80ad-0df75948cd4c",
  "poster375webpurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter375webp%2Fp134.webp?alt=media&token=4b2659b3-4e75-4f47-af97-03e140322b07",
  "bgurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fbg%2Fb134.jpg?alt=media&token=33a178c1-7161-478d-91e8-951057c75f00",
  "genres": "Comedia",
  "age": "13+",
  "maxres": "1080p",
  "type": "Cortometraje",
  "language": "ES",
  "status": "active",
  "fomo": 1,
  
 },
 {
  "id": 135,
  "videoId_yt": "qllYdgcfNF8",
  "videoId_vm": null,
  "videoId_yt_dmq": "9ablsHiKrQA",
  "videoId_svr": null,
  "videohref_yt": "https://www.youtube.com/watch?v=qllYdgcfNF8",
  "videohref_vm": null,
  "videohref_yt_dmq": "https://www.youtube.com/watch?v=9ablsHiKrQA",
  "videohref_svr": null,
  "title": "La mente de Sagel",
  "synopsis": "Izan es un niño de 14 años que sufre claustrofobia y en el colegio es un objeto de burla para sus compañeros. Al salir del colegio se va a casa todos los días, pero esta vez a la de su tío para pasar unos días. En plena noche sucederán cosas extrañas que Izan deberá superar sin la ayuda de nadie.",
  "description": null,
  "awards": null,
  "duration": "17:36",
  "starring": "Guion: Josep Rodglá, Josep Hernández\nReparto: Toni Gómez, Federico Alonso, Silvia Torregrosa, Jorge Martínez\nFotografía: Guillem Pons\nMúsica: Sara Galiana\nSonido: Fernando Gisbert, Ferran Ausina\nDirección Artística: Óscar Gozálvez\nEfectos visuales: Mike Galeck\nVestuario: M. Ángeles Camacho\nMaquillaje: Sandra Arnal\nMontaje: Alberto Zamora",
  "producer": "Entertainment Stories, Nubo films, Josep Rodglá",
  "director": "Josep Rodglá",
  "city": null,
  "country": "España",
  "year": 2016,
  "thumbnailurl": null,
  "posterurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter%2Fp135.jpg?alt=media&token=e4e8f886-6175-4340-b999-0f2edece7a08",
  "poster375webpurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter375webp%2Fp135.webp?alt=media&token=8044d191-2b27-4513-a985-dd97b150beff",
  "bgurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fbg%2Fb135.jpg?alt=media&token=a7efa007-bdb3-4743-8f9d-35048d15dfc7",
  "genres": "Fantasía, Ficción, Drama",
  "age": "13+",
  "maxres": "1080p",
  "type": "Cortometraje",
  "language": "ES",
  "status": "active",
  "fomo": 1,
  
 },
 {
  "id": 136,
  "videoId_yt": "LNqn10m1VI",
  "videoId_vm": null,
  "videoId_yt_dmq": "-YDMd9CR0K4",
  "videoId_svr": null,
  "videohref_yt": "https://www.youtube.com/watch?v=-LNqn10m1VI",
  "videohref_vm": null,
  "videohref_yt_dmq": "https://www.youtube.com/watch?v=-YDMd9CR0K4",
  "videohref_svr": null,
  "title": "Federica",
  "synopsis": "\"Federica\" es un cortometraje que narra los motivos por los cuales una mucama ha decidido matar a su jefe, un hombre excéntrico que se hace llamar a sí mismo \"Dios\".",
  "description": null,
  "awards": null,
  "duration": "09:00",
  "starring": "Guión: Pablo Álvarez\nReparto: Mel Villacis / Andy Ruilova / Ian Malo / Erick Espadero\nSonido: Jose Alvear / Paulo Castro\nFotografía: Alejandro Pacheco / Pablo Álvarez\nArte: Pablo Álvarez\nMontaje: Pablo Álvarez",
  "producer": "Jose Álvear, Pablo Álvarez",
  "director": "Pablo Álvarez",
  "city": null,
  "country": "Ecuador",
  "year": 2018,
  "thumbnailurl": null,
  "posterurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter%2Fp136.jpg?alt=media&token=87551bdc-2e9d-4702-99f5-35d396975396",
  "poster375webpurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter375webp%2Fp136.webp?alt=media&token=b6011b0b-70d2-4d2d-ad5c-1bced048c3f2",
  "bgurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fbg%2Fb136.jpg?alt=media&token=dc2b088b-7fe3-4fc0-ac65-a43e63f51491",
  "genres": "Ficción, Suspenso, Thriller",
  "age": "16+",
  "maxres": "1080p",
  "type": "Cortometraje",
  "language": "ES",
  "status": "active",
  "fomo": 1,
  
 },
 {
  "id": 137,
  "videoId_yt": null,
  "videoId_vm": 249205681,
  "videoId_yt_dmq": "Z-Fksqbtlto",
  "videoId_svr": null,
  "videohref_yt": null,
  "videohref_vm": "https://vimeo.com/249205681",
  "videohref_yt_dmq": "https://www.youtube.com/watch?v=Z-Fksqbtlto",
  "videohref_svr": null,
  "title": "El Baile de los Muñecos",
  "synopsis": "Virginia está punto de escaparse de su casa para seguir sus sueños de convertirse en bailarina pero una visita inesperada la sumergirá en una cena familiar cada vez más desconcertante.",
  "description": null,
  "awards": null,
  "duration": "08:00",
  "starring": "Guion: Denis Arroyo / Lucho Salazar\nSonido: Dante Quispe / Andrea Huarancca\nFotografía: Lucho Salazar\nArte: Connie Calderón / Mariana Podbrscek\nMontaje: Yazmin Trujillo",
  "producer": "Diana Arroyo",
  "director": "Denis Arroyo",
  "city": null,
  "country": "Perú",
  "year": 2018,
  "thumbnailurl": null,
  "posterurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter%2Fp137.jpg?alt=media&token=737876a4-c501-4e86-bfdd-fcb23c167a8e",
  "poster375webpurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter375webp%2Fp137.webp?alt=media&token=9d5ed2aa-0e2f-4fb3-8a4f-c1e8c491620e",
  "bgurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fbg%2Fb137.jpg?alt=media&token=b3e986ce-ea74-4c76-afc3-6a3277e4be00",
  "genres": "Ficción, Suspenso, Comedia",
  "age": "16+",
  "maxres": "1080p",
  "type": "Cortometraje",
  "language": "ES",
  "status": "active",
  "fomo": 0,
  
 },
 {
  "id": 138,
  "videoId_yt": "KVYMCTOvd74",
  "videoId_vm": null,
  "videoId_yt_dmq": "Gl5hOdv-pIY",
  "videoId_svr": null,
  "videohref_yt": "https://www.youtube.com/watch?v=KVYMCTOvd74",
  "videohref_vm": null,
  "videohref_yt_dmq": "https://www.youtube.com/watch?v=Gl5hOdv-pIY",
  "videohref_svr": null,
  "title": "Glitch",
  "synopsis": "Un futuro cercano hiperconectado por múltiples dispositivos, pero que se ha olvidado de la palabra y la comunicación cara a cara entre las personas.",
  "description": null,
  "awards": null,
  "duration": "08:00",
  "starring": "Guion: Mauricio Lara Y Camilo Marulanda\nSonido: Christian Castells\nFotografía: Fernando Rodriguez\nMúsica: Christian Castells\nArte: Lara Blisniuk\nMontaje: Daniela Yuasa",
  "producer": "Melissa García, Leonardo Oviedo",
  "director": "Mauricio Lara",
  "city": null,
  "country": "Argentina",
  "year": 2019,
  "thumbnailurl": null,
  "posterurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter%2Fp138.jpg?alt=media&token=b9705a45-e0d0-488d-9085-76d49e680bb1",
  "poster375webpurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter375webp%2Fp138.webp?alt=media&token=3c00c164-66ca-4b66-82d8-775041633d2d",
  "bgurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fbg%2Fb138.jpg?alt=media&token=79cc4dbe-b1db-4ea0-b04b-e235bd013407",
  "genres": "Ficción",
  "age": "16+",
  "maxres": "1080p",
  "type": "Cortometraje",
  "language": "ES",
  "status": "active",
  "fomo": 1,
  
 },
 {
  "id": 139,
  "videoId_yt": null,
  "videoId_vm": 330222744,
  "videoId_yt_dmq": "PlglLbEJZWk",
  "videoId_svr": null,
  "videohref_yt": null,
  "videohref_vm": "https://vimeo.com/330222744",
  "videohref_yt_dmq": "https://www.youtube.com/watch?v=PlglLbEJZWk",
  "videohref_svr": null,
  "title": "Fin de campaña",
  "synopsis": "La candidata más sonada para el Congreso y su asesor de campaña se dirigen hacia el último mitin antes de elecciones. Sin embargo, un descuido suyo provocará un accidente que podría provocar muchos inconvenientes para su brillante futuro político.",
  "description": null,
  "awards": null,
  "duration": "12:00",
  "starring": "Sonido: Antonella Olavarria\nFotografía: Bryant Wong\nArte: José Rodriguez",
  "producer": "Ingrid Contreras, Regina Raygada",
  "director": "Darío Muñoz",
  "city": null,
  "country": "Perú",
  "year": 2019,
  "thumbnailurl": null,
  "posterurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter%2Fp139.jpg?alt=media&token=4a80fb0c-5860-4d50-8556-7d476f4e2708",
  "poster375webpurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter375webp%2Fp139.webp?alt=media&token=4db22fd2-1c92-45ba-bbf2-d0fde1439d61",
  "bgurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fbg%2Fb139.jpg?alt=media&token=fc5e72f0-b3a3-4ff1-9e65-fff95779f551",
  "genres": "Ficción, Suspenso",
  "age": "16+",
  "maxres": "1080p",
  "type": "Cortometraje",
  "language": "ES",
  "status": "active",
  "fomo": 1,
  
 },
 {
  "id": 140,
  "videoId_yt": null,
  "videoId_vm": 453515094,
  "videoId_yt_dmq": "7dCxzuUBMpg",
  "videoId_svr": null,
  "videohref_yt": null,
  "videohref_vm": "https://vimeo.com/453515094",
  "videohref_yt_dmq": "https://www.youtube.com/watch?v=7dCxzuUBMpg",
  "videohref_svr": null,
  "title": "El encargo",
  "synopsis": "Víctor es un taxista sordomudo que un día, durante su jornada, se topa con Roberto y Ernesto, dos asaltantes que deben hacer su primera entrega.",
  "description": null,
  "awards": null,
  "duration": "13:00",
  "starring": "Guion: Ariana Andrade Y Eduardo Mendoza\nReparto: Víctor Prada / Anibal Lozano / Walter Ramirez\nSonido: Mariano López\nFotografía: Vanessa Rodríguez\nArte: Hernán Castillo\nMontaje: Valentino Rodríguez",
  "producer": "Nicole Torres, Paola Nanino",
  "director": "Ariana Andrade, Eduardo Mendoza",
  "city": null,
  "country": "Perú",
  "year": 2021,
  "thumbnailurl": null,
  "posterurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter%2Fp140.jpg?alt=media&token=60181da9-c358-4b57-94d3-495f53b8455c",
  "poster375webpurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter375webp%2Fp140.webp?alt=media&token=10e4dc0b-1d45-4a83-a67f-7494ceced981",
  "bgurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fbg%2Fb140.jpg?alt=media&token=accd29d9-cd21-4264-a390-3a72ec0b1265",
  "genres": "Ficción, Acción",
  "age": "16+",
  "maxres": "1080p",
  "type": "Cortometraje",
  "language": "ES",
  "status": "active",
  "fomo": 1,
  
 },
 {
  "id": 141,
  "videoId_yt": null,
  "videoId_vm": 515349844,
  "videoId_yt_dmq": null,
  "videoId_svr": null,
  "videohref_yt": null,
  "videohref_vm": "https://vimeo.com/515349844",
  "videohref_yt_dmq": null,
  "videohref_svr": null,
  "title": "Judith",
  "synopsis": "¿Hasta qué punto llegarías por descubrir quien hizo daño a tus seres queridos?\nEl sentimiento de venganza es tan trascendental como gratificante, pero a su vez puede ser frio y vacío, Judith es el ejemplo perfecto.\nSalvador se verá inmerso en la investigación para encontrar al responsable de la muerte de alguien demasiado especial para quedarse con los brazos cruzados.",
  "description": null,
  "awards": null,
  "duration": "16:43",
  "starring": null,
  "producer": null,
  "director": null,
  "city": null,
  "country": null,
  "year": 2021,
  "thumbnailurl": null,
  "posterurl": null,
  "bgurl": null,
  "genres": "Ficción, Suspenso, Acción",
  "age": "16+",
  "maxres": "1080p",
  "type": "Cortometraje",
  "language": "ES",
  "status": "none",
  "fomo": 0,
  
 },
 {
  "id": 142,
  "videoId_yt": null,
  "videoId_vm": 579576934,
  "videoId_yt_dmq": null,
  "videoId_svr": null,
  "videohref_yt": null,
  "videohref_vm": "https://vimeo.com/579576934",
  "videohref_yt_dmq": null,
  "videohref_svr": null,
  "title": "Flores muertas",
  "synopsis": "Un sábado a la noche, Lucas intenta llamar por teléfono a su novia pero se equivoca al marcar y lo atiende una mujer llorando muy angustiada.",
  "description": "Preocupado por ayudarla habla con ella y la conversación genera en ambos el interés de verse.\nEl encuentro se produce a altas horas de la madrugada y lo que parecía ser una cita romántica cargada de tensión sexual, se convierte en un laberinto de emociones contrapuestas que revela las intenciones ocultas de cada uno.",
  "awards": null,
  "duration": "14:33",
  "starring": null,
  "producer": null,
  "director": "Rodrigo Romano.",
  "city": null,
  "country": null,
  "year": 2021,
  "thumbnailurl": null,
  "posterurl": null,
  "bgurl": null,
  "genres": "Ficción, Suspenso",
  "age": "16+",
  "maxres": "1080p",
  "type": "Cortometraje",
  "language": "ES",
  "status": "none",
  "fomo": 0,
  
 },
 {
  "id": 143,
  "videoId_yt": null,
  "videoId_vm": 620220394,
  "videoId_yt_dmq": "xJUMti5PX0M",
  "videoId_svr": null,
  "videohref_yt": null,
  "videohref_vm": "https://vimeo.com/620220394",
  "videohref_yt_dmq": "https://www.youtube.com/watch?v=xJUMti5PX0M",
  "videohref_svr": null,
  "title": "La audición",
  "synopsis": "Don Marco es un actor retirado que trabaja como de guía de un museo. Su nieta Lucía encuentra el anuncio de una audición para la obra del El Rey Lear. El abuelo se niega hacer la audición por recuerdos de cuando actuaba con su difunta esposa. Por eso Lucía, convencerá a su abuelo para que sea feliz actuando.",
  "description": null,
  "awards": null,
  "duration": "27:05",
  "starring": "Guion: José Morales, Jorge Morales\nMúsica: Matías Muñoz\nFotografía: Pablo Vásquez",
  "producer": "Sibú Producciones",
  "director": "José Morales, Jorge Morales",
  "city": null,
  "country": "Costa Rica",
  "year": 2019,
  "thumbnailurl": null,
  "posterurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter%2Fp143.jpg?alt=media&token=c22e2cae-bfa2-4ccb-8228-8c407f0c2d28",
  "poster375webpurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter375webp%2Fp143.webp?alt=media&token=bd432672-200e-4ea2-959d-9138a4a43371",
  "bgurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fbg%2Fb143.jpg?alt=media&token=8a74c556-24c1-4dbf-974a-cc477dce9299",
  "genres": "Drama",
  "age": "16+",
  "maxres": "1080p",
  "type": "Cortometraje",
  "language": "ES",
  "status": "active",
  "fomo": 0,
  
 },
 {
  "id": 144,
  "videoId_yt": null,
  "videoId_vm": 520408835,
  "videoId_yt_dmq": null,
  "videoId_svr": null,
  "videohref_yt": null,
  "videohref_vm": "https://vimeo.com/520408835",
  "videohref_yt_dmq": null,
  "videohref_svr": null,
  "title": "Desechables",
  "synopsis": null,
  "description": "Cortometraje Desechables, extracto de la película La Danza de las Fieras.",
  "awards": null,
  "duration": "21:38",
  "starring": "DP: Alejandro Chávez AMC\nCast: Ángel Noé Alvarado, Rodolfo Calderón, Eduardo Rodríguez, Rafael Romero, Eric Wilfrido",
  "producer": "Germán Castilla.",
  "director": "Miguel Ángel Fernández",
  "city": null,
  "country": "México",
  "year": 2017,
  "thumbnailurl": null,
  "posterurl": null,
  "bgurl": null,
  "genres": "Ficción",
  "age": "16+",
  "maxres": "1080p",
  "type": "Cortometraje",
  "language": "ES",
  "status": "none",
  "fomo": 0,
  
 },
 {
  "id": 145,
  "videoId_yt": null,
  "videoId_vm": 502779885,
  "videoId_yt_dmq": "y6PLEAaqmYc",
  "videoId_svr": null,
  "videohref_yt": null,
  "videohref_vm": "https://vimeo.com/502779885",
  "videohref_yt_dmq": "https://www.youtube.com/watch?v=y6PLEAaqmYc",
  "videohref_svr": null,
  "title": "Pasajeros",
  "synopsis": "Manu, un adolescente de 15 años, se ha negado a convertirse en delincuente a pesar de que esa es la única forma de crecer en su barrio. Pero un día, invitado por Camera, su mejor amigo, comienza a coquetear con el crimen robando un camión de pasajeros, pero el ataque inesperado de un pasajero provoca la muerte de Camera y un quiebre en el comportamiento de Manu.",
  "description": null,
  "awards": null,
  "duration": "15:43",
  "starring": "Cast: Kristyan Ferrer, Enrique Medina, Edgar Marchena\nGuión y Dirección: Miguel Ángel Fernández\nDP: Renato Gómez\nDir. de Arte: Lituania Ceballos\nMontaje: Miguel Ángel Fernández",
  "producer": "Miguel Ángel Fernández, Stephanie Fucugauchi",
  "director": "Miguel Ángel Fernández",
  "city": null,
  "country": "México",
  "year": 2013,
  "thumbnailurl": null,
  "posterurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter%2Fp145.jpg?alt=media&token=c70155f6-99ee-4876-9bbf-614de1c339c0",
  "poster375webpurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter375webp%2Fp145.webp?alt=media&token=6e1c100c-3715-4312-9281-9f6b0027a95c",
  "bgurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fbg%2Fb145.jpg?alt=media&token=6df14e84-2a63-40a1-adce-8a4ac01eae25",
  "genres": "Ficción, Drama",
  "age": "16+",
  "maxres": "1080p",
  "type": "Cortometraje",
  "language": "ES",
  "status": "active",
  "fomo": 0,
  
 },
 {
  "id": 146,
  "videoId_yt": null,
  "videoId_vm": 538468550,
  "videoId_yt_dmq": "4OHi5XAT1jQ",
  "videoId_svr": null,
  "videohref_yt": null,
  "videohref_vm": "https://vimeo.com/538468550",
  "videohref_yt_dmq": "https://www.youtube.com/watch?v=4OHi5XAT1jQ",
  "videohref_svr": null,
  "title": "Rompecabezas",
  "synopsis": "Dos secuestradores raptan a cuatro personas. Les proponen un juego macabro para salvar sus vidas; si lo resuelven les prometen que nos les harán ningún daño. Uno de los secuestrados descifra rápidamente el acertijo para asombro de los captores. Este hecho inesperado cambia el juego por completo.",
  "description": null,
  "awards": null,
  "duration": "11:21",
  "starring": "Reparto: José Vergara, Clara Gillman, Jorge Porras, Judit Bayón, Estela Alcaide",
  "producer": null,
  "director": "Germán Ortiz",
  "city": null,
  "country": "España",
  "year": 2016,
  "thumbnailurl": null,
  "posterurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter%2Fp146.jpg?alt=media&token=4fcfab46-4bec-4066-b0cb-2d21a260c516",
  "poster375webpurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter375webp%2Fp146.webp?alt=media&token=70f3da28-19af-4142-8b11-50604de5f11a",
  "bgurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fbg%2Fb146.jpg?alt=media&token=f17ec0b8-9388-4900-ad1a-29ed5ee085e0",
  "genres": "Ficción, Suspenso",
  "age": "16+",
  "maxres": "1080p",
  "type": "Cortometraje",
  "language": "ES",
  "status": "active",
  "fomo": 0,
  
 },
 {
  "id": 147,
  "videoId_yt": null,
  "videoId_vm": 621906883,
  "videoId_yt_dmq": "7rpdervB6IU",
  "videoId_svr": null,
  "videohref_yt": null,
  "videohref_vm": "https://vimeo.com/621906883",
  "videohref_yt_dmq": "https://www.youtube.com/watch?v=7rpdervB6IU",
  "videohref_svr": null,
  "title": "Capitán Escudo",
  "synopsis": "Relata los increíbles acontecimientos que llevaron a un héroe común a convertirse en el Superhéroe Tricolor. Misterio, aventura y mucha acción se juntan en la más emocionante aventura del Capitán Escudo, el superhéroe 100% ecuatoriano.",
  "description": null,
  "awards": null,
  "duration": "18:47",
  "starring": "Producción: Alejandro Bustos e Isabel Dávalos",
  "producer": "ZONACUARIO, CABEZAHUECA",
  "director": "Beto Valencia y Diego Castillo",
  "city": null,
  "country": "Ecuador",
  "year": 2018,
  "thumbnailurl": null,
  "posterurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter%2Fp147.jpg?alt=media&token=0cce1330-4a58-435c-a7af-07d4f0f40e8e",
  "poster375webpurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter375webp%2Fp147.webp?alt=media&token=916eeae7-4c56-4a81-b05f-3fd78027fdee",
  "bgurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fbg%2Fb147.jpg?alt=media&token=45f54a6b-133c-48e9-959e-4dcc5a418580",
  "genres": "Animación, Acción, Comedia",
  "age": "13+",
  "maxres": "1080p",
  "type": "Cortometraje",
  "language": "ES",
  "status": "active",
  "fomo": 0,
  
 },
 {
  "id": 148,
  "videoId_yt": null,
  "videoId_vm": 663151186,
  "videoId_yt_dmq": "S7p1ccXYQvU",
  "videoId_svr": null,
  "videohref_yt": null,
  "videohref_vm": "https://vimeo.com/663151186",
  "videohref_yt_dmq": "https://www.youtube.com/watch?v=S7p1ccXYQvU",
  "videohref_svr": null,
  "title": "Murkoa",
  "synopsis": "Nila es una joven responsable que cuida de su madre, Amaia, una ex exploradora que sufre una enfermedad terminal que no le permite levantarse de la cama. Mientras está limpiando el despacho de Amaia, Nila encuentra un objeto que le atraerá especialmente. A partir de ese momento, nada será igual.",
  "description": null,
  "awards": null,
  "duration": "13:40",
  "starring": "Cortometraje escrito por Ibon Alberdi y dirigido por Aitor Abio. Equipo técnico: Aroa Paris, Manex Ancisar, Janire Rodriguez y Andoni Ibargutxi.\nProtagonizado por Inma Koiote y Marta Oyarzabal.",
  "producer": "Ainhoa Uribarri. ",
  "director": "Aitor Abio",
  "city": null,
  "country": "España",
  "year": 2021,
  "thumbnailurl": null,
  "posterurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter%2Fp148.jpg?alt=media&token=d87e173b-6805-4268-b8a2-d28a92d15d51",
  "poster375webpurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter375webp%2Fp148.webp?alt=media&token=6eb261cc-55fd-4d7f-a978-6387d95ddae2",
  "bgurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fbg%2Fb148.jpg?alt=media&token=7918bcc2-8d88-4630-98d2-f3a66e068272",
  "genres": "Ficción, Drama, Suspenso",
  "age": "16+",
  "maxres": "1080p",
  "type": "Cortometraje",
  "language": "ES",
  "status": "active",
  "fomo": 0,
  
 },
 {
  "id": 149,
  "videoId_yt": null,
  "videoId_vm": 661994377,
  "videoId_yt_dmq": "-3jj-ZQQ-So",
  "videoId_svr": null,
  "videohref_yt": null,
  "videohref_vm": "https://vimeo.com/661994377",
  "videohref_yt_dmq": "https://www.youtube.com/watch?v=-3jj-ZQQ-So",
  "videohref_svr": null,
  "title": "Nacer",
  "synopsis": "Abelardo, cansado de luchar contra la vida, ha decidido emprender un viaje hacia la libertad. Es entonces que un extraño personaje aparece en su vida para mostrarle la única forma de llevar a cabo su propósito: atravesar la puerta del miedo.",
  "description": null,
  "awards": null,
  "duration": "17:12",
  "starring": "Producción: Pablo Marino\nDirector: Leonardo Pérez García\nGuion: Jóse Antonio Ramirez & Leonardo P. García\nFotografía: Iván Sánchez\nDirección Artística: Marta Torán\nMúsica: Eduardo Leiva\nSonido: Sergio F. Borrás\nMontaje: Leonardo P. García\nIntérpretes:\nSamuel Claxton, Abelardo\nVicenta Ndongo, Eva\nMirella Chapman, Oku\nGeorge Abreu, Ignacio\nZaida Díaz, Eloísa",
  "producer": "Causa & Afecto Producciones, Clapham, Punto Ciego",
  "director": "Leo Pérez",
  "city": null,
  "country": "España",
  "year": null,
  "thumbnailurl": null,
  "posterurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter%2Fp149.jpg?alt=media&token=0d958d23-c040-4c4c-82fc-b179414afc13",
  "poster375webpurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter375webp%2Fp149.webp?alt=media&token=0a95853d-5355-4017-9847-07a752ff9cdf",
  "bgurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fbg%2Fb149.jpg?alt=media&token=8fbd7a05-2b99-4584-aa89-97f24d55c782",
  "genres": "Ficción, Suspenso",
  "age": "16+",
  "maxres": "1080p",
  "type": "Cortometraje",
  "language": "ES",
  "status": "active",
  "fomo": 0,
  
 },
 {
  "id": 150,
  "videoId_yt": null,
  "videoId_vm": 517539870,
  "videoId_yt_dmq": "6U5WJoS1FUE",
  "videoId_svr": null,
  "videohref_yt": null,
  "videohref_vm": "https://vimeo.com/517539870",
  "videohref_yt_dmq": "https://www.youtube.com/watch?v=6U5WJoS1FUE",
  "videohref_svr": null,
  "title": "AA - Artistas Anónimos",
  "synopsis": "Un grupo de personas adictas al arte se reúnen para buscar ayuda y tratar sus problemas. En un mundo donde todo es una mierda, lo importante es aparentar.",
  "description": null,
  "awards": null,
  "duration": "10:13",
  "starring": "Con Claudia Sancho, Fran Martínez, Christian Dragan, Christian Andrade, Isabel Hebrero, Álvaro Gelabert, Eva Magaña, Manuel Buenaventura, Lucie Beuken, Alberto Segovia, Claudia Sierra y El Bake.",
  "producer": null,
  "director": "Guilherme Oliveira",
  "city": null,
  "country": "España",
  "year": null,
  "thumbnailurl": null,
  "posterurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter%2Fp150.jpg?alt=media&token=66a082ed-b31d-446b-92f7-4e9bb7770a71",
  "poster375webpurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter375webp%2Fp150.webp?alt=media&token=46ff1280-ebd4-438b-a460-350ba6e31065",
  "bgurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fbg%2Fb150.jpg?alt=media&token=443d5eb2-af14-4da2-a0bb-8e4a3788c612",
  "genres": "Drama",
  "age": "16+",
  "maxres": "1080p",
  "type": "Cortometraje",
  "language": "ES",
  "status": "active",
  "fomo": 0,
  
 },
 {
  "id": 151,
  "videoId_yt": null,
  "videoId_vm": 526161364,
  "videoId_yt_dmq": "io9ekPcW2N8",
  "videoId_svr": null,
  "videohref_yt": null,
  "videohref_vm": "https://vimeo.com/526161364",
  "videohref_yt_dmq": "https://www.youtube.com/watch?v=io9ekPcW2N8",
  "videohref_svr": null,
  "title": "Soy tú",
  "synopsis": "Es un cuento místico que se refleja en la pantalla a través de las leyes de la causa/efecto y la correspondencia. La historia narra los problemas de una madre con su hijo y su marido y cómo estos se proyectan inconscientemente en la relación de la madre con su propio padre. Todo cambia cuando aparece un sabio de lo invisible que guía a la madre para sanar las heridas del presente y del pasado.",
  "description": null,
  "awards": null,
  "duration": "29:57",
  "starring": "Guion: Joan Cutrina\nFotografía: Sergi Sampol\nReparto: Ana Milán, Miquel Fernández, Lluís Soler, Pep Cruz, Arnau Llausí, Carme Perelló, Eva Martí, Miguel Alamillo",
  "producer": "Alqvimia 222, AMORC",
  "director": "Joan Cutrina",
  "city": null,
  "country": "España",
  "year": 2019,
  "thumbnailurl": null,
  "posterurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter%2Fp151.jpg?alt=media&token=449353a2-fd30-47a5-bc6b-c9b71608c499",
  "poster375webpurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter375webp%2Fp151.webp?alt=media&token=e6bbb8b9-7ce4-4ba9-88ab-4503e66624fd",
  "bgurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fbg%2Fb151.jpg?alt=media&token=6aa390a5-7b18-4bf8-a8d3-14f78b6267b9",
  "genres": "Drama, Ficción",
  "age": "16+",
  "maxres": "1080p",
  "type": "Cortometraje",
  "language": "ES",
  "status": "active",
  "fomo": 0,
  
 },
 {
  "id": 152,
  "videoId_yt": null,
  "videoId_vm": 538144541,
  "videoId_yt_dmq": "XCvzsbgXvjY",
  "videoId_svr": null,
  "videohref_yt": null,
  "videohref_vm": "https://vimeo.com/538144541",
  "videohref_yt_dmq": "https://www.youtube.com/watch?v=XCvzsbgXvjY",
  "videohref_svr": null,
  "title": "Ada office",
  "synopsis": "Ada Office es la nueva jefa virtual que ha llegado a las empresas de todo el mundo con el objetivo de optimizar al máximo su productividad y rendimiento. Emilio, un tímido oficinista, lleva una rutina aparentemente perfecta guiado por esta IA. Compite por ser el mejor programador informático, hasta que una mala noticia que pone al descubierto la faceta más oscura de ambos.",
  "description": null,
  "awards": null,
  "duration": "12:55",
  "starring": "Reparto: Sharani Ballesteros, Emmanuel Zavala, Lucía Villegas, Adrian Villanueva",
  "producer": null,
  "director": "Carlos V. Diupotex",
  "city": null,
  "country": "México",
  "year": 2021,
  "thumbnailurl": null,
  "posterurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter%2Fp152.jpg?alt=media&token=58dea9ca-49d8-4516-acc5-08b4e3af4ed4",
  "poster375webpurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter375webp%2Fp152.webp?alt=media&token=c47b7c73-4176-4bdd-8093-02f5c51b3f9f",
  "bgurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fbg%2Fb152.jpg?alt=media&token=87968652-85b1-4b09-8929-528506d3689a",
  "genres": "Ciencia Ficción",
  "age": "16+",
  "maxres": "1080p",
  "type": "Cortometraje",
  "language": "ES",
  "status": "active",
  "fomo": 0,
  
 },
 {
  "id": 153,
  "videoId_yt": null,
  "videoId_vm": 555330742,
  "videoId_yt_dmq": "9_5E9eqti9I",
  "videoId_svr": null,
  "videohref_yt": null,
  "videohref_vm": "https://vimeo.com/555330742",
  "videohref_yt_dmq": "https://www.youtube.com/watch?v=9_5E9eqti9I",
  "videohref_svr": null,
  "title": "La sangre llama",
  "synopsis": "Una niña estadounidense viaja a México en busca de su identidad y del padre del que fue separada cuando era niña.",
  "description": null,
  "awards": null,
  "duration": "12:05",
  "starring": "Guion: Iván García GzzVannessa Vásquez\nReparto: Vannessa Vásquez, David Carreño, Arturo González Aguirre",
  "producer": null,
  "director": "\nIvan Garcia Gzz",
  "city": null,
  "country": "México",
  "year": 2019,
  "thumbnailurl": null,
  "posterurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter%2Fp153.jpg?alt=media&token=c35c8d41-9256-4dc6-9222-bb5e9e062b27",
  "poster375webpurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter375webp%2Fp153.webp?alt=media&token=5fa3b494-5958-4ea3-9622-39d1f6488ece",
  "bgurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fbg%2Fb153.jpg?alt=media&token=081d567b-b873-48a3-9890-75f1c943a349",
  "genres": "Drama",
  "age": "16+",
  "maxres": "1080p",
  "type": "Cortometraje",
  "language": "ES",
  "status": "active",
  "fomo": 0,
  
 },
 {
  "id": 154,
  "videoId_yt": null,
  "videoId_vm": 552572508,
  "videoId_yt_dmq": "Ccn9LLdIHto",
  "videoId_svr": null,
  "videohref_yt": null,
  "videohref_vm": "https://vimeo.com/552572508",
  "videohref_yt_dmq": "https://www.youtube.com/watch?v=Ccn9LLdIHto",
  "videohref_svr": null,
  "title": "Pozo vacante",
  "synopsis": null,
  "description": null,
  "awards": null,
  "duration": "16:46",
  "starring": "Víctor Díaz, Ileana Jaciw, Sebastian Jaramillo, Nina Manfredi, Juan Pablo Passarino, Juan Silva, Norma Vispo\nGuion: Emiliano Romero, Juan Pablo Passarino\nMontaje: Gonzalo Aranda, \nCámara y Fotografía: Emiliano Romero\nAsistentes de Dirección: Evelia Gonzalez Heredia, Julieta Bermudez",
  "producer": null,
  "director": "Emiliano Romero",
  "city": null,
  "country": "Argentina",
  "year": 2021,
  "thumbnailurl": null,
  "posterurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter%2Fp154.jpg?alt=media&token=30531fd4-9823-496b-9a49-8b06e5200ca8",
  "poster375webpurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter375webp%2Fp154.webp?alt=media&token=5ac6d598-d3b7-416b-bc03-d639632a1649",
  "bgurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fbg%2Fb154.jpg?alt=media&token=4f906d65-b39b-405f-9f71-adb9af5b016c",
  "genres": "Ficción",
  "age": "16+",
  "maxres": "1080p",
  "type": "Cortometraje",
  "language": "ES",
  "status": "active",
  "fomo": 0,
  
 },
 {
  "id": 155,
  "videoId_yt": null,
  "videoId_vm": 665215997,
  "videoId_yt_dmq": "_7nR1gOVZWM",
  "videoId_svr": null,
  "videohref_yt": null,
  "videohref_vm": "https://vimeo.com/665215997",
  "videohref_yt_dmq": "https://www.youtube.com/watch?v=_7nR1gOVZWM",
  "videohref_svr": null,
  "title": "En el Útero",
  "synopsis": "Tana está pronto a dar la prueba de selección universitaria, sin embargo, aún no sabe qué estudiar. Vive con Marta, su madre, quien padece de ataques de pánico y alcoholismo. Tras recibir una orden de embargo, Marta colapsa, Tana deberá afrontar sus miedos e inseguridades para seguir adelante.",
  "description": null,
  "awards": null,
  "duration": "20:04",
  "starring": "Producción Ejecutiva: Joaquín Rodríguez - Marco Tillería - José Matamala - Francisco Toro\nProducción General: Joaquín Rodríguez\nDirección de Fotografía: César Valdivia\nDirección de Arte: Nicole Flores\nDirección de Sonido: Oscar Vilugrón",
  "producer": "Cinescombro, Artistas Felices, Raconto Cine",
  "director": "Marco Tillería",
  "city": null,
  "country": "Chile",
  "year": 2017,
  "thumbnailurl": null,
  "posterurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter%2Fp155.jpg?alt=media&token=487bcbe5-238c-4716-8990-2cfe35f24321",
  "poster375webpurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter375webp%2Fp155.webp?alt=media&token=65659a1d-4b9d-4351-a419-a9a91c561cf6",
  "bgurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fbg%2Fb155.jpg?alt=media&token=2bf1dd26-5870-41d9-b51a-3715c388f6e4",
  "genres": "Drama",
  "age": "16+",
  "maxres": "1080p",
  "type": "Cortometraje",
  "language": "ES",
  "status": "active",
  "fomo": 0,
  
 },
 {
  "id": 156,
  "videoId_yt": null,
  "videoId_vm": 662678840,
  "videoId_yt_dmq": null,
  "videoId_svr": null,
  "videohref_yt": null,
  "videohref_vm": "https://vimeo.com/662678840",
  "videohref_yt_dmq": null,
  "videohref_svr": null,
  "title": "Atado a ti",
  "synopsis": null,
  "description": null,
  "awards": null,
  "duration": "11:40",
  "starring": null,
  "producer": null,
  "director": null,
  "city": null,
  "country": null,
  "year": null,
  "thumbnailurl": null,
  "posterurl": null,
  "bgurl": null,
  "genres": "Ficción",
  "age": "16+",
  "maxres": "1080p",
  "type": "Cortometraje",
  "language": "ES",
  "status": "none",
  "fomo": 0,
  
 },
 {
  "id": 157,
  "videoId_yt": null,
  "videoId_vm": 641606242,
  "videoId_yt_dmq": "ETEcHpxDOh8",
  "videoId_svr": null,
  "videohref_yt": null,
  "videohref_vm": "https://vimeo.com/641606242",
  "videohref_yt_dmq": "https://www.youtube.com/watch?v=ETEcHpxDOh8",
  "videohref_svr": null,
  "title": "Soldado de paz",
  "synopsis": "Es el año 2070, la tierra está destruida. Un soldado del \"Ejército de Paz\" cae herido y solo en un barranco esperando una salvación.",
  "description": null,
  "awards": null,
  "duration": "22:56",
  "starring": "Redacción: Alex Chango, Andrea Ruiz, Alejandro Gadvay",
  "producer": null,
  "director": "Henry SC",
  "city": null,
  "country": "Ecuador",
  "year": 2021,
  "thumbnailurl": null,
  "posterurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter%2Fp157.jpg?alt=media&token=be407ec7-0477-40a0-8bc1-0ecebe17c5f3",
  "poster375webpurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter375webp%2Fp157.webp?alt=media&token=bd7de192-fed0-430c-ad13-e143694ce1be",
  "bgurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fbg%2Fb157.jpg?alt=media&token=c15ee423-edc5-4952-9388-d3d4f4ee6324",
  "genres": "Ficción, Acción",
  "age": "16+",
  "maxres": "1080p",
  "type": "Cortometraje",
  "language": "ES",
  "status": "active",
  "fomo": 0,
  
 },
 {
  "id": 158,
  "videoId_yt": null,
  "videoId_vm": 629052143,
  "videoId_yt_dmq": "EznBXpp0_Mc",
  "videoId_svr": null,
  "videohref_yt": null,
  "videohref_vm": "https://vimeo.com/629052143",
  "videohref_yt_dmq": "https://www.youtube.com/watch?v=EznBXpp0_Mc",
  "videohref_svr": null,
  "title": "Entrega Por Error",
  "synopsis": "Susana es una joven que trabaja desde casa. Está aburrida de su rutina: desayunar, trabajar, contestar los WhatsApps de su amiga Lucía, ducharse, cocinar y volver a trabajar. Esa rutina se rompe por completo al conocer a Ana, una particular y encantadora cartera que le entrega un paquete por equivocación.",
  "description": null,
  "awards": null,
  "duration": "13:35",
  "starring": "Reparto: Belén Mayordomo, Laura Lafuente, Noelia Jiménez, Diego Estrada, Tweeko Sowy \nGuion: Iratxe Arroyo ",
  "producer": "Laura Chernov",
  "director": "Mario López",
  "city": null,
  "country": "España",
  "year": 2020,
  "thumbnailurl": null,
  "posterurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter%2Fp158.jpg?alt=media&token=fc7fe190-1adc-4d40-a347-49039ea543d7",
  "poster375webpurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter375webp%2Fp158.webp?alt=media&token=242ad1a1-004c-44f8-a2cf-9cb33afd2f34",
  "bgurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fbg%2Fb158.jpg?alt=media&token=579e43e5-e40f-4bc4-bd08-103baffc3481",
  "genres": "Drama, Suspenso",
  "age": "16+",
  "maxres": "1080p",
  "type": "Cortometraje",
  "language": "ES",
  "status": "active",
  "fomo": 0,
  
 },
 {
  "id": 159,
  "videoId_yt": null,
  "videoId_vm": 524175097,
  "videoId_yt_dmq": "5qgBdCrsBms",
  "videoId_svr": null,
  "videohref_yt": null,
  "videohref_vm": "https://vimeo.com/524175097",
  "videohref_yt_dmq": "https://www.youtube.com/watch?v=5qgBdCrsBms",
  "videohref_svr": null,
  "title": "Expediente",
  "synopsis": "Un psiquiatra decide revelar los entresijos del expediente clínico de un paciente que sufre trastornos de sueños. Así descubriremos como Jorge, el afectado, hará todo lo posible para que no se vuelva a repetir una trágica pesadilla en la que su hijo finalmente muere. El temor a volver a quedarse dormido provocará que este padre haga auténticas barbaridades para que sus ojos no vuelvan a cerrarse, tratando de mantener un severo pulso contra sí mismo y contra un cansancio que, noche tras noche, irá haciendo mella en su cabeza.",
  "description": null,
  "awards": null,
  "duration": "23:28",
  "starring": "Guion: Fran J. Marber\nMontaje: Bernardo Hernández\nDirector de Fotografía: Pedro Darso\nSonido: Joaquín Ruiz Y Juanchi Ruiz\nScript: Pascual Pérez\nClaqueta: Dani Ruix\nVoz en Off: Guillermo Brazalez\nMúsica\n“Eternal Winter”(Michael Scherchen)",
  "producer": "Oscar Fuentes, Juan Cerezo",
  "director": "Bernardo Hernández",
  "city": null,
  "country": "España",
  "year": 2021,
  "thumbnailurl": null,
  "posterurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter%2Fp159.jpg?alt=media&token=70540129-a19f-4d03-95a4-389941e5e939",
  "poster375webpurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter375webp%2Fp159.webp?alt=media&token=a2631069-91a1-4910-9b70-8468aafae51f",
  "bgurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fbg%2Fb159.jpg?alt=media&token=e8cf5c59-60f3-4832-ba99-246646b9adb5",
  "genres": "Suspenso, Thriller, Drama",
  "age": "16+",
  "maxres": "1080p",
  "type": "Cortometraje",
  "language": "ES",
  "status": "active",
  "fomo": 0,
  
 },
 {
  "id": 160,
  "videoId_yt": null,
  "videoId_vm": 520656367,
  "videoId_yt_dmq": "fYnjS4NMx4w",
  "videoId_svr": null,
  "videohref_yt": null,
  "videohref_vm": "https://vimeo.com/520656367",
  "videohref_yt_dmq": "https://www.youtube.com/watch?v=fYnjS4NMx4w",
  "videohref_svr": null,
  "title": "Un cuarto de hora",
  "synopsis": "Cada día en la madrugada aparece la Reina en la cantina. Hoy habrá algo distinto. José comprenderá que este cuarto de hora con ella es un intervalo de tiempo, una conexión entre los vivos y los muertos. Basado en un cuento de Gabriel García Márquez",
  "description": null,
  "awards": "Mejor Cortometraje 2º Festival de Cine Urbano, Peñalolén, Chile.\nSelección oficial 20º FESANCOR. Santiago, Chile.\nSelección oficial 10º Festival internacional de Cine LEBU. Lebu, Chile.\nSelección oficial 3º Festival de cine de la Patagonia Aysén. Coyhaique, Chile.",
  "duration": "16:21",
  "starring": "Guion: Pablo Stephens, Rodrigo Hidalgo\nReparto: Alejandro Trejo, Cristina Volpi, Cristián Hidalgo\nProducción ejecutiva: María Angélica Pérez\nAsistente de dirección: Rodrigo Hidalgo\nDirección de fotografía: Lucas Combina\nDirección de arte: Constanza Stephens\nSonido: Hardy Cotal\nMúsica: Patricio Chico\nMaquillaje: Margarita Robertson\nVestuario: José Pinto",
  "producer": "Claudio Cid",
  "director": "Carlos Vásquez",
  "city": null,
  "country": "Chile",
  "year": 2011,
  "thumbnailurl": null,
  "posterurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter%2Fp160.jpg?alt=media&token=99d03d90-0bf1-4c70-8a7a-dedafac2baee",
  "poster375webpurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter375webp%2Fp160.webp?alt=media&token=ae25e8e5-78fb-46fa-8d67-0cf3b0c93685",
  "bgurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fbg%2Fb160.jpg?alt=media&token=572539a1-8a88-439f-a71a-04e2f93633a1",
  "genres": "Ficción",
  "age": "16+",
  "maxres": "1080p",
  "type": "Cortometraje",
  "language": "ES",
  "status": "active",
  "fomo": 0,
  
 },
 {
  "id": 161,
  "videoId_yt": "Ows1XtLuVpc",
  "videoId_vm": null,
  "videoId_yt_dmq": "w3HLkNENd5Q",
  "videoId_svr": null,
  "videohref_yt": "https://www.youtube.com/watch?v=Ows1XtLuVpc",
  "videohref_vm": null,
  "videohref_yt_dmq": "https://www.youtube.com/watch?v=w3HLkNENd5Q",
  "videohref_svr": null,
  "title": "007 - Hue Bond: un polvo no basta",
  "synopsis": "Catorce años después de su última aventura, Hue Bond (Kike Colunge) y su hijo Car Bond (Joaquín Escobar) deben liberar a la millonaria Elektra Vergara (Mónica Sánchez) de las garras del siniestro Gustave Good (Gustavo Bueno). Bajo las órdenes de su jefa \"M\" (Yvonne Frayssinet) y con la ayuda de la Doctora Solange Fierro (Daniela Camaiora), los agentes descubrirán un oscuro secreto dentro de este peligroso último caso, en el que tendrán que poner punto final a las fechorías del terrorista Blofeld (Mauricio Caravedo) y su reinado del mal.",
  "description": null,
  "awards": null,
  "duration": "26:22",
  "starring": "Reparto: Kike Colunge, Gustavo Bueno, Joaquín Escobar, Mónica Sánchez, Yvonne Frayssinet, Marcelo Oxenford, Carlota Luna, Daniela Camaiora y Ernesto Pimentel\nGuion: Kike Colunge\nFotografía: Iván Herrera, Luis Véliz\nEdición: Kike Colunge",
  "producer": null,
  "director": "Kike Colunge",
  "city": null,
  "country": "Perú",
  "year": 2013,
  "thumbnailurl": null,
  "posterurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter%2Fp161.jpg?alt=media&token=440b7168-e8aa-4a2d-bd03-631eafe2f111",
  "poster375webpurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter375webp%2Fp161.webp?alt=media&token=37bdccbc-e3e2-4784-883c-f50178ca9464",
  "bgurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fbg%2Fb161.jpg?alt=media&token=ebf8375a-a02c-4a8e-aede-c91654ddce25",
  "genres": "Ficción, Acción, Comedia",
  "age": "16+",
  "maxres": "1080p",
  "type": "Cortometraje",
  "language": "ES",
  "status": "none",
  "fomo": 0,
  
 },
 {
  "id": 162,
  "videoId_yt": null,
  "videoId_vm": 432369215,
  "videoId_yt_dmq": "4HJ26fbWMN8",
  "videoId_svr": null,
  "videohref_yt": null,
  "videohref_vm": "https://vimeo.com/432369215",
  "videohref_yt_dmq": "https://www.youtube.com/watch?v=4HJ26fbWMN8",
  "videohref_svr": null,
  "title": "Abril",
  "synopsis": "Abril es una niña diferente al resto, vive junto a su madre Ana, esta última está a punto de tomar una decisión que cambiará la vida de ella y la de su hija para siempre.",
  "description": null,
  "awards": null,
  "duration": "16:51",
  "starring": "Reparto: Luis Rubianes, Fernanda Manrique y María Avila\nGuion: Kevin Zevallos Hinostroza\nFotografía: Jean Pierre Suarez\nScript: Alejandro Nuñez\nAsistencia de Dirección: David Camahualí\nEdición: Alexandra Cárdenas, Noemí Loredo\nMúsica: Juan Carlos Vega\nPostproducción de Sonido: Denis Lazo\nAsistencia de Producción: Yelsin Chamorro",
  "producer": "Alicia Manrique",
  "director": "Kevin Zevallos Hinostroza",
  "city": null,
  "country": "Perú",
  "year": 2017,
  "thumbnailurl": null,
  "posterurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter%2Fp162.jpg?alt=media&token=92943dff-1b3c-4cc5-b50e-f2f98233dc26",
  "poster375webpurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter375webp%2Fp162.webp?alt=media&token=e89a0729-fe92-4f4b-9441-e98edd7de9ea",
  "bgurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fbg%2Fb162.jpg?alt=media&token=378680fb-1c98-4633-9da1-f8f41fc7cef7",
  "genres": "Drama, Suspenso",
  "age": "16+",
  "maxres": "1080p",
  "type": "Cortometraje",
  "language": "ES",
  "status": "active",
  "fomo": 0,
  
 },
 {
  "id": 163,
  "videoId_yt": "hlCMidq7MGA",
  "videoId_vm": null,
  "videoId_yt_dmq": "hca2W7WKiWA",
  "videoId_svr": null,
  "videohref_yt": "https://www.youtube.com/watch?v=hlCMidq7MGA",
  "videohref_vm": null,
  "videohref_yt_dmq": "https://www.youtube.com/watch?v=hca2W7WKiWA",
  "videohref_svr": null,
  "title": "Arriba",
  "synopsis": "A través de una radio pirata, sutiles detalles revelan las contradicciones de la cotidianeidad de miles de personas que viven en la ciudad de Lima, Perú.",
  "description": null,
  "awards": "PERÚ\n21º Festival de Cine de Lima\nSeleccionado en Muestra Itinerante\nESTADOS UNIDOS\n33º Chicago Latino Film Festival 2017\nSeleccionado en Sección Oficial\nESPAÑA\nFestival Iberoamericano de Cortometrajes ABC 2017\nSeleccionado\nPERÚ • CICLO DE CINE\nHecho en Perú 2017\nPERÚ • CONCURSO\nSolo Para Soñadores\nFinalista\nPERÚ\nConcurso de Proyectos y Obras Cinematográficas del Ministerio de Cultura 2016\nGanador en Concurso Nacional de Cortometrajes\nCUBA\nFestival Internacional del Nuevo Cine Latinoamericano 2015\nSeleccionado en La hora del corto\nFRANCIA\nFestival de Cannes 2015\nParticipante en Short Film Corner",
  "duration": "08:15",
  "starring": "Reparto: Hannah Mellissa Bazán Nuñez, Daniel Sánchez García, Rosa Micaela Távara Arroyo y Rosa Rebeca Nuñez Montoya\nGuion: Adrián Hartill Montalvo, Daniel Sánchez García, Miguel Ángel Moulet\nFotografía: Danil Massip\nMúsica: Víctor Casahuamán",
  "producer": null,
  "director": "Adrián Hartill Montalvo",
  "city": null,
  "country": "Perú",
  "year": 2015,
  "thumbnailurl": null,
  "posterurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter%2Fp163.jpg?alt=media&token=e78172ee-a14a-4f75-a275-9bd63ba0a0a9",
  "poster375webpurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter375webp%2Fp163.webp?alt=media&token=adf4f100-4dbf-4e36-9a9b-125405dd61a3",
  "bgurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fbg%2Fb163.jpg?alt=media&token=24dd85d5-fa09-45eb-8e79-a5cde4f0d96d",
  "genres": "Drama, Comedia",
  "age": "16+",
  "maxres": "1080p",
  "type": "Cortometraje",
  "language": "ES",
  "status": "active",
  "fomo": 0,
  
 },
 {
  "id": 164,
  "videoId_yt": "Ysd2R_UYdNc",
  "videoId_vm": null,
  "videoId_yt_dmq": "6wHJu4tNuRQ",
  "videoId_svr": null,
  "videohref_yt": "https://www.youtube.com/watch?v=Ysd2R_UYdNc",
  "videohref_vm": null,
  "videohref_yt_dmq": "https://www.youtube.com/watch?v=6wHJu4tNuRQ",
  "videohref_svr": null,
  "title": "Asesino de la verdad",
  "synopsis": "Una joven periodista realiza una investigación sobre corrupción, el inmiscuirse en ese mundo le causa muchos problemas.",
  "description": null,
  "awards": "PERÚ\n1º Festival de Cortometrajes Desde Huancayo 2020\nSeleccionado",
  "duration": "10:28",
  "starring": "Reparto: Rosángela Jiménez Barzola, Alex Tomás Villa, Brenda Peña Aliaga, Jesús Hilario Laura, Joseph Clemente Tueros, Junior Espinoza Malpartida y Alexis Santa Cruz Carrión",
  "producer": "Martha Vargas Huaroc",
  "director": "Erika Balvin Vilcahuamán",
  "city": null,
  "country": "Perú",
  "year": 2019,
  "thumbnailurl": null,
  "posterurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter%2Fp164.jpg?alt=media&token=1b9bc0ac-09f7-4ec6-8f61-601d0aa28ad6",
  "poster375webpurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter375webp%2Fp164.webp?alt=media&token=2d89ce65-d81b-48af-b079-c96e2dba70f0",
  "bgurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fbg%2Fb164.jpg?alt=media&token=b0ac18c0-6d8d-400d-b4d3-9a7256190734",
  "genres": "Suspenso, Drama",
  "age": "16+",
  "maxres": "1080p",
  "type": "Cortometraje",
  "language": "ES",
  "status": "active",
  "fomo": 0,
  
 },
 {
  "id": 165,
  "videoId_yt": null,
  "videoId_vm": 184253058,
  "videoId_yt_dmq": "MiovmkYmsLo",
  "videoId_svr": null,
  "videohref_yt": null,
  "videohref_vm": "https://vimeo.com/184253058",
  "videohref_yt_dmq": "https://www.youtube.com/watch?v=MiovmkYmsLo",
  "videohref_svr": null,
  "title": "Asesinos",
  "synopsis": "Relata la violenta espera de dos hombres que llegan a interrumpir la tranquilidad de un desolado restaurante. Conforme avanza la historia se develan sus oscuras intenciones. La impaciencia crece, los clientes vienen y van, los asesinos, espera.",
  "description": null,
  "awards": null,
  "duration": "15:58",
  "starring": "Guion: Gonzalo Gutiérrez Prado\nMúsica: Daniel Valle Riestra\nFotografía: Roberto Maceda Kohatsu\nReparto: André Silva, Renato Rueda, Atilio Farina, Emanuel Soriano, Carlos Victoria",
  "producer": "Bokeh Films",
  "director": "Gonzalo Gutiérrez Prado",
  "city": null,
  "country": "Perú",
  "year": 2016,
  "thumbnailurl": null,
  "posterurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter%2Fp165.jpg?alt=media&token=2ceff5e7-69db-437c-ac10-241d9382fb18",
  "poster375webpurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter375webp%2Fp165.webp?alt=media&token=d22a78a2-f502-4504-9cf3-c10df48ff430",
  "bgurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fbg%2Fb165.jpg?alt=media&token=374a2d91-d730-4130-9275-70c8418f0c0c",
  "genres": "Thriller, Suspenso",
  "age": "16+",
  "maxres": "1080p",
  "type": "Cortometraje",
  "language": "ES",
  "status": "active",
  "fomo": 1,
  
 },
 {
  "id": 166,
  "videoId_yt": "s5SeWQnZQVA",
  "videoId_vm": null,
  "videoId_yt_dmq": "ebcQJHZxhUI",
  "videoId_svr": null,
  "videohref_yt": "https://www.youtube.com/watch?v=s5SeWQnZQVA",
  "videohref_vm": null,
  "videohref_yt_dmq": "https://www.youtube.com/watch?v=ebcQJHZxhUI",
  "videohref_svr": null,
  "title": "Asfixia",
  "synopsis": "Una mujer está en los últimos momentos de su vida, está siendo asfixiada y no sabe por qué.",
  "description": null,
  "awards": "PERÚ\n1º Festival de Cortometrajes Desde Huancayo 2020\nSeleccionado",
  "duration": "13:07",
  "starring": "Guion: Alejandro C. Romero\nFotografía: Daniel Laureano",
  "producer": "Nhorman Delzo, Pavel Aguirre",
  "director": "Alejandro C. Romero",
  "city": null,
  "country": "Perú",
  "year": 2018,
  "thumbnailurl": null,
  "posterurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter%2Fp166.jpg?alt=media&token=c3f32119-8b9e-4b59-92ce-0176b8bcde57",
  "poster375webpurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter375webp%2Fp166.webp?alt=media&token=7cffb436-339a-4a82-8443-ea1d8a4ae728",
  "bgurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fbg%2Fb166.jpg?alt=media&token=507bcc89-d3b1-44f5-8978-7bb69b611ed7",
  "genres": "Suspenso, Thriller, Acción",
  "age": "16+",
  "maxres": "1080p",
  "type": "Cortometraje",
  "language": "ES",
  "status": "active",
  "fomo": 0,
  
 },
 {
  "id": 167,
  "videoId_yt": "XyUMVRUdX0w",
  "videoId_vm": null,
  "videoId_yt_dmq": "3Wh3Vc72pqg",
  "videoId_svr": null,
  "videohref_yt": "https://www.youtube.com/watch?v=XyUMVRUdX0w",
  "videohref_vm": null,
  "videohref_yt_dmq": "https://www.youtube.com/watch?v=3Wh3Vc72pqg",
  "videohref_svr": null,
  "title": "Así son las cosas",
  "synopsis": "Un chico recuerda los momentos que vivió junto a una chica de la que se enamoró. Él no sabe por qué le fue mal y trata de encontrar alguna respuesta en sus recuerdos.",
  "description": null,
  "awards": null,
  "duration": "08:57",
  "starring": "Reparto: Laura Amasifuén y Manolo Serrano\nGuion: Salvador Pariona",
  "producer": "Cámara En Mano Producciones (Perú)",
  "director": "Salvador Pariona",
  "city": null,
  "country": "Perú",
  "year": 2012,
  "thumbnailurl": null,
  "posterurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter%2Fp167.jpg?alt=media&token=750966dd-09ca-4d48-abd8-0dd95bb2d3a2",
  "poster375webpurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter375webp%2Fp167.webp?alt=media&token=a3aaf710-619b-4092-9d6c-4406ad4b8038",
  "bgurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fbg%2Fb167.jpg?alt=media&token=253bb1cb-df2e-43c7-bda4-96c9c7ef1d43",
  "genres": "Drama",
  "age": "16+",
  "maxres": "1080p",
  "type": "Cortometraje",
  "language": "ES",
  "status": "active",
  "fomo": 0,
  
 },
 {
  "id": 168,
  "videoId_yt": null,
  "videoId_vm": 134168907,
  "videoId_yt_dmq": "W1BnEpOU1Jg",
  "videoId_svr": null,
  "videohref_yt": null,
  "videohref_vm": "https://vimeo.com/134168907",
  "videohref_yt_dmq": "https://www.youtube.com/watch?v=W1BnEpOU1Jg",
  "videohref_svr": null,
  "title": "Astrid y Leonor",
  "synopsis": "Dos mujeres, veinteañeras, van a terapia de pareja. Leonor ha obligado a Astrid. Ella quiere arreglar los problemas de la relación. La otra no. Esta harta del comportamiento tan voluble de Leonor; que nunca supo bien qué hacer de su vida. Luego de estudiar fotografía en París, regresó después de cinco años con el corazón roto. Se mudó sola con la ayuda de sus padres. No hizo nada. A los dos meses, se enamoró perdidamente de Astrid. Se mudaron juntas a los días; cosa que le convenía a Astrid, que se sentía atrapada en su monótona y misia vida de secretaria. Se sentía frustrada porque nunca consiguió trabajo como contadora y, por ayudar a sus padres desde adolescente.",
  "description": null,
  "awards": null,
  "duration": "18:28",
  "starring": "Reparto: Sofía Humala y Yidda Eslava\nGuion: Bruno Alvarado Arévalo\nMaría Luisa Adrianzén",
  "producer": "La Furia Films (Perú)",
  "director": "Bruno Alvarado Arévalo",
  "city": null,
  "country": "Perú",
  "year": 2010,
  "thumbnailurl": null,
  "posterurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter%2Fp168.jpg?alt=media&token=b887594f-b53b-46c1-ad01-9ea858d4a4e0",
  "poster375webpurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter375webp%2Fp168.webp?alt=media&token=4b67f006-d2e4-40a3-a38e-2a122a0523be",
  "bgurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fbg%2Fb168.jpg?alt=media&token=686964a0-38df-4e48-a2ab-5fc3a045db51",
  "genres": "Drama",
  "age": "16+",
  "maxres": "1080p",
  "type": "Cortometraje",
  "language": "ES",
  "status": "active",
  "fomo": 0,
  
 },
 {
  "id": 169,
  "videoId_yt": "cAgfXAdkbe4",
  "videoId_vm": null,
  "videoId_yt_dmq": "Ctyp3Pdu3xs",
  "videoId_svr": null,
  "videohref_yt": "https://www.youtube.com/watch?v=cAgfXAdkbe4",
  "videohref_vm": null,
  "videohref_yt_dmq": "https://www.youtube.com/watch?v=Ctyp3Pdu3xs",
  "videohref_svr": null,
  "title": "Sheut",
  "synopsis": "\"En el Antiguo Egipto, el Sheut era la sombra. No se puede existir sin la sombra, y ella no existe sin la persona.\" Luis es un marino que vive y trabaja en un buque de carga. Un sueño perturbador lo llevará a iniciar un viaje por su pasado, en el cual deberá revivir sus recuerdos y temores para reencontrarse consigo mismo, enfrentándose con su parte más oscura: su sombra.",
  "description": null,
  "awards": "Mejor Fotografía, Mejor Tratamiento de color, Mejor Música Original y Mejor Experimental en el Creation Internacional Film Festival 2019, Estados Unidos.\nMejor actriz de reparto en el Redline International Film Festival 2019, Canadá. Ganadora en Mejor Elenco y Mejor Dirección de arte en el Sensus Film Festival 2019, Rusia.\nMención Honrosa dada por la Asociación Peruana de Prensa Cinematográfica en el Insólito Fest  2019, Perú. \nBest Shortfilm en el Starlight Film Awards Winter Edition 2018, India.\nMejor guion producido, Mejor tratamiento de color, Mejor edición y Mejor maquillaje en el Cinema World Fest 2018, Canadá.\nMejor Dirección, Mejor Producción y Mejor Maquillaje en los Premios Latinos 2018, España.\nBest Experimental en el Alternative Film Festival Summer 2018, Canadá. - Honorable Mention en el Yes! Let’s Make a Movie Film Festival 2018, Canadá.",
  "duration": "15:40",
  "starring": "Reparto: José Luis Ruiz, Brando Gallesi, Norma Martínez, Miguel Medina, Alejandro Zucca.\nGuionista: Teo Belton\nProducción ejecutiva: Alberto Ramirez, Teo Belton\nDirector de Fotografía: Rulo Santivañez\nDirectora de arte: Romina Ortega\nPrimer asistente de dirección: María Claudia Gutiérrez",
  "producer": "Macarena Coello",
  "director": "Teo Belton",
  "city": "Lima",
  "country": "Perú",
  "year": 2020,
  "thumbnailurl": null,
  "posterurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter%2Fp169.jpg?alt=media&token=9842c0d4-657e-48db-9831-98df3227c8c6",
  "poster375webpurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter375webp%2Fp169.webp?alt=media&token=d86935b5-a2ed-4fe4-b070-2557aac815ef",
  "bgurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fbg%2Fb169.jpg?alt=media&token=0cfee8c1-779b-46cc-96fc-f334322a3f8a",
  "genres": "Ficción",
  "age": "16+",
  "maxres": "1080p",
  "type": "Cortometraje",
  "language": "ES",
  "status": "active",
  "fomo": 0,
  
 },
 {
  "id": 170,
  "videoId_yt": "tO4eIce3oyc",
  "videoId_vm": null,
  "videoId_yt_dmq": "aY_UUqXR-Z0",
  "videoId_svr": null,
  "videohref_yt": "https://www.youtube.com/watch?v=tO4eIce3oyc",
  "videohref_vm": null,
  "videohref_yt_dmq": "https://www.youtube.com/watch?v=aY_UUqXR-Z0",
  "videohref_svr": null,
  "title": "Vocatio",
  "synopsis": "Carmela, una monja de aproximadamente 40 años de edad quiere renunciar a su estilo de vida y Olivia, una novicia muy cercana a ella, trata de convencerla a no dejar a su hermandad.",
  "description": null,
  "awards": "Ganadora en Mejor dirección, Mejor guion y Mejor interpretación femenina en Los Premios Latinos 2018, España; en Best Crime Drama en el Harrogate Film Festival 2018, Inglaterra; en Best Crime Drama en el AltFF Alternative Film Festival 2017 en Canadá; y, ganadora del Award of Distinction en el Canada Shorts Film Festival 2017, Canadá.\nSelecciones oficiales: Festival Internacional Cinema da Fronteira 2018, Brasil; Festival Internacional de Cine de San Luis Potosí 2018, México; 16vo Festival de Cortos BogoShorts 2018, Colombia; Festival de Cortometraje Diario El Heraldo 2018, Honduras; 12vo concurso de cortometrajes de Lima Filmocorto 2018, Perú; Concurso Nacional de Cortometrajes de las 3ra semana del cine de la Universidad de Lima, 2018, Perú; Director`s Circle Festival of Shorts 2017, Canadá; Festival Internacional de Cortometrajes Pilas en Corto, 2017, España.",
  "duration": "08:10",
  "starring": "Reparto: Alejandra Guerra Morales; Daniela Trucíos; Enrique Nieto; Eduardo Espejo.\nGuion: Roberto valdivieso\nAsistencia de dirección: Rogger Vergara Adrianzén\nDirección de fotografía: Jorge Cerna.\nDirección de arte: Nataly Vergara Adrianzén\nDiseño de sonido: Juan Torres Tapia\nSonido directo: Willy Ilizarbe Pizarro\nEdición: Guillermo Loli",
  "producer": "Bonnie Vela",
  "director": "Juan Torres Tapia",
  "city": null,
  "country": "Perú",
  "year": 2019,
  "thumbnailurl": null,
  "posterurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter%2Fp170.jpg?alt=media&token=ae12896b-0bf8-44fc-b13e-2d5652ade74e",
  "poster375webpurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter375webp%2Fp170.webp?alt=media&token=4a65e14d-a91e-48ba-8651-b7021eb0e500",
  "bgurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fbg%2Fb170.jpg?alt=media&token=08d07677-6888-4ae9-89f6-73fb3a5df0c1",
  "genres": "Drama",
  "age": "16+",
  "maxres": "1080p",
  "type": "Cortometraje",
  "language": "ES",
  "status": "active",
  "fomo": 0,
  
 },
 {
  "id": 171,
  "videoId_yt": null,
  "videoId_vm": 385998181,
  "videoId_yt_dmq": "1e49_at-lik",
  "videoId_svr": null,
  "videohref_yt": null,
  "videohref_vm": "https://vimeo.com/385998181",
  "videohref_yt_dmq": "https://www.youtube.com/watch?v=1e49_at-lik",
  "videohref_svr": null,
  "title": "AYA",
  "synopsis": "En las montañas de Perú, Juan, un niño de 9 años, trabaja en un salar para poder cumplir el último deseo de su madre así tenga que alejarse de sus propias enseñanzas.",
  "description": null,
  "awards": null,
  "duration": "09:33",
  "starring": "Reparto: Hebert Randy Pillco y Eulalia Saloma\nGuion: Francesca Canepa\nFotografía: Christian Valera\nSonido: Ángel Romero Pacheco\nEdición: Gino Moreno",
  "producer": "Supersonica (Perú)",
  "director": "Francesca Canepa",
  "city": null,
  "country": "Perú",
  "year": 2016,
  "thumbnailurl": null,
  "posterurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter%2Fp171.jpg?alt=media&token=65315d49-3974-4de8-a1da-c29063c43f96",
  "poster375webpurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter375webp%2Fp171.webp?alt=media&token=6f1e9bed-ee48-4a3c-a522-6c6e4c8eccfb",
  "bgurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fbg%2Fb171.jpg?alt=media&token=0d005987-12a2-49ed-a4cf-5a9e372255f0",
  "genres": "Ficción, Drama",
  "age": "16+",
  "maxres": "1080p",
  "type": "Cortometraje",
  "language": "ES",
  "status": "active",
  "fomo": 0,
  
 },
 {
  "id": 172,
  "videoId_yt": "mXVkaScFXOU",
  "videoId_vm": null,
  "videoId_yt_dmq": "QQRNxjSnWAs",
  "videoId_svr": null,
  "videohref_yt": "https://www.youtube.com/watch?v=mXVkaScFXOU",
  "videohref_vm": null,
  "videohref_yt_dmq": "https://www.youtube.com/watch?v=QQRNxjSnWAs",
  "videohref_svr": null,
  "title": "Bajo la cama",
  "synopsis": "El mundo real es mucho más pequeño que el mundo de la imaginación.",
  "description": null,
  "awards": null,
  "duration": "07:00",
  "starring": "Reparto: Jordan Quinto, Jefferson Quinto, Habraham Medrano, Jimena Chavez, José Modesto y Angel Yaranga\nGuion: Angel G. Yaranga Garcia",
  "producer": null,
  "director": "Angel G. Yaranga Garcia",
  "city": null,
  "country": "Perú",
  "year": 2018,
  "thumbnailurl": null,
  "posterurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter%2Fp172.jpg?alt=media&token=bfeab8c9-d3f4-4418-9f1b-637b33911de0",
  "poster375webpurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter375webp%2Fp172.webp?alt=media&token=ff279fe0-c0b3-45b1-806a-308007a35197",
  "bgurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fbg%2Fb172.jpg?alt=media&token=0da8dd4d-fb10-4a74-85a7-c0b965d12708",
  "genres": "Ficción",
  "age": "16+",
  "maxres": "1080p",
  "type": "Cortometraje",
  "language": "ES",
  "status": "active",
  "fomo": 0,
  
 },
 {
  "id": 173,
  "videoId_yt": null,
  "videoId_vm": 557870838,
  "videoId_yt_dmq": "FCvHqM4GVZQ",
  "videoId_svr": null,
  "videohref_yt": null,
  "videohref_vm": "https://vimeo.com/557870838",
  "videohref_yt_dmq": "https://www.youtube.com/watch?v=FCvHqM4GVZQ",
  "videohref_svr": null,
  "title": "Barreto",
  "synopsis": "Mariela es una agente del grupo paramilitar Colina, conformado para desaparecer el terrorismo en el Perú. Su convicción sobre los ideales del grupo empieza a destruirse al hallar pistas de la inocencia de algunas víctimas del grupo. Mariela se ve sumergida en la decisión de delatar la verdad sobre Colina, para así ayudar a encontrar justicia y liberarse de la culpa.",
  "description": null,
  "awards": "PERÚ\n21º Festival de Cine de Lima\nSeleccionado en Muestra Itinerante",
  "duration": "18:26",
  "starring": "Producida por Sebastián Schroth y Sebastián Cáceres\nEscrito por Víctor Arce Arica\nFotografía de Sebastián Pérez\nDiseño de producción de Angélica Dávila y Mayra Villavicencio\nDiseño de sonido por Yazmín Camacho\nEditado por Víctor Arce Arica",
  "producer": null,
  "director": "Michelle Carrasco",
  "city": null,
  "country": "Perú",
  "year": 2018,
  "thumbnailurl": null,
  "posterurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter%2Fp173.jpg?alt=media&token=44b7efda-88d5-4e20-8b91-7b96819af482",
  "poster375webpurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter375webp%2Fp173.webp?alt=media&token=5912aefa-6b70-43c9-8178-fa9bb4fafc03",
  "bgurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fbg%2Fb173.jpg?alt=media&token=7bad7dc8-ca4a-4e21-aab3-e6d92a8d51d4",
  "genres": "Acción, Suspenso",
  "age": "16+",
  "maxres": "1080p",
  "type": "Cortometraje",
  "language": "ES",
  "status": "active",
  "fomo": 0,
  
 },
 {
  "id": 174,
  "videoId_yt": "vmxo845sTuM",
  "videoId_vm": null,
  "videoId_yt_dmq": "l9uc77heyjM",
  "videoId_svr": null,
  "videohref_yt": "https://www.youtube.com/watch?v=vmxo845sTuM",
  "videohref_vm": null,
  "videohref_yt_dmq": "https://www.youtube.com/watch?v=l9uc77heyjM",
  "videohref_svr": null,
  "title": "Bésame mucho",
  "synopsis": "La última aventura de amor de un profesor de música con su alumna preferida.",
  "description": null,
  "awards": null,
  "duration": "10:05",
  "starring": "Reparto: Stephie Jacobs y Enrique Victoria\nGuion: Victor Hugo Awapara Albarracín\n",
  "producer": "Nickelode Producciones (Perú)",
  "director": "Victor Hugo Awapara Albarracín",
  "city": null,
  "country": "Perú",
  "year": 2013,
  "thumbnailurl": null,
  "posterurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter%2Fp174.jpg?alt=media&token=91fb7fcf-64fb-4c86-9bc8-d8a2bbf7d7bc",
  "poster375webpurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter375webp%2Fp174.webp?alt=media&token=cda3edf6-cada-43c7-90f5-ffb371a3ae59",
  "bgurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fbg%2Fb174.jpg?alt=media&token=e4371bad-89d6-4fa5-8733-c5a235c3a95a",
  "genres": "Ficción, Drama",
  "age": "16+",
  "maxres": "1080p",
  "type": "Cortometraje",
  "language": "ES",
  "status": "active",
  "fomo": 0,
  
 },
 {
  "id": 175,
  "videoId_yt": null,
  "videoId_vm": 178522700,
  "videoId_yt_dmq": "5Be7MAFQvuE",
  "videoId_svr": null,
  "videohref_yt": null,
  "videohref_vm": "https://vimeo.com/178522700",
  "videohref_yt_dmq": "https://www.youtube.com/watch?v=5Be7MAFQvuE",
  "videohref_svr": null,
  "title": "Bienvenida",
  "synopsis": "Una adolescente se enfrenta a la indiferencia de su propia familia.",
  "description": null,
  "awards": null,
  "duration": "19:31",
  "starring": "Guion: Jorge Ochoa Rebaza\nFotografía: Jorge Ochoa Rebaza\nEdición: Jorge Ochoa Rebaza",
  "producer": "Jorge Ochoa Rebaza, Illari Orccottoma Mendoza",
  "director": "Jorge Ochoa",
  "city": null,
  "country": "Perú",
  "year": 2016,
  "thumbnailurl": null,
  "posterurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter%2Fp175.jpg?alt=media&token=882059a9-9c6f-4208-8e38-fb10edabb7d2",
  "poster375webpurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter375webp%2Fp175.webp?alt=media&token=ff16255b-5496-48b2-8777-8f9489c01e5a",
  "bgurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fbg%2Fb175.jpg?alt=media&token=53e10526-8a4e-4e1d-b7d9-baf31ab5d988",
  "genres": "Drama, Suspenso",
  "age": "16+",
  "maxres": "1080p",
  "type": "Cortometraje",
  "language": "ES",
  "status": "active",
  "fomo": 0,
  
 },
 {
  "id": 176,
  "videoId_yt": "qASTRN0EXaM",
  "videoId_vm": null,
  "videoId_yt_dmq": "mbsvywSiaTo",
  "videoId_svr": null,
  "videohref_yt": "https://www.youtube.com/watch?v=qASTRN0EXaM",
  "videohref_vm": null,
  "videohref_yt_dmq": "https://www.youtube.com/watch?v=mbsvywSiaTo",
  "videohref_svr": null,
  "title": "Blush",
  "synopsis": "En su cumpleaños una niña quiere maquillarse para estar presentable ante sus amigos, la madre se lo prohíbe argumentando su corta edad. La niña insiste en hacerlo, molestando a su madre y provocando entre ellas una fuerte reyerta.",
  "description": null,
  "awards": "La noche de los cortos 2010\nTercer lugar en Rally peruano de cortometrajes",
  "duration": "06:12",
  "starring": "Reparto: Rebecca Echegaray y Stephanie Sinclair\nGuion: Cristian Cancho",
  "producer": "Producción: INKART Digital (Perú)",
  "director": "Cristian Cancho",
  "city": null,
  "country": "Perú",
  "year": 2010,
  "thumbnailurl": null,
  "posterurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter%2Fp176.jpg?alt=media&token=1777f703-b01a-4a56-bb9b-bf03b6400a8b",
  "poster375webpurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter375webp%2Fp176.webp?alt=media&token=add0d2de-ec79-454f-80cd-61fc42be2394",
  "bgurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fbg%2Fb176.jpg?alt=media&token=f6a06dfb-4284-419e-b6b3-67e0b9e619d3",
  "genres": "Ficción, Drama",
  "age": "16+",
  "maxres": "1080p",
  "type": "Cortometraje",
  "language": "ES",
  "status": "active",
  "fomo": 0,
  
 },
 {
  "id": 177,
  "videoId_yt": null,
  "videoId_vm": 345066480,
  "videoId_yt_dmq": "VDJUh6d9VU0",
  "videoId_svr": null,
  "videohref_yt": null,
  "videohref_vm": "https://vimeo.com/345066480",
  "videohref_yt_dmq": "https://www.youtube.com/watch?v=VDJUh6d9VU0",
  "videohref_svr": null,
  "title": "Bolero Negro",
  "synopsis": "Nos habla de la frustración en la mujer de mediana edad.",
  "description": null,
  "awards": null,
  "duration": "18:31",
  "starring": null,
  "producer": "EPIC - Escuela de Cine y Artes Visuales de Lima",
  "director": "Lucas Belaúnde",
  "city": null,
  "country": "Perú",
  "year": 2017,
  "thumbnailurl": null,
  "posterurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter%2Fp177.jpg?alt=media&token=3bbf2499-ee0d-4494-b1c4-57e5efd7a258",
  "poster375webpurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter375webp%2Fp177.webp?alt=media&token=18c0ea12-91b5-40a6-921e-989aa2ca0a73",
  "bgurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fbg%2Fb177.jpg?alt=media&token=885a0ee1-9094-414f-9bfe-074e3ff361d3",
  "genres": "Drama",
  "age": "16+",
  "maxres": "1080p",
  "type": "Cortometraje",
  "language": "ES",
  "status": "active",
  "fomo": 0,
  
 },
 {
  "id": 178,
  "videoId_yt": "tLarJqD9R-A",
  "videoId_vm": null,
  "videoId_yt_dmq": "L_azKzxbJGE",
  "videoId_svr": null,
  "videohref_yt": "https://www.youtube.com/watch?v=tLarJqD9R-A",
  "videohref_vm": null,
  "videohref_yt_dmq": "https://www.youtube.com/watch?v=L_azKzxbJGE",
  "videohref_svr": null,
  "title": "Camila",
  "synopsis": "Una niña muda vende caramelos y anhela ser bailarina de ballet. Un día, observa en la calle un afiche donde se anuncia la presentación de una bailarina famosa. Motivada por este anuncio, camina por las calles portando en su mochila una cajita musical. En su trajín se encuentra con una bailarina de ballet que baila en la calle y le pide que le enseñe a bailar; sin embargo, ella no entiende y la niña se frustra. Camina hasta volver a ver el afiche de la bailarina. Observa el anuncio hasta que ocurre algo mágico.",
  "description": null,
  "awards": "PERÚ\n22ª Feria Internacional del Libro de Lima 2017",
  "duration": "08:26",
  "starring": null,
  "producer": null,
  "director": "Walter Villanueva Azaña",
  "city": null,
  "country": "Perú",
  "year": 2017,
  "thumbnailurl": null,
  "posterurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter%2Fp178.jpg?alt=media&token=c1877505-a211-49e9-b23a-f414b60ba1fd",
  "poster375webpurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter375webp%2Fp178.webp?alt=media&token=c2882bda-33f6-4e24-9780-e5a8760692ee",
  "bgurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fbg%2Fb178.jpg?alt=media&token=4d405cf7-0f6a-48b4-a443-784b1ed2c9d3",
  "genres": "Ficción, Drama",
  "age": "16+",
  "maxres": "1080p",
  "type": "Cortometraje",
  "language": "ES",
  "status": "active",
  "fomo": 0,
  
 },
 {
  "id": 179,
  "videoId_yt": null,
  "videoId_vm": 259872307,
  "videoId_yt_dmq": "a5nfljHczO0",
  "videoId_svr": null,
  "videohref_yt": null,
  "videohref_vm": "https://vimeo.com/259872307",
  "videohref_yt_dmq": "https://www.youtube.com/watch?v=a5nfljHczO0",
  "videohref_svr": null,
  "title": "Camión de basura",
  "synopsis": "A tres muchachos descarriados un día se les ocurre asaltar un camión de basura y robárselo para empezar a meter ahí a la gente que ellos consideran \"basura\" para la sociedad limeña. Políticos, gente ociosa, periodistas de prensa amarilla, celebridades, hombres, mujeres, ancianos, metaleros, locos, drogadictos y gente en general. Todo termina cuando, por un hecho fortuito, asesinan realmente a una persona. La adrenalina de la noche llega a un punto cero, en el que los muchachos vuelven a casa, no sin antes colocar un asalto final al padre maltratador de la novia de uno de ellos.",
  "description": null,
  "awards": null,
  "duration": "28:07",
  "starring": null,
  "producer": "Tabaco & Loco Films (Perú)",
  "director": "José Carlos García",
  "city": null,
  "country": "Perú",
  "year": 2008,
  "thumbnailurl": null,
  "posterurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter%2Fp179.jpg?alt=media&token=e5bc88e0-9458-4845-873d-a097ff0bcd22",
  "poster375webpurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter375webp%2Fp179.webp?alt=media&token=8f6a26ec-c22b-4b8d-ae88-d251fbab8c6c",
  "bgurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fbg%2Fb179.jpg?alt=media&token=34c8da3f-8ecb-4e30-8620-f1974f99bc68",
  "genres": "Acción, Suspenso",
  "age": "16+",
  "maxres": "1080p",
  "type": "Cortometraje",
  "language": "ES",
  "status": "active",
  "fomo": 0,
  
 },
 {
  "id": 180,
  "videoId_yt": "2Z40BkMPMbs",
  "videoId_vm": null,
  "videoId_yt_dmq": "6AFIMFWSPoM",
  "videoId_svr": null,
  "videohref_yt": "https://www.youtube.com/watch?v=2Z40BkMPMbs",
  "videohref_vm": null,
  "videohref_yt_dmq": "https://www.youtube.com/watch?v=6AFIMFWSPoM",
  "videohref_svr": null,
  "title": "Catarsis",
  "synopsis": "Un hombre agonizante recordara todos sus pecados cometidos, y reflexionara sobre la violencia, la vida y la muerte, durante la época de violencia política interna que se desato en el Perú.",
  "description": null,
  "awards": "PERÚ\n1º Festival de Cortometrajes Desde Huancayo 2020\nSeleccionado",
  "duration": "17:12",
  "starring": "Guion: León Cáceres Torres\nFotografía: León Cáceres Torres\nAsistencia de Cámara: Hugo Palomino Espinoza, Javier Ancalle Vásquez\nAsistencia de Rodaje: Adriana Saqua Manyari\nEdición: Juan Solis",
  "producer": "Hugo Palomino Espinoza",
  "director": "León Caceres Torres",
  "city": null,
  "country": "Perú",
  "year": 2017,
  "thumbnailurl": null,
  "posterurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter%2Fp180.jpg?alt=media&token=ad04cb11-c602-4497-b261-5fa06a028ab0",
  "poster375webpurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter375webp%2Fp180.webp?alt=media&token=5cbd47aa-2c5f-40cb-b27e-c1d24b8ec0c2",
  "bgurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fbg%2Fb180.jpg?alt=media&token=2b975979-d4f9-41e8-9e01-790bafe675ab",
  "genres": "Ficción, Suspenso",
  "age": "16+",
  "maxres": "1080p",
  "type": "Cortometraje",
  "language": "ES",
  "status": "active",
  "fomo": 0,
  
 },
 {
  "id": 181,
  "videoId_yt": null,
  "videoId_vm": 370369421,
  "videoId_yt_dmq": "bdufZnssyzQ",
  "videoId_svr": null,
  "videohref_yt": null,
  "videohref_vm": "https://vimeo.com/370369421",
  "videohref_yt_dmq": "https://www.youtube.com/watch?v=bdufZnssyzQ",
  "videohref_svr": null,
  "title": "Catarsis global",
  "synopsis": "Un chamán y un hacker. Dos formas se complementan para revolucionar desde lo individual hacia lo colectivo. Una crisis global exige una catarsis global.",
  "description": null,
  "awards": "PERÚ • FESTIVAL DE CINE\nCinesuyu 2018\nSeleccionado en Cortometraje Cusqueño\nPERÚ\nMuestra Itinerante del Festival de Cine de Lima 2018",
  "duration": "14:25",
  "starring": null,
  "producer": null,
  "director": "Ludovic Pigeon",
  "city": null,
  "country": "Perú",
  "year": 2017,
  "thumbnailurl": null,
  "posterurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter%2Fp181.jpg?alt=media&token=c588674b-e580-4eb4-820b-a96bf1c55601",
  "poster375webpurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter375webp%2Fp181.webp?alt=media&token=9f24ae2f-482f-429f-b018-a7f2d642897f",
  "bgurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fbg%2Fb181.jpg?alt=media&token=c6bd5169-bbaf-4c7b-8a17-4210f688e3f2",
  "genres": "Ficción, Acción",
  "age": "16+",
  "maxres": "1080p",
  "type": "Cortometraje",
  "language": "ES",
  "status": "active",
  "fomo": 0,
  
 },
 {
  "id": 182,
  "videoId_yt": null,
  "videoId_vm": 279663351,
  "videoId_yt_dmq": "-7Cvqi46kYs",
  "videoId_svr": null,
  "videohref_yt": null,
  "videohref_vm": "https://vimeo.com/279663351",
  "videohref_yt_dmq": "https://www.youtube.com/watch?v=-7Cvqi46kYs",
  "videohref_svr": null,
  "title": "Ch'allaña Uru",
  "synopsis": "Ante la baja producción agrícola, una pareja de esposos aymaras deciden realizar el ritual del “Ch ?allachi”, para ello, se dirigen al “Achachila” más próximo.",
  "description": null,
  "awards": "PERÚ\n22º Festival de Cine de Lima\nSeleccionado en Tierra en Trance",
  "duration": "11:01",
  "starring": "Reparto: Clemente Quispe y Hilaria Catacora\nGuion: Tito Catacora",
  "producer": "American Condors (Puno, Perú)",
  "director": "Tito Catacora",
  "city": null,
  "country": "Perú",
  "year": 2014,
  "thumbnailurl": null,
  "posterurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter%2Fp182.jpg?alt=media&token=009a6dd2-5999-4736-a6c9-f0f007ab4ca0",
  "poster375webpurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter375webp%2Fp182.webp?alt=media&token=660f103d-92d9-4ee6-8803-178717a0b304",
  "bgurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fbg%2Fb182.jpg?alt=media&token=9de0e953-e8ae-402c-9e99-abe0529546ff",
  "genres": "Drama",
  "age": "16+",
  "maxres": "1080p",
  "type": "Cortometraje",
  "language": "ES",
  "status": "active",
  "fomo": 0,
  
 },
 {
  "id": 183,
  "videoId_yt": "rLDmnxpe6NE",
  "videoId_vm": null,
  "videoId_yt_dmq": "QekGHkgGK6s",
  "videoId_svr": null,
  "videohref_yt": "https://www.youtube.com/watch?v=rLDmnxpe6NE",
  "videohref_vm": null,
  "videohref_yt_dmq": "https://www.youtube.com/watch?v=QekGHkgGK6s",
  "videohref_svr": null,
  "title": "Chillido",
  "synopsis": "La historia es una ficción basada en hechos reales de lo acontecido durante la época de violencia librada en territorio nacional por los años 90’s. Esta vez desde la posición de un miembro de seguridad del Estado, sus cuestionadas razones y su lucha interna entre la obediencia y la autocrítica, además de la resistencia a perder su cable a tierra que justificaba en cierta parte y disminuía el pesar de sus acciones.",
  "description": null,
  "awards": null,
  "duration": "16:23",
  "starring": "Reparto: Dante Dioses y Karin Morris\nGuion: Roy Palacios Cortez\nFotografía: Bryan Suárez\nSonido: Juan Diego Ponce",
  "producer": "Nahomi Quiñones, Cabaña Films (Perú)",
  "director": "Roy Palacios Cortez",
  "city": null,
  "country": "Perú",
  "year": 2018,
  "thumbnailurl": null,
  "posterurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter%2Fp183.jpg?alt=media&token=67b95e7e-9550-41c5-b5b5-533187e699a9",
  "poster375webpurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter375webp%2Fp183.webp?alt=media&token=75295127-0d8d-44e4-88e2-fe72326f1023",
  "bgurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fbg%2Fb183.jpg?alt=media&token=b9d4e3e5-b298-4741-9b3e-7f702f8632f2",
  "genres": "Drama, Suspenso",
  "age": "16+",
  "maxres": "1080p",
  "type": "Cortometraje",
  "language": "ES",
  "status": "active",
  "fomo": 0,
  
 },
 {
  "id": 184,
  "videoId_yt": null,
  "videoId_vm": 350019614,
  "videoId_yt_dmq": "mynEPTvAOyM",
  "videoId_svr": null,
  "videohref_yt": null,
  "videohref_vm": "https://vimeo.com/350019614",
  "videohref_yt_dmq": "https://www.youtube.com/watch?v=mynEPTvAOyM",
  "videohref_svr": null,
  "title": "Cicatrices",
  "synopsis": "Tras un accidente, Ernesto se ve forzado a volver a casa y queda atrapado en una vida ajena y rutinaria. Sus sueños frustrados irrumpen en la monotonía de sus días y viejas heridas reclaman ser cicatrizadas.",
  "description": null,
  "awards": null,
  "duration": "14:49",
  "starring": "Reparto: Renzo Quintanilla Chávez\nGuion: Renzo Quintanilla Chávez\nEdición: Walter Manrique Cervantes\nMúsica: Elías Rodríguez Ponce",
  "producer": "Yapa Films (Arequipa, Perú)",
  "director": "Walter Manrique Cervantes",
  "city": "Arequipa",
  "country": "Perú",
  "year": 2019,
  "thumbnailurl": null,
  "posterurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter%2Fp184.jpg?alt=media&token=cb88c2bc-fa4b-4d25-9000-7b32bcf268fc",
  "poster375webpurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter375webp%2Fp184.webp?alt=media&token=480ca6fa-6dbb-4428-aed9-758dc3353f61",
  "bgurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fbg%2Fb184.jpg?alt=media&token=c1b7f9e1-2fcf-4ef5-ab27-129dfdcf88b3",
  "genres": "Ficción, Drama",
  "age": "16+",
  "maxres": "1080p",
  "type": "Cortometraje",
  "language": "ES",
  "status": "active",
  "fomo": 0,
  
 },
 {
  "id": 185,
  "videoId_yt": "as_0iNqqdDo",
  "videoId_vm": null,
  "videoId_yt_dmq": "t8rKoK2QWII",
  "videoId_svr": null,
  "videohref_yt": "https://www.youtube.com/watch?v=as_0iNqqdDo",
  "videohref_vm": null,
  "videohref_yt_dmq": "https://www.youtube.com/watch?v=t8rKoK2QWII",
  "videohref_svr": null,
  "title": "Como un reflejo",
  "synopsis": "Hace varios meses que el sol dejó de verse en la ciudad. Reina la oscuridad y la sociedad está en la confusión más absoluta. Aurelio, psicólogo de profesión y padre soltero, trata de no volverse loco y todo parece un reflejo de lo que le pasa.",
  "description": "Jorge Pablo Quiroz (director de cine) y Jose Antonio Muñoz (psicólogo) se juntaron para escribir la Trilogía: Los 3 Eslabones de la Involución, compuesta por dos cortometrajes y un documental. Estas tres historias exploran distintos aspectos de la conducta humana y se cuestiona si estamos evolucionando o involucionando como especie. La Presencia de la Ausencia es el primer cortometraje. El segundo episodio, Como un Reflejo, ha sido premiado en el Concurso Nacional de Cortometrajes (2017) por el Ministerio de Cultura del Perú. El documental, Camino a la Extinción se encuentra en su etapa de distribución.",
  "awards": "PERÚ\nMuestra Itinerante del Festival de Cine de Lima 2018",
  "duration": "20:00",
  "starring": "Guion: Jorge Pablo Quiroz Salem y José Antonio Muñoz.",
  "producer": "Edith Nako.",
  "director": "Jorge Quiróz y José Antonio Muñoz",
  "city": null,
  "country": "Perú",
  "year": 2017,
  "thumbnailurl": null,
  "posterurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter%2Fp185.jpg?alt=media&token=4f05eb1a-47a8-45c3-9895-d0927d59055f",
  "poster375webpurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter375webp%2Fp185.webp?alt=media&token=70caa072-30c1-4cf7-8a66-44229e84fcff",
  "bgurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fbg%2Fb185.jpg?alt=media&token=d40caa33-4a9e-48b8-85d3-551104ec4849",
  "genres": "Ficción, Suspenso, Acción",
  "age": "16+",
  "maxres": "1080p",
  "type": "Cortometraje",
  "language": "ES",
  "status": "active",
  "fomo": 0,
  
 },
 {
  "id": 186,
  "videoId_yt": null,
  "videoId_vm": 169389718,
  "videoId_yt_dmq": "loIFy1XP234",
  "videoId_svr": null,
  "videohref_yt": null,
  "videohref_vm": "https://vimeo.com/169389718",
  "videohref_yt_dmq": "https://www.youtube.com/watch?v=loIFy1XP234",
  "videohref_svr": null,
  "title": "Conciencia",
  "synopsis": "A veces las personas que menos esperas, hacen lo que nunca imaginaste, acciones que duran tan sólo unos segundos, pero que llevarán encima por siempre. Es entonces cuando tu conciencia lo único que puede hacer es sentarse, y mirar.",
  "description": null,
  "awards": "ESPAÑA\nFestival Iberoamericano de Cortometrajes ABC 2017\nSeleccionado",
  "duration": "05:58",
  "starring": "Reparto: Estefanía Fuxet y Roberto Palacios\nFotografía: Diego Vega Cotrina",
  "producer": "Delavega (Arequipa, Perú)",
  "director": "Diego Vega Cotrina",
  "city": "Arequipa",
  "country": "Perú",
  "year": 2016,
  "thumbnailurl": null,
  "posterurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter%2Fp186.jpg?alt=media&token=d90cb201-ee5f-4112-aff8-4a5e80361217",
  "poster375webpurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter375webp%2Fp186.webp?alt=media&token=2d3cba4e-d9ce-4cab-b1cc-ef348d774124",
  "bgurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fbg%2Fb186.jpg?alt=media&token=6aef8381-495b-4c5d-b68a-52cc027a9e0d",
  "genres": "Ficción, Drama",
  "age": "16+",
  "maxres": "1080p",
  "type": "Cortometraje",
  "language": "ES",
  "status": "active",
  "fomo": 0,
  "Column31": "https://www.facebook.com/dlvegas/"
 },
 {
  "id": 187,
  "videoId_yt": null,
  "videoId_vm": 66704489,
  "videoId_yt_dmq": "qgq97DqWQBQ",
  "videoId_svr": null,
  "videohref_yt": null,
  "videohref_vm": "https://vimeo.com/66704489",
  "videohref_yt_dmq": "https://www.youtube.com/watch?v=qgq97DqWQBQ",
  "videohref_svr": null,
  "title": "Consuelo",
  "synopsis": "La historia de una joven bailarina peruana tratando de perseguir sus sueños en el duro mundo de la ciudad más grande de la selva amazónica.",
  "description": null,
  "awards": null,
  "duration": "18:56",
  "starring": "Reparto: Consuelo Suarez, Juan Fernandez y María Vera\nGuion: Paul Johnson Rojas",
  "producer": "Say Goodnight Films (Perú)",
  "director": "Paul Johnson Rojas",
  "city": null,
  "country": "Perú",
  "year": 2013,
  "thumbnailurl": null,
  "posterurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter%2Fp187.jpg?alt=media&token=59633c0a-5b80-40aa-b7d3-6e816a45e6d5",
  "poster375webpurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter375webp%2Fp187.webp?alt=media&token=e1deb589-417b-478d-90fd-931967645d94",
  "bgurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fbg%2Fb187.jpg?alt=media&token=adaf1ea6-2e39-449c-af17-0bc30213193d",
  "genres": "Drama",
  "age": "16+",
  "maxres": "1080p",
  "type": "Cortometraje",
  "language": "ES",
  "status": "active",
  "fomo": 0,
  
 },
 {
  "id": 188,
  "videoId_yt": "Vrswf5KK0Yw",
  "videoId_vm": null,
  "videoId_yt_dmq": "fAiw_zO4GbI",
  "videoId_svr": null,
  "videohref_yt": "https://www.youtube.com/watch?v=Vrswf5KK0Yw",
  "videohref_vm": null,
  "videohref_yt_dmq": "https://www.youtube.com/watch?v=fAiw_zO4GbI",
  "videohref_svr": null,
  "title": "Corazón delator",
  "synopsis": "Rómulo vive cada día al cuidado de un anciano al que estima muchísimo. Este cariño entra en conflicto con la aberración que empieza a sentir al obsesionarse con el ojo enfermo del abuelo, a quien planea matar, luego de vigilarlo varias noches mientras duerme. Rómulo relata con mucha ansiedad cada parte del macabro plan, hasta que una visita inesperada amenaza con revelar el crimen que acaba de cometer.",
  "description": null,
  "awards": null,
  "duration": "22:29",
  "starring": "Reparto: Enrique Victoria, Juan Carlos Pastor, Bárbara Mazzochi, Ricardo Mejía y Fernando Vázquez\nGuion: Claudio Ceino Gordillo",
  "producer": null,
  "director": "Claudio Ceino Gordillo",
  "city": null,
  "country": "Perú",
  "year": 2011,
  "thumbnailurl": null,
  "posterurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter%2Fp188.jpg?alt=media&token=caca453b-a933-47e2-9d0a-3ba3d56f4c90",
  "poster375webpurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter375webp%2Fp188.webp?alt=media&token=50bfed9a-d32c-46b8-b8fa-93569ac1786c",
  "bgurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fbg%2Fb188.jpg?alt=media&token=002703c5-46a7-4686-b45b-cf4de763ccbd",
  "genres": "Drama, Suspenso",
  "age": "16+",
  "maxres": "1080p",
  "type": "Cortometraje",
  "language": "ES",
  "status": "active",
  "fomo": 0,
  
 },
 {
  "id": 189,
  "videoId_yt": null,
  "videoId_vm": 181426562,
  "videoId_yt_dmq": "xfjHOqEnQsU",
  "videoId_svr": null,
  "videohref_yt": null,
  "videohref_vm": "https://vimeo.com/181426562",
  "videohref_yt_dmq": "https://www.youtube.com/watch?v=xfjHOqEnQsU",
  "videohref_svr": null,
  "title": "Cuéntame de ti",
  "synopsis": "Lucy ha dejado de ir a la escuela. Thomas, su mejor amigo, tratará de buscar la respuesta buscándola; pero sobre todo aclarar sus sentimientos confusos por ella.",
  "description": null,
  "awards": "PERÚ\n2º Semana del Cine 2016\nSeleccionado en Concurso Nacional de Cortometrajes\nPERÚ\n3º Festival de Cine de Trujillo 2016\nSeleccionado en Cortometrajes Categoría Ficción",
  "duration": "14:50",
  "starring": null,
  "producer": null,
  "director": "Alexander Márquez",
  "city": null,
  "country": "Perú",
  "year": 2016,
  "thumbnailurl": null,
  "posterurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter%2Fp189.jpg?alt=media&token=11f317fb-4779-4d8b-9e6c-cec9686e4348",
  "poster375jpgurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter375jpg%2Fp189.jpg?alt=media&token=b5b432ab-e234-42ba-9d07-46295ca9ec7c",
  "bgurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fbg%2Fb189.jpg?alt=media&token=b0926dfb-2384-4c85-b0f5-09d01dfae3e7",
  "genres": "Ficción, Acción, Suspenso",
  "age": "16+",
  "maxres": "1080p",
  "type": "Cortometraje",
  "language": "ES",
  "status": "active",
  "fomo": 0,
  
 },
 {
  "id": 190,
  "videoId_yt": null,
  "videoId_vm": 158983592,
  "videoId_yt_dmq": "fd6w5dqDU_M",
  "videoId_svr": null,
  "videohref_yt": null,
  "videohref_vm": "https://vimeo.com/158983592",
  "videohref_yt_dmq": "https://www.youtube.com/watch?v=fd6w5dqDU_M",
  "videohref_svr": null,
  "title": "Danubio azul",
  "synopsis": "Poco después que Martina ha sido desalojada de su casa, su novio termina con ella. Con tantos eventos desafortunados, Martina decide ir al abismo más cercano y terminar con su vida, pero ahí encontrará la inesperada presencia de un hombre mayor que también está a punto de matarse.",
  "description": null,
  "awards": "PERÚ\n2º Semana del Cine 2016\nSeleccionado en Concurso Nacional de Cortometrajes\nPERÚ\n3º Festival de Cine de Trujillo 2016\nSeleccionado en Cortometrajes Categoría Ficción\nPERÚ • FESTIVAL DE CINE\nCinesuyu 2016\nSeleccionado en Cortometrajes\nPERÚ\n20º Festival de Cine de Lima\nSeleccionado en Muestra Itinerante\nPERÚ\nFilmocorto 2016\nMención Especial\nFRANCIA\nFestival de Cannes 2016\nParticipante en Short Film Corner",
  "duration": "20:00",
  "starring": "Reparto: Fiorella Pennano, Nicolás Galindo y Germán González\nGuion: Jonatan Medina Espinal",
  "producer": "Roi Films (Perú)",
  "director": "Jonatan Medina Espinal",
  "city": null,
  "country": "Perú",
  "year": 2016,
  "thumbnailurl": null,
  "posterurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter%2Fp190.jpg?alt=media&token=019fa9fb-74df-4e78-95e5-2cabf7abc655",
  "poster375jpgurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter375jpg%2Fp190.jpg?alt=media&token=c4b92cdf-b145-47da-86c0-8423a08a2c00",
  "bgurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fbg%2Fb190.jpg?alt=media&token=dcfb57b6-bba4-43c1-bfa1-4e699e0b153c",
  "genres": "Drama, Suspenso",
  "age": "16+",
  "maxres": "1080p",
  "type": "Cortometraje",
  "language": "ES",
  "status": "active",
  "fomo": 0,
  
 },
 {
  "id": 191,
  "videoId_yt": "3wQtTgLbtrM",
  "videoId_vm": null,
  "videoId_yt_dmq": "fElAer5oljI",
  "videoId_svr": null,
  "videohref_yt": "https://www.youtube.com/watch?v=3wQtTgLbtrM",
  "videohref_vm": null,
  "videohref_yt_dmq": "https://www.youtube.com/watch?v=fElAer5oljI",
  "videohref_svr": null,
  "title": "Dasein",
  "synopsis": "Manuel es un joven quien ha perdido el rumbo de su vida, toma la decisión de vender sus pertenencias para poder seguir pagando su cuarto en Lima, alimentos y cigarrillos; ese acto lo hace re-descubrir su vieja cámara que posee un video que tocaré sentimientos encontrados en él.",
  "description": null,
  "awards": null,
  "duration": "11:50",
  "starring": "Reparto: Fernando Cárdenas y Pedro Delacruz\nGuion: Luis Narbasta\nFotografía: Luis Narbasta, Cristina Abigail Jara Llanos\nCámara: Luis Narbasta, Karla Nicol Marín Durand\nSonido: Alessandra Ortiz\nArte: Vanessa Andrea Tamayo Sanchez, Cristina Abigail Jara Llanos\nEdición: Luis Narbasta",
  "producer": "Filmestesia (Perú)",
  "director": "Luis Narbasta",
  "city": null,
  "country": "Perú",
  "year": 2020,
  "thumbnailurl": null,
  "posterurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter%2Fp191.jpg?alt=media&token=1cd42978-b780-4120-9329-95b1898bed8f",
  "poster375webpurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter375webp%2Fp191.webp?alt=media&token=ed0b913a-5a54-4fa1-bfbd-ca42d131cdb1",
  "bgurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fbg%2Fb191.jpg?alt=media&token=8a6d637a-2c2e-4268-9eea-827a5327f47f",
  "genres": "Drama",
  "age": "16+",
  "maxres": "1080p",
  "type": "Cortometraje",
  "language": "ES",
  "status": "active",
  "fomo": 0,
  
 },
 {
  "id": 192,
  "videoId_yt": "Zyb5vmKZp80",
  "videoId_vm": null,
  "videoId_yt_dmq": "JcIrQWBdB4U",
  "videoId_svr": null,
  "videohref_yt": "https://www.youtube.com/watch?v=Zyb5vmKZp80",
  "videohref_vm": null,
  "videohref_yt_dmq": "https://www.youtube.com/watch?v=JcIrQWBdB4U",
  "videohref_svr": null,
  "title": "Deseos encontrados",
  "synopsis": "Natalia está enamorada de su mejor amiga pero todo juega en su contra.",
  "description": null,
  "awards": null,
  "duration": "10:01",
  "starring": "Reparto: Katia Salazar Miranda y Karina Jordán\nGuion: Andrea Matta",
  "producer": null,
  "director": "Sergio Guerra",
  "city": null,
  "country": "Perú",
  "year": 2013,
  "thumbnailurl": null,
  "posterurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter%2Fp192.jpg?alt=media&token=297104f0-6b05-4a9a-847d-58d1d427e0d9",
  "poster375jpgurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter375jpg%2Fp192.jpg?alt=media&token=0a0dc59f-c808-4098-8512-100da6d5d5b2",
  "bgurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fbg%2Fb192.jpg?alt=media&token=febe9984-34eb-4ee1-a330-7a1a57ce5b0d",
  "genres": "Drama",
  "age": "16+",
  "maxres": "1080p",
  "type": "Cortometraje",
  "language": "ES",
  "status": "active",
  "fomo": 0,
  
 },
 {
  "id": 193,
  "videoId_yt": null,
  "videoId_vm": 43049074,
  "videoId_yt_dmq": "vXA6W0Qp9ZQ",
  "videoId_svr": null,
  "videohref_yt": null,
  "videohref_vm": "https://vimeo.com/43049074",
  "videohref_yt_dmq": "https://www.youtube.com/watch?v=vXA6W0Qp9ZQ",
  "videohref_svr": null,
  "title": "Desnúdame",
  "synopsis": "Hans representa a una modista obsesionada con la belleza perfecta. Claudia es una mujer joven cuyo único objetivo es llenar sus deficiencias. Los dos personajes estarán en un espacio que fundirá sus deseos más profundos.",
  "description": null,
  "awards": null,
  "duration": "20:48",
  "starring": "Reparto: María Cristina Alegría y Enrique Victoria\nGuion: César Bustos, Aldo Li",
  "producer": "Melmac Films (Perú)",
  "director": "César Bustos y Aldo Li",
  "city": null,
  "country": "Perú",
  "year": 2011,
  "thumbnailurl": null,
  "posterurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter%2Fp193.jpg?alt=media&token=e872f64a-c1b2-4c84-af3b-69a2a27979dd",
  "poster375jpgurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter375jpg%2Fp193.jpg?alt=media&token=0f743836-1df9-46b2-9c22-c016ed9607c6",
  "bgurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fbg%2Fb193.jpg?alt=media&token=bbf816cc-fd27-4ab6-b0ee-63e2c700f096",
  "genres": "Suspenso, Drama",
  "age": "16+",
  "maxres": "1080p",
  "type": "Cortometraje",
  "language": "ES",
  "status": "active",
  "fomo": 0,
  
 },
 {
  "id": 194,
  "videoId_yt": "JuR0mx9dDWk",
  "videoId_vm": null,
  "videoId_yt_dmq": "Pz-NVxPkv2U",
  "videoId_svr": null,
  "videohref_yt": "https://www.youtube.com/watch?v=JuR0mx9dDWk",
  "videohref_vm": null,
  "videohref_yt_dmq": "https://www.youtube.com/watch?v=Pz-NVxPkv2U",
  "videohref_svr": null,
  "title": "Después de ti",
  "synopsis": "Tras quince años sin verse, Santiago recibe la visita de Mateo, su mejor amigo del Colegio con la excusa de un reencuentro de promoción. Revelando historias del pasado pondrán en juego una amistad a seguir.",
  "description": null,
  "awards": "PERÚ\nOUTFESTPERÚ Festival de Cine Gay, Lésbico, Trans, Bi 2019\nSeleccionado en Cortometrajes",
  "duration": "08:37",
  "starring": "Reparto: Jean Pierre Díaz y Aróm Vega\nGuion: Fernando Villena\nFotografía: Caoba Echagüe\nSonido: Natalia Fanola\nArte: Jema Portocarrero",
  "producer": "Fabricio Concha, Sativa Films (Perú)",
  "director": "Fernando Villena",
  "city": null,
  "country": "Perú",
  "year": 2019,
  "thumbnailurl": null,
  "posterurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter%2Fp194.jpg?alt=media&token=d2e9f83d-378c-4819-a4d6-c78272559c6e",
  "poster375webpurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter375webp%2Fp194.webp?alt=media&token=2a33c188-3543-4626-822e-374682f5a778",
  "bgurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fbg%2Fb194.jpg?alt=media&token=64d6c82d-9c89-480a-81a7-41000ac28223",
  "genres": "Ficción, Drama",
  "age": "16+",
  "maxres": "1080p",
  "type": "Cortometraje",
  "language": "ES",
  "status": "none",
  "fomo": 0,
  
 },
 {
  "id": 195,
  "videoId_yt": "1w2lZyjCSZE",
  "videoId_vm": null,
  "videoId_yt_dmq": "zWw_TaRDg3M",
  "videoId_svr": null,
  "videohref_yt": "https://www.youtube.com/watch?v=1w2lZyjCSZE",
  "videohref_vm": null,
  "videohref_yt_dmq": "https://www.youtube.com/watch?v=zWw_TaRDg3M",
  "videohref_svr": null,
  "title": "Detrás de la ventana",
  "synopsis": "Iván es un invidente que vive junto a su esposa Mercedes. Ambos viven una vida tranquila y sosegada, pero un suceso estará a punto de cambiar sus vidas. Iván se someterá a una operación que le devolverá la vista, algo que él ha deseado siempre, sin embargo poco a poco irá descubriendo que el mundo que está por ver esconde un oscuro secreto.",
  "description": null,
  "awards": null,
  "duration": "23:13",
  "starring": "Reparto: Carlos Gassols y Sonia Seminario\nGuion: Josué Chávez",
  "producer": "Haba's Films (Perú), Universidad de Lima (Perú)",
  "director": "Josué Chávez",
  "city": null,
  "country": "Perú",
  "year": 2009,
  "thumbnailurl": null,
  "posterurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter%2Fp195.jpg?alt=media&token=d6ea86b6-0e36-4910-a8af-b881aee1eb93",
  "poster375webpurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter375webp%2Fp195.webp?alt=media&token=e0c625d0-7813-4156-ab42-55a1a2978ad6",
  "bgurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fbg%2Fb195.jpg?alt=media&token=057c6bc0-05a4-4984-8f29-0707c10cd53a",
  "genres": "Drama",
  "age": "16+",
  "maxres": "1080p",
  "type": "Cortometraje",
  "language": "ES",
  "status": "active",
  "fomo": 0,
  
 },
 {
  "id": 196,
  "videoId_yt": null,
  "videoId_vm": 45270841,
  "videoId_yt_dmq": "BKQCRJctEvQ",
  "videoId_svr": null,
  "videohref_yt": null,
  "videohref_vm": "https://vimeo.com/45270841",
  "videohref_yt_dmq": "https://www.youtube.com/watch?v=BKQCRJctEvQ",
  "videohref_svr": null,
  "title": "Detrás del espejo",
  "synopsis": "Un joven esposo y futuro padre, dirige un motel de mala muerte con su esposa. Para ganarse la vida recurre a medidas cuestionables. Cuando encuentra muerta a una de las huéspedes se hallará entre la espada y la pared.",
  "description": null,
  "awards": "2012 Palm Springs Short Fest - BEST OF THE FEST\n2012 Festival de La Habana - JURY PRIZE\n2012 Zinebi - Bilbao Film Festival - BEST LATIN AMERICAN FILM\n2013 Nashville Film Festival - HONORABLE MENTION FOR BEST STUDENT SHORT\n2012 DGA Student Awards - JURY PRIZE / WEST REGION",
  "duration": "12:38",
  "starring": "Reparto: Marcello Rivera, Mayella Lloclla, Carlos Cano y Susan León\nGuion: Julio O. Ramos",
  "producer": "Andino Films Producciones (Lima, Perú)",
  "director": "Julio O. Ramos",
  "city": null,
  "country": "Perú",
  "year": 2012,
  "thumbnailurl": null,
  "posterurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter%2Fp196.jpg?alt=media&token=59387489-f39b-4eff-b72b-78cafeaa9a0e",
  "poster375webpurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter375webp%2Fp196.webp?alt=media&token=b02521ca-cd92-4b87-9fc8-de05a18fa316",
  "bgurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fbg%2Fb196.jpg?alt=media&token=9a90df34-e92c-49e4-a4e6-f4fed524c28a",
  "genres": "Suspenso, Thriller, Acción",
  "age": "16+",
  "maxres": "1080p",
  "type": "Cortometraje",
  "language": "ES",
  "status": "none",
  "fomo": 0,
  
 },
 {
  "id": 197,
  "videoId_yt": null,
  "videoId_vm": 57261630,
  "videoId_yt_dmq": "se_W6J22RiM",
  "videoId_svr": null,
  "videohref_yt": null,
  "videohref_vm": "https://vimeo.com/57261630",
  "videohref_yt_dmq": "https://www.youtube.com/watch?v=se_W6J22RiM",
  "videohref_svr": null,
  "title": "Domingo",
  "synopsis": "Una relación de pareja sumida en el egoísmo y el machismo puede estar cargada, tal vez sin darnos cuenta, de indiferencia y humillación.",
  "description": null,
  "awards": null,
  "duration": "08:12",
  "starring": "Reparto: Rómulo Assereto y Urpi Gibbons\nGuion: Rodrigo Moreno del Valle",
  "producer": "Consuelo Cine (Perú)",
  "director": "Rodrigo Moreno del Valle",
  "city": null,
  "country": "Perú",
  "year": 2010,
  "thumbnailurl": null,
  "posterurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter%2Fp197.jpg?alt=media&token=a7fce176-ed15-42fa-add4-6b6f6212eec0",
  "poster375webpurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter375webp%2Fp197.webp?alt=media&token=0c55a35c-3e2d-47db-ba88-0d223d0e3409",
  "bgurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fbg%2Fb197.jpg?alt=media&token=88c33edb-d6b6-49eb-b1db-71880bac5e37",
  "genres": "Drama",
  "age": "16+",
  "maxres": "1080p",
  "type": "Cortometraje",
  "language": "ES",
  "status": "active",
  "fomo": 0,
  
 },
 {
  "id": 198,
  "videoId_yt": null,
  "videoId_vm": 174397691,
  "videoId_yt_dmq": "89Zdh__Qm2M",
  "videoId_svr": null,
  "videohref_yt": null,
  "videohref_vm": "https://vimeo.com/174397691",
  "videohref_yt_dmq": "https://www.youtube.com/watch?v=89Zdh__Qm2M",
  "videohref_svr": null,
  "title": "Donde duermen los sueños",
  "synopsis": "Diego es un arquitecto que intenta superar la muerte de su pareja. Para esto recurre a una técnica de intervención psicológica en la que se proyecta junto a su pareja durante sus últimos momentos de vida.",
  "description": null,
  "awards": "PERÚ\n21º Festival de Cine de Lima\nSeleccionado en Muestra Itinerante\nPERÚ\nFilmocorto 2017\nSeleccionado\nPERÚ\n2º Semana del Cine 2016\nSeleccionado en Concurso Nacional de Cortometrajes",
  "duration": "11:00",
  "starring": "Reparto: Sebastián Monteghirfo, Cécica Bernasconi y Carlos Mesta\nGuion: Daniel Riglos Flores, Andrea Hoyos\nFotografía: Roberto Maceda Kohatsu D.F.P.\nSonido: Willy Ilizarbe\nEdición: Víctor Hugo Gámez",
  "producer": null,
  "director": "Daniel Riglos Flores",
  "city": null,
  "country": "Perú",
  "year": 2016,
  "thumbnailurl": null,
  "posterurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter%2Fp198.jpg?alt=media&token=5e790d18-6e36-474a-a59a-40cd7833bff4",
  "poster375webpurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter375webp%2Fp198.webp?alt=media&token=fdeea764-df46-4cd3-8957-2009c9be6acb",
  "bgurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fbg%2Fb198.jpg?alt=media&token=ac5f05d6-77d9-451c-8238-e14e5a8e3a30",
  "genres": "Drama, Suspenso",
  "age": "16+",
  "maxres": "1080p",
  "type": "Cortometraje",
  "language": "ES",
  "status": "active",
  "fomo": 0,
  
 },
 {
  "id": 199,
  "videoId_yt": "z9JddF1T-kY",
  "videoId_vm": null,
  "videoId_yt_dmq": "d4JP12BZJRs",
  "videoId_svr": null,
  "videohref_yt": "https://www.youtube.com/watch?v=z9JddF1T-kY",
  "videohref_vm": null,
  "videohref_yt_dmq": "https://www.youtube.com/watch?v=d4JP12BZJRs",
  "videohref_svr": null,
  "title": "J A N E",
  "synopsis": "Después de tu muerte, serás lo que fuiste antes de nacer. ― Arthur Schopenhauer\nAfter your death, you will be what you were before your birth.― Arthur Schopenhauer",
  "description": null,
  "awards": null,
  "duration": "08:34",
  "starring": "Actores: Diana Chávez, Fabrizio Aguilar\nGuionista: Sebastián Plasencia Gutiérrez\nAsistente de dirección: Ursula Coello\nProductores ejecutivos: Kevin Vera y Sebastián Plasencia\nDirector de fotografía: Gonzalo Valdez\nAsistente de iluminación: Eduardo Mendoza\nDirectoras de arte: Catherine Acosta y Ericka García\nSonido directo: Andrea Varon\nEditores: Sebastián Plasencia y Ursula Coello\nVFX: Sebastián Plasencia\nColorización: José Peñafiel\nFoley: Ericka García y Sebastián Plasencia",
  "producer": "Andrea Varon y Valentín Viacava",
  "director": "Sebastián Plasencia Gutiérrez",
  "city": null,
  "country": "Perú",
  "year": 2019,
  "thumbnailurl": null,
  "posterurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter%2Fp199.jpg?alt=media&token=6eb52170-b73d-4fc1-8132-923ed03f85d5",
  "poster375webpurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter375webp%2Fp199.webp?alt=media&token=34cfd3aa-28de-418d-801b-2b12c8b0e290",
  "bgurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fbg%2Fb199.jpg?alt=media&token=ffb376b2-6742-42d6-aa11-92d6e5ba889a",
  "genres": "Drama, Suspenso",
  "age": "16+",
  "maxres": "1080p",
  "type": "Cortometraje",
  "language": "ES",
  "status": "active",
  "fomo": 1,
  
 },
 {
  "id": 200,
  "videoId_yt": null,
  "videoId_vm": 589711754,
  "videoId_yt_dmq": "e8tmxR2ovug",
  "videoId_svr": null,
  "videohref_yt": null,
  "videohref_vm": "https://vimeo.com/589711754",
  "videohref_yt_dmq": "https://www.youtube.com/watch?v=e8tmxR2ovug",
  "videohref_svr": null,
  "title": "Donde la luz no llega",
  "synopsis": "Lima, 1988. Dos hermanos se quedan solos en su casa mientras hay un apagón. Uno es asmático, y el otro, le tiene miedo a lo que se esconde en la oscuridad.",
  "description": null,
  "awards": null,
  "duration": "14:53",
  "starring": "Reparto: Diego Müller, Facundo Vásquez de Velasco y Karina Jordán",
  "producer": null,
  "director": "Sebastián Plasencia",
  "city": null,
  "country": "Perú",
  "year": 2020,
  "thumbnailurl": null,
  "posterurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter%2Fp200.jpg?alt=media&token=86ebcbd8-4d16-4f93-9382-38258274356d",
  "poster375webpurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter375webp%2Fp200.webp?alt=media&token=768dbff7-d0cd-4b08-abb7-7ba05cf01d25",
  "bgurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fbg%2Fb200.jpg?alt=media&token=122acb0d-0628-4b5c-9ee0-36346e096a96",
  "genres": "Suspenso, Thriller, Terror",
  "age": "16+",
  "maxres": "1080p",
  "type": "Cortometraje",
  "language": "ES",
  "status": "active",
  "fomo": 1,
  
 },
 {
  "id": 201,
  "videoId_yt": "3XEPsY8etbc",
  "videoId_vm": null,
  "videoId_yt_dmq": "7sjG4KGWnNg",
  "videoId_svr": null,
  "videohref_yt": "https://www.youtube.com/watch?v=3XEPsY8etbc",
  "videohref_vm": null,
  "videohref_yt_dmq": "https://www.youtube.com/watch?v=7sjG4KGWnNg",
  "videohref_svr": null,
  "title": "Donde viven los descalzos",
  "synopsis": "Bruno es un pequeño de 8 años con muchas dudas respecto al motivo por el cual su padre lo abandonó, sin embargo, un día el padre decide regresar para aclararle la verdadera razón por la que se fue de la casa.",
  "description": null,
  "awards": "Terror Fest Peru 2014\nSeleccionado en Selección Oficial\nFeria Internacional de Cine de Manizales 2014\nSeleccionado en Sección Oficial\nClifton Film Fest 2014\nMejor sonido\nPERÚ\nXI Festival Internacional de Cortometrajes FENACO Cusco Perú\nSeleccionado en Categoría Nacional",
  "duration": "10:01",
  "starring": "Reparto: Oscar Ludeña, Bruno Simon, Lourdes Castillo\nGuion: Gianmarco Castillo\nFotografía: Renato Deza\nSonido: Dayana Cervantes\nArte: Kriss Olivera, Daniela Castillo\nEdición: Gianmarco Castillo\nProducción: Belén Farje",
  "producer": null,
  "director": "Gianmarco Castillo",
  "city": null,
  "country": "Perú",
  "year": 2014,
  "thumbnailurl": null,
  "posterurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter%2Fp201.jpg?alt=media&token=4bd2824c-0e7f-4205-add6-ea9a390f7970",
  "poster375webpurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter375webp%2Fp201.webp?alt=media&token=ebd09817-c691-470f-8d9b-de8b3070d4a9",
  "bgurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fbg%2Fb201.jpg?alt=media&token=e05eb71e-cdda-402c-881c-a7466f23f4c6",
  "genres": "Drama",
  "age": "16+",
  "maxres": "1080p",
  "type": "Cortometraje",
  "language": "ES",
  "status": "active",
  "fomo": 0,
  
 },
 {
  "id": 202,
  "videoId_yt": null,
  "videoId_vm": 312775040,
  "videoId_yt_dmq": "0IHod9TCCiI",
  "videoId_svr": null,
  "videohref_yt": null,
  "videohref_vm": "https://vimeo.com/312775040",
  "videohref_yt_dmq": "https://www.youtube.com/watch?v=0IHod9TCCiI",
  "videohref_svr": null,
  "title": "Ecos del pasado",
  "synopsis": "Un hijo visita a su anciana y enferma madre, sin embargo, no todo sale como planeado.",
  "description": null,
  "awards": "PERÚ\nFestival de Cine Universitario Render 2019\nSeleccionado en Selección Oficial Lima\nCortometraje seleccionado y finalista del concurso internacional FACIUNI (2020)\nCortometraje seleccionado del Festival Render (2019)",
  "duration": "08:19",
  "starring": "Producción: Valentín Viacava",
  "producer": null,
  "director": "Sebastián Plasencia",
  "city": null,
  "country": "Perú",
  "year": 2019,
  "thumbnailurl": null,
  "posterurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter%2Fp202.jpg?alt=media&token=81861b4a-5257-4603-9c53-2cf5f62cc9f6",
  "poster375webpurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter375webp%2Fp202.webp?alt=media&token=5d3d16c4-61b8-4402-beb5-1ffe4f57da6c",
  "bgurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fbg%2Fb202.jpg?alt=media&token=628a8cf0-68dc-4938-bed1-b379b513b524",
  "genres": "Drama, Suspenso",
  "age": "16+",
  "maxres": "1080p",
  "type": "Cortometraje",
  "language": "ES",
  "status": "active",
  "fomo": 0,
  
 },
 {
  "id": 203,
  "videoId_yt": null,
  "videoId_vm": 6292636,
  "videoId_yt_dmq": "SPI3_0v71S8",
  "videoId_svr": null,
  "videohref_yt": null,
  "videohref_vm": "https://vimeo.com/6292636",
  "videohref_yt_dmq": "https://www.youtube.com/watch?v=SPI3_0v71S8",
  "videohref_svr": null,
  "title": "El americano",
  "synopsis": "Una muchacha peruana de 17 años vende películas americanas piratas en las calles de una zona industrial de Lima y sueña con mudarse a los Estados Unidos con la esperanza de un futuro mejor.",
  "description": null,
  "awards": null,
  "duration": "24:16",
  "starring": "Reparto: Suilma Rodriguez, J.R. Killigrew, Chalo González, Andreina Rios, Maria Calix, Karina Colon y Maria Aceves\nGuion: Claudia Sparrow",
  "producer": null,
  "director": "Claudia Sparrow",
  "city": null,
  "country": "Perú",
  "year": 2008,
  "thumbnailurl": null,
  "posterurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter%2Fp203.jpg?alt=media&token=cf52780d-2608-4c83-92f3-6ac63c712d79",
  "poster375webpurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter375webp%2Fp203.webp?alt=media&token=9d53a886-dc21-4291-9c6d-70635a2a76a2",
  "bgurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fbg%2Fb203.jpg?alt=media&token=163c4177-9cf0-4bd2-9571-9c477fe28e5e",
  "genres": "Ficción, Drama",
  "age": "16+",
  "maxres": "1080p",
  "type": "Cortometraje",
  "language": "ES",
  "status": "active",
  "fomo": 0,
  
 },
 {
  "id": 204,
  "videoId_yt": "S4l2XkNNl6k",
  "videoId_vm": null,
  "videoId_yt_dmq": "gMhE1YfbI-A",
  "videoId_svr": null,
  "videohref_yt": "https://www.youtube.com/watch?v=S4l2XkNNl6k",
  "videohref_vm": null,
  "videohref_yt_dmq": "https://www.youtube.com/watch?v=gMhE1YfbI-A",
  "videohref_svr": null,
  "title": "El auriga",
  "synopsis": "Gerardo es un argentino que trabaja como modelo en la ciudad de Lima, Perú. Es alto, guapo y fuerte, y pudiendo tenerlo todo ha decidido abocar su vida a una existencia fútil, varada en una feroz espiral auto-destructiva. En el corto presenciaremos las últimas 24 horas antes de que lo pierda absolutamente todo y tras lo cual no tenga más remedio que domar su lado salvaje.",
  "description": null,
  "awards": "ESPAÑA\nFestival Iberoamericano de Cortometrajes ABC 2017\nSeleccionado",
  "duration": "16:13",
  "starring": "Reparto: Gerardo Meister, Liliana Saravia, Darling Cavero, Juanjo Scafoglio, Luis Cárdenas y Rodrigo Ugarte\nGuion: Diego de León\nFotografía: Miguel Valle",
  "producer": "Pelícano Films (Perú), VK Producciones, Little Movies (España)",
  "director": "Diego de León",
  "city": null,
  "country": "Perú",
  "year": 2017,
  "thumbnailurl": null,
  "posterurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter%2Fp204.jpg?alt=media&token=5bfa6f32-97ff-43f4-8246-4601875f3679",
  "poster375webpurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter375webp%2Fp204.webp?alt=media&token=63eeeff1-607f-4f97-a0e2-b36572935afe",
  "bgurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fbg%2Fb204.jpg?alt=media&token=e231c5e8-a6bc-41e0-bad2-ba30f0fb1b81",
  "genres": "Drama, Suspenso",
  "age": "16+",
  "maxres": "1080p",
  "type": "Cortometraje",
  "language": "ES",
  "status": "none",
  "fomo": 0,
  
 },
 {
  "id": 205,
  "videoId_yt": null,
  "videoId_vm": 229081242,
  "videoId_yt_dmq": "SujUVeDWBr8",
  "videoId_svr": null,
  "videohref_yt": null,
  "videohref_vm": "https://vimeo.com/229081242",
  "videohref_yt_dmq": "https://www.youtube.com/watch?v=SujUVeDWBr8",
  "videohref_svr": null,
  "title": "El bidón",
  "synopsis": "Gato vuelve al club del cual lo expulsaron a buscar una indemnización o algún tipo de respuesta tras la traición de sus amigos, antes de ejecutar su último movimiento.",
  "description": null,
  "awards": "PERÚ\nMuestra Itinerante del Festival de Cine de Lima 2018\nPERÚ\n4º Festival de Cine de Trujillo 2017\nSeleccionado en Cortometrajes Categoría Ficción\nPERÚ\n21º Festival de Cine de Lima\nSeleccionado en Muestra Itinerante\nPERÚ\nFilmocorto 2017\nSeleccionado\nARGENTINA\nBAFICI 2017\nSeleccionado en Muestra de Cortometrajes Argentinos\nARGENTINA\nFestival Internacional de Cine de las Alturas 2017\nSeleccionado en Semana del Cine Peruano\nFestival de Cine Latinoamericano de La Plata 2017\nSeleccionado en La Plata Filma Corto\nCurta Cinema: Festival Internacional de Cortometrajes de Rio de Janeiro 2017\nSeleccionado en Panorama Latinoamericano\nConcurso Nacional de Cine Independiente de Cipolletti 2017\nSeleccionado en Selección Oficial de Cortometrajes\nPERÚ\n3º Semana del Cine 2017\nSeleccionado en Concurso Nacional de Cortometrajes\nPERÚ\nConcurso de Proyectos y Obras Cinematográficas del Ministerio de Cultura 2017\nGanador en Concurso Nacional de Cortometrajes",
  "duration": "13:55",
  "starring": "Reparto: Javier Sisti Ripoll, Mora Sánchez Viamonte, Juan Pablo Bava y Raquel Luco\nGuion: Diego Cendra Woodman\nFotografía: Juliana González, Dora Palomino Navarro\nSonido: Alberto Cendra Woodman, Sebastián Romero\nEdición: Diego Cendra Woodman, Tirso Vásquez",
  "producer": "Películas Fantasma (Perú)",
  "director": "Diego Cendra Woodman",
  "city": null,
  "country": "Perú",
  "year": 2017,
  "thumbnailurl": null,
  "posterurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter%2Fp205.jpg?alt=media&token=8cb50f4b-8ff3-495d-ba4b-228c313c718b",
  "poster375webpurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter375webp%2Fp205.webp?alt=media&token=8631353c-8793-40d6-a76a-6e13e4c4ae68",
  "bgurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fbg%2Fb205.jpg?alt=media&token=63055426-9c49-4c93-a723-d403ac66f318",
  "genres": "Drama, Comedia",
  "age": "16+",
  "maxres": "1080p",
  "type": "Cortometraje",
  "language": "ES",
  "status": "active",
  "fomo": 0,
  
 },
 {
  "id": 206,
  "videoId_yt": null,
  "videoId_vm": 101377069,
  "videoId_yt_dmq": "ccB_NtuYqiw",
  "videoId_svr": null,
  "videohref_yt": null,
  "videohref_vm": "https://vimeo.com/101377069",
  "videohref_yt_dmq": "https://www.youtube.com/watch?v=ccB_NtuYqiw",
  "videohref_svr": null,
  "title": "El campeón de la muerte",
  "synopsis": "Liberato Tucto es un ganadero que, a través de un misterioso caza recompensas, busca vengar la muerte de su última hija Faustina.",
  "description": null,
  "awards": "PERÚ\n20º Festival de Cine de Lima\nSeleccionado en Muestra Itinerante\nPERÚ\nFilmocorto 2016\nPrimer Premio\nESPAÑA\nFestival de Cine Western de Almería 2015\nSeleccionado en Sección Oficial Cortos Western\nPERÚ\nConcurso de Proyectos y Obras Cinematográficas del Ministerio de Cultura 2015\nMejor Fotografía en Concurso Nacional de Cortometrajes\nGanador en Concurso Nacional de Cortometrajes",
  "duration": "19:54",
  "starring": "Reparto: Sergio Espinoza, Víctor Prada y Amiel Cayo\nGuion: Juan Cristóbal Armesto\nCámara: Carlos Quiroz, Rulo Santibañez",
  "producer": "Jorge Monsalve, La Madeja (Perú), Cazador Films (Perú), Dos Patas Films (Perú)",
  "director": "Juan Cristóbal Armesto",
  "city": null,
  "country": "Perú",
  "year": 2015,
  "thumbnailurl": null,
  "posterurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter%2Fp206.jpg?alt=media&token=69d83835-885b-4b1d-9f13-f315486e9417",
  "poster375webpurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter375webp%2Fp206.webp?alt=media&token=8afaf7d7-b21d-4ca2-a200-2f0c01aaa0fc",
  "bgurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fbg%2Fb206.jpg?alt=media&token=8482558c-736b-426f-a357-a1441ce07f52",
  "genres": "Ficción, Acción",
  "age": "16+",
  "maxres": "1080p",
  "type": "Cortometraje",
  "language": "ES",
  "status": "active",
  "fomo": 0,
  
 },
 {
  "id": 207,
  "videoId_yt": null,
  "videoId_vm": 177549647,
  "videoId_yt_dmq": "wZrfj11zMSA",
  "videoId_svr": null,
  "videohref_yt": null,
  "videohref_vm": "https://vimeo.com/177549647",
  "videohref_yt_dmq": "https://www.youtube.com/watch?v=wZrfj11zMSA",
  "videohref_svr": null,
  "title": "El ciego",
  "synopsis": "José es un ciego al que le gusta escuchar la televisión pero un día el televisor se malogra y decide ir a arreglarla.",
  "description": null,
  "awards": "PERÚ\nMuestra Itinerante del Festival de Cine de Lima 2018\nPERÚ\n2º Semana del Cine 2016\nSeleccionado en Concurso Nacional de Cortometrajes",
  "duration": "11:16",
  "starring": "Productor Ejecutivo: Yepherson Rojas\nGuión: Ramses Gomez, Guillermo Meza\nDirector de Fotografía: Gonzalo Loo\nDirección de Arte: Belen Sarmiento, Daniela Lino\nProductora de Campo: Micaela Machuca\nAsist de Producción: Ramses Gomez, Juan Carlos Pinedo\nAsistente de Dirección: Diego Saavedra\nAsistentes de Arte: Jasmine Diaz, Hela Spangenberg\nGaffer: Santiago Pestana\nLuminotécnicos: Tony Marina, Alonso Benavides, Diego Reategui\nSonido Directo: Bruno Rojas\nMusica: Cae Vizcarra",
  "producer": null,
  "director": "Guillermo Meza Castro",
  "city": null,
  "country": "Perú",
  "year": 2016,
  "thumbnailurl": null,
  "posterurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter%2Fp207.jpg?alt=media&token=45d1329d-3d4d-40b1-969b-2b145310c6e6",
  "poster375webpurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter375webp%2Fp207.webp?alt=media&token=6399dfde-bab0-4188-9997-5b5b33a7509f",
  "bgurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fbg%2Fb207.jpg?alt=media&token=5347074d-12c4-464a-90dd-66b3ad9b8f3b",
  "genres": "Drama, Suspenso",
  "age": "16+",
  "maxres": "1080p",
  "type": "Cortometraje",
  "language": "ES",
  "status": "active",
  "fomo": 0,
  
 },
 {
  "id": 208,
  "videoId_yt": null,
  "videoId_vm": 170653259,
  "videoId_yt_dmq": "FUPz3PtrT2c",
  "videoId_svr": null,
  "videohref_yt": null,
  "videohref_vm": "https://vimeo.com/170653259",
  "videohref_yt_dmq": "https://www.youtube.com/watch?v=FUPz3PtrT2c",
  "videohref_svr": null,
  "title": "El disfraz equivocado",
  "synopsis": "Catalina va a la fiesta de disfraces de su primo Lautaro, muy lejos de su casa. Una vez ahí, una serie de misteriosos eventos harán que Catalina descubra que su familia, poco conocida por ella, es muy diferente al resto del mundo.",
  "description": "Catalina llega con su familia a la fiesta de disfraces por el cumpleaños de su primo Lautaro, en las afueras de la ciudad. Su presencia en la fiesta hará que su primo se comporte de manera extraña, lo cual desencadenará una serie de eventos inesperados que la harán darse cuenta de que su familia es muy distinta de lo que ella conoce sobre el mundo.",
  "awards": "PERÚ\n21º Festival de Cine de Lima\nSeleccionado en Muestra Itinerante\nPERÚ • CICLO DE CINE\nNuevas Miradas Vol. 1\nPERÚ • CICLO DE CINE\nHecho en Perú 2017\nPERÚ • CONCURSO\nSolo Para Soñadores\nFinalista\nPERÚ\n2º Semana del Cine 2016\nSeleccionado en Concurso Nacional de Cortometrajes\nPERÚ\n20º Festival de Cine de Lima\nSeleccionado en Muestra Itinerante\nPERÚ\nFilmocorto 2016\nMejor Corto Ficción\nFRANCIA\nFestival de Cannes 2016\nParticipante en Short Film Corner\nPERÚ\nConcurso de Proyectos y Obras Cinematográficas del Ministerio de Cultura 2016\nGanador en Concurso Nacional de Cortometrajes",
  "duration": "10:18",
  "starring": "Reparto: Valeria Peralta, Germán González, Katerina D'Onofrio, Yaku Rivas, Billy Bell Taylor y Verónica Sánchez-Moreno\nGuion: Mateo Krystek, Andrea Aramburú\nFotografía: José Zelada, Mario Santillana\nSonido: Omar Colchado\nArte: Mateo Krystek, Daniel Riglos Flores\nEdición: Guillermo Vásquez, Alexander Ibañez",
  "producer": "Sebastián Dañino, Diego Peñaranda, RIP Films (Lima, Perú)",
  "director": "Mateo Krystek",
  "city": null,
  "country": "Perú",
  "year": 2015,
  "thumbnailurl": null,
  "posterurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter%2Fp208.jpg?alt=media&token=4e80cf40-a49f-4427-867e-dc30239ecf2d",
  "poster375webpurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter375webp%2Fp208.webp?alt=media&token=8eedf624-cb14-4a73-aa1b-266508f79c75",
  "bgurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fbg%2Fb208.jpg?alt=media&token=4e27af4d-2b94-49e5-9a0c-812098b26196",
  "genres": "Comedia, Drama",
  "age": "16+",
  "maxres": "1080p",
  "type": "Cortometraje",
  "language": "ES",
  "status": "active",
  "fomo": 0,
  
 },
 {
  "id": 209,
  "videoId_yt": "OKhcGRvPL94",
  "videoId_vm": null,
  "videoId_yt_dmq": "r3SeZvpTjYY",
  "videoId_svr": null,
  "videohref_yt": "https://www.youtube.com/watch?v=OKhcGRvPL94",
  "videohref_vm": null,
  "videohref_yt_dmq": "https://www.youtube.com/watch?v=r3SeZvpTjYY",
  "videohref_svr": null,
  "title": "El encuentro",
  "synopsis": "Mario, es un teniente en aviación que regresa a su pueblo, que ha tenido conflictos con el estado central. Finalizado el estado de emergencia, y en un aparente tiempo de paz, el estado, ha tomado una decisión.",
  "description": null,
  "awards": null,
  "duration": "13:10",
  "starring": "Reparto: Mirian Neira, Alonso Larrea, Lía Monteza y Majnu Monteza\nGuion: Carlos Guerrero\nSonido: Bryan Aguirre Estela, Johnathan Muñoz\nEdición: Carlos Guerrero",
  "producer": "Carlos Guerrero",
  "director": "Carlos Guerrero",
  "city": null,
  "country": "Perú",
  "year": 2015,
  "thumbnailurl": null,
  "posterurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter%2Fp209.jpg?alt=media&token=dd27a4ec-1c33-42d1-af94-d22c6b45eea9",
  "poster375webpurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter375webp%2Fp209.webp?alt=media&token=d9e64faa-0c19-4382-bfec-cbd8495d8f42",
  "bgurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fbg%2Fb209.jpg?alt=media&token=182f2c5c-4d49-42ed-8125-e1c58017cdc9",
  "genres": "Drama, Suspenso",
  "age": "16+",
  "maxres": "1080p",
  "type": "Cortometraje",
  "language": "ES",
  "status": "active",
  "fomo": 0,
  
 },
 {
  "id": 210,
  "videoId_yt": null,
  "videoId_vm": 626978369,
  "videoId_yt_dmq": "20p23eBEsgU",
  "videoId_svr": null,
  "videohref_yt": null,
  "videohref_vm": "https://vimeo.com/626978369",
  "videohref_yt_dmq": "https://www.youtube.com/watch?v=20p23eBEsgU",
  "videohref_svr": null,
  "title": "El hijo de mi madre",
  "synopsis": "Carlos es un mesero de mediana edad quien lucha contra la vida, ya que su madre vive muy enferma y el dinero que gana en el restaurante es insuficiente. Cuando su hermano mayor, Luis, llega por sorpresa, Carlos se enfrentará al odio y resentimiento contra él. Ahora, ambos tendrán que tratar el uno con el otro mientras luchan sentimientos negativos para finalmente convivir como lo que son; como hermanos.",
  "description": "Carlos tendrá que escoger entre seguir sus sueños y obtener lo que siempre quiso o ayudar a su familia. Es una historia sobre honor familiar, pérdida y sacrificio por amor.",
  "awards": "5º Festival de Cine de Trujillo 2018\nSeleccionado en Cortometrajes Categoría Ficción",
  "duration": "18:12",
  "starring": "Guion: Julian Baquerizo\nDF: Christian Valera\nSonido directo: Jean Paul Revilla\nDirección de Arte: Amanda Peláez\nEditor: Julian Baquerizo\nEdición de Color: Daniel Carcelen\nMusicalización: Marcus Mancilla",
  "producer": "Daniela Sarmiento",
  "director": "Julian Baquerizo",
  "city": null,
  "country": "Perú",
  "year": 2018,
  "thumbnailurl": null,
  "posterurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter%2Fp210.jpg?alt=media&token=cdf07403-9147-484a-8c97-2a90ee4d40d9",
  "poster375webpurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter375webp%2Fp210.webp?alt=media&token=38a118e2-0b4c-4a12-8908-043149879344",
  "bgurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fbg%2Fb210.jpg?alt=media&token=a76eaf7b-331d-4434-b271-f8604fdc5807",
  "genres": "Drama",
  "age": "16+",
  "maxres": "1080p",
  "type": "Cortometraje",
  "language": "ES",
  "status": "active",
  "fomo": 0,
  
 },
 {
  "id": 211,
  "videoId_yt": null,
  "videoId_vm": 136314132,
  "videoId_yt_dmq": "mC6jG-S4H9I",
  "videoId_svr": null,
  "videohref_yt": null,
  "videohref_vm": "https://vimeo.com/136314132",
  "videohref_yt_dmq": "https://www.youtube.com/watch?v=mC6jG-S4H9I",
  "videohref_svr": null,
  "title": "El Huallaga",
  "synopsis": "Leandro y Miguel son dos niños que se conocen en un pueblo llamado Chazuta, en donde emprenden un viaje al interior del bosque, descubrirán sus fortalezas y debilidades.",
  "description": null,
  "awards": "PERÚ\n21º Festival de Cine de Lima\nSeleccionado en Muestra Itinerante\nPERÚ • CICLO DE CINE\nHecho en Perú 2017\nPERÚ • CICLO DE CINE\nCine Peruano de Primavera 2016\nPERÚ\nConcurso de Proyectos y Obras Cinematográficas del Ministerio de Cultura 2016\nGanador en Concurso Nacional de Cortometrajes\nPERÚ\nTranscinema Festival Internacional de No-ficción 2015\nSeleccionado en Transficciones",
  "duration": "14:02",
  "starring": "Guion: Lupe Benites",
  "producer": "Curuwinsi Cine (Perú)",
  "director": "Lupe Benites",
  "city": null,
  "country": "Perú",
  "year": 2015,
  "thumbnailurl": null,
  "posterurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter%2Fp211.jpg?alt=media&token=654038d7-e1ce-4343-9c1f-63a0a0eda699",
  "poster375webpurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter375webp%2Fp211.webp?alt=media&token=198f32f0-7959-44d3-9c0a-471857b8204f",
  "bgurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fbg%2Fb211.jpg?alt=media&token=9482ab60-4f1e-4b06-bf02-c3fa31e78bdb",
  "genres": "Acción, Drama",
  "age": "16+",
  "maxres": "1080p",
  "type": "Cortometraje",
  "language": "ES",
  "status": "active",
  "fomo": 0,
  
 },
 {
  "id": 212,
  "videoId_yt": null,
  "videoId_vm": 154637985,
  "videoId_yt_dmq": "tiNOEStypB4",
  "videoId_svr": null,
  "videohref_yt": null,
  "videohref_vm": "https://vimeo.com/154637985",
  "videohref_yt_dmq": "https://www.youtube.com/watch?v=tiNOEStypB4",
  "videohref_svr": null,
  "title": "El hueco",
  "synopsis": "Robert ahorra dinero suficiente para comprar el espacio al lado de la tumba de su amada. Sin embargo, cuando va a reclamarlo encuentra que ha sido vendido a otro, su entierro es mañana. Los celos de Robert lo llevarán a situaciones extremas en su intento por pasar a la eternidad al lado de Yenni.",
  "description": null,
  "awards": "ESPAÑA • MUESTRA DE CINE\nNuevas generaciones del cine peruano\nESPAÑA\nFestival Internacional de Cine de Huesca 2016\nSeleccionado en Concurso Iberoamericano de Cortometrajes\nFRANCIA\n38º Festival Internacional de Cortometrajes de Clermont-Ferrand 2016\nPremio Canal+ en Competencia Internacional\nESPAÑA\n41º Festival de Cine Iberoamericano de Huelva\nSeleccionado en Sección Oficial Cortometrajes\nPERÚ\nFilmocorto 2015\nSeleccionado\nFRANCIA\nFestival de Cannes 2015\nParticipante en Short Film Corner",
  "duration": "14:16",
  "starring": "Reparto: Norka Ramirez, Emilram Cossío, Ismael Contreras, Mayella Lloclla, André Silva, Leslie Guillén y José Medina\nGuion: Germán Tejada, Daniel Martín Rodriguez",
  "producer": "Señor Z (Lima, Perú)",
  "director": "Germán Tejada y Daniel Martín Rodriguez",
  "city": null,
  "country": "Perú",
  "year": 2015,
  "thumbnailurl": null,
  "posterurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter%2Fp212.jpg?alt=media&token=0bd15c05-bcd5-4afa-aa8a-f4b90d944385",
  "poster375webpurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter375webp%2Fp212.webp?alt=media&token=b71ee0ef-335f-46a9-af43-7af9d7b7477e",
  "bgurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fbg%2Fb212.jpg?alt=media&token=d3d1c24b-5e95-4d35-a94f-160c2253bc4f",
  "genres": "Ficción, Drama, Comedia",
  "age": "16+",
  "maxres": "1080p",
  "type": "Cortometraje",
  "language": "ES",
  "status": "active",
  "fomo": 1,
  
 },
 {
  "id": 213,
  "videoId_yt": null,
  "videoId_vm": 135516565,
  "videoId_yt_dmq": "ggTp1FSGmGs",
  "videoId_svr": null,
  "videohref_yt": null,
  "videohref_vm": "https://vimeo.com/135516565",
  "videohref_yt_dmq": "https://www.youtube.com/watch?v=ggTp1FSGmGs",
  "videohref_svr": null,
  "title": "El huésped",
  "synopsis": "Una joven mujer está sola en casa, pero se da cuenta que hay un extraño en la casa.",
  "description": null,
  "awards": "PERÚ\n20º Festival de Cine de Lima\nSeleccionado en Muestra Itinerante\nPERÚ\nFilmocorto 2016\nSeleccionado",
  "duration": "04:27",
  "starring": "Reparto: Mouna Rmiki\nGuion: Bill Silva",
  "producer": null,
  "director": "Bill Silva",
  "city": null,
  "country": "Perú",
  "year": 2015,
  "thumbnailurl": null,
  "posterurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter%2Fp213.jpg?alt=media&token=54deac9b-e624-428e-9205-70ca8a71f996",
  "poster375webpurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter375webp%2Fp213.webp?alt=media&token=4df39c0a-ea90-498b-90fe-f13d6819543c",
  "bgurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fbg%2Fb213.jpg?alt=media&token=c23465c7-f73f-40b2-a11b-2a9709a1cc54",
  "genres": "Acción, Suspenso",
  "age": "16+",
  "maxres": "1080p",
  "type": "Cortometraje",
  "language": "ES",
  "status": "active",
  "fomo": 0,
  
 },
 {
  "id": 214,
  "videoId_yt": null,
  "videoId_vm": 270578194,
  "videoId_yt_dmq": "EzAKL4jQMds",
  "videoId_svr": null,
  "videohref_yt": null,
  "videohref_vm": "https://vimeo.com/270578194",
  "videohref_yt_dmq": "https://www.youtube.com/watch?v=EzAKL4jQMds",
  "videohref_svr": null,
  "title": "Wañuy",
  "synopsis": "Pablo es un taxista y padre de familia limeño, desencantado, pesimista y fatigado de su rutina diaria. Una noche, por una larga carrera con tres sospechosos pasajeros lo pondrán en una situación extrema y cambiará su forma de afrontar la vida. Un profeta, una oscura carretera y un secreto ayudan a contar esta misteriosa historia.",
  "description": null,
  "awards": "Aspen Shortfest 2017: Jury Special Recognition\nLima Film Festival 2017 (Filmocorto): Best Short Film\nLima Film Festival 2017 (Filmocorto): Best First Film\nLima Film Festival 2017 (Filmocorto): Audience Award\nDAFO - Ministerio de Cultura del Perú - Concurso de Cortometrajes 2017",
  "duration": "20:00",
  "starring": "Guion: Alejandro Roca-Rey, Federico Abrill.\nFotografía: Roberto Maceda DFP.\nSonido: Rosa María Oliart.\nEdición: Alejandro Roca-Rey, Miguel González.\nIntérpretes: Gonzalo Molina, Sandro Caldrón, Alaín Salinas, Ana María Jordán, William Gómez, Federico Abrill, Andrés Moyo, Liliana Alegría.",
  "producer": "Alejandro Roca-Rey, Marylin Vergel.",
  "director": "Alejandro Roca-Rey",
  "city": null,
  "country": "Perú",
  "year": 2017,
  "thumbnailurl": null,
  "posterurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter%2Fp214.jpg?alt=media&token=6bba1beb-0668-4a0e-93f3-4ae8a2c49b3f",
  "poster375webpurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter375webp%2Fp214.webp?alt=media&token=2a5954fe-fa68-4636-9568-f258b9fa7ed8",
  "bgurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fbg%2Fb214.jpg?alt=media&token=aaed8620-3294-4533-a4ba-d23127e47468",
  "genres": "Drama, Suspenso, Acción",
  "age": "16+",
  "maxres": "1080p",
  "type": "Cortometraje",
  "language": "ES",
  "status": "active",
  "fomo": 0,
  
 },
 {
  "id": 215,
  "videoId_yt": null,
  "videoId_vm": 200901539,
  "videoId_yt_dmq": "tqbgg7eHOR0",
  "videoId_svr": null,
  "videohref_yt": null,
  "videohref_vm": "https://vimeo.com/200901539",
  "videohref_yt_dmq": "https://www.youtube.com/watch?v=tqbgg7eHOR0",
  "videohref_svr": null,
  "title": "Pareciera que amanece",
  "synopsis": "Dante trabaja editando reportajes violentos en un canal de televisión nocturno. Sumergido en la rutina, distintos encuentros a lo largo de un día le harán darse cuenta de que se ha convertido en alguien que jamás pensó ser.",
  "description": null,
  "awards": "PERÚ\nMuestra Itinerante del Festival de Cine de Lima 2018\nPERÚ\n21º Festival de Cine de Lima\nSeleccionado en Muestra Itinerante\nPERÚ\nFilmocorto 2017\nSeleccionado\nPERÚ\n7º Festival de Cine Lima Independiente\nSeleccionado en Competencia Nacional\nESPAÑA • MUESTRA DE CINE\nNuevas generaciones del cine peruano",
  "duration": "19:55",
  "starring": "Guion: Mateo Krystek, Rafael de Orbegoso, Rodrigo Ahumada. \nFotografía: Fernando Cobián. \nSonido: Tomás Gistav Soldi. \nEdición: Alejandro Small. \nIntérpretes: Rodrigo Ahumada, Elisa Tenaud.",
  "producer": "Alonso Elías.",
  "director": "Mateo Krystek",
  "city": null,
  "country": "Perú",
  "year": 2017,
  "thumbnailurl": null,
  "posterurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter%2Fp215.jpg?alt=media&token=7da8d9b4-2bb6-4297-951f-25cd22067aee",
  "poster375webpurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter375webp%2Fp215.webp?alt=media&token=c90a4057-7000-4d89-b0d3-21138dda06b3",
  "bgurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fbg%2Fb215.jpg?alt=media&token=8a8bb67a-f3c9-411b-83a1-9c6773c17c1d",
  "genres": "Drama, Suspenso",
  "age": "16+",
  "maxres": "1080p",
  "type": "Cortometraje",
  "language": "ES",
  "status": "active",
  "fomo": 0,
  
 },
 {
  "id": 216,
  "videoId_yt": null,
  "videoId_vm": 154390696,
  "videoId_yt_dmq": "3x4rw24RDbA",
  "videoId_svr": null,
  "videohref_yt": null,
  "videohref_vm": "https://vimeo.com/154390696",
  "videohref_yt_dmq": "https://www.youtube.com/watch?v=3x4rw24RDbA",
  "videohref_svr": null,
  "title": "La Última Función",
  "synopsis": "Jorge trabaja como payaso en un circo de barrio. Ser payaso es todo lo que sabe y quiere hacer por el resto de su vida. De pronto, un día su jefe le informa que tendrá que vender el circo debido a la poca concurrencia del público. Esta noticia hará que Jorge luche por evitar que el circo sea vendido a toda costa, hasta su última función.",
  "description": null,
  "awards": "PERÚ\n21º Festival de Cine de Lima\nSeleccionado en Muestra Itinerante\nPERÚ\nFilmocorto 2017\nSeleccionado\nCOLOMBIA\nFeria Internacional de Cine Independiente de Bogotá 2016\nSeleccionado en Selección Oficial Ficción\nFRANCIA\nFestival de Cannes 2016\nParticipante en Short Film Corner\nPERÚ\n19º Festival de Cine de Lima\nSeleccionado en Muestra Itinerante\nPERÚ\nConcurso de Proyectos y Obras Cinematográficas del Ministerio de Cultura 2015\nMejor actor en Concurso Nacional de Cortometrajes\nGanador en Concurso Nacional de Cortometrajes",
  "duration": "14:30",
  "starring": "Guion: Diego Luna – Victoria. \nSonido: Karl Candela. Edición: Roberto Flores. \nIntérpretes: Gonzalo Molina, Víctor Prada, Janncarlo Torrese.",
  "producer": "Kelly Sánchez. Fotografía: Jorge Cerna.",
  "director": "Roberto Flores",
  "city": null,
  "country": "Perú",
  "year": 2015,
  "thumbnailurl": null,
  "posterurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter%2Fp216.jpg?alt=media&token=a261a65a-311d-42be-ae2c-d482fdf2b25a",
  "poster375webpurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter375webp%2Fp216.webp?alt=media&token=b9b0f539-994c-4bf5-ab03-ed843fa0a2fc",
  "bgurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fbg%2Fb216.jpg?alt=media&token=a3c9f15a-4eea-4aa9-aa41-0188fce6ec25",
  "genres": "Drama",
  "age": "16+",
  "maxres": "1080p",
  "type": "Cortometraje",
  "language": "ES",
  "status": "active",
  "fomo": 0,
  
 },
 {
  "id": 217,
  "videoId_yt": null,
  "videoId_vm": 179828271,
  "videoId_yt_dmq": "85db6DoUAfk",
  "videoId_svr": null,
  "videohref_yt": null,
  "videohref_vm": "https://vimeo.com/179828271",
  "videohref_yt_dmq": "https://www.youtube.com/watch?v=85db6DoUAfk",
  "videohref_svr": null,
  "title": "Juguetes",
  "synopsis": "Juguetes es la historia de Javier, un carpintero que vive en una carpa hecha de esteras y telas rotas cerca del mar, sobrevive gracias a su habilidad, construyendo cosas, especialmente unos juguetes de madera. Su rutina se verá trastocada con la aparición de Ariel, un pequeño niño que ha quedado varado en la playa escapando de las constantes discusiones de sus padres.",
  "description": null,
  "awards": "PERÚ\n21º Festival de Cine de Lima\nSeleccionado en Muestra Itinerante\nPERÚ\nFilmocorto 2017\nSeleccionado\nPERÚ\n2º Semana del Cine 2016\nSeleccionado en Concurso Nacional de Cortometrajes",
  "duration": "10:53",
  "starring": "Reparto: Sebastián Montoya Pardo, Nicolás Cadenillas, Dave Erástes y Edison Ruiz\nGuion: Gabriel Páucar Vásquez\nFotografía: Gustavo Saavedra Calle\nSonido: Alejandro Roca Rey\nEdición: Gabriela Páucar Vásquez",
  "producer": null,
  "director": "Gabriel Páucar Vásquez",
  "city": null,
  "country": "Perú",
  "year": 2015,
  "thumbnailurl": null,
  "posterurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter%2Fp217.jpg?alt=media&token=62eb8d79-cd70-4162-8427-f267f0aefcee",
  "poster375webpurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter375webp%2Fp217.webp?alt=media&token=97f3a794-a5af-4721-9a93-b439256dab86",
  "bgurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fbg%2Fb217.jpg?alt=media&token=2267c477-1bbd-44d5-8313-429937b57919",
  "genres": "Drama",
  "age": "16+",
  "maxres": "1080p",
  "type": "Cortometraje",
  "language": "ES",
  "status": "active",
  "fomo": 0,
  
 },
 {
  "id": 218,
  "videoId_yt": null,
  "videoId_vm": 409733907,
  "videoId_yt_dmq": "pzgqWoQieBw",
  "videoId_svr": null,
  "videohref_yt": null,
  "videohref_vm": "https://vimeo.com/409733907",
  "videohref_yt_dmq": "https://www.youtube.com/watch?v=pzgqWoQieBw",
  "videohref_svr": null,
  "title": "Apacheta",
  "synopsis": "Chopo, un citadino, ha dedicado 10 años de su vida a la búsqueda de una herencia enterrada en la antigua hacienda de su familia. Alejado totalmente de la vida moderna, lleva una cómoda rutina, la cual será interrumpida cuando obtenga lo que tanto ha buscado.",
  "description": null,
  "awards": "PERÚ • MUESTRA DE CINE\nHistorias cotidianas / historias fílmicas\nPERÚ\n21º Festival de Cine de Lima\nSeleccionado en Muestra Itinerante\nPERÚ\nFilmocorto 2017\nSeleccionado\nCOLOMBIA\nSalón Internacional de la Luz 2017\nSeleccionado en Cortometraje de ficción\nFRANCIA\n9º Festival de Cinéma Péruvien de Paris 2017\nSeleccionado en Cortometrajes\nPERÚ\n2º Semana del Cine 2016\nSeleccionado en Concurso Nacional de Cortometrajes\nFRANCIA\nFestival de Cannes 2016\nParticipante en Short Film Corner",
  "duration": "12:09",
  "starring": "Reparto: Chopo Barrenechea, Carlos Carmen y Jorge Vásquez\nGuion: Jorge Monsalve\nFotografía: Jose Gustin\nMúsica: Jose Aguirre, Daniel Ruiz-Gonzalez",
  "producer": "Jorge Monsalve, Javier Mendoza, Cazador Films (Perú)",
  "director": "Jorge Monsalve",
  "city": null,
  "country": "Perú",
  "year": 2016,
  "thumbnailurl": null,
  "posterurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter%2Fp218.jpg?alt=media&token=d9fbc3e5-6f76-4294-acb7-051b1bc52a98",
  "poster375webpurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter375webp%2Fp218.webp?alt=media&token=8c6f3032-bce5-4ea1-b5f3-20b2b33910dd",
  "bgurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fbg%2Fb218.jpg?alt=media&token=0f9525ac-0603-4cf7-b011-7e0e978b42ef",
  "genres": "Suspenso",
  "age": "16+",
  "maxres": "1080p",
  "type": "Cortometraje",
  "language": "ES",
  "status": "active",
  "fomo": 0,
  
 },
 {
  "id": 219,
  "videoId_yt": null,
  "videoId_vm": 313709175,
  "videoId_yt_dmq": "kQr-JVLPEfE",
  "videoId_svr": null,
  "videohref_yt": null,
  "videohref_vm": "https://vimeo.com/313709175",
  "videohref_yt_dmq": "https://www.youtube.com/watch?v=kQr-JVLPEfE",
  "videohref_svr": null,
  "title": "WAKCHA",
  "synopsis": "Una empleada doméstica y un gasfitero tienen un encuentro inusitado que los remitirá a un pasado doloroso",
  "description": null,
  "awards": "Concurso Nacional de Cortometrajes de los estímulos económico para la Cultura 2020 (Ministerio de Cultura del Perú)\nMejor Ficción, Mejor Dirección y Mejor Actriz (Cortos de Vista 2017)\nMejor Ópera Prima (FilmoCorto 2018)\nMejor Cortometraje y Mejor Actuación Femenina (Festival del Cortometraje Peruano 2018).\nMejor Guion y Mejor Diseño Sonoro (Festival Internacional Audiovisual FIA FEST 2019)",
  "duration": "19:56",
  "starring": "Dirección de Fotografía: Edgar Sardón Saenz\nDirección de Arte: Anapaula Parodi\nAsistencia de dirección: Andrea Chirinos\nAsistencia de producción: Valeria Cornejo\nGaffer: Freddy Quispe\nAsistencia en iluminación: Paul Valencia\nAsistencia en cámara: Valentin Vergara, Mario Acha\nAsistencia en arte: Alexandra Porth, Xiomara Castro\nDiseño de sonido y sonido Directo: Luigi Lizza, Luis Villar\nGuion: Pierre G. Llanos\nEdición: Pierre G. Llanos, Edgar Sardon Saenz\nColorización: Valentin Vergara\nPost sonido: Matko Audio",
  "producer": "Dani Combe",
  "director": "Pierre G. Llanos",
  "city": null,
  "country": "Perú",
  "year": 2019,
  "thumbnailurl": null,
  "posterurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter%2Fp219.jpg?alt=media&token=07db802a-0c25-4881-80c3-496a0bfa1f05",
  "poster375webpurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter375webp%2Fp219.webp?alt=media&token=0ca219b3-15fe-4ee9-9f6b-d9872172960b",
  "bgurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fbg%2Fb219.jpg?alt=media&token=a12a6a50-be45-4521-835f-61b5cab6d9c4",
  "genres": "Drama",
  "age": "16+",
  "maxres": "1080p",
  "type": "Cortometraje",
  "language": "ES",
  "status": "active",
  "fomo": 0,
  
 },
 {
  "id": 220,
  "videoId_yt": null,
  "videoId_vm": 228776590,
  "videoId_yt_dmq": "md00YleO9-s",
  "videoId_svr": null,
  "videohref_yt": null,
  "videohref_vm": "https://vimeo.com/228776590",
  "videohref_yt_dmq": "https://www.youtube.com/watch?v=md00YleO9-s",
  "videohref_svr": null,
  "title": "Suspenso o Sorpresa",
  "synopsis": "Un director y un guionista discuten sobre si escribir una escena de suspenso o de sorpresa. ¿Cómo lo haría Hitchcock, Scorsese o Tarantino.",
  "description": null,
  "awards": "Festivales: España: FICC. Fest. Int. de Cine de Cartagena. Primer Premio, Carabela de Plata ‘Ciudad de Cartagena’ al mejor cortometraje. Festival De Cinema Fantàstic I Terror de Sant Vicent del Raspeig. Suspiria Fest. Premio del Público. Marató de Cinema Fantàstic / Terror Sants; Fest. Curtmetratges de Vila-seca; Fest. De Zaragoza; Novembre Negre: finalista premio del público, Fest. De Baleares; Fest. Fantástico Europeo de Murcia C-FEM; Semana Corta de Cartagena. Faten Hamma Film Festival, El Cairo, Egipto: Premio al Mejor Cortometraje. Hispanic Short Film in Munich, Alemania. Kalpanijhar Int. Short Ficction Film Fest., India. EE UU: Moviescreenpro Film Fest.; The Americas Film Fest. New York. Fest. Int. De Horror de Guanajuato, México.Fantastic Film Fest. Of Bourg, Bresse, Francia. Mostra del Cinema di Taranto, Italia. Horrorant Film Fest., Grecia. Colombia: Fest. De Cine del Sur; Fest. Int. Cine en las Montañas. Perú: Insólito, Fest. De Cine de Terror y Fantasía.",
  "duration": "12:58",
  "starring": "Fotografía: Fergan Chávez-Ferrer. \nSonido: Tomás Gistau. \nEdición: Miguel González. \nIntérpretes: Nicolás Galindo, Guillermo Castañeda.",
  "producer": "Daniel Bernal.",
  "director": "Daniel Bernal",
  "city": null,
  "country": "Perú",
  "year": 2018,
  "thumbnailurl": null,
  "posterurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter%2Fp220.jpg?alt=media&token=ce084071-dba4-4db5-81fd-7c1b25d18f11",
  "poster375webpurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter375webp%2Fp220.webp?alt=media&token=da145726-e546-4730-b3fb-c088548e8aae",
  "bgurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fbg%2Fb220.jpg?alt=media&token=d1ab973c-02ea-4794-9d90-a90d8a24de27",
  "genres": "Comedia, Drama",
  "age": "13+",
  "maxres": "1080p",
  "type": "Cortometraje",
  "language": "ES",
  "status": "active",
  "fomo": 0,
  
 },
 {
  "id": 221,
  "videoId_yt": null,
  "videoId_vm": 332777315,
  "videoId_yt_dmq": "JGglU4Fq8eI",
  "videoId_svr": null,
  "videohref_yt": null,
  "videohref_vm": "https://vimeo.com/332777315",
  "videohref_yt_dmq": "https://www.youtube.com/watch?v=JGglU4Fq8eI",
  "videohref_svr": null,
  "title": "Retukiri tukiri",
  "synopsis": "Cuenta la historia de Camila y Jhony, conductores de un programa infantil. Jhony está enamorado de Camila y Camila está enamorada de Tadeo, el problema es que Tadeo es un dinosaurio de peluche que Jhony personifica en el programa infantil que conducen.",
  "description": null,
  "awards": "Festivales: Los Angeles Film Awards, Best Dark Comedy. ",
  "duration": "18:25",
  "starring": "Fotografía: Andrés Mazón. \nSonido: Ernesto Trujillo. \nEdición: Chino Pinto. \nIntérpretes: Emilram Cossio, Mayra Goñi. Miguel Iza, Claret Quea, José Almanza. ",
  "producer": "Bien ou Bien (Francia), Señor Z (Perú).",
  "director": "German Tejada, Daniel Martín Rodríguez.",
  "city": null,
  "country": "Perú",
  "year": 2019,
  "thumbnailurl": null,
  "posterurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter%2Fp221.jpg?alt=media&token=5c406075-82b1-451d-85aa-b4b2ea10b859",
  "poster375webpurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter375webp%2Fp221.webp?alt=media&token=ac63c2a3-0ff2-4c07-957f-6d3434d3c526",
  "bgurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fbg%2Fb221.jpg?alt=media&token=4c97a77d-6de4-4c41-9443-9d9b68ba659e",
  "genres": "Comedia, Drama",
  "age": "16+",
  "maxres": "1080p",
  "type": "Cortometraje",
  "language": "ES",
  "status": "active",
  "fomo": 1,
  
 },
 {
  "id": 222,
  "videoId_yt": null,
  "videoId_vm": 272274573,
  "videoId_yt_dmq": "BhaBL8wlSrw",
  "videoId_svr": null,
  "videohref_yt": null,
  "videohref_vm": "https://vimeo.com/272274573",
  "videohref_yt_dmq": "https://www.youtube.com/watch?v=BhaBL8wlSrw",
  "videohref_svr": null,
  "title": "Pisahueco",
  "synopsis": "Ángel, un entusiasta profesor de secundaria, descubre una página web en donde es objeto de burla de sus alumnos mediante unos extraños videos. Mezclándose la realidad y el universo digital adolescente, Ángel se verá envuelto en un mundo hasta entonces desconocido.",
  "description": null,
  "awards": null,
  "duration": "17:26",
  "starring": "Guion: Sergio Fernández Muñoz. \nFotografía: Piero Varda. \nSonido: Sebastián Legaspi, Tomás Gistau Soldi. \nEdición: Alejandro Small. \nIntérpretes: Oswaldo Salas, Paulina Bazán, Adrián Du Bois. ",
  "producer": "Sergio Fernández Muñoz, Milagros Távara.",
  "director": "Sergio Fernández Muñoz",
  "city": null,
  "country": "Perú",
  "year": 2019,
  "thumbnailurl": null,
  "posterurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter%2Fp222.jpg?alt=media&token=d35a9173-3b1b-44a7-b9b6-1101c6ffcc0e",
  "poster375webpurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter375webp%2Fp222.webp?alt=media&token=d6642614-af7e-4943-9f6a-892da725df21",
  "bgurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fbg%2Fb222.jpg?alt=media&token=dd5850e0-0dca-48d1-ba1c-44b95d3f4b59",
  "genres": "Drama, Suspenso",
  "age": "16+",
  "maxres": "1080p",
  "type": "Cortometraje",
  "language": "ES",
  "status": "active",
  "fomo": 0,
  
 },
 {
  "id": 223,
  "videoId_yt": null,
  "videoId_vm": 225935870,
  "videoId_yt_dmq": "_HBQaI1V_BY",
  "videoId_svr": null,
  "videohref_yt": null,
  "videohref_vm": "https://vimeo.com/225935870",
  "videohref_yt_dmq": "https://www.youtube.com/watch?v=_HBQaI1V_BY",
  "videohref_svr": null,
  "title": "Níveo",
  "synopsis": "Tras terminar su práctica de natación, Marina se encontrará con Julián, un niño curioso que está en un lugar donde no debería estar. Este encuentro inesperado llevará a ambos a confrontar los deseos propios de su edad con respecto a su sexualidad.",
  "description": null,
  "awards": "Festivales: Reel Erotic Art Film Festival: Mención Honrosa. Sarasota Film Festival. Through Women’s Eyes. Erotic & Bizarre Art Film Festival: premio a mejor cineasta mujer. Festival de Cine Peruano “Hecho por mujeres”. ",
  "duration": "11:36",
  "starring": "Guion: Noelia Crispin. \nFotografía: Roberto Maceda Kohatsu. \nSonido: Rosa María Oliart. \nEdición: Guillermo Loli. \nIntérpretes: Micaela Belmont, Brando Gallesi, Joaquín De Orbegoso. ",
  "producer": "Nataly Vergara Adrianzén. ",
  "director": "Noelia Crispin",
  "city": null,
  "country": "Perú",
  "year": 2017,
  "thumbnailurl": null,
  "posterurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter%2Fp223.jpg?alt=media&token=39b200ba-2c49-479c-8c3d-5a2cc99caa42",
  "poster375webpurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter375webp%2Fp223.webp?alt=media&token=42296341-af0d-4f50-b626-398a6949308f",
  "bgurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fbg%2Fb223.jpg?alt=media&token=7c5d50bf-c1f0-4f58-9abf-0d51b232eaa0",
  "genres": "Drama",
  "age": "16+",
  "maxres": "1080p",
  "type": "Cortometraje",
  "language": "ES",
  "status": "active",
  "fomo": 0,
  
 },
 {
  "id": 224,
  "videoId_yt": null,
  "videoId_vm": 244773695,
  "videoId_yt_dmq": "RJIV4IvpRyU",
  "videoId_svr": null,
  "videohref_yt": null,
  "videohref_vm": "https://vimeo.com/244773695",
  "videohref_yt_dmq": "https://www.youtube.com/watch?v=RJIV4IvpRyU",
  "videohref_svr": null,
  "title": "Mamífera",
  "synopsis": "Es la historia de Isabel y Rocío (madre e hija) que se ven forzadas a convivir unos días, en los que Rocío revive el rechazo de su madre y en el que descubrirá el origen del mismo.",
  "description": null,
  "awards": "Festivales: Festival de Cortometrajes Universitarios “Corto de Vista”. Festival de Cine Peruano “Hecho por Mujeres”.",
  "duration": "18:16",
  "starring": "Guion: Angi Lozano. \nFotografía: Julián Amaru Estrada DFP. \nSonido directo: David Acevedo. \nPost: Félix Borrell. Edición: Víctor Gámez, Brian Jacobs.\nIntérpretes: Delfina Paredes, Liliana Trujillo, Arais Pozo. ",
  "producer": "Angi Lozano, Elías Candia.",
  "director": "Angi Lozano",
  "city": null,
  "country": "Perú",
  "year": 2017,
  "thumbnailurl": null,
  "posterurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter%2Fp224.jpg?alt=media&token=21419b34-d014-48ab-8ac3-f17f9461d441",
  "poster375webpurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter375webp%2Fp224.webp?alt=media&token=01da109b-43ff-4d15-b86a-4ab8393bf809",
  "bgurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fbg%2Fb224.jpg?alt=media&token=6aa1edb4-e618-4814-a3f7-80f5dcec9940",
  "genres": "Drama, Suspenso",
  "age": "16+",
  "maxres": "1080p",
  "type": "Cortometraje",
  "language": "ES",
  "status": "active",
  "fomo": 0,
  
 },
 {
  "id": 225,
  "videoId_yt": null,
  "videoId_vm": 120767247,
  "videoId_yt_dmq": "KUqkcScH_og",
  "videoId_svr": null,
  "videohref_yt": null,
  "videohref_vm": "https://vimeo.com/120767247",
  "videohref_yt_dmq": "https://www.youtube.com/watch?v=KUqkcScH_og",
  "videohref_svr": null,
  "title": "El muerto",
  "synopsis": "Un muchacho que se pensaba muerto vuelve a casa de sus padres luego de siete años.",
  "description": null,
  "awards": "Shortfilm Corner Cannes 2015\nPremio del Publico Online Palm Springs Shortfest 2015\nMención del Jurado Filmocorto 2015\nSan Francisco Latino Film Festival 2015\nKerry Film Festival 2015\nFestival Internacional de Nuevo Cine Latinoamericano 2015\nFestival de Cine de Trujillo 2015\nMuestra Cine Peruano Casa de America 2015\nMuestra de Cine Peruano en Paris 2015",
  "duration": "15:11",
  "starring": "Guion: Alonso Elías & Franco Finocchiaro.\nDirección de Fotografía: Cesar Fe.\nDirección de Arte: Rodrigo Nuñez Más.\nDiseño de Sonido: Amador Del Solar.\nMusicalización: Pauchi Sasaki.\nEdición: Guillermo Vásquez Mendoza\nJefatura de Producción: Denisse Best.\nProducción de Arte: Nicole Remy.\nDiseño de Vestuario: Bettina Fiol.\nAsist. de Dirección: Sergio Fernandez.\nScrip & Continuidad: Mateo Krystek.\nProducción Técnica: Fernando Cobian & Guillermo Vasquez.\nProducción Ejecutiva Asociada: Adriano Moreno. (Visualizarte)",
  "producer": "Alonso Elías.",
  "director": "Franco Finocchiaro",
  "city": null,
  "country": "Perú",
  "year": 2014,
  "thumbnailurl": null,
  "posterurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter%2Fp225.jpg?alt=media&token=c4a83b92-4161-45a3-a895-2cc056c717f1",
  "poster375webpurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter375webp%2Fp225.webp?alt=media&token=082d994b-20c8-4d86-af33-89bce25bb420",
  "bgurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fbg%2Fb225.jpg?alt=media&token=956cb01e-605b-4ce3-be2e-1d64e9165c34",
  "genres": "Thriller, Suspenso",
  "age": "13+",
  "maxres": "1080p",
  "type": "Cortometraje",
  "language": "ES",
  "status": "active",
  "fomo": 1,
  
 },
 {
  "id": 226,
  "videoId_yt": null,
  "videoId_vm": 98949538,
  "videoId_yt_dmq": "6J43B__DB6A",
  "videoId_svr": null,
  "videohref_yt": null,
  "videohref_vm": "https://vimeo.com/98949538",
  "videohref_yt_dmq": "https://www.youtube.com/watch?v=6J43B__DB6A",
  "videohref_svr": null,
  "title": "Kung Fu cuñado",
  "synopsis": "La nueva relación sentimental de Rosa y Pepe pasa por una difícil prueba de Brandon, el hermano celoso de Rosa.",
  "description": null,
  "awards": null,
  "duration": "13:15",
  "starring": null,
  "producer": null,
  "director": "Jiecheng “Jack” Wu",
  "city": null,
  "country": "Perú",
  "year": 2014,
  "thumbnailurl": null,
  "posterurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter%2Fp226.jpg?alt=media&token=42cc5865-ee89-4e4d-ac5c-03c006fdc935",
  "poster375webpurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter375webp%2Fp226.webp?alt=media&token=2df426a1-0115-4af3-8fff-9038ad34de79",
  "bgurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fbg%2Fb226.jpg?alt=media&token=93703f9f-56b6-4dd8-b630-cecf28cf314c",
  "genres": "Acción",
  "age": "13+",
  "maxres": "1080p",
  "type": "Cortometraje",
  "language": "ES",
  "status": "none",
  "fomo": 0,
  
 },
 {
  "id": 227,
  "videoId_yt": null,
  "videoId_vm": 156754711,
  "videoId_yt_dmq": "14-BhYHCtS8",
  "videoId_svr": null,
  "videohref_yt": null,
  "videohref_vm": "https://vimeo.com/156754711",
  "videohref_yt_dmq": "https://www.youtube.com/watch?v=14-BhYHCtS8",
  "videohref_svr": null,
  "title": "Hasta que regrese",
  "synopsis": "Una mujer emprende un viaje a Paucartambo, el pueblo de su abuela. En medio de los preparativos de la fiesta patronal conoce a un danzante quien le devela el origen de su fé.",
  "description": null,
  "awards": null,
  "duration": "17:48",
  "starring": "Guion: Luisa García Alva \nFotografía: Carlos Sánchez Giraldo \nEdición: Sofía Velásquez Núñez \nSonido: Karina Lozano Marín \nMúsica: Daniel Willis",
  "producer": "Luisa García Alva.",
  "director": "Luisa García Alva",
  "city": null,
  "country": "Perú",
  "year": 2015,
  "thumbnailurl": null,
  "posterurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter%2Fp227.jpg?alt=media&token=e1f5bdac-b4ba-4b10-acb1-25b8c6c0a533",
  "poster375webpurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter375webp%2Fp227.webp?alt=media&token=b2d4bb13-586d-428a-b70b-d9e6940bb07d",
  "bgurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fbg%2Fb227.jpg?alt=media&token=22817968-50cb-45c2-a485-0887b4357daf",
  "genres": "Documental, Drama",
  "age": "13+",
  "maxres": "1080p",
  "type": "Cortometraje",
  "language": "ES",
  "status": "active",
  "fomo": 0,
  
 },
 {
  "id": 228,
  "videoId_yt": null,
  "videoId_vm": 292230452,
  "videoId_yt_dmq": "2ReN__BYGxI",
  "videoId_svr": null,
  "videohref_yt": null,
  "videohref_vm": "https://vimeo.com/292230452",
  "videohref_yt_dmq": "https://www.youtube.com/watch?v=2ReN__BYGxI",
  "videohref_svr": null,
  "title": "Porno Star",
  "synopsis": "Luca es un joven sin oficio que al ser amenazado con ser echado de su casa, se ve obligado a ganar dinero rápido.",
  "description": null,
  "awards": "PERÚ\nFilmocorto 2011\nMejor Corto Ficción\nPremio de Preferencia del Público",
  "duration": "20:24",
  "starring": "Reparto: Miguel Iza, Manuel Gold, Lucho Cáceres y Milagros López Arias\nGuion: Yashim Bahamonde, Nelson Mendoza",
  "producer": "Hermanos Conchen Films (Perú)",
  "director": "Yashim Bahamonde y Nelson Mendoza",
  "city": null,
  "country": "Perú",
  "year": 2011,
  "thumbnailurl": null,
  "posterurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter%2Fp228.jpg?alt=media&token=64a74184-3d5a-4d7b-a9b4-1568aa24fef8",
  "poster375webpurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter375webp%2Fp228.webp?alt=media&token=84e2fe8c-cef1-43ae-a1ad-4818eb1ffdf0",
  "bgurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fbg%2Fb228.jpg?alt=media&token=76eb3644-e003-4c9b-a384-e5f82e09b652",
  "genres": "Ficción",
  "age": "16+",
  "maxres": "1080p",
  "type": "Cortometraje",
  "language": "ES",
  "status": "none",
  "fomo": 0,
  
 },
 {
  "id": 229,
  "videoId_yt": null,
  "videoId_vm": 292227692,
  "videoId_yt_dmq": "X_xbcjxgUKw",
  "videoId_svr": null,
  "videohref_yt": null,
  "videohref_vm": "https://vimeo.com/292227692",
  "videohref_yt_dmq": "https://www.youtube.com/watch?v=X_xbcjxgUKw",
  "videohref_svr": null,
  "title": "Ven",
  "synopsis": "Los separan algunos años y un evento del pasado que los dejó profundamente marcados. A pesar del abismo que linda entre ambos, ella ha decidido visitarlo y él ha aceptado. ¿Es posible el reencuentro cuando el dolor, la culpa y la desesperación se han apoderado de nosotros?",
  "description": null,
  "awards": "PERÚ\nMuestra Itinerante del Festival de Cine de Lima 2018\nPERÚ • CICLO DE CINE\nHecho en Perú 2017\nPERÚ\nConcurso de Proyectos y Obras Cinematográficas del Ministerio de Cultura 2016\nGanador en Concurso Nacional de Cortometrajes",
  "duration": "20:00",
  "starring": "Reparto: Lucho Cáceres y Silvana Cañote\nGuion: Carlos Galiano\nFotografía: Mario Bassino DFP\nEdición: Gino Moreno",
  "producer": "Amakeya Producciones (Perú)",
  "director": "Carlos Galiano",
  "city": null,
  "country": "Perú",
  "year": 2016,
  "thumbnailurl": null,
  "posterurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter%2Fp229.jpg?alt=media&token=cc8a8aa3-fb72-4f3d-a234-6c6825aeb57e",
  "poster375webpurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter375webp%2Fp229.webp?alt=media&token=2d36675c-ff7e-4cbb-8431-1fd06ccc25e1",
  "bgurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fbg%2Fb229.jpg?alt=media&token=e1c4fa05-afbe-48c2-aa90-7ebef632acf0",
  "genres": "Drama",
  "age": "16+",
  "maxres": "1080p",
  "type": "Cortometraje",
  "language": "ES",
  "status": "active",
  "fomo": 0,
  
 },
 {
  "id": 230,
  "videoId_yt": "sedmFsugDzg",
  "videoId_vm": null,
  "videoId_yt_dmq": "X3EKpJK1M_I",
  "videoId_svr": null,
  "videohref_yt": "https://www.youtube.com/watch?v=sedmFsugDzg",
  "videohref_vm": null,
  "videohref_yt_dmq": "https://www.youtube.com/watch?v=X3EKpJK1M_I",
  "videohref_svr": null,
  "title": "En el café",
  "synopsis": "Durante una solitaria noche, dos misteriosos clientes llegan a la cafetería. Alma, una joven mesera, intentará averiguar de distintas maneras que es lo que ocultan, llevándola a tener teorías y acciones cada vez más locas.",
  "description": null,
  "awards": null,
  "duration": "15:20",
  "starring": "Guion: Raúl Duarte\nReparto: Paulina Flores, Judith Hernández, Gerardo Hernández y Carolina Vázquez\nSonido: Amaury Murillo\nFotografía: Enav Ruiz\nMúsica: Raúl Duarte\nMontaje: Raúl Duarte",
  "producer": "Luis Nava.",
  "director": "Raúl Duarte",
  "city": null,
  "country": "México",
  "year": 2021,
  "thumbnailurl": null,
  "posterurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter%2Fp230.jpg?alt=media&token=9333a391-6c61-48e3-bb79-cbad744d8827",
  "poster375webpurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter375webp%2Fp230.webp?alt=media&token=38bb7b03-f28a-4897-b6d5-a250f0c092f3",
  "bgurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fbg%2Fb230.jpg?alt=media&token=310c1da2-f03a-41ef-8c4b-c01f4ed28702",
  "genres": "Suspenso, Acción",
  "age": "16+",
  "maxres": "1080p",
  "type": "Cortometraje",
  "language": "ES",
  "status": "active",
  "fomo": 0,
  
 },
 {
  "id": 231,
  "videoId_yt": null,
  "videoId_vm": 544357789,
  "videoId_yt_dmq": "hqjPPlb4lZM",
  "videoId_svr": null,
  "videohref_yt": null,
  "videohref_vm": "https://vimeo.com/544357789",
  "videohref_yt_dmq": "https://www.youtube.com/watch?v=hqjPPlb4lZM",
  "videohref_svr": null,
  "title": "Matilde",
  "synopsis": "Julia Urquidi convive con Alan Somocursio y están a punto de casarse. A pocos días de su boda él empieza a soñar con una mujer llamada Matilde. Julia, obsesionada con el sueño, hará todo lo posible por saber quién es ella, llevándola a situaciones absurdas entre la realidad y la ficción.",
  "description": null,
  "awards": null,
  "duration": "07:10",
  "starring": "Guion: Dante Chanduví\nReparto: Katiuska Granda y Juan Manuel García\nSonido: Jerson Anastasio\nFotografía: Sebastian Arias\nArte: Frida Cárdenas\nMontaje: María Gracía Asmat y Dante Chanduví",
  "producer": "Arturo Palacios",
  "director": "Dante Chanduví",
  "city": null,
  "country": "Perú",
  "year": 2021,
  "thumbnailurl": null,
  "posterurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter%2Fp231.jpg?alt=media&token=5bd331ef-a2db-4d02-9d8d-71ae9f08109a",
  "poster375webpurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter375webp%2Fp231.webp?alt=media&token=6bfd977c-850a-4677-82d1-3e7b3a0920f4",
  "bgurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fbg%2Fb231.jpg?alt=media&token=c3604d0b-b0cc-40e0-b8fd-5b949e0baeaf",
  "genres": "Ficción, Drama, Suspenso",
  "age": "16+",
  "maxres": "1080p",
  "type": "Cortometraje",
  "language": "ES",
  "status": "active",
  "fomo": 0,
  
 },
 {
  "id": 232,
  "videoId_yt": "LgsN8cdX8D4",
  "videoId_vm": null,
  "videoId_yt_dmq": "TbOq84joAzI",
  "videoId_svr": null,
  "videohref_yt": "https://www.youtube.com/watch?v=LgsN8cdX8D4",
  "videohref_vm": null,
  "videohref_yt_dmq": "https://www.youtube.com/watch?v=TbOq84joAzI",
  "videohref_svr": null,
  "title": "Picante",
  "synopsis": "Jorge, un sicario retirado, vuelve a su cruento oficio luego de la repentina muerte de su hijo. Sin embargo, el fantasma de la culpa no lo dejará en paz y lo hará cuestionar el sentido de sus acciones.",
  "description": null,
  "awards": "PERÚ\n21º Festival de Cine de Lima\nSeleccionado en Muestra Itinerante",
  "duration": "12:13",
  "starring": "Reparto: Pietro Sibille, Ricardo Mejía, Thiago Basurto y Ysoline Huerta\nGuion: Alejandro Caro",
  "producer": null,
  "director": "Alejandro Caro",
  "city": null,
  "country": "Perú",
  "year": 2016,
  "thumbnailurl": null,
  "posterurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter%2Fp232.jpg?alt=media&token=48ec94cf-0378-4c85-85c3-7a032538501e",
  "poster375webpurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter375webp%2Fp232.webp?alt=media&token=dc099622-3feb-4b42-90b7-092d70973828",
  "bgurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fbg%2Fb232.jpg?alt=media&token=0b441de4-8c4b-47cc-8e54-2367e0e89502",
  "genres": "Drama, Suspenso, Acción",
  "age": "16+",
  "maxres": "1080p",
  "type": "Cortometraje",
  "language": "ES",
  "status": "active",
  "fomo": 1,
  
 },
 {
  "id": 233,
  "videoId_yt": "eYMZ18rrPH0",
  "videoId_vm": null,
  "videoId_yt_dmq": "yICsAfQFRHY",
  "videoId_svr": null,
  "videohref_yt": "https://www.youtube.com/watch?v=eYMZ18rrPH0",
  "videohref_vm": null,
  "videohref_yt_dmq": "https://www.youtube.com/watch?v=yICsAfQFRHY",
  "videohref_svr": null,
  "title": "Desolvido",
  "synopsis": "Esta es la historia de una familia que representa la memoria colectiva de un país que por más de setenta años ha vivido en guerra, que ha dejado un costo de más de 260.000 personas asesinadas, más de 8.000.000 de personas desplazadas de sus tierras y más de 80.000 colombianos desaparecidos.",
  "description": null,
  "awards": "Independent Shorts Awards Los Ángeles. United States. Best Cinematography, Best Production Design, Best Music Video, Best Director (Male)\nDANCES WITH FILMS Studio City, CA, Best DownBeat Music Video 2021\nIndieX Film Fest Los Ángeles California Best Music Video, best Director\nSouth Film and Arts Academy Festival Rancagua, O’Higgins Chile Short Film Of The festival, Best Director, Best\nMusical Short Film, Best Cinematography\nColorado International Activism Film Festival Denver, Colorado Best Music Video\nPortoviejo Film Festival Portoviejo, Manabi Ecuador Best International Video Clip",
  "duration": "09:45",
  "starring": "Guion Andrés Roa Ariza\nReparto Jimena Diaz, Alex Roma, Maithe Hurtado\nMúsica Edson Velandia, Adriana Lizcano\nAsistente de producción Gilbert Franco Tatiana Salcedo\nDirector de fotografía James Montealegre\nCamarógrafo Juan Tenorio\nFoquista Julián Castillo\nGafer Juan Sebastián Bustos\nBest boy Esteban Castaño\nMaking of Diego Zuluaga, Michael Clopatofsky\nDirectora de arte Tatiana Salcedo, David Vargas\nStylling Marcela Escobar\nAsistente stylling Andrés Quiroz\nProducción de campo Laura Barbosa\nAsistente de producción de campo Carolina Ramírez\nAsistente de producción Yesica Castaño Contreras\nProductor musical Edson Velandia\nCantante Edson Velandia, Adriana Lizcano",
  "producer": null,
  "director": "Andrés Roa Ariza",
  "city": null,
  "country": "Colombia",
  "year": 2021,
  "thumbnailurl": null,
  "posterurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter%2Fp233.jpg?alt=media&token=d5756597-ef52-406f-8bde-d15fd0331290",
  "poster375webpurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter375webp%2Fp233.webp?alt=media&token=594a7eec-37f3-42e6-b5a2-2b55424ee2f7",
  "bgurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fbg%2Fb233.jpg?alt=media&token=a032f652-8400-4a43-886f-306bd70a6f51",
  "genres": "Drama",
  "age": "16+",
  "maxres": "1080p",
  "type": "Cortometraje",
  "language": "ES",
  "status": "active",
  "fomo": 0,
  
 },
 {
  "id": 234,
  "videoId_yt": "hKEPucQIhYs",
  "videoId_vm": null,
  "videoId_yt_dmq": "IQH_jmGFYgU",
  "videoId_svr": null,
  "videohref_yt": "https://www.youtube.com/watch?v=hKEPucQIhYs",
  "videohref_vm": null,
  "videohref_yt_dmq": "https://www.youtube.com/watch?v=IQH_jmGFYgU",
  "videohref_svr": null,
  "title": "Comemuerto",
  "synopsis": "Un chofer que conduce con sueño atropella a una persona, tratando de ocultarlo se dará cuenta de que este huía de algo o alguien.",
  "description": null,
  "awards": null,
  "duration": "11:23",
  "starring": "Guion: Paulo Orellana\nReparto: Paulo Orellana, Anthony Arcentales, Gabriel Romero, Kevin Suarez",
  "producer": "Paparazzi Producciones",
  "director": "Paulo Orellana",
  "city": null,
  "country": "Ecuador",
  "year": 2020,
  "thumbnailurl": null,
  "posterurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter%2Fp234.jpg?alt=media&token=d258fdbb-ad58-47e2-a79f-e7bdca5d6d09",
  "poster375webpurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter375webp%2Fp234.webp?alt=media&token=bb26bf53-3fa3-4925-bdd2-3232f55054a1",
  "bgurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fbg%2Fb234.jpg?alt=media&token=d09fac27-0c72-46bc-804f-dcd1807b1c69",
  "genres": "Terror, Suspenso",
  "age": "16+",
  "maxres": "1080p",
  "type": "Cortometraje",
  "language": "ES",
  "status": "active",
  "fomo": 0,
  
 },
 {
  "id": 235,
  "videoId_yt": "3SkewBXxJ_E",
  "videoId_vm": null,
  "videoId_yt_dmq": "K9ViQMTI3j8",
  "videoId_svr": null,
  "videohref_yt": "https://www.youtube.com/watch?v=3SkewBXxJ_E",
  "videohref_vm": null,
  "videohref_yt_dmq": "https://www.youtube.com/watch?v=K9ViQMTI3j8",
  "videohref_svr": null,
  "title": "Entre Mamushkas",
  "synopsis": "Moa es una muñeca de madera que vive en un Edén y tiene la particularidad de ser la única de su linaje que ha nacido con brazos de astillas. Al nacer, su familia le establece que tiene que cumplir su destino de ser madre, pero ella, en su afán de investigar su entorno aprovechando su cualidad de manipular objetos, se rehúsa a hacerlo y emprende una búsqueda de un objeto que la ayude a salir de su hogar. En su intento de escape, observa un avión movilizado por una niña humana llamada Ana, quien al descubrir que Moa le envía mensajes, decide llevarla a su casa para dejarle pilotear su avión.",
  "description": null,
  "awards": "Premio Mejor Guion, Perugia, Italia, 2021.",
  "duration": "10:11",
  "starring": "Guion: Maribel Vásquez\nRealización: Maribel Vásquez\nActuación: María Lorena Ruiz, Patricia Naranjo, Ximena Ferrín, María José Zapata\nFotografía: María Paula Páez\nSonido Directo: Mishell Freire\nSonorización: Mishell Freire\nMontaje Visual: María Paula Páez, Maribel Vásquez\nMezcla: Mishell Freire, Maribel Vásquez\nArte: Daniela Anagumbla\nVestuario: Allison López, Emilia Bucheli, Dayana Andino\nMaquillaje: Allison López, Emilia Bucheli, Dayana Andino",
  "producer": "Alejandro Cabrera",
  "director": "Maribel Vásquez",
  "city": null,
  "country": "Ecuador",
  "year": 2020,
  "thumbnailurl": null,
  "posterurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter%2Fp235.jpg?alt=media&token=43db3b97-af45-4b29-bac4-d2f403595e75",
  "poster375webpurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fposter375webp%2Fp235.webp?alt=media&token=ab017a41-3325-4405-bd23-855fc6fb489a",
  "bgurl": "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fcatalogo%2Fbg%2Fb235.jpg?alt=media&token=33d7b372-ab36-412e-93ca-7151b890fafc",
  "genres": "Fantasía, Ficción",
  "age": "13+",
  "maxres": "1080p",
  "type": "Cortometraje",
  "language": "ES",
  "status": "active",
  "fomo": 0,
  
 }
]