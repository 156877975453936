import React, { useState } from 'react';
import './OriginalsUpdate.css';
import { original } from "../originalContent";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";

function Originals() {
    const [selectOriginal, setSelectOriginal] = useState("");
    const { originalId } = useParams();
    
    return (
    <div className="originals__update">
        <div className="originals__update__container">
            <h2 className="originals__update__title">Otras series que te pueden interesar</h2>
            <p className="originals__update__explora">Explora presionando SHIFT + rueda del mouse</p>
        </div>
        <div className="originals__update__posters" title="">
        
        {original
        .filter((poster) => 
        !poster.videoId.includes(originalId)
        ).map((poster, key) => (
            <div className = {`originals__update__poster__props`} key={poster.id}>
                <Link to={`/originals/${poster.videoId}`}>
                <img 
                    key={poster.id}
                    id={poster.id}
                    value={selectOriginal}
                    className= {`originals__update__poster`}
                    src={poster.thumbnailurl2} 
                    title={poster.title}
                    alt={poster.title}
                />
                </Link>
            </div>
            ))}
    </div>
    </div>
    );
}

export default Originals;