import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import "./RowCortoTendencia.css";
import YouTube from 'react-youtube';
import movieTrailer from "movie-trailer";
import { NavigateBeforeSharp, NavigateNextSharp, FlagSharp } from "@material-ui/icons";
import Skeleton from "./skeleton/Skeleton";
import { cortometrajes } from "../assets/catalogoTLBNG";
import { bgcover } from "../assets/bgcover";


function RowCortoFiccion({ title, fetchUrl, isLargeRow }) {
    const [creation,setCreation] = useState([]);
    const [trailerUrl, setTrailerUrl] = useState("");
    const [scrollX, setScrollX] = useState(0); //-50
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        async function fetchData() {
            setIsLoading(true);
            setCreation(cortometrajes
            );
            setIsLoading(false);
            return cortometrajes;
        }
        fetchData();

    }, []);

    const opts = {
        height: "390",
        width: "100%",
        playerVars: {
            //https://developers.google.com/youtube/player_parameters
            autoplay: 1,
            modestbranding: 1,
            rel: 0,
            showinfo: 0,
        },
    };

    const handleClick = (video) => {
        if (trailerUrl) {
            setTrailerUrl("");
        } else {
            movieTrailer(video?.title || "TELEBOING en vivo")
                .then((url) => {
                    const urlParams = new URLSearchParams(new URL(video?.videohref_yt_dmq).search);
                    setTrailerUrl(urlParams.get("v"));
                })
                .catch((error) => console.log(error));
        }
    };

    const handleLeftArrow = () => {
        let x = scrollX + Math.round(window.innerWidth / 2);
        if(x > 0) {
            x=0;
        }
        setScrollX(x);
    }

    const handleRightArrow = () => {
        let x = scrollX - Math.round(window.innerWidth / 2);
        let listW = creation.length * 420;
        if ((window.innerWidth - listW) > x) {
            x = (window.innerWidth - listW) - 1500;
        }
        setScrollX(x);
    }

    function truncate(str, n) {
        return str?.length > n ? str.substr(0, n-1) + '...' : str;
    }

    return (
        <div className="rowcortotendencia"
            style={{
            backgroundSize: "cover",
            backgroundImage: `linear-gradient(to top, black 10%, transparent 60%), linear-gradient(to right, #0c0810, transparent), linear-gradient(to bottom, black 35%, transparent 90%), url(${bgcover[5]?.url})`,
            backgroundPosition: "top center",
            }}
        >
            <div className="rowcortotendencia__container">
            <h2> {title} </h2>
            </div>
            <div className="rowcortotendencia__poster__left" onClick={handleLeftArrow}>
                < NavigateBeforeSharp style={{fontSize: 50}} />
            </div>
            <div className="rowcortotendencia__poster__right" onClick={handleRightArrow}>
                < NavigateNextSharp style={{fontSize: 50}} />
            </div>
            <div className="rowcortotendencia__posters" style={{marginLeft: scrollX/* , width: creation.length * 150 */}}>

            {isLoading ? (
            <Skeleton type="feed" />
            ) : (
            creation.filter((video, key) => 
            (video.status.toLowerCase()).includes("active")&&(video.genres.toLowerCase()).includes("ficción")
            ).map((video, key) => (
                    <div className = {`rowcortotendencia__poster__props`} key={video.id}>
                        <Link to={`/watch/${video.id}`}>
                        <img 
                            key={video.id}
                            className= {`rowcortotendencia__poster`}
                            src={video.poster375webpurl || video.poster375jpgurl}
                            alt={video.title}
                        />
                        </Link>
                        <p className= {`rowcortotendencia__poster__title`}>{video.title} 
                        &nbsp;&nbsp; • &nbsp;&nbsp; 
                        <FlagSharp style={{fontSize:"20px", paddingRight:"5px", opacity:"0.7"
                        }} />
                        {video.country} 
                        </p>
                    
                        <div className = {`rowcortotendencia__poster__details`}>
                            <h2 className= {`rowcortotendencia__poster__description`}>
                            {truncate(video?.synopsis, 100)}
                            </h2>
                            <div className= {`rowcortotendencia__poster__buttons`}>
                            <button
                            onClick={() => handleClick(video)}
                            style={{padding:"10px",
                            backgroundColor:"#4a3060",
                            color:"white",
                            fontSize:"0.7rem",
                            fontWeight:"smaller",
                            cursor:"pointer",
                            border:"none",
                            borderRadius:"20%",
                            marginTop:"10px"
                            }}
                            >
                                Play
                            </button>

                            <button
                            onClick={() => handleClick(video)}
                            style={{padding:"10px",
                            backgroundColor:"#282828",
                            color:"white",
                            fontSize:"0.7rem",
                            fontWeight:"smaller",
                            cursor:"pointer",
                            border:"none",
                            borderRadius:"20%",
                            marginTop:"10px",
                            marginLeft:"10px"
                            }}
                            >
                                Stop
                            </button>
                            </div>
                        </div>
                    </div>
                )).reverse()
            )}

            </div>
            <div className="rowcortotendencia__player">
            {trailerUrl && <YouTube videoId ={trailerUrl} opts={opts} />}
            </div>

        </div>
    );
}

export default RowCortoFiccion;
