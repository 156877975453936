import React from 'react';
/* import { useParams } from "react-router-dom"; */
import "./So1Screen.css";
import logo from "../../assets/teleboing.svg";
import FooterLinks from "../../components/Footer/FooterLinks";
import Social from "../../components/Footer/SocialLinks";
import Copyright from "../../components/Footer/Copyright";
import GoToTop from '../../GoToTop';
import { Link } from "react-router-dom";


function So1Screen() {

    const shoots = [
        {
            id: 1,
            imgUrl: "https://firebasestorage.googleapis.com/v0/b/teleboing.appspot.com/o/resources%2Fcontent%2FSerieOriginal01%2Fshoot1-ERDM.jpg?alt=media&token=d09ad07a-927d-4352-b0ea-a50c27f80b0c",
            description: "La copa, la vida."
        },
        {
            id: 2,
            imgUrl: "https://firebasestorage.googleapis.com/v0/b/teleboing.appspot.com/o/resources%2Fcontent%2FSerieOriginal01%2Fshoot2-ERDM.jpg?alt=media&token=b7e80d1f-ccd2-4900-be66-74a5013663eb",
            description: "Helena de Doha"
        },
        {
            id: 3,
            imgUrl: "https://firebasestorage.googleapis.com/v0/b/teleboing.appspot.com/o/resources%2Fcontent%2FSerieOriginal01%2Fshoot3-ERDM.jpg?alt=media&token=49c73e1d-7435-4eef-9c92-c0c6639f721b",
            description: "La joya de latinoamérica"
        },
        {
            id: 4,
            imgUrl: "https://firebasestorage.googleapis.com/v0/b/teleboing.appspot.com/o/resources%2Fcontent%2FSerieOriginal01%2Fshoot4-ERDM.jpg?alt=media&token=1f3bb9d1-77ce-4846-819b-d63d27aab5a0",
            description: "Bambí está en casa."
        },
        {
            id: 5,
            imgUrl: "https://firebasestorage.googleapis.com/v0/b/teleboing.appspot.com/o/resources%2Fcontent%2FSerieOriginal01%2Fshoot5-ERDM.jpg?alt=media&token=bdae981b-7975-4b55-bb8b-cdf3ef987e1d",
            description: "Hackendo lo imposible."
        },
        {
            id: 6,
            imgUrl: "https://firebasestorage.googleapis.com/v0/b/teleboing.appspot.com/o/resources%2Fcontent%2FSerieOriginal01%2Fshoot6-ERDM.jpg?alt=media&token=69fd9577-b544-4f29-925a-ba23d43105fd",
            description: "Día del golpe."
        }
    ]
    
    return (
        <div>
            <div className="so1screen">

                <div className="so1screen__box__signup">
                    <Link to="/" style={{ textDecoration: 'none' }}>
                        <div className="so1screen__logo__signup">
                            <img
                                className="so1screen__logo"
                                src={logo}
                                alt="logo TELEBOING" title="made for fun"
                            />
                        </div>
                    </Link>

                    <div className="so1screen__signup">
                        <form >
                            <button>
                                <Link to="/signin" style={{ textDecoration: 'none', color: "white" }}>Inicia sesión
                                </Link>
                            </button>
                            <button>
                                <Link to="/signup" style={{ textDecoration: 'none', color: "white" }}>Suscríbete
                                </Link>
                            </button>
                        </form>
                    </div>
                </div>
                
                <div className="so1screen__container">

                <header className="so1screen__banner"
                style={{
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundImage: `linear-gradient(to top, black 10%, transparent 85%), linear-gradient(to right, black, transparent), 
                     url("https://firebasestorage.googleapis.com/v0/b/teleboing.appspot.com/o/resources%2Fcontent%2FSerieOriginal01%2FBg-ERDM.png?alt=media&token=3563551e-0317-4866-9e70-c9f17b3f82a0"
                )`,
                backgroundAttachment:"fixed", 
                backgroundPosition: "top center",
                height:"100%",
                width:"100%"
                }}>

                <div className = {`so1screen__poster`}>
                    <p className="so1screen__details__headline__banner">una serie original de Teleboing</p>
                    <img 
                    className= {`so1screen__poster__item1`}
                    src="https://firebasestorage.googleapis.com/v0/b/teleboing.appspot.com/o/resources%2Fcontent%2FSerieOriginal01%2FLogo-LCDM.png?alt=media&token=de254d96-ffd4-45d0-a434-553ff9a48da6"
                    alt="La copa del mundo"
                    />
                    <p className="so1screen__details__description__banner"> 7 desconocidos de diferentes países de latinoamérica se encuentran en Qatar durante la fiesta deportiva más importante del mundo para llevar a cabo una tarea imposible.
                    <br/><br/>
                    Robar la copa del mundial FIFA Qatar 2022.</p>
                </div>
                </header>
                </div>

                <div className="so1screen__video">
                    <iframe 
                    src={`https://www.youtube.com/embed/VNzljcJHGWE?modestbranding=1&rel=0&showinfo=0&fs=1&cc_load_policy=1`}
                    frameBorder="0"
                    allowFullScreen
                    title="La copa del mundo"
                    width="100%"
                    height="720px" >
                    </iframe>
                </div>

                <div className="so1screen__props">
                    <div>
                    <img 
                    className= {`so1screen__poster__item2`}
                    src="https://firebasestorage.googleapis.com/v0/b/teleboing.appspot.com/o/resources%2Fcontent%2FSerieOriginal01%2FPoster-LaCopaDelMundo-2.webp?alt=media&token=e6c65458-b9a0-4c69-a224-3766170c723e"
                    alt="El robo del mundial"
                    />
                    </div>

                    <div className="so1screen__details" >
                        <p className="so1screen__details__headline">una serie original de Teleboing</p>
                        <h2 className="so1screen__details__title">La copa del mundo</h2>

                        <p className="so1screen__details__subinfo">2023 &nbsp;&nbsp; | &nbsp;&nbsp; 8 episodios &nbsp;&nbsp; | &nbsp;&nbsp; 16+ &nbsp;&nbsp; | &nbsp;&nbsp; 4K </p>

                        <p className="so1screen__details__description0"> 7 desconocidos de diferentes países de latinoamérica se encuentran en Qatar durante la fiesta deportiva más importante del mundo para llevar a cabo una tarea imposible.
                        <br/><br/>
                        Robar la copa del mundial FIFA Qatar 2022.</p>

                        <p className="so1screen__details__description"> <span>Sinopsis:</span>
                        <br/>
                        La copa del mundial de fútbol tiene un valor de USD 20 millones. El magnate árabe “Al Janoub Hadid” ha planeado en secreto bañar en diamantes la copa para que alcance, sin que nadie lo sepa, un valor de USD 1,000 millones.
                        <br/><br/>
                        7 desconocidos de diferentes países de latinoamérica se encuentran en Qatar durante la fiesta deportiva más importante del mundo para llevar a cabo una tarea imposible.
                        <br/><br/>
                        <strong>Robar la copa del mundial FIFA Qatar 2022.</strong>
                        <br/><br/>
                        ***
                        <br/><br/>
                        El líder del grupo y quien reclutó a todos se hace llamar, El Presidente. Cada miembro del grupo para esconder su verdadero nombre debe elegir ser llamado por un número que represente algo sólo para ellos. Un código nombre que signifique algo muy importante en sus vidas. De esa forma nunca serán descubiertos.
                        <br/><br/>
                        La primera escena en la que conocemos a El Presidente, lo vemos en medio de un pasadizo interminable, con la copa del mundo en sus manos, bañada de sangre, y un ejército de las fuerzas especiales qatarí, fuertemente armado, entrando a matar.
                        <br/><br/>
                        ¿Habrá fracasado el plan? ¿Dónde está el resto de la banda?
                        <br/><br/>
                        </p>
                        <p className="so1screen__details__genres"> <span>Género:</span> Thriller, Comedia, Acción.</p>
                        <p className="so1screen__details__genres"> <span>Estreno global:</span>2023.</p>
                        <p className="so1screen__details__genres"> <span>Idioma:</span>Español.</p>
                        <p className="so1screen__details__genres"> <span>Subtítulos disponibles:</span>EN&nbsp;&nbsp; | &nbsp;&nbsp;PT&nbsp;&nbsp; | &nbsp;&nbsp;FR&nbsp;&nbsp; | &nbsp;&nbsp;DE&nbsp;&nbsp; | &nbsp;&nbsp;ZH&nbsp;&nbsp; | &nbsp;&nbsp;JP&nbsp;&nbsp; | &nbsp;&nbsp;KR&nbsp;&nbsp; | &nbsp;&nbsp;HI</p>
                    </div>
                </div>

                <div className="so1screen__container2">

                <div className="so1screen__details" >
                    <p className="so1screen__details__headline">Única en su estilo</p>
                    <h2 className="so1screen__details__title">El propósito de la serie</h2>

                    <p className="so1screen__details__subinfo">Ficción + Acción + Comedia + Fútbol </p>

                    <p className="so1screen__details__description"> <span>Es un himno audiovisual latinoamericano:</span>
                    <br/>
                    "El robo del mundial" es una producción de y para Latinoamérica. Icónica y única es su tipo. Es la primera serie producida durante el desarrollo de un mundial de fútbol.
                    <br/><br/>
                    Para latinoamérica el fútbol es una fuente de emociones y un evento que nos conecta como personas y con aquello que nos acerca y nos abraza, sin importan quienes seamos.
                    <br/><br/>
                    Con esta serie tenemos el propósito de construir un himno audiovisual para latinoamérica, que permita a todos los ciudadanos de la región ver una y otra vez la serie cuando sientan nostalgia y cuando quieran revivir sensaciones y sentimientos.
                    <br/><br/>
                    En esta producción convergen diferentes tipos de culturas, pensamientos y personas, de varios países como: Perú, Colombia, Ecuador, Argentina, Chile, México, Costa Rica, Estados Unidos hispano y España.
                    <br/><br/>
                    ¿Qué nos une a todos ellos? Una misma lengua, una sola emoción, un mismo orgullo. El fútbol latinoamericano.
                    </p>
                    <p className="so1screen__details__genres"> <span>Género:</span> Thriller, Comedia, Acción.</p>
                    <p className="so1screen__details__genres"> <span>Locaciones:</span>Doha, Al Rayyan, Al-Khor, Lusail, Al-Wakrah en Qatar.<br/>Lima, Medellín, Buenos Aires, Ciudad de México, Santiago de Chile, Quito, San José de CR y Miami. </p>
                    <p className="so1screen__details__genres"> <span>Producida por:</span>Teleboing.</p>
                    <p className="so1screen__details__genres"> <span>En asociación con:</span>Latinamerica.</p>
                    <p className="so1screen__details__genres"> <span>Productor ejecutivo:</span>Jorge Toledo-Chacón.</p>
                    {/* <p className="so1screen__details__country"> <span>País:</span> Perú.</p> */}
                </div>
                </div>

                <div className="so1screen__shoots">
                    {
                    shoots.map(({ id, imgUrl, description }) =>
                        <div key={id}>
                            <img
                                key={id}
                                src={imgUrl}
                                style={{ width: "100%", height: "auto", padding: "20px 0", margin: "0", transition: "all 0.8s ease" }}
                                effect='blur'
                                alt={description}
                                placeholderSrc={process.env.PUBLIC_URL + "/android-chrome-192x192.png"} />
                            <p>Escena: {description}</p>
                        </div>
                    )
                    }
                </div>

            </div>
            <Social />
            <FooterLinks />
            <Copyright />
            <GoToTop />
        </div>
    )
}
export default So1Screen;