import React from 'react';
import { useParams } from "react-router-dom";
import "./WatchScreen.css";
import Nav from "../components/Nav";
import { cortometrajes } from "../assets/catalogoTLBNG";
import WatchUpdate from '../components/WatchUpdate';
import FooterLinks from "../components/Footer/FooterLinks";
import Social from "../components/Footer/SocialLinks";
import Copyright from "../components/Footer/Copyright";
import NavBottom from "../components/NavBottom";
import GoToTop from '../GoToTop';
import {Helmet} from "react-helmet";
import Head from "next/head";


function WatchScreen() {

    const { watchId } = useParams();
    const a = cortometrajes.filter((item, key) => 
    (item.id === watchId));
    
    return (
        <main>
            <Head>
                <title>{a[0]?.title} {a[0]?.genres} {a[0]?.year} {a[0]?.country}</title>
                <meta 
                name='description'
                content={a[0]?.synopsis}
                />
            </Head>

            <Nav />
            <div className="watchscreen">
                {cortometrajes
                .filter((poster) => 
                poster.id === parseInt(watchId))
                .map((poster) => (
                
                <div className="watchscreen__container">

                <Helmet>
                    <title>{poster.title} / {poster.genres} {(poster.year).toString()} {poster.country}</title>
                    <meta 
                    name='description'
                    content={poster.synopsis}
                    />
                </Helmet>

                <header className="watchscreen__banner"
                style={{
                backgroundSize: "cover",
                backgroundImage: `linear-gradient(to top, black 20%, transparent 90%), linear-gradient(to right, black, transparent), 
                     url("${poster.bgurl}"
                )`, 
                backgroundPosition: "top center",
                }}>

                <div className = {`watchscreen__poster`}>
                    <img 
                    key={poster.id}
                    id={poster.videoId}
                    className= {`watchscreen__poster__item`}
                    src={poster.poster375webpurl || poster.poster375jpgurl} 
                    title={poster.title}
                    description={poster.synopsis}
                    alt={poster.title}
                    />
                </div>

                <div className="watchscreen__props">
                
                    <div className="watchscreen__details" >
                        {/* <p className="watchscreen__details__headline">{poster.genres}</p> */}
                        <h2 className="watchscreen__details__title">{poster.title}</h2>

                        <p className="watchscreen__details__subinfo">{ poster.year } &nbsp;&nbsp; | &nbsp;&nbsp; 00:{poster.duration} &nbsp;&nbsp; | &nbsp;&nbsp; {poster.age} &nbsp;&nbsp; | &nbsp;&nbsp; {poster.maxres}</p>

                        <p className="watchscreen__details__description"> <span>Sinopsis:</span> {poster.synopsis} </p>
                        {/* <p className="watchscreen__details__description"> <span></span> {poster.description} </p> */}
                        <p className="watchscreen__details__genres"> <span>Director:</span> {poster.director} </p>
                        <p className="watchscreen__details__genres"> <span>Casa Productora:</span> {poster.producer} </p>
                        <p className="watchscreen__details__genres"> <span>Elenco:</span> {poster.starring} </p>
                        <p className="watchscreen__details__genres"> {poster.awards} </p>
                        <p className="watchscreen__details__genres"> <span>Género:</span> {poster.genres} </p>
                        <p className="watchscreen__details__country"> <span>País:</span> {poster.country} </p>
                    </div>
                </div>
                </header>
                <div className="watchscreen__video">
                        <iframe 
                        src={`https://www.youtube.com/embed/${poster.videoId_yt_dmq}?modestbranding=1&rel=0&showinfo=0&fs=1&cc_load_policy=1&iv_load_policy=3`}
                        frameBorder="0"
                        allowFullScreen
                        title={poster.title}
                        width="100%"
                        height="400px" >
                        </iframe>
                    </div>
                </div>
                ))}

            </div>
            <WatchUpdate />
            <Social />
            <FooterLinks />
            <Copyright />
            <GoToTop />
            <NavBottom />
        </main>
    )
}
export default WatchScreen;