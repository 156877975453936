import React, { useState } from 'react';
import { useHistory} from "react-router-dom";
import "../screens/ErrorScreen.css";
import logo from "../assets/teleboing.svg";
import Copyright from '../components/Footer/Copyright';

function ErrorPage({ error }) {

  const history = useHistory();

  const handleBack = (e) => {
    e.preventDefault();
    history.goBack();
  };

  const handleHome = (e) => {
      e.preventDefault();
      history.push("/")
  };

  return (
    <div>
      <div className="errorScreen">
        <header className="errorScreen__banner"
        style={{
            backgroundSize: "cover",
            backgroundImage: `linear-gradient(to top, #111, transparent), 
                  url(
                "https://firebasestorage.googleapis.com/v0/b/teleboing.appspot.com/o/resources%2Fcontent%2Fteleboing-error-cover.webp?alt=media&token=90ca38c5-2785-499c-9a91-9b3de7bc658e&_gl=1*171ec9n*_ga*MTI2Mzc0OTYyNS4xNjg2MzI4MjQw*_ga_CW55HF8NVT*MTY4NjM3MDE1NS4yLjEuMTY4NjM3MDI0Ni4wLjAuMA.."
            )`, 
            backgroundPosition: "center center",
            height:"100%",
            width:"auto",
            objectFit:"contain",
        }}>

            
        <div className="errorScreen__content">

          <div className="errorScreen__msg">
              <h2 className="errorScreen__msg__t1">¡Oops!</h2>

              <p className="errorScreen__msg__t2">Algo salió mal.</p>

              <p className="errorScreen__msg__t3">{error?.toString() || "404"}</p>

              <button onClick={handleBack}>Volver atrás</button>
              <button onClick={handleHome}>Ir al inicio</button>

              <div className='errorScreen__logo'>
                <img
                    src={logo}
                    alt="demoqrata" title="Sólo la verdad."
                />
              </div>

          </div>
          
        </div>
        </header>
        </div>
        {/* <SocialLinks/> */}
        <Copyright/>
    </div>
  );
}

export function useErrorBoundary() {
  const [error, setError] = useState(null);

  const ErrorBoundary = ({ children }) => {
    if (error) {
      return <ErrorPage error={error} />;
    }
    return children;
  };

  const handleError = (error) => {
    setError(error);
  };

  const resetError = () => {
    setError(null);
  };

  return { ErrorBoundary, handleError, resetError, ErrorPage };
}