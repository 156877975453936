import React, { useEffect, useState, useContext } from 'react';
import { SearchContext } from '../context/SearchContext';
import "./RecommendedVideos.css";
import VideoCard from './VideoCard';
import axios from "../axios";
import axiosCancelToken from "axios";
import FlipMove from 'react-flip-move';
import requests from "../requests";
import Skeleton from "./skeleton/Skeleton";

function RecommendedVideos() {

    let cancelToken;

    const [inputSearch, setInputSearch] = useContext(SearchContext);
    const [videos, setVideos] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    if (typeof cancelToken != typeof undefined) {
        cancelToken.cancel("Canceling the previous req.")
    }
    cancelToken = axiosCancelToken.CancelToken.source();

    
    useEffect(() => {
        async function fetchData() {
            setIsLoading(true);
            const request = await axios.get(requests.fetchTodosTop, {cancelToken: cancelToken.token});
            setVideos(request.data);
            setIsLoading(false);
            console.log("re-rendered Search");
            return request;
        };
        fetchData();
    }/* , [] */)/* ) */;
    

    return (
        <div className="recommendedVideos">
            <div className="recommendedVideos__msg">
                <h2>Te recomendamos ver el siguiente contenido para: # <span>{inputSearch}</span></h2>
            </div>
            <div className="recommendedVideos__videocard">
            <FlipMove>
            {isLoading ? (
            <Skeleton type="feed" />
            ) : (
            videos.filter((video, key) => 
                (video.title.toLowerCase() && 
                JSON.stringify(video.description).toLowerCase()).includes(inputSearch.toLowerCase())
                
            ).map((video, key) => (
                <VideoCard  
                    key={video.id} 
                    video={video} 
                />
            ))
            )}  
            </FlipMove>
            </div>
        </div>
    )
}

export default RecommendedVideos;