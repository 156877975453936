import React, { useState } from 'react';
import './OriginalsUpdate.css';
import { cortometrajes } from "../assets/catalogoTLBNG";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";

function WatchUpdate() {
    const [selectWatch, setSelectWatch] = useState("");
    const { watchId } = useParams();
    
    return (
    <div className="originals__update">
        <div className="originals__update__container">
            <h2 className="originals__update__title">Otras películas que te pueden gustar</h2>
            <p className="originals__update__explora">Explora presionando SHIFT + rueda del mouse</p>
        </div>
        <div className="originals__update__posters" title="">
        
        {cortometrajes
        .filter((poster) => 
        (poster.id !== watchId)&&((poster.genres.toLowerCase()).includes("comedia"/* poster.genres */))&&((poster.status.toLowerCase()).includes("active"))
        ).map((poster, key) => (
            <div className = {`originals__update__poster__props`} key={poster.id}>
                <Link to={`/watch/${poster.id}`}>
                <img 
                    key={poster.id}
                    id={poster.id}
                    value={selectWatch}
                    className= {`originals__update__poster`}
                    src={poster.poster375webpurl || poster.poster375jpgurl} 
                    title={poster.title}
                    alt={poster.title}
                />
                </Link>
            </div>
            ))}
    </div>
    </div>
    );
}

export default WatchUpdate;