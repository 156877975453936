import React, { useEffect, useState, Suspense/* , lazy */ } from 'react';
import { useErrorBoundary } from './components/ErrorBoundary';
import { SearchContext } from './context/SearchContext';
import { SubsIdContext } from './context/SubsIdContext';
import { KeywordsContext } from './context/KeywordsContext';
import './App.css';
import HomeScreen from './screens/HomeScreen';
import LoginScreen from './screens/landing/LoginScreen';
import So1Screen from './screens/landing/So1Screen';
import Originals from './screens/OriginalsScreen';
import Watch from './screens/WatchScreen';
import Search from './screens/SearchScreen';
import NoSearch from './screens/NoSearchScreen';
import ProfileScreen from './screens/ProfileScreen';
/* import PersonalizeScreen from './screens/PersonalizeScreen'; */
import TvScreen from './screens/TvScreen';
import PlanScreen from './screens/PlanScreen';
import NewUserScreen from './screens/NewUserScreen';
import SignInScreen from './screens/SignInScreen';
import ResetPasswordScreen from './screens/ResetPasswordScreen';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect/* ,
  useHistory */
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logout, login, selectUser } from "./features/userSlice";
import firebaseApp, { auth } from "./firebase.js";
import 'firebase/compat/storage';

function App() {
  const db = firebaseApp.firestore();
  const { ErrorBoundary, ErrorPage } = useErrorBoundary();
  const user = useSelector(selectUser);
  const dispatch = useDispatch();
  const [ datasub, setDatasub ] = useState();
  const [inputSearch, setInputSearch] = useState('');
  const [subsId, setSubsId] = useState('');
  const [words, setWords] = useState([]);

    useEffect(() => {
      const unsubscribe = auth.onAuthStateChanged((userAuth) => {
        if (userAuth) {
          // Logged in
          console.log(userAuth);
          dispatch(login({
            uid: userAuth.uid,
            email: userAuth.email,
          }));
        } else {
          // Logged out
          dispatch(logout());
        }
      }, (error) => {
        console.log('Error during authentication:', error);
      });
      return unsubscribe;
    }, [dispatch]);
  
    //visualizar los datos ingresados del suscriptor.
    useEffect(() => {
      const getSub = async () => {
        if (user) {
          const query = db.collection('subsDetails').where('email', '==', user.email);
          query.get()
            .then((querySnapshot) => {
              querySnapshot.forEach((doc) => {
                const data = doc.data();
                setDatasub(data);
              });
            })
            .catch((error) => {
              console.log('Error getting documents:', error);
            });
        }
      };
      getSub();
    }, [user, db]);
  
  
  return (
    
    <KeywordsContext.Provider value={[words, setWords]}>
    <SearchContext.Provider value={[inputSearch, setInputSearch]}>
    <SubsIdContext.Provider value={[subsId, setSubsId]}>
    <div className="app" >
    <ErrorBoundary>
      <Router onUpdate={() => window.scrollTo(0, 0)}>
        {!user && datasub?.status !== "ACTIVE"
        ? (
          
        <Switch>

          <Route exact path="/error">
            <ErrorPage />
          </Route>

          <Route exact path="/lacopadelmundo">
            <So1Screen />
          </Route>

          <Route exact path="/reset">
            <ResetPasswordScreen />
          </Route>

          <Route exact path="/signin">
            <SignInScreen />
          </Route>

          <Route exact path="/newuser">
            <NewUserScreen />
          </Route>

          <Route exact path="/signup">
            <PlanScreen />
          </Route>

          <Route path="/">
            <LoginScreen />
          </Route>

          <Route exact path="/home">
            {user ? <Redirect to="/home" /> : <LoginScreen />}
          </Route >

        </Switch>

        ) : (
          <Suspense fallback={<h1>Cargando...</h1>}>
            <Switch>

            <Route exact path="/">
              {user ? <Redirect to="/home" /> : <LoginScreen />}
            </Route>
            {/* <Route exact path="/">
              {<Redirect to="/home" />}
            </Route> */}
            <Route path="/home">
              <HomeScreen correoUsuario={user.email} />
            </Route>
            <Route exact path="/newuser">
              {<Redirect to="/home" />}
            </Route>
            <Route path="/search/:searchTerm">
              <Search />
            </Route>
            <Route path="/search">
              <NoSearch />
            </Route>
            <Route path="/originals/:originalId" exact>
              <Originals />
            </Route>
            <Route path="/watch/:watchId" exact>
              <Watch />
            </Route>
            <Route path="/profile" exact>
              <ProfileScreen />
            </Route>
            {/* <Route path="/personalize" exact>
              <PersonalizeScreen />
            </Route> */}
            <Route path="/tv">
              <TvScreen />
            </Route>
            <Route exact path="/error">
              <ErrorPage />
            </Route>
            </Switch>
          </Suspense>
        )}
      </Router>
    </ErrorBoundary>
    </div>
    </SubsIdContext.Provider>
    </SearchContext.Provider>
    </KeywordsContext.Provider>
  );
}

export default App;