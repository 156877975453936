import React, { useRef, useState, useEffect, useContext } from 'react';
import { useHistory } from "react-router-dom";
import { SubsIdContext } from '../context/SubsIdContext';
import "./NewUserScreen.css";
import firebaseApp from "../firebase";
import 'firebase/compat/storage';
import "firebase/compat/firestore";
import NavProfile from "../components/NavProfile";
import FooterLinks from "../components/Footer/FooterLinks";
import Social from "../components/Footer/SocialLinks";
import Copyright from "../components/Footer/Copyright";
import { auth } from "../firebase.js";
import GoToTop from '../GoToTop';
import RemoveRedEyeSharp from "@material-ui/icons/RemoveRedEyeSharp";
import VisibilityOffSharp from "@material-ui/icons/VisibilityOffSharp";
import { Helmet } from "react-helmet-async";

function NewUserScreen() {
    const [subsId, setSubsId] = useContext(SubsIdContext);
    const emailRef = useRef(null);
    const passwordRef = useRef(null);
    const [pushHome, setPushHome] = useState(false);
    const history = useHistory();
    const db = firebaseApp.firestore();
    const collectionRef = db.collection('subsDetails');
    const query = collectionRef.where('subsId', '==', subsId);
    const [paypalAccessToken, setPaypalAccessToken] = useState("");
    const [subDetail, setSubDetail] = useState([]);
    const [password, setPassword] = useState(false);
    const [showPassword, setShowPassword] = useState(false);

    /* const clientId = 'AZIZ1hXuAYNQRDBZ6Gsfrz60Ta0VRXB6XXsfUiwsid59LsyUMZ1lInz7wXcX8nRoLc6btRKjgdLQ7EuI';
    const clientSecret = 'EKBM6KKqVm74de8GyILal1yNoqgOqij25jy04W7c81DcwJZjCeZGzQyU6TBf5oxshXrxucrMqtMPRWIE'; */
    /* const credentials = `${clientId}:${clientSecret}`;
    const encodedCredentials = btoa(credentials); */
    /* console.log("encoded", encodedCredentials); */

    const handleSubDetails = async (e) => {

        // crear access token
        var myHeadersAT = new Headers();
        myHeadersAT.append("Authorization", "Basic QVdkNDJjRktRMDE2TEpiaXBGSjJnc3k1TFAzc3B5TUJ4QTRpVWxvSVdBU0FaR1BzQ3NHLVJ3cUY3dXZoOWVjejJ6b2pmajZSakpPNUdQV2Q6RUZwOWRMdVhvSzVhRXdOaUZHUmhfS0t6TmNKQnphUE0ya0t0U01fa2EtcWZndHYzVlhidGlIS3c5aVFjU0NmQUNNNjZMR0RlZnR3MmF2M3Y=");
        myHeadersAT.append("Content-Type", "application/x-www-form-urlencoded");

        var urlencodedAT = new URLSearchParams();
        urlencodedAT.append("grant_type", "client_credentials");

        var requestOptionsAT = {
            method: 'POST',
            headers: myHeadersAT,
            body: urlencodedAT,
            redirect: 'follow'
        };

        fetch("https://api-m.paypal.com/v1/oauth2/token", requestOptionsAT)
            .then(responseAT => responseAT.json())
            .then(resultAT => {
                /* console.log(typeof resultAT.access_token);
                console.log("Token:", resultAT.access_token); */
                setPaypalAccessToken(resultAT.access_token);

                // Obtiene datos del suscriptor.
                var myHeaders = new Headers();
                myHeaders.append("Content-Type", "application/json");
                myHeaders.append("Authorization", `Bearer ${resultAT.access_token}`);

                var requestOptions = {
                    method: 'GET',
                    headers: myHeaders,
                    redirect: 'follow'
                };

                fetch(`https://api-m.paypal.com/v1/billing/subscriptions/${subsId}`, requestOptions)
                    .then(response => response.json())
                    .then(
                        result => {
                            /* console.log("result:", JSON.stringify(result, null, 2)); */
                            setSubDetail(result);
                        }
                    )
                    .catch(error => console.log('error', error));
            })
            .catch(error => console.log('error', error));
    };

    useEffect(() => {
        handleSubDetails();
    }/* , [] */)

    /* console.log("SubDetail:", subDetail); */

    const register = (e) => {
        e.preventDefault();

        auth
            .createUserWithEmailAndPassword(
                emailRef.current.value,
                passwordRef.current.value
            )
            .then((authUser) => {
                /* console.log(authUser); */

                query.get().then((querySnapshot) => {
                    const updatePromises = [];

                    querySnapshot.forEach((doc) => {
                        const documentRef = collectionRef.doc(doc.id);

                        updatePromises.push(
                            documentRef.update({
                                uid: authUser.user.uid,
                                email: authUser.user.email,
                                status: subDetail.status,
                                billing: subDetail.billing_info,
                                links: subDetail.links,
                                planId: subDetail.plan_id,
                                subscriber: subDetail.subscriber,
                            })
                        );
                    });

                    Promise.all(updatePromises)
                        .then(() => {
                            setPushHome(true);
                            history.replace("/home");
                        })
                        .catch((error) => {
                            console.error('Error updating documents:', error);
                        });
                });
            })
            .catch((error) => {
                alert(error.message);
            });
    };

    const handlePasswordChange = (e) => {
        e.preventDefault();
        setPassword(e.target.value);
      };
    
    const handleTogglePassword = (e) => {
        e.preventDefault();
        setShowPassword(!showPassword);
      };

    return (
        <div>
            <Helmet>
                <title>NewUser : Teleboing</title>
                <meta
                    name="title"
                    content="NewUser : Teleboing"
                />
                <meta
                    name="description"
                    content=""
                    />
                <link
                    rel='canonical'
                    href='/'
                />
                <meta 
                    name='image' 
                    content="https://firebasestorage.googleapis.com/v0/b/teleboing.appspot.com/o/resources%2Fcontent%2Fteleboing1-feat1-1.webp?alt=media&token=0a9957dc-c2db-4f29-b3cf-a8b077f80d04"
                />
            </Helmet>

            <NavProfile />

            <header className="newuser__banner"
                style={{
                    backgroundSize: "cover",
                    backgroundImage: `linear-gradient(to top, black 5%, transparent 30%), linear-gradient(to top, #00001b, transparent), 
                     url(
                    "https://firebasestorage.googleapis.com/v0/b/teleboing.appspot.com/o/resources%2Fcontent%2Fteleboing-feat4.webp?alt=media&token=7599db98-2eeb-4d6c-9326-86747c1e2b97"
                )`,
                    backgroundPosition: "center center",
                }}>

                <div className="newuser">

                    <div className="newuser__container">
                        <div className="newuser__msg">
                            <span>Paso 2 de 2</span>
                            <p className="newuser__msg__approvedpay">El proceso de pago se ha realizado con éxito.</p>
                            <p className="newuser__msg__subcode">Tu código de suscriptor es: <span>{subsId}</span> </p>
                            <p className="newuser__msg__enterdata">Ingresa tu email y un password para empezar tu suscripción:</p>
                        </div>

                        <form >
                            <input ref={emailRef} type="email" placeholder="email" id="email"
                            autoComplete="email" />

                            <div className="newuser__formpassword">
                            <input ref={passwordRef} type={showPassword ? 'text' : 'password'}
                            value={password}
                            placeholder="password" id="password"
                            onChange={handlePasswordChange} />
                            <button onClick={handleTogglePassword}>
                            {showPassword 
                            ? <RemoveRedEyeSharp style={{ color: 'black' }}/> 
                            : <VisibilityOffSharp style={{ color: 'black' }}/>}
                            </button>
                            </div>
                            
                            <button
                                className="signup__signup"
                                type="submit" onClick={register}>
                                Entra a TELEBOING
                            </button>
                        </form>

                        <div className="newuser__help">
                            <ul>
                                <li>
                                    Este es el último paso antes de empezar a disfrutar de TELEBOING.
                                </li>
                                <li>
                                    Si no llegaste a completar el ingreso de tu email y password, contáctanos a: teleboing.com@gmail.com para asistirte en el registro y recuperar los detalles de tu pago.
                                </li>
                            </ul>
                        </div>

                    </div>
                </div>

            </header>

            <Social />
            <FooterLinks />
            <Copyright />
            <GoToTop />
        </div>
    )
}

export default NewUserScreen;