import React from 'react';
import { useParams } from "react-router-dom";
import "./OriginalsScreen.css";
import Nav from "../components/Nav";
import { original } from "../originalContent";
import OriginalsUpdate from '../components/OriginalsUpdate';
import FooterLinks from "../components/Footer/FooterLinks";
import Social from "../components/Footer/SocialLinks";
import Copyright from "../components/Footer/Copyright";
import NavBottom from "../components/NavBottom";
import GoToTop from '../GoToTop';
import { Helmet } from "react-helmet";
import Head from "next/head";


function OriginalsScreen() {

    const { originalId } = useParams();
    const a = original.filter((item, key) => 
    (item.videoId === originalId));
    
    return (
        <main>
            <Head>
                <title>{a[0]?.title} {a[0]?.genres} {a[0]?.country}</title>
                <meta 
                name='description'
                content={a[0]?.description}
                />
            </Head>

            <Nav />
            <div className="originalsscreen">
                {original.filter((poster) => 
                poster.videoId.includes(originalId)
                ).map((poster) => (
                
                <div className="originalsscreen__container">

                <Helmet>
                    <title>{poster.title} / {poster.genres} {poster.country} / Serie Original de Teleboing</title>
                    <meta 
                    name='description'
                    content={poster.description}
                    />
                </Helmet>    

                <header className="originalsscreen__banner"
                style={{
                backgroundSize: "cover",
                backgroundImage: `linear-gradient(to top, black 10%, transparent 90%), linear-gradient(to right, black, transparent), 
                     url("${poster.bgurl}"
                )`, 
                backgroundPosition: "center center",
                }}>

                <div className = {`originalsscreen__poster`}>
                    <img 
                    key={poster.id}
                    id={poster.videoId}
                    className= {`originalsscreen__poster__item`}
                    src={poster.thumbnailurl} 
                    title={poster.title}
                    description={poster.description}
                    alt={poster.title}
                    />
                </div>

                <div className="originalsscreen__props">
                
                    <div className="originalsscreen__details" >
                        <p className="originalsscreen__details__headline">una serie original de teleboing</p>
                        <h2 className="originalsscreen__details__title">{poster.title}</h2>

                        <p className="originalsscreen__details__subinfo">2022 &nbsp;&nbsp; | &nbsp;&nbsp; {poster.episodes} episodios &nbsp;&nbsp; | &nbsp;&nbsp; {poster.age}+ </p>

                        <p className="originalsscreen__details__description"> <span>Sinopsis:</span> {poster.description} </p>
                        <p className="originalsscreen__details__genres"> <span>Género:</span> {poster.genres} </p>
                        <p className="originalsscreen__details__country"> <span>País:</span> {poster.country} </p>
                    </div>

                    <div className="originalsscreen__video">
                        <iframe 
                        src={`https://www.youtube.com/embed/${poster.videoId}?modestbranding=1&rel=0&showinfo=0&fs=1&cc_load_policy=1`}
                        frameborder="0"
                        allowFullScreen
                        title={poster.title}
                        width="100%"
                        height="400px" >
                        </iframe>
                    </div>
                </div>
                </header>
                </div>
                ))}

            </div>
            <OriginalsUpdate />
            <Social />
            <FooterLinks />
            <Copyright />
            <GoToTop />
            <NavBottom />
        </main>
    )
}
export default OriginalsScreen;