export const original = [
	{
	id:1,
	videoId:"XDs-Bk7JPdQ",
	channelId:"UCUcenIWc-YsLKNGxb0ZVSrQ",
	title:"ELIOT",
	videohref:"https://www.youtube.com/watch?v=XDs-Bk7JPdQ",
	authorname:"teleboing",
	authoruri:"https://www.youtube.com/channel/UCUcenIWc-YsLKNGxb0ZVSrQ",
	published:"2022-01-21T00:00:00+00:00",
	videourl:"https://www.youtube.com/v/XDs-Bk7JPdQ?version=3",
	bgurl:"https://firebasestorage.googleapis.com/v0/b/teleboing.appspot.com/o/resources%2Fcontent%2Fteleboing-serie-01-bg1.webp?alt=media&token=14b7cd67-2f90-404f-80f3-884a87db637d",
	thumbnailurl:"https://firebasestorage.googleapis.com/v0/b/teleboing.appspot.com/o/resources%2Fcontent%2Fteleboing-serie-01-ver.webp?alt=media&token=d8942d4f-daaf-4b45-a892-5ed08f885cbc",
	thumbnailurl2:"https://firebasestorage.googleapis.com/v0/b/teleboing.appspot.com/o/resources%2Fcontent%2Fteleboing-serie-01-hor.webp?alt=media&token=d0e95bd3-898e-4a58-9f40-b6ff9a1264d9",
	description:"Eliot Ferrada es inteligente, astuto y encantador.	Lleva la vida que todos quisieran tener pero tiene impulsos escondidos que apenas puede controlar, que ya no quiere controlar. ¿Logrará seguir sus impulsos sin destruir su vida?",
	episodes:10,
	genres:"Thriller, Horror",
	age:"16",
	country:"Perú",
	},
	{
	id:2,
	videoId:"RfvjUyyN7kM",
	channelId:"UCUcenIWc-YsLKNGxb0ZVSrQ",
	title:"Terranauta",
	videohref:"https://www.youtube.com/watch?v=RfvjUyyN7kM",
	authorname:"demoqrata",
	authoruri:"https://www.youtube.com/channel/UCUcenIWc-YsLKNGxb0ZVSrQ",
	published:"2022-01-21T00:00:00+00:00",
	videourl:"https://www.youtube.com/v/RfvjUyyN7kM?version=3",
	bgurl:"https://firebasestorage.googleapis.com/v0/b/teleboing.appspot.com/o/resources%2Fcontent%2Fteleboing-serie-02-bg1.webp?alt=media&token=99f6e036-a4e4-45c0-a61a-4c9c0dcd0558",
	thumbnailurl:"https://firebasestorage.googleapis.com/v0/b/teleboing.appspot.com/o/resources%2Fcontent%2Fteleboing-serie-02-ver.webp?alt=media&token=8592fdaf-23b9-4d0e-a11c-99e5722a64de",
	thumbnailurl2:"https://firebasestorage.googleapis.com/v0/b/teleboing.appspot.com/o/resources%2Fcontent%2Fteleboing-serie-02-hor.webp?alt=media&token=d7a500e3-ec91-4bdc-a0bc-a1c1e0430aeb",
	description:"Año 2022. Un grupo de astronautas conformado por científicos de 7 países y liderados por la capitana peruana Lucía Andéres inician un viaje para conquistar un mundo nuevo. Durante el trayecto la nave es atacada, lo que provoca que cambie su curso y se pierda hasta encontrar un lugar donde aterrizar. Después de algunos días, los astronautas creen haber llegado a un lugar seguro, piensan que puede ser el planeta que tenían como misión conquistar pero al aterrizar descubren que algo inusual y peligroso ocurre en esa nueva tierra. ¿Dónde estaban? ¿Por qué pueden respirar en el exterior? ¿Por qué no envejecen?",
	episodes:10,
	genres:"Ciencia Ficción",
	age:"11",
	country:"Perú",
	},
	{
	id:3,
	videoId:"JBOcEWqm9x4",
	channelId:"UCUcenIWc-YsLKNGxb0ZVSrQ",
	title:"Heredero$",
	videohref:"https://www.youtube.com/watch?v=JBOcEWqm9x4",
	authorname:"demoqrata",
	authoruri:"https://www.youtube.com/channel/UCUcenIWc-YsLKNGxb0ZVSrQ",
	published:"2022-01-21T00:00:00+00:00",
	videourl:"https://www.youtube.com/v/JBOcEWqm9x4?version=3",
	bgurl:"https://firebasestorage.googleapis.com/v0/b/teleboing.appspot.com/o/resources%2Fcontent%2Fteleboing-serie-03-bg.webp?alt=media&token=2d61f419-3667-4cc7-883f-4197f6053365",
	thumbnailurl:"https://firebasestorage.googleapis.com/v0/b/teleboing.appspot.com/o/resources%2Fcontent%2Fteleboing-serie-03-ver.webp?alt=media&token=4c9a54d6-1fcc-4449-94fa-099c7805c766",
	thumbnailurl2:"https://firebasestorage.googleapis.com/v0/b/teleboing.appspot.com/o/resources%2Fcontent%2Fteleboing-serie-03-hor.webp?alt=media&token=81e25065-745c-44c6-981f-965a96a8145a",
	description:"7 adolescentes heredarán la fortuna de sus billonarios padres y aunque esto parezca el sueño de muchos, ellos nos demostraran lo difícil que es descubrir quiénes son y qué quieren ser en la vida mientras gastan millones de dólares, se divierten sin control, se rebelan ante la sociedad y tratan de escapar de la gran responsabilidad que significa ser un heredero.",
	episodes:10,
	genres:"Drama, Comedia",
	age:"13",
	country:"Perú",
	},
	{
	id:4,
	videoId:"XIWZGA6NJ5s",
	channelId:"UCUcenIWc-YsLKNGxb0ZVSrQ",
	title:"Ladron de Guevara",
	videohref:"https://www.youtube.com/watch?v=XIWZGA6NJ5s",
	authorname:"demoqrata",
	authoruri:"https://www.youtube.com/channel/UCUcenIWc-YsLKNGxb0ZVSrQ",
	published:"2022-01-21T00:00:00+00:00",
	videourl:"https://www.youtube.com/v/XIWZGA6NJ5s?version=3",
	bgurl:"https://firebasestorage.googleapis.com/v0/b/teleboing.appspot.com/o/resources%2Fcontent%2Fteleboing-serie-04-bg.webp?alt=media&token=a0f8e67e-a55d-4b5d-afce-aa834640bae3",
	thumbnailurl:"https://firebasestorage.googleapis.com/v0/b/teleboing.appspot.com/o/resources%2Fcontent%2Fteleboing-serie-04-ver.webp?alt=media&token=4c2af759-59ab-4245-bf36-54db4edc5bea",
	thumbnailurl2:"https://firebasestorage.googleapis.com/v0/b/teleboing.appspot.com/o/resources%2Fcontent%2Fteleboing-serie-04-hor.webp?alt=media&token=d13039d3-8d4b-469b-ae35-43767eba3781",
	description:"Durante 7 generaciones una sola familia, los Ladron de Guevara, ha controlado a todo un país. Ellos han corrompido la justicia, han actuado con impunidad y tienen el poder para conseguir todo lo que desean. Una decisión del patriarca de la familia, agobiado por la culpa de un secreto y obligado por una promesa que le hizo a su esposa antes de morir, pone en peligro todo el imperio de los Ladron de Guevara. ¿Conseguirá la familia más poderosa del país derrocar a su patriarca antes que destruya todo?",
	episodes:10,
	genres:"Thriller, Drama",
	age:"16",
	country:"Perú",
	}
]