import React from 'react';
import "./FooterLinks.css";

const FooterLinks = () => {

    return (
        <div className="linksList">
            <span>
                <a href="https://shop.teleboing.com/" target={'_blank'} rel="noreferrer" className="link">shop in teleboing</a>
            </span>
            <span>
                <a href="https://help.teleboing.com/creators" target={'_blank'} rel="noreferrer" className="link">Relación con creadores</a>
            </span>
            <span>
                <a href="https://help.teleboing.com/upload" target={'_blank'} rel="noreferrer" className="link">Sube tu contenido</a>
            </span>
            {/* <span>
                <a href="https://help.teleboing.com/media" target={'_blank'} rel="noreferrer" className="link">Media Center</a>
            </span> */}
            <span>
                <a href="https://help.teleboing.com/terms" target={'_blank'} rel="noreferrer" className="link">Términos de uso</a>
            </span>
            <span>
                <a href="https://help.teleboing.com/privacy" target={'_blank'} rel="noreferrer" className="link">Privacidad</a>
            </span>
            <span>
                <a href="https://help.teleboing.com" target={'_blank'} rel="noreferrer" className="link">Centro de ayuda</a>
            </span>
        </div>
    );
};

export default FooterLinks;