import React, { useState, useEffect, useRef } from "react";
import ReactPlayer from 'react-player/youtube';
import { Link, useHistory } from "react-router-dom";
import logo from "../../assets/teleboing.svg";
import { cortometrajes } from "../../assets/catalogoTLBNG.js"
import { Link as ScrollVideo } from "react-scroll";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import "./BannerTvIntro.css";
import { TimelapseSharp, FlagSharp, NavigateNextSharp } from "@material-ui/icons";
import fromicon from "../../assets/from.svg";
import iconsilencio from "../../assets/sound-off.svg";
import iconsonido from "../../assets/sound-loud.svg";
import fullscreen from "../../assets/fullscreen.svg";
import playicon from "../../assets/play.svg";
import pauseicon from "../../assets/pause.svg";
import preload from "../../assets/TVloading2.gif";
import menuvert from "../../assets/menu-vertical.svg";
import closemenu from "../../assets/close.svg";

const base_url = "https://i2.ytimg.com/vi/";
const end_url = "/hqdefault.jpg";

function Banner() {
    const [show, handleShow] = useState(false);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [progress, setProgress] = useState(0);
    const [duration, setDuration] = useState(0);
    const [muted, setMuted] = useState(true);
    const [volume, setVolume] = useState(0.9);
    const [play, setPlay] = useState(true);
    const [pausePrev, setPausePrev] = useState(false);
    const [arrayKey, setArrayKey] = useState(0);
    const [playingIntermediateVideo, setPlayingIntermediateVideo] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [showDatavideo, setShowDatavideo] = useState(true);
    const [open, setOpen] = useState(false);
    const [timeLeft, setTimeLeft] = useState(30);
    const [quality, setQuality] = useState('auto');
    const [internetSpeed, setInternetSpeed] = useState(0);
    const [arraySelectedIntro, setArraySelectedIntro] = useState([]);
    const [expanded, setExpanded] = useState(false);
    const playerRef = useRef(null);
    const handle = useFullScreenHandle();
    const menuRef = useRef();
    const imgRef = useRef();
    const history = useHistory();

    const muteIconStyle = {
        filter: muted ? "invert(28%) sepia(92%) saturate(7358%) hue-rotate(1deg) brightness(98%) contrast(127%)" : "invert(100%) sepia(0%) saturate(0%) hue-rotate(150deg) brightness(103%) contrast(101%)",
        display: "flex",
        alignItems: "center",
        cursor: "pointer",
        width: '25px',
        height: '100%',
        margin: "auto 10px",
    };

    const playIconStyle = {
        filter: "invert(100%) sepia(0%) saturate(0%) hue-rotate(150deg) brightness(103%) contrast(101%)",
        display: "flex",
        alignItems: "center",
        cursor: "pointer",
        width: '25px',
        height: '100%',
        margin: "auto 10px",
    };

    function shuffle(array) {
        let currentIndex = array.length, randomIndex;
        while (currentIndex !== 0) {
            randomIndex = Math.floor(Math.random() * currentIndex);
            currentIndex--;
            [array[currentIndex], array[randomIndex]] = [
                array[randomIndex], array[currentIndex]];
        }
        return array;
    }

    const handlePlayerEnded = () => {
        if (parseInt(arrayKey) < arraySelectedIntro.length - 1) {
            setArrayKey(parseInt(arrayKey) + 1);
            /* setPlayingIntermediateVideo(true); */
        } else {
            setArrayKey(0);
            setShowDatavideo(true);
        };
        setPlay(true);
    };

    useEffect(() => {
        const timer =
            timeLeft > 0 && setInterval(() => setTimeLeft(timeLeft - 1), 1000);
        return () => clearInterval(timer);
    }, [timeLeft]);

    window.addEventListener("click", (e) => {
        if (e.target !== menuRef.current && e.target !== imgRef.current) {
            setOpen(false);
        }
    });

    useEffect(() => {
        const checkInternetSpeed = setInterval(() => {
            const img = new Image();
            const startTime = Date.now();
            img.onload = () => {
                const duration = Date.now() - startTime;
                const bitsLoaded = img.length * 8;
                const speedBps = (bitsLoaded / duration) * 1000;
                setInternetSpeed(speedBps);
            };
            img.src = 'https://i.imgur.com/qWLGSgP.png?cache=' + Math.random();
        }, 1000);

        return () => clearInterval(checkInternetSpeed);
    }, []);
    useEffect(() => {
        if (internetSpeed < 100000) {
            setQuality('240p');
        } else if (internetSpeed < 500000) {
            setQuality('360p');
        } else if (internetSpeed < 1000000) {
            setQuality('480p');
        } else if (internetSpeed < 2500000) {
            setQuality('720p');
        } else if (internetSpeed < 5000000) {
            setQuality('1080p');
        } else {
            setQuality('max');
        }
    }, [internetSpeed]);

    const handlePlayerReady = (player) => {
        playerRef.current = player;
    };

    const handleSonido = (e) => {
        e.preventDefault();
        if (muted) {
            setMuted(false);
        } else {
            setMuted(true);
        }
    };

    const handlePlay = (e) => {
        e.preventDefault();
        if (play) {
            setPlay(false);
            setPausePrev(true);
        } else if (!play) {
            setPlay(true);
            setPausePrev(false);
        }
    };

    const handleImage = (e) => {
        setArrayKey(parseInt(e.target.id));
        setPlay(true);
    };

    function toHoursAndMinutes(totalSeconds) {
        const totalMinutes = Math.floor(totalSeconds / 60);
        const seconds = totalSeconds % 60;
        const hours = Math.floor(totalMinutes / 60);
        const minutes = totalMinutes % 60;
        const todo = `${hours}:${minutes}:${seconds}`;
        return todo
    }

    const handleVolumeChange = (e) => {
        setVolume(parseFloat(e.target.value));
    };

    //Cortos selected
    useEffect(() => {
        setArraySelectedIntro(shuffle(cortometrajes.filter(item => item.status.toLowerCase() === 'active')).slice(0, 3));
        setIsLoading(true);
    }, [])

    const volumeControl = (
        <div className="volume-control">
            <input
                type="range"
                min={0}
                max={1}
                step={0.01}
                value={volume}
                onChange={handleVolumeChange}
                className="volume-control__slider"
            />
        </div>
    );

    //Pause with spacebar
    useEffect(() => {
        const handleKeyDown = (event) => {
          if (event.code === 'Space') {
            event.preventDefault();
            if (playerRef.current && playerRef.current.getInternalPlayer()) {
            if (play) {
                playerRef.current.getInternalPlayer().pauseVideo();
                setPlay(false);
                setPausePrev(true);
            } else if (!play) {
                playerRef.current.getInternalPlayer().playVideo();
                setPlay(true);
                setPausePrev(false);
            }
          }
        }
        };
        document.addEventListener('keydown', handleKeyDown);
        return () => {
          document.removeEventListener('keydown', handleKeyDown);
        };
    }, [play, playerRef]);

    function truncate(str, n) {
        return str?.length > n ? str.substr(0, n-1) + '...' : str;
    }

    const handleSignup = (e) => {
        e.preventDefault();
        history.push("/signup")
    };

    const toggleMenuNav = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const toggleExpanded = () => {
        setExpanded((prevExpanded) => !prevExpanded);
    };

    return (
        <div className="prebannerTvIntro">
            {isLoading
            ? (
            <div id="divcode1" >
                {arraySelectedIntro.filter((news, index) =>
                    parseInt(index) === parseInt(arrayKey)
                ).map((news) => (
                <div id="divcode2">
                    <header id="head" className="bannerTvIntro">
                    <FullScreen handle={handle}>
                        <ReactPlayer
                            ref={playerRef}
                            id="video"
                            key={news?.videoId}
                            className='react-player noHover'
                            url={`https://www.youtube.com/embed/${news?.videoId_yt_dmq}?rel=0`}
                            volume={volume}
                            playbackRate={1}
                            width='100%'
                            height='100%'
                            playing={play && !playingIntermediateVideo}
                            muted={muted}
                            controls={false}

                            onReady={handlePlayerReady}

                            onError={(err) => {
                                console.log("error:", err);

                                if (parseInt(arrayKey) < arraySelectedIntro.length - 1) {
                                    setArrayKey(parseInt(arrayKey) + 1)
                                } else {
                                    setArrayKey(0)
                                }
                            }}

                            onDuration={(state) => {
                                if (state) {
                                    setDuration(state);
                                }
                            }}

                            onStart={() => {
                                /* setPlay(false); */
                                setShowDatavideo(true);
                            }}

                            onProgress={(state) => {

                                if (state) {
                                    setProgress(state.playedSeconds);

                                    if (parseInt(state.playedSeconds) === parseInt(duration) - 5) {
                                        setShowDatavideo(false);
                                    }
                                }
                            }}

                            onEnded={handlePlayerEnded}

                            config={{
                                youtube: {
                                    playerVars: { origin: window.location.origin, showinfo: 0, controls: 0 },
                                    preload: true,
                                    quality: quality,
                                    file: { attributes: { crossOrigin: "true" } }
                                },
                            }}
                        />
                    </FullScreen>

                    <div className="bannerTvIntro__frame">
                        <div className={`bannerTvIntro__nav ${show && "bannerTvIntro__nav__black"}`}>
                            <div id='video' className="bannerTvIntro__nav__contents">

                                <Link to="/" style={{ textDecoration: 'none' }}>
                                    <div className="bannerTvIntro__logo">
                                        <img
                                            className="bannerTvIntro__logo__img"
                                            src={logo}
                                            alt="logo TELEBOING" title="made for fun"
                                        />
                                    </div>
                                </Link>

                                <div className="bannerTvIntro__nav__contents__right">
                                    <div className="bannerTvIntro__nav__contents__right__accesos">

                                        <div
                                            className="bannerTvIntro__nav__contents__iconos">

                                            <img
                                                src={play ? pauseicon : playicon}
                                                style={playIconStyle}
                                                onClick={handlePlay}
                                                alt={play ? "Pausar" : "Reproducir"}
                                                title={play ? "Pausar" : "Reproducir"}
                                            />

                                            <div className="bannerTvIntro__nav__contents__iconos__sonidomobile">
                                                <img
                                                    src={muted ? iconsilencio : iconsonido}
                                                    style={muteIconStyle}
                                                    onClick={handleSonido}
                                                    alt={muted ? "En silencio" : "Con sonido"}
                                                    title={muted ? "En silencio" : "Con sonido"}
                                                />
                                            </div>

                                            <div className="bannerTvIntro__nav__contents__iconos__sonidodesktop">
                                            {!muted && (
                                            <div className="volume-icon-container" >
                                                <img
                                                    className="bannerTvIntro__volumendesktop"
                                                    src={iconsonido}
                                                    onClick={handleSonido}
                                                    alt="Volumen"
                                                    title="Volumen"
                                                />
                                                {volumeControl}
                                            </div>
                                            )}
                                            {muted && (
                                            <div className="volume-icon-container" >
                                                <img
                                                    src={iconsilencio}
                                                    onClick={handleSonido}
                                                    style={muteIconStyle}
                                                    alt="En silencio"
                                                    title="En silencio"
                                                />
                                            </div>
                                            )}
                                            </div>

                                            <img
                                                className="bannerTvIntro__fullscreen"
                                                src={fullscreen}
                                                onClick={handle.enter}
                                                alt="Pantalla completa"
                                                title="Pantalla completa"
                                            />

                                        </div>
                                    </div>

                                    <div className="loginScreen__signup">
                                        <form >
                                            <button>
                                                <Link to="/signin" style={{ textDecoration: 'none', color: "white" }}>Inicia sesión
                                                </Link>
                                            </button>
                                            <button>
                                                <Link to="/signup" style={{ textDecoration: 'none', color: "white" }}>Suscríbete
                                                </Link>
                                            </button>
                                        </form>
                                    </div>

                                    <div className="bannerTvIntro__container__menu">
                                        <img
                                            className="bannerTvIntro__menu"
                                            src={menuvert}
                                            onClick={toggleMenuNav}
                                            alt="Menu"
                                            title="Menu"
                                        />

                                    </div>
                                </div>
                            </div>
                        </div>

                        {isMenuOpen && (
                        <div className="bannerTvIntro__menu-box">
                            <div className="bannerTvIntro__close-icon" onClick={toggleMenuNav}>
                                <img
                                    className="bannerTvIntro__menu__close"
                                    src={closemenu}
                                    alt="Cerrar"
                                    title="Cerrar"
                                />
                            </div>
                            <div className="bannerTvIntro__mobile__signup">
                                <form >
                                    <button>
                                        <Link to="/signin" style={{ textDecoration: 'none', color: "white" }}>Inicia sesión
                                        </Link>
                                    </button>
                                    <button>
                                        <Link to="/signup" style={{ textDecoration: 'none', color: "white" }}>Suscríbete
                                        </Link>
                                    </button>
                                </form>
                            </div>
                        </div>
                        )}
                    </div>

                    {showDatavideo &&
                        <div className="bannerTvIntro__datavideo" key={news?.videoId} >

                            <div className="bannerTvIntro__datavideo__author">
                                <h2>{news?.title}</h2>
                            </div>

                            <div className="bannerTvIntro__datavideo__info">
                                <div className="bannerTvIntro__datavideo__info__title">
                                    <h1>{news?.genres}</h1>
                                </div>
                                <div className="bannerTvIntro__datavideo__info__ticker">
                                    <div className="bannerTvIntro__ticker">
                                        <p>
                                        {news?.genres} : {news?.year} : {news?.synopsis} : {news?.description}
                                        </p>
                                    </div>

                                    <div className="bannerTvIntro__progress">
                                        <div className="bannerTvIntro__progress__counter1">
                                            {toHoursAndMinutes(parseInt(progress))}
                                        </div>
                                        <div className="bannerTvIntro__progress__counter2">
                                            {"[" + toHoursAndMinutes(parseInt(duration)) + "]"}
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div className="bannerTvIntro__datavideo__category">
                                <p>{news?.country}</p>
                            </div>

                        </div>
                    }

                    <div className="bannerTvIntro__datavideo__mobile" key={news?.videoId} >

                        <div className="bannerTvIntro__datavideo__author__mobile">
                            <h2>{news?.title}</h2>
                        </div>

                        <div className="bannerTvIntro__progress__mobile">
                            <div className="bannerTvIntro__progress__counter1__mobile">
                                {toHoursAndMinutes(parseInt(progress))}
                            </div>
                            <div className="bannerTvIntro__progress__counter2__mobile">
                                {"[" + toHoursAndMinutes(parseInt(duration)) + "]"}
                            </div>
                        </div>
                    </div>
                    </header>

                    <div className="bannerTvIntro__datavideo__description__mobile">
                    <p>
                        {expanded ? news?.synopsis : news?.synopsis?.slice(0, 70)}
                        {news?.synopsis.length > 70 && (
                        <span onClick={toggleExpanded}>
                            {expanded ? ' Ver menos -' : ' Ver más +'}
                        </span>
                        )}
                    </p>
                    </div>

                    <div id="head" className="bannerTvIntro__row" >
                        <div id="containerArray" className="bannerTvIntro__row__posters">

                            {arraySelectedIntro.map((video, index) => (

                                <div id={index} key={index} className={`bannerTvIntro__row__poster__props`} >
                                    <ScrollVideo to="video" offset={0} smooth={true} delay={300} style={{ textDecoration: 'none' }}>
                                    {parseInt(index) === parseInt(arrayKey)
                                        ? (
                                            <p
                                                className="bannerTvIntro__row__poster__props__catselected"
                                                id={index}
                                                onClick={handleImage}
                                            >   Estás viendo:
                                            </p>
                                        )
                                        : (
                                            <p
                                                className="bannerTvIntro__row__poster__props__category"
                                                id={index}
                                                onClick={handleImage}
                                            >{truncate(video?.genres, 25)}
                                            <img
                                                src={fromicon}
                                                id={index}
                                                onClick={handleImage}
                                                alt="ver desde aquí"
                                                title="ver desde aquí"
                                                width="20px"
                                            />
                                            </p>
                                        )
                                        }
                                        <img
                                            id={index}
                                            key={index}
                                            onClick={handleImage}
                                            className={`bannerTvIntro__row__poster`}
                                            src={`${base_url}${video?.videoId_yt_dmq}${end_url}`} alt={video?.title}
                                            title="ver desde aquí"
                                        />
                                    </ScrollVideo>
                                    <p className={`bannerTvIntro__row__poster__author`}>
                                    <FlagSharp style={{fontSize:"20px", paddingRight:"5px", opacity:"0.7", display:"flex", alignItems:"center", mnargin:"auto 0"
                                    }} />{video?.country} &nbsp;&nbsp; • &nbsp;&nbsp;
                                    <TimelapseSharp style={{fontSize:"20px", paddingRight:"5px", opacity:"0.7", display:"flex", alignItems:"center", mnargin:"auto 0"
                                    }}/>00: {video?.duration}</p>

                                    <div className={`bannerTvIntro__row__poster__details`}>
                                        <h2 className={`bannerTvIntro__row__poster__title`}>{video?.title} <span>: [{video?.genres}]</span></h2>
                                    </div>
                                </div>

                            ))
                            }
                        </div>
                        
                        <div className={`bannerTvIntro__row__poster__more`} onClick={handleSignup} >
                            <p>
                            Más películas<NavigateNextSharp style={{fontSize:"30px", paddingLeft:"5px", opacity:"1", display:"flex", alignItems:"center", mnargin:"auto 0"
                            }} /></p>
                        </div>
                        
                    </div>
                </div>
                ))}
            </div>
            )
            : (
            <div className="bannerTvIntro__preload">
            <img
                className="bannerTvIntro__preload__logo"
                src={logo}
                alt="logo TELEBOING" title="made for fun"
            />
            <img
                className="bannerTvIntro__preload__img"
                src={preload}
                alt="Cargando Teleboing"
            />
            </div>
            )
            }
        </div>
    );
}

export default Banner;