import React, { useState } from 'react';
import "./faq.css";

const FAQ = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const faqData = [
    {
        "id":1,
        "question": '¿Qué es Teleboing?',
        "answer": 'Teleboing es una plataforma de streaming de cortometrajes que ofrece acceso rápido, sencillo y personalizado a una amplia variedad de cortometrajes, películas, documentales, programas originales y series basadas en historias fascinantes, drama, acción, comedia, suspenso y terror, que pueden ser vistas en cualquier pantalla de cualquier dispositivo conectado a internet. Todo lo que quieras ver, sin límites, sin publicidad, a un costo muy accesible. Siempre hay una historia nueva por descubrir, ¡y todos los días, a cada hora, agregamos más contenido original!',
    },
    {
        "id":2,
        "question": '¿Cuánto cuesta acceder a Teleboing?',
        "answer": 'Disfruta Teleboing en tu smartphone, tablet, TV análogo/digital conectado a internet, laptop o dispositivo de streaming, todo por una tarifa plana mensual desde USD $1.99. Sin costos adicionales ni contratos.',
    },
    {
        "id":3,
        "question": '¿Dónde puedo ver Teleboing?',
        "answer": 'Disfrútalo donde quieras, cuando quieras y con quien quieras. Inicia sesión en tu cuenta de teleboing para ver contenido al instante a través de Teleboing.com desde tu computadora personal o en cualquier dispositivo con conexión a internet, como TV análoga, smart TV, smartphones, tablets, reproductores multimedia y consolas de juegos.',
    },
    {
        "id":4,
        "question": '¿Cómo cancelo?',
        "answer": 'Teleboing es flexible. Sin contratos molestos ni compromisos. Cancela la membresía online con sólo un clic. No hay cargos por cancelación. Empiezas y terminas cuando quieras.',
    },
    {
        "id":5,
        "question": '¿Qué puedo ver en Teleboing?',
        "answer": 'Teleboing tiene miles de cortometrajes, historias únicas, películas independientes y series originales que son producidas por cineastas, artistas y personas como tú.',
    },
    {
        "id":6,
        "question": '¿Cuál es el propósito de Teleboing?',
        "answer": 'Teleboing escucha lo que piensas de cada historia y entiende lo que más te interesa, para producir cortometrajes, series, películas y documentales que cuenten historias que son importantes para ti.',
    },
    {
        "id":7,
        "question": '¿En qué países puedo encontrar a Teleboing?',
        "answer": 'Actualmente Teleboing llega a toda la región latinoamericana, pero desde Octubre de 2023 se podrá encontrar también en Europa, Estados Unidos y Asia. Nuestro objetivo es llegar a cada hogar de habla hispana para entregar aquello que tanto se necesita, ¡El control de lo que más te gusta!',
    }
  ];

  const toggleAccordion = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  return (
    <div className='faq'>
      <h1 className='faq__title'>PREGUNTAS FRECUENTES</h1>
      {faqData.map((item, index) => (
        <div className='faq__container' key={index}>
          <div
            className={`faq__item ${index === activeIndex ? 'active' : ''}`}
            onClick={() => toggleAccordion(index)}
          >
            <h3>{item.question}</h3>
            <span>{index === activeIndex ? '-' : '+'}</span>
          </div>
          {index === activeIndex && <p className='faq__answer'>{item.answer}</p>}
        </div>
      ))}
    </div>
  );
};

export default FAQ;