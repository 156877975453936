import React, { useRef, useState } from 'react';
import { useHistory } from "react-router-dom";
import "./SignInScreen.css";
import NavProfile from "../components/NavProfile";
import FooterLinks from "../components/Footer/FooterLinks";
import Social from "../components/Footer/SocialLinks";
import Copyright from "../components/Footer/Copyright";
import { auth } from "../firebase.js";
import { Link } from "react-router-dom";
import GoToTop from '../GoToTop';
import {Helmet} from "react-helmet";
import Head from "next/head";
import RemoveRedEyeSharp from "@material-ui/icons/RemoveRedEyeSharp";
import VisibilityOffSharp from "@material-ui/icons/VisibilityOffSharp";

function SignInScreen() {

    const emailRef = useRef(null);
    const passwordRef = useRef(null);
    const history = useHistory();
    const [password, setPassword] = useState(false);
    const [showPassword, setShowPassword] = useState(false);

    const signIn = (e) => {
        e.preventDefault();

        auth
            .signInWithEmailAndPassword(
                emailRef.current.value,
                passwordRef.current.value
            )
            .then((authUser) => {
                /* console.log(authUser); */
            })
            .catch((error) => {
                alert(error.message);
            });
        
        history.push("/home");
    };

    const handlePasswordChange = (e) => {
        e.preventDefault();
        setPassword(e.target.value);
      };
    
    const handleTogglePassword = (e) => {
        e.preventDefault();
        setShowPassword(!showPassword);
      };

  return (
    <main>

        <Head>
            <title>Inicio de sesión : Teleboing</title>
            <meta 
            name='description'
            content="Inicio de sesión al servicio de entretenimiento audiovisual de Teleboing."
            />
        </Head>

        <Helmet>
            <title>Inicio de sesión : Teleboing</title>
            <meta 
            name='description'
            content="Inicio de sesión al servicio de entretenimiento audiovisual de Teleboing."
            />
            <meta 
            name='image' 
            content="https://firebasestorage.googleapis.com/v0/b/teleboing.appspot.com/o/resources%2Fcontent%2Fteleboing_cover08.webp?alt=media&token=f5d12ee1-3d72-4f00-8f20-e2687369315a"
            />
        </Helmet>

        <NavProfile />

        <header className="banner"
            style={{
                backgroundSize: "cover",
                backgroundImage: `linear-gradient(to top, black 5%, transparent 30%), linear-gradient(to top, #00001b, transparent), 
                     url(
                    "https://firebasestorage.googleapis.com/v0/b/teleboing.appspot.com/o/resources%2Fcontent%2Fteleboing_cover08.webp?alt=media&token=f5d12ee1-3d72-4f00-8f20-e2687369315a"
                )`, 
                backgroundPosition: "center center",
            }}>

            <>
            <div className="signin">

            <div className="signin__container">
                <div className="signin__msg">
                    <p>entra a TELEBOING</p>
                </div>

                <form >
                    <input  ref={emailRef} type="email" placeholder="email" id="email"
                    autoComplete="email" />

                    <div className="signin__formpassword">
                    <input ref={passwordRef} type={showPassword ? 'text' : 'password'} placeholder="password" id="password"
                    onChange={handlePasswordChange} />
                    <button onClick={handleTogglePassword}>
                        {showPassword 
                        ? <RemoveRedEyeSharp style={{ color: 'black' }}/> 
                        : <VisibilityOffSharp style={{ color: 'black' }}/>}
                    </button>
                    </div>

                    <button 
                    className="signin__button"
                    type="submit" onClick={signIn}>
                        Inicia Sesión
                    </button>
                </form>

                <div className="signin__help">
                    <p className="signin__help__password">¿Olvidaste tu password?
                    <span>
                    <Link to="/reset">
                    Recupéralo
                    </Link>
                    </span>
                    </p>

                    <div className="signin__help__signup">
                    <p >¿Aún no tienes cuenta?</p>
                    <Link to="/signup">
                    <span >Suscríbete aquí</span>
                    </Link>
                    </div>
                </div>

            </div>
            </div>
            </>
        </header>
        
        <Social />
        <FooterLinks />
        <Copyright />
        <GoToTop />
    </main>
  )
}

export default SignInScreen;