import React, { useState, useEffect } from "react";
import "./Nav.css";

import { getAuth } from "firebase/auth";
import  firebaseApp from "../firebase";
import 'firebase/compat/storage';

import { Link, /* useHistory,  useNavigate*/ } from "react-router-dom";

import logo from "../assets/teleboing.svg";

import HomeSharpIcon from "@material-ui/icons/HomeSharp";
import LiveTvSharpIcon from "@material-ui/icons/LiveTvSharp";
import ArrowDropDownSharpIcon from "@material-ui/icons/ArrowDropDownSharp";
import  { DropdownMenu, NavItem }  from "./DropdownMenu";

function Nav() {
    const auth = getAuth(firebaseApp);
    const user = auth.currentUser;
    const db = firebaseApp.firestore();

    const [show, handleShow] = useState(false);
    const [ datauser, setDatauser ] = useState();
    const [ imguser, setImguser ] = useState("");

    /* const navigate = useNavigate();

    const handleHome = (e) => {
        e.preventDefault();
        navigate('/');
    }; */

    const transitionNavBar = () => {
        if (window.scrollY > 100) {
            handleShow(true);
        } else {
            handleShow(false);
        }
    };

    useEffect (() => {
        window.addEventListener("scroll", transitionNavBar);
        return () => window.removeEventListener("scroll", transitionNavBar);
    }, []);

    //visualizar los datos ingresados en el profile.
    const getData = async () => {
        try {
          const documentSnapshot = await db
            .collection('users')
            .doc(user.uid)
            .get();
    
          const userData = documentSnapshot.data();
          setDatauser(userData);
        } catch(err) {
            console.log(err);
        }
    };
    // Get user on mount
    useEffect(() => {
    getData();
    // visualizar la foto de perfil desde el profileScreen.
    if (user) {
        const storage = firebaseApp.storage();
        const storageRef = storage.ref();
        const imagesRef = storageRef.child(`users/${user.uid}/profile.jpg`);

        imagesRef
            .getDownloadURL()
            .then((url) => {
            setImguser(url);
            })
            .catch((error) => {
            console.log('Error getting image URL:', error);
            });
        }
    }, [user]);

    return (
        <div className={`nav ${show && "nav__black"}`}>
            <div className="nav__contents">
                <Link to="/home" style={{ textDecoration: 'none', margin:"0", alignItems:"center", display: "flex" }}>
                <div className='nav__logo'>
                    <img 
                    /* onClick={handleHome} */
                    src={logo}
                    alt="TELEBOING" title="made for fun"
                    />
                </div> 
                </Link>


                <div className="nav__profile">
                    
                <div className="nav__icons">
                    
                    <Link to="/home" style={{ textDecoration: 'none', color:"white" }}>
                    <div className="nav__icon"
                    >
                    <HomeSharpIcon />
                    <p>Inicio</p>
                    </div>
                    </Link>

                    {/* <Link to="/categories" style={{ textDecoration: 'none', color:"white" }}>
                    <div className="nav__icon">
                        <CategorySharpIcon />
                        <p>Categorías</p>
                    </div>
                    </Link> */}
                    
                    <Link to="/tv" style={{ textDecoration: 'none', color:"white" }}>
                    <div className="nav__icon">
                        <LiveTvSharpIcon />
                        <p>Modo TV</p>
                    </div>
                    </Link>

                </div>
                
                <div className="nav__profile__block">
                    <p>Hola {datauser && datauser.firstname}</p>
                    <img 
                    className='nav__avatar'
                    src= {imguser} 
                    style={{borderRadius:"50%",}} 
                    id="img_nav"
                    alt="" 
                    onError={(e)=>{e.target.onerror = null; e.target.src="https://firebasestorage.googleapis.com/v0/b/teleboing.appspot.com/o/resources%2Fcontent%2FTLBNGbrand_tshirt_square.webp?alt=media&token=2f23ddfe-d381-4f94-a829-fe162ea4a004"}}
                    />
                   
                    <NavItem icon={<ArrowDropDownSharpIcon />} className="nav__profile__dropdown">
                        <DropdownMenu></DropdownMenu>
                    </NavItem>
                </div>
                </div>
            </div>
        </div>
    )
}

export default Nav;
