import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import "./TvScreen.css";
import Nav from "../components/Nav";
import FooterLinks from "../components/Footer/FooterLinks";
import Social from "../components/Footer/SocialLinks";
import Copyright from "../components/Footer/Copyright";
import NavBottom from "../components/NavBottom";
import GoToTop from '../GoToTop';
import Skeleton from "../components/skeleton/Skeleton";
import { TimelapseSharp, FlagSharp } from "@material-ui/icons";
import { cortometrajes } from "../assets/catalogoTLBNG";
import {Helmet} from "react-helmet";
import Head from "next/head";

const base_url = "https://i2.ytimg.com/vi/";
const end_url = "/hqdefault.jpg";

function TvScreen() {
    const [creation,setCreation] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [option, setOption] = useState("comedia");
    const [selected, setSelected] = useState("4fOqDvzikPI");
    const drama = "drama";
    const comedia = "comedia";
    const accion = "acción";
    const thriller = "thriller";
    const ficcion = "ficción";
    const suspenso = "suspenso";
    const terror = "terror";
    
    const handleClickTv = (e) => {
        setOption(e.target.id);
    }

    useEffect(() => {
        async function fetchData() {
            setIsLoading(true);
            setCreation(cortometrajes);
            setIsLoading(false);
            return cortometrajes;
        }
        fetchData();
    }, []);

    function truncate(str, n) {
        return str?.length > n ? str.substr(0, n-1) + '...' : str;
    }

    return (
        <main>
            <Head>
            <title>Modo TV : Teleboing</title>
            <meta 
            name='description'
            content="Modo TV del servicio de entretenimiento audiovisual de Teleboing."
            />
            </Head>

            <Helmet>
            <title>Modo TV : Teleboing</title>
            <meta 
            name='description'
            content="Modo TV del servicio de entretenimiento audiovisual de Teleboing."
            />
            </Helmet>

            <Nav />
            <div className="tv">
                <div className="tv__player__container">
                    <div className="tv__player">
                    <div className="tv__player__buttons">
                        <button
                        onClick={handleClickTv}
                        key="drama"
                        id={drama}
                        >
                            Drama
                        </button>
                        <button
                        onClick={handleClickTv}
                        key="comedia"
                        id={comedia}
                        >
                            Comedia
                        </button>
                        <button
                        onClick={handleClickTv}
                        key="accion"
                        id={accion}
                        >
                            Acción
                        </button>
                        <button
                        onClick={handleClickTv}
                        key="thriller"
                        id={thriller}
                        >
                            Thriller
                        </button>
                        <button
                        onClick={handleClickTv}
                        key="ficcion"
                        id={ficcion}
                        >
                            Ficción
                        </button>
                        <button
                        onClick={handleClickTv}
                        key="suspenso"
                        id={suspenso}
                        >
                            Suspenso
                        </button>
                        <button
                        onClick={handleClickTv}
                        key="terror"
                        id={terror}
                        >
                            Terror
                        </button>
                    </div>
                    <div className="tv__player__iframe">
                    <iframe 
                        src={`https://www.youtube.com/embed/${selected}?modestbranding=1&rel=0&showinfo=0&fs=1&cc_load_policy=1` /* || "https://www.youtube.com/embed/YmCOsDw85zk" */}
                        frameborder="0"
                        allowFullScreen
                        title="teleboing TV"
                        width="100%"
                        height="400px" >
                    </iframe>
                    <div className="tv__player__details">
                    {creation.filter(vid => vid.videoId_yt_dmq===(`${selected}`)).map(newData => (
                        <div className="tv__player__details__content">
                        <p className="tv__player__details__content__author">       
                            {newData.genres} 
                            &nbsp;&nbsp; • &nbsp;&nbsp; 
                            <FlagSharp style={{fontSize:"20px", paddingRight:"5px", opacity:"0.7"
                            }} />
                            {newData.country} 
                            &nbsp;&nbsp; • &nbsp;&nbsp;
                            <TimelapseSharp style={{fontSize:"20px", paddingRight:"5px", opacity:"0.7"
                            }}/>
                            00:{newData.duration} &nbsp;&nbsp; • &nbsp;&nbsp; {newData.year} &nbsp;&nbsp; • &nbsp;&nbsp; {newData.maxres} &nbsp;&nbsp; • &nbsp;&nbsp; {newData.age}
                            </p>
                        <h1>{newData.title} &nbsp;&nbsp;
                            <Link to={`/watch/${newData?.id}`}>
                            <button 
                            className="banner__button">Ver ficha técnica</button>
                            </Link></h1>
                        <p className="tv__player__details__content__description"> {newData.synopsis} </p>
                        <p className="tv__player__details__content__director" ><span>Director:</span> {newData.director} </p>
                        <p className="tv__player__details__content__starring"><span>Elenco:</span> {newData.starring} </p>
                        {/* <p className="tv__player__details__content__stats">
                        {newData.published.substr(0, 10)} {" "} • {" "}
                        <ThumbUpSharp />{" "}
                        {newData.likescount}</p> */}
                        </div>
                    ))}
                    </div>
                    </div>
                </div>
                </div>
                
                <div className="tv__videolist">
                    <p>A continuación: {option}</p>
                <div className="tv__videolist__container">
                {isLoading ? (
                <Skeleton type="feed" />
                ) : (
                creation.filter((video, key) => 
                (video.status.toLowerCase()).includes("active")&&(video.genres.toLowerCase()).includes(option)
                ).map((video, key) => (
                    <div className = {`tv__videolist__poster__props`} key={video.id}>
                        <img 
                            key={video.id}
                            id="valor"
                            onClick={(e) => setSelected(video.videoId_yt_dmq)}
                            className= {`tv__videolist__poster`}
                            src={`${base_url}${video.videoId_yt_dmq}${end_url}`} alt={video.title}
                        />
                        <p className= {`tv__videolist__poster__title`}>{video.title}</p>
                    
                        <div className = {`tv__videolist__poster__details`}>
                            {/* <h2 className= {`tv__videolist__poster__title`}>{video.title}</h2> */}
                            {/* <p className= {`tv__videolist__poster__stats`} >
                            {video.published.substr(0, 10)} {" "} • {" "}
                            <ThumbUpSharp />{" "}
                            {video.likescount}</p> */}
                            <p className= {`tv__videolist__poster__genres`}>{video.genres} 
                            &nbsp;&nbsp; • &nbsp;&nbsp; 
                            <FlagSharp style={{fontSize:"20px", paddingRight:"5px", opacity:"0.7"
                            }} />
                            {video.country} 
                            &nbsp;&nbsp; • &nbsp;&nbsp;
                            <TimelapseSharp style={{fontSize:"20px", paddingRight:"5px", opacity:"0.7"
                            }}/>
                            00:{video.duration} </p>
                        </div>

                        <div className = {`tv__videolist__poster__synopsis`}>
                        <p>{truncate(video?.synopsis, 120)}</p>
                        </div>
                    </div>
                    ))
                    )}
                </div>
            </div>
            </div>
            <Social />
            <FooterLinks />
            <Copyright />
            <GoToTop />
            <NavBottom />
        </main>
    )
};

export default TvScreen;
