const requests = {
    fetchSubidosHoy: `/subidos`,
    fetchTodo: `/todo`,
    fetchNoticias: `/noticias`,
    fetchHumor: `/humor`,
    fetchTodosTop: `/todotop`,
    fetchCreador2: `/creador2`,
    fetchCreador3: `/creador3`,
    fetchCreador5: `/creador5`,
    fetchCreador6: `/creador6`,
    fetchCreador10: `/creador10`,
    fetchCreador12: `/creador12`,
    fetchCreador16: `/creador16`,
    fetchCreador18: `/creador18`,
    fetchCreador19: `/creador19`,
    fetchCreador20: `/creador20`,

}

export default requests;