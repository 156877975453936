import "./DropdownMenu.css";
import React, { useState, useEffect, useRef } from 'react';
import { Link } from "react-router-dom";
import { CSSTransition } from 'react-transition-group';
import { auth } from "../firebase";


function NavItem(props) {
    const [open, setOpen] = useState(false);

    const handleButtonClick = () => {
      setOpen(!open);
    };
  
    return (
      <li className="nav-item">
      <button className="icon-button" onClick={handleButtonClick} type="button">
        {props.icon}
      </button>
      {open && props.children}
      </li>
    );
  }

function DropdownMenu() {
    const [activeMenu, setActiveMenu] = useState('main');
    const [menuHeight, setMenuHeight] = useState(null);
    const dropdownRef = useRef(null);

    useEffect(() => {
      setMenuHeight(dropdownRef.current?.firstChild.offsetHeight)
    }, [])
  
    /* function calcHeight(el) {
      const height = el.offsetHeight;
      setMenuHeight(height);
    } */
  
    function DropdownItem(props) {
      return (
        <a href={props.href} className="menu-item" onClick={() => props.goToMenu && setActiveMenu(props.goToMenu)}>
          {props.children}
        </a>
      );
    }
  
    return (
      <div className="dropdown" style={{ height: menuHeight }} ref={dropdownRef}>
  
        <CSSTransition
          >
          <div className="menu">

          <Link to="/profile" style={{ textDecoration: 'none' }}>
            <DropdownItem >Mi Perfil</DropdownItem>
          </Link>

          {/* <Link to="/personalize" style={{ textDecoration: 'none' }}>
            <DropdownItem >Personalizar mi contenido</DropdownItem>
          </Link> */}
          
            {/* <DropdownItem>
                <p onClick={() => {
                auth.signOut();
                window.location.reload();
                }}>Cambiar Usuario</p>
            </DropdownItem> */}
            
            <DropdownItem>
                <p onClick={() => {
                auth.signOut();
                window.location.reload();
                }}>Salir de TELEBOING</p>
            </DropdownItem>
  
          </div>
        </CSSTransition>
  
        
      </div>
    );
  }

  export {DropdownMenu, NavItem};