import React/* , { useContext } */ from 'react';
/* import { SearchContext } from '../context/SearchContext'; */
import "./SearchScreen.css";
import Nav from "../components/Nav";
import RecommendedVideos from "../components/RecommendedVideos";
import FooterLinks from "../components/Footer/FooterLinks";
import Social from "../components/Footer/SocialLinks";
import Copyright from "../components/Footer/Copyright";
import NavBottom from "../components/NavBottom";
import GoToTop from '../GoToTop';

function SearchScreen() {
    /* const [inputSearch, setInputSearch] = useContext(SearchContext); */

    return (
        <div>
            <Nav />
            <div className="NoSearch__msg"><p style= {{color: "white"}}>Toda gran búsqueda empieza con una palabra. Por favor ingresa una para empezar. Mientras tanto disfruta de todo nuestro contenido:</p></div>
            <div className="search__container">
                <RecommendedVideos  setInputSearch="2021" />
            </div>
            <Social />
            <FooterLinks />
            <Copyright />
            <GoToTop />
            <NavBottom />
        </div>
    )
}

export default SearchScreen;
