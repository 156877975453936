import React from 'react';
import { useHistory } from "react-router-dom";
import "./LoginScreen.css";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { ArrowDownwardSharp } from '@material-ui/icons';
import 'react-tabs/style/react-tabs.css';
import Banner from "../../components/tv/Banner"
import FooterLinks from "../../components/Footer/FooterLinks";
import Social from "../../components/Footer/SocialLinks";
import Copyright from "../../components/Footer/Copyright";
import { Helmet } from "react-helmet-async";
import GoToTop from '../../GoToTop';
import Faq from "../../components/Faq";


function LoginScreen() {

    const features = [
        {
            id: 1,
            imgUrl: "https://firebasestorage.googleapis.com/v0/b/teleboing.appspot.com/o/resources%2Fcontent%2Fteleboing-feat2.webp?alt=media&token=fadd7b0b-4c05-49f4-92ae-ca6e9c21a3aa",
            description: "Personaliza el contenido que quieras ver en tu propia lista de reproducción."
        },
        {
            id: 2,
            imgUrl: "https://firebasestorage.googleapis.com/v0/b/teleboing.appspot.com/o/resources%2Fcontent%2Fteleboing-feat1.webp?alt=media&token=4674240a-fc0d-43a3-9f26-33aabfd1ccbe",
            description: "Recibe alertas cada vez que se publique contenido que encaje con tu perfil."
        },
        {
            id: 3,
            imgUrl: "https://firebasestorage.googleapis.com/v0/b/teleboing.appspot.com/o/resources%2Fcontent%2Fteleboing-feat3.webp?alt=media&token=7dd986d2-637f-4e79-aecd-bab9701e2451",
            description: "Accede a cualquier hora desde tu computadora, teléfono celular y TV."
        },
        {
            id: 4,
            imgUrl: "https://firebasestorage.googleapis.com/v0/b/teleboing.appspot.com/o/resources%2Fcontent%2Fteleboing-feat4.webp?alt=media&token=7599db98-2eeb-4d6c-9326-86747c1e2b97",
            description: "Múltiples desenlaces de una misma historia. Para que decidas el camino."
        }
    ]

    const history = useHistory();

    const signIn = (e) => {
        e.preventDefault();
        history.push("/signup");
    };

    return (
        <>
            <Helmet>

                <title>Teleboing: cortometrajes en tu plataforma de streaming gratis.</title>

                <meta
                    name="title"
                    content="Teleboing: cortometrajes en tu plataforma de streaming gratis."
                />

                <meta
                    name="description"
                    content="Plataforma de streaming de cortometrajes gratis y bajo suscripción que ofrece acceso rápido, sencillo y personalizado a una amplia variedad de cortometrajes, películas, documentales, programas originales y series basadas en historias reales, drama, comedia, acción, terror y suspenso, y que pueden ser vistas en cualquier pantalla de cualquier dispositivo conectado a internet. Todo lo que quieras ver, sin límites, sin publicidad, a un costo muy accesible."
                />

                <link
                    rel='canonical'
                    href='/'
                />

                <meta
                    name='image'
                    content="https://firebasestorage.googleapis.com/v0/b/teleboing.appspot.com/o/resources%2Fcontent%2Fteleboing1-feat1-1.webp?alt=media&token=0a9957dc-c2db-4f29-b3cf-a8b077f80d04"
                />

            </Helmet>

            <div className="loginScreen">
                <Banner/>
                <header
                    className="loginScreen__box__s1__banner"
                    style={{
                        zIndex: "-1",
                        top: "0",
                        width: "100%",
                        height: "auto",
                        margin: "0",
                        backgroundSize: "cover",
                        backgroundImage: `linear-gradient(to top, rgba(0,0,0,0.3), rgba(0,0,0,1)), linear-gradient(to bottom, rgba(0,0,0,0.1), rgba(0,0,0,1)), url("https://firebasestorage.googleapis.com/v0/b/teleboing.appspot.com/o/resources%2Fcontent%2Fteleboing_cover08.webp?alt=media&token=f5d12ee1-3d72-4f00-8f20-e2687369315a")`,
                        backgroundPosition: "center center",
                    }}
                >
                    <div className="loginScreen__box__s1">
                        <div className="loginScreen__box__s1__content">
                            <h1>Convierte los momentos de espera en una experiencia increíble</h1>

                            <p>Suscríbete a Teleboing y disfruta de miles de películas cortas en tu idioma. Alegría, suspenso, amor e intriga en dosis impactantes. Sólo US$ 1<span>.99</span> al mes. ¡No te conformes con lo mismo de siempre!</p>

                            {/* <span>No te conformes con lo mismo de siempre. Suscríbete ahora y transforma esos momentos aburridos en pequeñas dosis de entretenimiento.</span> */}
                            <ArrowDownwardSharp />

                            <button onClick={signIn} >entra a teleboing</button>
                        </div>
                    </div>
                </header>
                <div className="loginScreen__box__s2">

                    <div className="loginScreen__box__s2__content">
                        <h2>IMAGINA QUE LAS HISTORIAS QUE MÁS TE INTERESAN SE CONVIERTAN EN SERIES Y PELÍCULAS
                        </h2>
                        <p>Teleboing es tu plataforma de entretenimiento por streaming de películas cortas de todo el mundo en tu idioma.</p>
                    </div>
                </div>
                <div className="loginScreen__box__s4">
                    {
                    features.map(({ id, imgUrl, description }) =>
                        <div key={id}>
                            <LazyLoadImage
                                key={id}
                                src={imgUrl}
                                style={{ width: "100%", height: "auto", padding: "20px 0", margin: "0", transition: "all 0.8s ease" }}
                                effect='blur'
                                alt={description}
                                placeholderSrc={process.env.PUBLIC_URL + "/android-chrome-192x192.png"} />
                            <h3>{description}</h3>
                        </div>
                    )
                    }
                </div>

                <Faq/>

                <div
                    style={{ backgroundColor: "#111", width: "auto" }}
                    className="loginScreen__box__s6__content">
                    <p>Regálate los mejores 15 minutos de tu día.</p>
                    <ArrowDownwardSharp />
                    <button onClick={signIn} >entra a TELEBOING</button>
                </div>

                <Social />
                <FooterLinks />
                <Copyright />
                <GoToTop />
            </div>
        </>
    )
}

export default LoginScreen;