import React, { useState } from 'react';
import './OriginalsRow.css';
import { original } from "../originalContent";
import { Link } from "react-router-dom";

function OriginalsRow() {
    const [selectOriginal, setSelectOriginal] = useState("");

    return (
    <div className="originals">
        <div className="originals__container">
            <h2 className="originals__title">Series Originales &gt;</h2>
            <p className="originals__explora">Explora presionando SHIFT + rueda del mouse</p>
        </div>

        <div className="originals__posters" title="">
        {original.map((poster, key) => (
            <div className = {`originals__poster__props`} key={poster.id}>
                <Link to={`/originals/${poster.videoId}`}>
                <img 
                    key={poster.id}
                    id={poster.channelId}
                    value={selectOriginal}
                    className= {`originals__poster`}
                    src={poster.thumbnailurl} 
                    title={poster.title}
                    alt={poster.title}
                />
                </Link>
            </div>
            ))}
        </div>
    </div>
    );
}

export default OriginalsRow;